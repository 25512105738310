import React, { useContext, useEffect, useState } from 'react';
import { capitalizeNames, formatterPhone as utilsFormatterPhone } from '../../../../../../@common/utils/formatters/formatter';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import {
  Task,
  TaskResponse,
  TaskState
} from '../../../../../../gql/graphql';
import { TaskContext } from '../Insurance';
import ModalFinishTask from './ModalFinishTask';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import { Collapse, Form } from 'antd';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import ButtonSubmit from 'components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';

interface IForm {
  titleCompany: string;
  name: string;
  phone: string;
  email: string;
}

interface Props {
  setStep: Function;
  closeTask: Function;
}

const Step2 = ({ setStep, closeTask }: Props) => {
  const [form] = Form.useForm();
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { fetching, data: isCreateResponse, execute: createResponseTask } = useCreateResponseTask();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [responseInfo, setResponseInfo] = useState<TaskResponse | any>();
  const [responseDoc, setResponseDoc] = useState<TaskResponse | any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    if(isCreateResponse) 
      setListFileUpload([]);
  }, [isCreateResponse]);

  useEffect(() => {
    if (responseInfo && responseInfo.state !== TaskState.RequestError)
      form.setFieldsValue(responseInfo.metadata);
  }, [responseInfo])

  useEffect(() => {
    const _response = taskResponses?.find((item) => item.step === 2 && item.key === 'insurance-information');
    setResponseInfo(_response);

    const _response2 = taskResponses?.find((item) => item.step === 2 && item.key === 'insurance-doc');
    setResponseDoc(_response2);

    setTaskCompleted(_response?.state === TaskState.Approved && _response2?.state === TaskState.Approved);
  }, [taskResponses]);

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const onFinish = async (values: IForm) => {
    values.name = capitalizeNames(values.name);

    await createResponseTask({
      _id: responseInfo?._id,
      task: task._id,
      key: `insurance-information`,
      label: `We’ll reach out and get insurance documents`,
      step: 2,
      metadata: values,
      state: TaskState.Review
    });

    getTaskResponses({ requestPolicy: 'network-only' });

    if (responseDoc)
      setModalFinishTask(true);
  }

  const onSubmitDoc = async () => {
    await createResponseTask({
      _id: responseDoc?._id,
      task: task._id,
      key: `insurance-doc`,
      label: `I want to upload my own insurance docs`,
      step: 2,
      metadata: {},
      state: TaskState.Review,
      files: listFileUpload.filter((item: any) => item.size)
    });

    getTaskResponses({ requestPolicy: 'network-only' });

    if (responseInfo)
      setModalFinishTask(true);
  }

  const filesPendings = responseDoc?.files?.filter((file: any) => file?.state !== 'request_error')

  return (
    <section className='insurance-step-2 '>
      <div className="section-1">
        <h3 className="h3">
          Who is your current insurance agent?
        </h3>
        <div className="mt-10">
          <p className="h4 h4-w4">
            We’ll reach out and get insurance documents
          </p>
        </div>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask
            taskId={task._id}
            componentDataError={(textDataError: any) => (
              <div className='text-data'>
                <div className='row'>
                  <span>Insurance company name</span>
                  <b>{textDataError.insuranceCompany}</b>
                </div>
                <div className='row'>
                  <span>Contact name</span>
                  <b>{textDataError.name}</b>
                </div>
                <div className='row'>
                  <span>Phone number</span>
                  <b>{textDataError.phone}</b>
                </div>
                <div className='row'>
                  <span>Email address</span>
                  <b>{textDataError.email}</b>
                </div>
              </div>
            )}
          />
        }

        <div
          id={responseInfo?._id}
          style={{ position: 'relative' }}
          className={`mt-40 title-company-step-1 title-company-step-1-${responseInfo?.state}`}
        >
          {responseInfo?.state && responseInfo?.state !== TaskState.Review && <StateTag state={responseInfo?.state} />}
          {responseInfo?.state && responseInfo?.state === TaskState.Review && <StateTag state={responseInfo?.state} isLabelLocked />}

          <Form
            name="edit"
            autoComplete="off"
            requiredMark="optional"
            colon={false}
            form={form}
            onFinish={onFinish}
            disabled={responseInfo && responseInfo?.state !== TaskState.RequestError}
          >
            <Form.Item
              label=""
              name="insuranceCompany"
              className="titleCompany"
              rules={[{ required: true, message: '' }]}
            >
              <InputBorderNone
                placeholder="Insurance company name"
                className="bold"
              />
            </Form.Item>
            <Form.Item
              label="Contact name (if different from lender)"
              name="name"
              className="name"
              rules={[{ required: true, message: '' }]}
            >
              <InputBorderNone
                placeholder="Edgar"
                height={20}
                className="bold-lith"
              />
            </Form.Item>

            <Form.Item
              label="Phone number"
              name="phone"
              className="phone"
              rules={[
                { required: true, message: '' },
                { min: 12, message: '' }
              ]}
            >
              <InputBorderNone
                placeholder="329-230-2390"
                height={20}
                className="bold-lith"
                onChange={formatterPhone}
              />
            </Form.Item>

            <Form.Item
              label="Email address"
              name="email"
              className="email"
              rules={[{ required: true, type: 'email', message: ' ' }]}
            >
              <InputBorderNone
                type="email"
                placeholder="ricardo@mervetscoolbiz.com"
                height={20}
                color={emailError ? 'error' : 'bold-lith'}
                onBlur={(e: any) => setEmailError(e.target.value && form.getFieldError('email').length > 0)}
              />
            </Form.Item>

            {responseInfo?.state !== TaskState.Approved &&
              <Form.Item className="submit" shouldUpdate>
                {() => (
                  <ButtonSubmit
                    disabled={
                      responseInfo?.state === TaskState.Review ||
                      !form.getFieldsError().every((fieldErr) => fieldErr.errors.length === 0) ||
                      !form.isFieldsTouched() ||
                      !Object.values(form.getFieldsValue()).every(value => value)
                    }
                    loading={fetching}
                    modalConfirm={{ show: true }}
                    onClick={form.submit}
                  >
                    Submit for review
                  </ButtonSubmit>
                )}
              </Form.Item>
            }
          </Form>
        </div>
        <div className='mt-10'>
          <Collapse
            ghost
            expandIconPosition="right"
            expandIcon={(collapse) => !collapse.isActive ? <DownOutlined /> : <UpOutlined />}
            items={[
              {
                key: '1',
                label: 'I WANT TO UPLOAD MY OWN INSURANCE DOCS',
                children:
                  <UploadDocs
                    numberOfFiles={10}
                    listFileUpload={
                      filesPendings?.length > 0 ? 
                        [...filesPendings, ...listFileUpload.filter((item: any) => item.size)] : 
                        listFileUpload
                    }
                    setListFileUpload={setListFileUpload}
                    button={
                      <ButtonSubmit
                        disabled={
                          listFileUpload?.length === 0 || responseDoc?.state === TaskState.Review || responseDoc?.state === TaskState.Approved
                        }
                        loading={fetching}
                        modalConfirm={{ show: true }}
                        onClick={onSubmitDoc}
                      >
                        Upload docs to submit
                      </ButtonSubmit>
                    }
                  />,
              }]} />
        </div>
      </div>

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required' />

      <ModalFinishTask
        visible={modalFinishTask}
        setVisible={setModalFinishTask}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step2;
