import React, { useContext, useEffect, useState } from 'react';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import { AutoComplete, Button, Divider, Form, Input, Space } from 'antd';
import { File, Task, TaskState } from 'gql/graphql';

import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import {
  formatterSsn as utilsFormatterSsn,
  formatterPhone as utilsFormatterPhone,
  formatterDate as utilsFormatterDate
} from '../../../../../../@common/utils/formatters/formatter';
import ModalSubmitProfile from './ModalSubmitProfile';
import { Ownership } from './CardOwnership';
import { useDeleteFileResponse } from '../../../../../../services/task/mutations';
import UploadDocs from '../../../../../../components/Atoms/UploadDocs/UploadDocs';
import { InputAutocompleteAddress } from 'components/Atoms/Inputs/InputAutocompleteAddress/InputAutocompleteAddress';
import iconSpinnerPurple from 'assets/images/spinner-purple.svg';
import checkGreenSvg from 'assets/images/check-green.svg';
import { useGetTaskResponseComments } from 'services/task/querys';

interface Props {
  ownership: Ownership;
  hiddenTextEdit?: boolean;
  disabledForm?: boolean;
  hiddenSubmitChanges?: boolean;
  hiddenColorsState?: boolean;
  isModalSubmit?: boolean;
  saveEdit?: string;
  fetching: boolean;
  saveChanges: Function;
  ownerships?: Ownership[];
  otherButton?: any;
  stepVerify?: boolean;
  verifyProfile: any;
  isExpand?: any;
  createResponse?: any;
}

const FormEdit = ({
  ownership,
  hiddenTextEdit,
  disabledForm,
  hiddenSubmitChanges,
  hiddenColorsState,
  saveChanges,
  isModalSubmit,
  saveEdit,
  fetching,
  ownerships,
  otherButton,
  stepVerify,
  verifyProfile,
  isExpand,
  createResponse
}: Props) => {
  const [form] = Form.useForm();
  const { fetching: loadingDeleteFile, execute: _deleteFile } = useDeleteFileResponse();
  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId: ownership.taskResponse.task._id, state: 'pending' }
  });

  const [listFileUpload, setListFileUpload] = useState<any>([]);
  const [isTextDataError, setTextDataError] = useState<boolean>();
  const [isEditVerify, setEditVerify] = useState<boolean>(false);
  const [isChangeExpand, setIsChangeExpand] = useState<boolean>(false);

  useEffect(() => {
    if (taskComments) {
      const issue = taskComments?.find(item => item.taskResponse._id === ownership.taskResponse._id);
      let textDataError = issue?.metadata?.dataOrDocumentSelected.includes('Text/data');

      if ((!issue || textDataError === false) && isExpand !== isChangeExpand) {
        form.setFieldsValue(getInitialValues());
      }

      setTextDataError(textDataError);

      //Se soluciona el error para poder editar diferentes formularios sin que se borre info
      if (isExpand !== isChangeExpand)
        setIsChangeExpand(isExpand);
    }

    setListFileUpload([]);
  }, [isExpand, taskComments]);

  useEffect(() => {
    if (createResponse) {
      setListFileUpload([]);
    }
  }, [createResponse])

  const getInitialValues = () => {
    return ({
      ...ownership,
      dateBirth: utilsFormatterDate(ownership.dateBirth),
      ssn: utilsFormatterSsn(ownership.ssn),
      phone: utilsFormatterPhone(ownership.phone)
    })
  };

  const formatterSsn = (e: any) => {
    form.setFieldValue('ssn', utilsFormatterSsn(e.target.value));
  };

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const formatterDate = (e: any) => {
    form.setFieldValue('dateBirth', utilsFormatterDate(e.target.value));
  };

  const isFormDisabled = () => {
    const isFieldsValidate = Object.values(form.getFieldsValue()).every(
      (value) => !!value
    );

    return (
      !isFieldsValidate ||
      form.getFieldsError().filter(({ errors }) => errors.length)?.length > 0 ||
      (listFileUpload?.length === 0 && ownership?.files?.length === 0)
    );
  };

  const deleteFile = (file: File) => {
    _deleteFile({ fileKey: file.key, taskResponseId: ownership.key });
    saveChanges(form.getFieldsValue(), null, TaskState.Incomplete);
  };

  const isDisabledItem = () => {
    return disabledForm ||
      (!!ownership._id && ownership.state === TaskState.Incomplete && !isEditVerify && isValidForm(ownership, ownership.files));
  }

  const isValidForm = (values: any, files: any) => {
    return (values.address || ownership.address) &&
      (values.dateBirth || ownership.dateBirth) &&
      (values.email || ownership.email) &&
      (values.phone || ownership.phone) &&
      (values.ssn || ownership.ssn) &&
      (files?.length > 0 || ownership?.files?.length > 0) &&
      !!(values.ownership || ownership.ownership);
  }

  const addCheckIsDataSuccess = (label: any) => {
    return (
      <div>
        {!isTextDataError && ownership.state === TaskState.RequestError &&
          <img width={12} src={checkGreenSvg} alt="check" />
        }{' '}
        <span>{label}</span>
      </div>
    );
  }

  const issue = taskComments?.find(item => item.taskResponse._id === ownership.taskResponse._id);
  let textDataError = issue?.metadata?.dataOrDocumentSelected.includes('Text/data');

  return (
    <Form
      form={form}
      name="edit"
      autoComplete="off"
      className="form-edit"
      requiredMark="optional"
      colon={false}
    >

      <Form.Item
        label={addCheckIsDataSuccess("SSN" + (form.getFieldValue("ssn") ? "" : "*"))}
        name="ssn"
        className="ssn"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is missing' },
          { min: 11, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="239-23-2323"
          height={20}
          style={{ marginLeft: !isTextDataError && ownership.state === TaskState.RequestError ? 15 : 0 }}
          className="bold-lith"
          disabled={isDisabledItem()}
          onChange={formatterSsn}
        />
      </Form.Item>

      <Form.Item
        label={addCheckIsDataSuccess("Date of birth" + (form.getFieldValue("dateBirth") ? "" : "*"))}
        name="dateBirth"
        className="datebirth"
        validateTrigger="onBlur"
        rules={[{ required: true, message: 'Field is missing' }]}
      >
        <InputBorderNone
          placeholder="12/31/1997"
          height={20}
          style={{ marginLeft: !isTextDataError && ownership.state === TaskState.RequestError ? 15 : 0 }}
          className="bold-lith"
          disabled={isDisabledItem()}
          onChange={formatterDate}
        />
      </Form.Item>
      <Form.Item
        label={addCheckIsDataSuccess("Phone number" + (form.getFieldValue("phone") ? "" : "*"))}
        name="phone"
        className="phone"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is missing' },
          { min: 12, message: 'Field is missing' }
        ]}
      >
        <InputBorderNone
          placeholder="329-230-2391"
          height={20}
          style={{ marginLeft: !isTextDataError && ownership.state === TaskState.RequestError ? 15 : 0 }}
          className="bold-lith"
          disabled={isDisabledItem()}
          onChange={formatterPhone}
        />
      </Form.Item>

      <Form.Item
        label={addCheckIsDataSuccess("Email address" + (form.getFieldValue("email") ? "" : "*"))}
        name="email"
        className="email"
        rules={[{ required: true, type: 'email', message: 'Invalid email address' }]}
      >
        <InputBorderNone
          type="email"
          placeholder="email@gmail.com"
          height={20}
          style={{ marginLeft: !isTextDataError && ownership.state === TaskState.RequestError ? 15 : 0 }}
          disabled={isDisabledItem()}
          color={'bold-lith'}
        />
      </Form.Item>

      {taskComments &&
        <Form.Item
          shouldUpdate
          label={addCheckIsDataSuccess("Home address" + (form.getFieldValue("address") ? "" : "*"))}
          name="address"
          className="address"
          validateDebounce={500}
          rules={[
            { required: true, message: 'Field is missing' },
            { min: 3, message: 'Field is missing' },
            { max: 50, message: 'Field is missing' }
          ]}
        >
          <InputAutocompleteAddress
            disabled={isDisabledItem()}
            style={{
              marginLeft: !isTextDataError && ownership.state === TaskState.RequestError ? 15 : 0
            }}
            initialValue={issue && textDataError ? '' : ownership.address}
            onSelect={({ formatted_address }: any) => {
              form.setFieldValue('address', formatted_address);
            }}
          />
        </Form.Item>
      }

      {!hiddenTextEdit && (
        <span className="textedit textedit-ab">
          {saveEdit === 'loading' && (
            <>
              <img src={iconSpinnerPurple} alt="upload" className="upload" width={10} />
              <span style={{ color: '#A278B3' }}>SAVING</span>
            </>
          )}
          {saveEdit === 'saved' && (
            <>
              <img src={fileCheckSvg} alt="upload" className="upload" width={12} />
              <span style={{ color: '#6B956E' }}>EDITS SAVED</span>
            </>
          )}
        </span>
      )}

      <div className="identification">
        <label>{`Driver’s License`}</label>
        {ownership.files?.filter(file => file.state !== 'request_error').map((file: File, index: number) => (
          <InputUploadedFile
            key={index}
            file={file}
            isActionSeeFile
            deleteFile={
              ownership.state === TaskState.Incomplete ||
                ownership.state === TaskState.Draft
                ? deleteFile : undefined
            }
            loadingDeleteFile={loadingDeleteFile}
            state={
              !hiddenColorsState
                ? ownership.state === TaskState.Draft? ownership.state: (file.state as any)
                : TaskState.Draft
            }
          />
        ))}
        {(
          !ownership.files ||
          ownership.files.length === 0 ||
          ownership.files[ownership.files.length - 1].state === 'request_error'
        ) && (
            <UploadDocs
              numberOfFiles={1}
              listFileUpload={listFileUpload}
              setListFileUpload={setListFileUpload}
              disabled={isDisabledItem() || fetching}
            />
          )}
      </div>
      {otherButton &&
        <Button className="button button-link-border submit">
          <b>{otherButton.b}</b>
          <span>{otherButton.span}</span>
        </Button>
      }
      {!isEditVerify && stepVerify && ownership._id && isValidForm(ownership, ownership.files) && ownership.state === TaskState.Incomplete &&
        <div className='submit verify-submits'>
          <Button
            className="button button-submit button-yellow"
            onClick={verifyProfile}
          >
            Verify profile
          </Button>
          <Button
            className="button button-submit button-edit"
            onClick={() => setEditVerify(true)}
          >
            Edit profile
          </Button>
        </div>
      }

      {
        !hiddenSubmitChanges &&
        //Condición negativa de los botones Verify profile y Edit profile
        !(!isEditVerify && stepVerify && ownership._id && isValidForm(ownership, ownership.files) && ownership.state === TaskState.Incomplete) &&
        (
          <Form.Item className="submit" shouldUpdate>
            {() =>
              isModalSubmit ? (
                <ModalSubmitProfile
                  ownerships={ownerships}
                  ownerState={ownership.state as TaskState}
                  fetching={fetching}
                  disabled={isFormDisabled()}
                  onClick={() => saveChanges(form.getFieldsValue(), listFileUpload)}
                />
              ) : (
                <Button
                  className="button button-submit"
                  loading={fetching}
                  disabled={isFormDisabled()}
                  onClick={() => saveChanges(form.getFieldsValue(), listFileUpload)}
                >
                  Save changes
                </Button>
              )
            }
          </Form.Item>
        )}
    </Form>
  );
};
export default FormEdit;
