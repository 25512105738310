import React, { useContext, useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from '../../../../../../gql/graphql';
import { TaskContext } from '../NewTask';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import ModalFinish from '../ModalFinish';
import CardUploadResponse from 'components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import AddAnotherDoc from 'scenes/Private/components/AddAnotherDoc/AddAnotherDoc';

interface Step1Props {
  closeTask: Function;
}
const Step1 = ({ closeTask }: Step1Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { execute: createResponseTask, fetching } = useCreateResponseTask();

  const [response, setResponse] = useState<TaskResponse | any>();
  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);

    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const submit = async (files: any) => {
    let payload: any = {
      key: 'doc-' + task._id,
      label: task.name,
      step: 1,
      metadata: {},
      state: TaskState.Review,
      task: task?._id,
      files
    };

    if (response) payload._id = response._id;

    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
  };

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          {task.name}
        </h3>
        <p className="h4 h4-w4 mt-5">
          {task.description}
        </p>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} />
        }

        <div id={response?._id} className="mt-60">
          <CardUploadResponse
            title={task.metadata.docName}
            description={task.metadata.docDescription}
            files={response?.files}
            state={response?.state}
            limitFiles={1}
            submit={{
              title: 'Submit for review',
              disabled: response?.state === TaskState.Review,
              loading: fetching,
              location: 'outside',
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => submit(files)
            }}
          />
        </div>
      </div>

      <AddAnotherDoc taskId={task._id} taskState={task.state}/>

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required' styleFixed />

      <ModalFinish
        visible={modalFinish}
        setVisible={setModalFinish}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step1;
