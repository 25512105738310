import { useQuery } from 'urql';
import { GET_LOANS_BY_OWNER, GET_LOAN_BY_ID, GET_LOAN_BY_OWNER, GET_LOAN_DOCUMENTS } from './graphql';
import { GetLoanByIdQuery, GetLoanByOwnerQuery, GetLoansByOwnerQuery, QueryGetLoanByIdArgs, QueryGetLoanDocByLoanArgs, QueryGetLoansByOwnerArgs, GetLoanDocByLoanQuery } from 'gql/graphql';
import { UseQueryInterface } from '../../../@common/utils/interfaces/use-query.interface';

export const useGetLoanById = (variables: QueryGetLoanByIdArgs, query?: any) => {
  const [{ data, fetching, error }] = useQuery<GetLoanByIdQuery>({
    query: query || GET_LOAN_BY_ID,
    variables
  });
  return { data: data?.GetLoanById, fetching, error };
}

export const useGetLoanByOwner = (variables?: {}, query?: any) => {
  const [{ data, fetching, error }] = useQuery<GetLoanByOwnerQuery>({
    query: query || GET_LOAN_BY_OWNER,
    variables
  });
  return { data: data?.GetLoanByOwner, fetching, error };
}

export const useGetLoansByOwner = (vars?: UseQueryInterface<QueryGetLoansByOwnerArgs>) => {
  const [{ data, ...others }, reexecute] = useQuery<GetLoansByOwnerQuery>({
    query: vars?.query || GET_LOANS_BY_OWNER,
    ...vars
  });
  return {
    data: data?.GetLoansByOwner,
    reexecute,
    ...others
  };
};

export const useGetLoanDocByLoan = (vars?: UseQueryInterface<QueryGetLoanDocByLoanArgs>) => {
  const [{ data, ...others }, reexecute] = useQuery<GetLoanDocByLoanQuery>({
    query: vars?.query || GET_LOAN_DOCUMENTS,
    ...vars
  });
  return {
    data: data?.GetLoanDocByLoan,
    reexecute,
    ...others
  };
};