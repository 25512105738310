import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import errorSvg from 'assets/images/alert-circle-bold.svg';
import SectionNewBank from './SectionNewBank/SectionNewBank';
import CardStateAccount from './CardStateAccount/CardStateAccount';
import InfoExpand from 'components/Atoms/Dropdown/InfoExpand/InfoExpand';
import { useGetTaskResponseComments, useGetTaskResponsesByTask } from 'services/task/querys';
import { TaskContext } from '../MinimumLiquidity';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import ModalFinalReview from './ModalFinalReview';
import { formatterCurrency } from '@common/utils/formatters/formatter';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';

const Step2 = ({
  totalBalance,
  limitBalance,
  handlerCloseModalTask,
  handlerSetTotalBalance
}: any) => {
  const monthBefore = moment().subtract(2, 'month');
  const monthNow = moment().subtract(1, 'month');
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses } = useGetTaskResponsesByTask({ variables: { findResponsesByTask: { taskId: task._id } } });

  const [bankAccounts, setBankAccounts] = useState<TaskResponse[]>();
  const [pensionAccounts, setPensionAccounts] = useState<TaskResponse[]>();
  const [cashAccounts, setCashAccounts] = useState<TaskResponse[]>();
  const [otherAccounts, setOtherAccounts] = useState<TaskResponse[]>();
  const [isModalFinalReview, setModalFinalReview] = useState<boolean>(false);
  const [errorTotalBalance, setErrorTotalBalance] = useState<number>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    let totalBalance = { draft: 0, review: 0, error: 0, success: 0 };

    if (taskResponses && taskResponses?.length > 0) {
      
      let responsesStep2 = taskResponses.filter(item => item.step === 2);
      setTaskCompleted(responsesStep2.length > 0 && responsesStep2.every(item => item.state === TaskState.Approved));

      taskResponses.map((account: any) => {
        if (account.step === 2) {
          if (account.state === TaskState.Approved)
            totalBalance.success += account.metadata.balance;
          else if (account.state === TaskState.RequestError)
            totalBalance.error += (account.metadata.balance - (account.metadata.rejectedAmount || 0));
          else if (account.state === TaskState.Review)
            totalBalance.review += account.metadata.balance;
        }

        return account;
      });

      setBankAccounts(
        taskResponses.filter((response) => response.key === 'account_bank') as any
      );

      setPensionAccounts(
        taskResponses.filter((response) => response.key === 'accounts_pension') as any
      );

      setCashAccounts(
        taskResponses.filter((response) => response.key === 'accounts_cash') as any
      );

      setOtherAccounts(
        taskResponses.filter((response) => response.key === 'accounts_others') as any
      );
    }

    let totalEror = totalBalance.error >= limitBalance ? limitBalance : totalBalance.error;
    setErrorTotalBalance(totalEror);
    handlerSetTotalBalance(totalBalance);
  }, [taskResponses]);

  const handlerBalanceDraft = (balanceDraft: number) => {
    handlerSetTotalBalance({ ...totalBalance, draft: balanceDraft });
  };

  const handlerCreateAccount = () => {
    let sum = totalBalance.draft + totalBalance.review + totalBalance.success;
    if (sum >= limitBalance) setModalFinalReview(true);
  };

  const renderReviewAccount = (accounts: any) => {
    const parserState = (state: string) => {
      if (state === TaskState.Approved)
        return { key: 'approved', label: 'APPROVED' };
      else if (state === TaskState.RequestError)
        return { key: 'error', label: 'ERROR' };

      return { key: 'inReview', label: 'IN REVIEW' };
    };

    return (
      accounts &&
      accounts.length > 0 && (
        <div className="mt-10 divider-top">
          <h4 className="h4 h4-w5">Already added accounts</h4>
          {accounts?.map((account: TaskResponse, index: number) => (
            <div key={index} className="mt-20">
              <CardStateAccount
                account={account}
                state={parserState(account.state).key}
                subTitle={
                  <p className="body-card">
                    Closing balance in{' '}
                    {moment(account.createdAt).format('MMMM')}
                  </p>
                }
                text={null}
                label={<StateTag state={account.state} isLabelLocked margin='0 0 10px 0' />}
              />
            </div>
          ))}
        </div>
      )
    );
  };
  
  return (
    <section className='minimum-step-2'>
      <h2 className="h2">Upload docs to use as proof of your $2MM in assets</h2>

      {taskCompleted &&
        <SectionSuccessTask
          title="You've met your minimum liquidity requirement."
          button={{ title: "Go back to dashboard", onClick: () => handlerCloseModalTask(false) }}
        />
      }

      {!taskCompleted &&
        <SectionErrorTask
          taskId={task._id}
          hidden={totalBalance?.success >= limitBalance}
          noteError={
            <h4 className="h4 h4-error">
              <img src={errorSvg} alt="check" className="check" />{' '}
              <span>You need to submit an additional</span>{' '}
              {formatterCurrency(errorTotalBalance)} in minimum liquidity
            </h4>
          }
        />
      }

      <div className="mt-50">
        <SectionNewBank
          accountKey="account_bank"
          title="Bank statements"
          buttonLabel="Add new bank account"
          accounts={bankAccounts}
          limitBalance={limitBalance}
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.
            </p>
          }
        />
      </div>
      <div className="mt-20">{renderReviewAccount(bankAccounts)}</div>
      <div className="mt-50">
        <SectionNewBank
          accountKey="accounts_pension"
          title="Retirement/Pension statements"
          buttonLabel="Add new retirement/pension account"
          accounts={pensionAccounts}
          limitBalance={limitBalance}
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          balancePercentage={0.5}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.{' '}
              <span className="body-regular-w6">
                Only 50% of your balance will apply toward minimum liquidity
              </span>
            </p>
          }
        />
      </div>

      {renderReviewAccount(pensionAccounts)}

      <div className="mt-50">
        <SectionNewBank
          accountKey="accounts_cash"
          title="Cash-equivalent brokerage statements"
          buttonLabel="Add new brokerage account"
          accounts={cashAccounts}
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          limitBalance={limitBalance}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.
            </p>
          }
        />
      </div>

      {renderReviewAccount(cashAccounts)}

      <div className="mt-50" style={{ marginBottom: 20 }}>
        <SectionNewBank
          accountKey="accounts_others"
          title="Other brokerage accounts"
          buttonLabel="Add new brokerage account"
          accounts={otherAccounts}
          handlerBalanceDraft={handlerBalanceDraft}
          handlerCreateAccount={handlerCreateAccount}
          balancePercentage={0.5}
          limitBalance={limitBalance}
          infoExpand={
            <InfoExpand
              title="HAS YOUR BALANCE INCREASED SINCE YOUR LATEST STATEMENT? (OPTIONAL)"
              text={
                <p className="body-card">
                  If your account balance has recently increased, you can use
                  the higher amount to meet this requirement. <br />
                  <br /> Upload your transaction history from the date of your
                  last statement to the current date along with the 2
                  statements. Re-enter the highest balance and we’ll use that
                  for our liquidity calculations.
                </p>
              }
            />
          }
          text={
            <p className="body-regular">
              Upload you{' '}
              <span className="body-regular-w6">
                {monthBefore.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              and{' '}
              <span className="body-regular-w6">
                {monthNow.format('MMM')} {monthNow.format('YYYY')}
              </span>{' '}
              bank account statements for each account you want to use.{' '}
              <span className="body-regular-w6">
                Only 50% of your balance will apply toward minimum liquidity
              </span>
            </p>
          }
        />
      </div>

      {renderReviewAccount(otherAccounts)}

      {!taskCompleted && totalBalance?.success >= limitBalance &&
        <CollapseIssues
          taskId={task._id}
          successBalance={totalBalance?.success}
          limitBalance={limitBalance}
        />
      }

      <ModalFinalReview
        visible={isModalFinalReview}
        setVisible={setModalFinalReview}
        submit={() => handlerCloseModalTask(false)}
      />
    </section>
  );
};
export default Step2;
