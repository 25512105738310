import React, { createContext, useState, useEffect, useContext } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import calcPng from 'assets/images/calculator.png';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { LoanContext } from '../../Dashboard';

const DSCRContext = {
  totalUnits: 0,
  setTotalUnits: () => {},
  vacantUnits: 0,
  setVacantUnits: () => {},
  occupiedUnits: 0,
  setOccupiedUnits: () => {}
}

export const TaskContext = createContext<Task | typeof DSCRContext>(DSCRContext);

interface DscrQuestionsAgreementsProps {
  task?: Task;
  currentStep: number | any;
}
const DscrQuestionsAgreements = ({
  // @ts-ignore
  task = {},
  currentStep

}: DscrQuestionsAgreementsProps) => {
  const loan: Loan | any = useContext(LoanContext);

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(currentStep || 1);

  const [totalUnits, setTotalUnits] = useState<number>(0);
  const [vacantUnits, setVacantUnits] = useState<number>(0);
  const [occupiedUnits, setOccupiedUnits] = useState<number>(0);
  const [response1, setResponse1] = useState<TaskResponse | any>();
  const [dscrValues, setDSCRValues] = useState<any>({
    monthlyRentalIncome: loan.loanData.monthlyRent,
    annualPropertyInsurance: loan.loanData.annualInsurance,
    annualPropertyTaxes: loan.loanData.annualTaxes,
    annualHOAFee: loan.loanData.annualHoa,
    estimatedDSCR: loan.loanData.selectedOption.dscr
  });
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])
  
  useEffect(() => {
    setOccupiedUnits(totalUnits - vacantUnits);
  }, [totalUnits, vacantUnits])

  useEffect(() => {
    if (taskResponses && taskResponses.length > 0) {
      const response = taskResponses.find((item) => item.key === 'market-rent');
      const response1 = taskResponses.find((item) => item.step === 1);
      setResponse1(response1);
      if (response?.metadata) {
  
        const { totalUnits, vacantUnits } = response.metadata;
  
        if (totalUnits !== undefined) setTotalUnits(totalUnits);
        if (vacantUnits !== undefined) setVacantUnits(vacantUnits);
        if (totalUnits !== undefined && vacantUnits !== undefined) {
          setOccupiedUnits(totalUnits - vacantUnits);
        }
      }
    }
  }, [taskResponses]); 

  useEffect(() => {
    if(task && task.metadata &&Object.keys(task.metadata).length > 0) {
      setDSCRValues({
        ...dscrValues,
        estimatedDSCR: task?.metadata?.estimatedDSCR
      });
      setTotalUnits(task?.metadata?.totalUnits || 0);
      setVacantUnits(task?.metadata?.vacantUnits || 0);
      setOccupiedUnits(task?.metadata?.occupiedUnits || 0);
    }
  }, [task])

  const dscrContextValue = {
    totalUnits,
    setTotalUnits,
    vacantUnits,
    setVacantUnits,
    occupiedUnits,
    setOccupiedUnits,
    dscrValues,
    setDSCRValues
  };

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  if (!task) return null;

  return (
    <TaskContext.Provider value={{...task, ...dscrContextValue}}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
          >
            {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
              (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                'Begin Task'
            }
          </Button>
        )}
        header={{
          title: <span>APPROVAL | DSCR & LEASE AGREEMENTS</span>,
          actionLeft: (
            <img
              src={calcPng}
              alt="icon"
              className="paperPng"
            />
          ),
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              Help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        responses={taskResponses}
        stepsTotal={3}
        currentStep={step}
        setStep={setStep}
        footer={
          <div className="text-footer mt-20">
            <p className="placeholder">
              The DSCR for my property is <span className="bold-label">
                {
                  response1 && response1?.metadata?.estimatedDSCR !== undefined ? 
                  response1?.metadata?.estimatedDSCR :
                  loan?.loanData?.selectedOption?.dscr
                }
                </span>
            </p>
          </div>
        }
      >
        <section className="children DscrQuestionsAgreements">
          {step === 1 && <Step1 setStep={setStep} task={task} />}
          {step === 2 && <Step2 setStep={setStep} task={task} />}
          {step === 3 && <Step3 task={task} />}
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default DscrQuestionsAgreements;
