import { Button, Dropdown, MenuProps } from 'antd';
import React, { useState } from 'react';

interface Props {
  items: { key: string, label: any }[];
  label?: string | any;
  onClickSelect: Function;
  disabled?: boolean;
  className?: string;
}

const DropdownBlue = ({ items, label, onClickSelect, disabled, className }: Props) => {

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onClickSelect(e.key);
  };

  return (
    <Dropdown
      className={"DrodownBlue " + className}
      menu={{ items, onClick: handleMenuClick }}
      placement="bottom"
      overlayClassName="DrodownBlue-blue"
      disabled={disabled}
    >
      <Button type="link" className="button button-link-blue">
        {label}
      </Button>
    </Dropdown>
  );
};
export default DropdownBlue;
