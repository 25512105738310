import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import TitleCompany from './TitleCompany';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { Button } from 'antd';

interface Props {
  tasks?: Task[] | any[],
}

const useCardTitleCompany = ({ tasks }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [response, setResponse] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.TITLE_COMPANY);
      setDataTask(data);
      setResponse(data?.responses[0]);

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const isAwaitingAction = dataTask?.state === TaskState.Review && response?.key === 'not-title';
  const isApprovedViewDoc = dataTask?.state === TaskState.Approved && response?.key === 'not-title';

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.TITLE_COMPANY,
    title: <h4 className="h4">Title Company</h4>,
    steps: 1,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    state: dataTask?.state,
    stateRename: isAwaitingAction ? { REVIEW: 'AWAITING ACTION' } : undefined,
    actions: dataTask ? [<TitleCompany key={1} task={dataTask} />] : [],
    text: (
      <div>
        {!isAwaitingAction && !isApprovedViewDoc && (
          <p className="body-card">
            My title company is {' '}
            <span className="body-card-bold-line">
              {response?.metadata?.titleCompany || 'unknown'}
            </span>
          </p>
        )}

        {isAwaitingAction && (
          <>
            <p className="body-card">
              My title company is {' '}
              <span className={`body-card-bold-line body-card-bold-line-${dataTask.state}`}>
                has been sent
              </span>
            </p>
            <div className="box-message-state-review">
              <span>Once payment is submitted, we’ll order your appraisal and notify you.</span>
            </div>
          </>
        )}

        {isApprovedViewDoc && (
          <>
            <p className="body-card">
              My title company is {' '}
              <span className={`body-card-bold-line body-card-bold-line-${dataTask.state}`}>
                has been sent
              </span>
            </p>
          </>
        )}
      </div>
    )
  };

  if (!dataTask)
    return { card: null };

  return {
    card,
    dataTask
  };
};

export default useCardTitleCompany;