import { RequestPolicy, useQuery } from 'urql';
import {
  GET_TASKS_BY_LOAN,
  GET_TASK_RESPONSES_BY_TASK,
  GET_TASK_RESPONSE_COMMENTS
} from './graphql';
import { UseQueryInterface } from '../../../@common/utils/interfaces/use-query.interface';
import {
  GetTaskResponseCommentsQuery,
  GetTaskResponsesByTaskQuery,
  GetTasksByLoanQuery,
  GetTasksByLoanQueryVariables,
  QueryGetTaskResponseCommentsArgs,
  QueryGetTaskResponsesByTaskArgs,
  QueryGetTasksByLoanArgs
} from 'gql/graphql';

export const useGetTasksByLoan = (
  vars?: UseQueryInterface<QueryGetTasksByLoanArgs>
) => {
  const [{ data, fetching, error }, reexecute] = useQuery<GetTasksByLoanQuery>({
    query: vars?.query || GET_TASKS_BY_LOAN,
    ...vars
  });
  return {
    data: data?.GetTasksByLoan,
    fetching,
    error,
    reexecute
  };
};

export const useGetTaskResponsesByTask = (
  vars?: UseQueryInterface<QueryGetTaskResponsesByTaskArgs>
) => {
  const [{ data, fetching, error }, reexecute] =
    useQuery<GetTaskResponsesByTaskQuery>({
      query: vars?.query || GET_TASK_RESPONSES_BY_TASK,
      variables: vars?.variables,
      requestPolicy: vars?.requestPolicy,
      pause: vars?.pause
    });
  return {
    data: data?.GetTaskResponsesByTask,
    fetching,
    error,
    reexecute
  };
};

export const useGetTaskResponseComments = (
  vars?: UseQueryInterface<QueryGetTaskResponseCommentsArgs>
) => {
  const [{ data, fetching, error }, reexecute] = useQuery<GetTaskResponseCommentsQuery>({
    query: vars?.query || GET_TASK_RESPONSE_COMMENTS,
    variables: vars?.variables,
    requestPolicy: vars?.requestPolicy,
    pause: vars?.pause
  });
  return {
    data: data?.GetTaskResponseComments,
    fetching,
    error,
    reexecute
  };
};
