import React, { createContext, useEffect, useState } from 'react';
import { Button, Checkbox } from 'antd';
import SectionRight from './SectionRight/SectionRight';
import calendarPng from 'assets/images/calendar.png';
import closePng from 'assets/images/close-gray.png';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TaskState } from '../../../../gql/graphql';

interface Props {
  visible: boolean;
  handlerSetVisibile: Function;
  renderButtonTrigger: Function;
  header?: {
    title: React.ReactElement;
    actionLeft?: React.ReactElement;
    actionRight?: React.ReactElement;
  };
  stepsTotal: number | 1;
  currentStep: number;
  setStep: Function | null;
  children: React.ReactElement;
  footer: React.ReactElement | null;
  animationDirection: string | 'onRight';
  responses?: any[];
  disabledDots?: boolean;
  isConfirmModalClose?: boolean;
}
interface dotObjet {
  id: number;
}

export const ModalTaskRightContext = createContext<{ value: string; setHandler: any } | {}>({});

const ModalTask = (props: Props) => {

  const [isDots, setDots] = useState<dotObjet[]>();
  const [isModalSubmit, setModalSubmit] = useState<string>("inactive");
  const [openSectionRight, setOpenSectionRight] = useState<{ show: boolean, params?: any }>();

  useEffect(() => {
    const arrayDots = [];
    for (let index = 0; index < props.stepsTotal; index++) {
      arrayDots.push({ id: index + 1, state: '' });
    }

    if (props.responses) {
      for (const dot of arrayDots) {
        //console.log('props.responses', props.header?.title.props.children, dot, props.responses);

        const responsesStep = props.responses.filter(response =>
          response?.step === dot.id ||
          //Entity guarantor
          ((dot.id === 3 || dot.id === 2) && response?.key?.includes('ownership')) ||
          //Minimium liquidity
          //totalBalanceCurrency >= props.maxBalance? 'approved': isReview? 'review': null
          //Track Record
          (response?.step === 2 && response?.key?.includes('purchased/sold'))
        );

        if (responsesStep?.length > 0) {
          const isApproved = responsesStep.every((item: any) => item.state === TaskState.Approved);
          const isError = !!responsesStep.find((item: any) => item.state === TaskState.RequestError);
          const isReview = !!responsesStep.find((item: any) => item.state === TaskState.Review);
          const isDraft = responsesStep.every((item: any) => item.state === TaskState.Draft || item.state === TaskState.Incomplete);

          if (isApproved) dot.state = 'approved';
          else if (isError) dot.state = 'error';
          else if (isReview) dot.state = 'review';
          else if (isDraft) dot.state = 'draft';
        }
      }
      //console.log('arrayDots', props.header?.title.props.children, arrayDots);

      setDots(arrayDots);
    }
  }, [props.responses])

  useEffect(() => {
    document.body.style.overflowY = (props.visible) ? "hidden" : "scroll";
  }, [props.visible])

  const isStyleDots = (item: any, index: number) => {
    if (item.id === props.currentStep) {
      if (item.state === 'approved') return 'dots-button-approved-active';
      else if (item.state === 'review') return 'dots-button-review-active';
      else if (item.state === 'error') return 'dots-button-error-active';
      else return 'dots-button-active';
    } else if (item.id < props.currentStep || item.state === 'approved') {
      return 'dots-button-success';
    } else {
      return '';
    }
  };

  const closeModal = () => {
    props.handlerSetVisibile(false);
    setOpenSectionRight({ show: false });
    setModalSubmit("inactive");
    /*
    if(localStorage.getItem('modalTaskClose') === 'true') {
      setModalSubmit("close")  
      return;
    }
    
    setModalSubmit(props.isConfirmModalClose ? "active" : "close")*/
  }

  const handlerModalTaskRight = (show: boolean, params: any) =>
    setOpenSectionRight({ show, params });

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    if (e.target.checked)
      localStorage.setItem('modalTaskClose', 'true');
    else
      localStorage.removeItem('modalTaskClose');
  };

  useEffect(() => {
    if (isModalSubmit === "close") {
      props.handlerSetVisibile(false);
      setOpenSectionRight({ show: false });
      setModalSubmit("inactive");
    }
  }, [isModalSubmit])

  return (
    <div className="cont-modal">
      {props.renderButtonTrigger(props.handlerSetVisibile)}
      {props.visible && (
        <ModalTaskRightContext.Provider value={{ value: openSectionRight, setHandler: handlerModalTaskRight }}>
          <div className="ModalTask">
            <Button
              className="button-close-mask"
              style={{ width: openSectionRight?.show ? '5%' : '30%' }}
              onClick={closeModal}
            />
            <div
              className={`ModalTask-content animate__animated ${props.animationDirection === 'onRight' && 'animate__fadeInRight'}`}
              style={{ width: openSectionRight?.show ? '60%' : '70%' }}
            >
              <div className="content-modal">
                <div className="modal-review-task__content">
                  <div className="modal-title">
                    <div className="flex-btw">
                      <h3 className="body-card body-card-w6">
                        {props.header?.title}
                      </h3>
                      <Button
                        className="body-card"
                        onClick={closeModal}
                      >
                        Close X
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="modal-container" style={{ height: 'default' }}>
                  {isDots && (
                    <div className="dots">
                      {isDots?.map((item, index) => (
                        <Button
                          onClick={() => props.setStep && props.setStep(item.id)}
                          disabled={
                            props.disabledDots || props.currentStep <= index
                          }
                          className={`dots-button ${isStyleDots(item, index)}`}
                          key={item.id}
                        />
                      ))}
                    </div>
                  )}
                  <div className="modal-cards modal-cards--full">
                    <div className="modal-cards-mx-w">
                      <div className="flex-btw">
                        {props.header?.actionLeft}
                        {props.header?.actionRight}
                      </div>
                      {props.children}
                    </div>
                  </div>
                </div>
              </div>
              {props.footer && (
                <div className="modal-review-task__footer">
                  <div className="modal-container">
                    <div className="modal-cards">{props.footer}</div>
                  </div>
                </div>
              )}
            </div>
            {openSectionRight?.show &&
              <SectionRight params={openSectionRight.params} close={() => setOpenSectionRight({ show: false })} />
            }
            {isModalSubmit === "active" &&
              <div className='ModalCloseTask'>
                <Button className='ModalCloseTask-mask' onClick={() => setModalSubmit("inactive")}></Button>
                <div className="modal-end-submit__content">
                  <Button className='button-close' onClick={() => setModalSubmit("inactive")}>
                    <img src={closePng} alt='close' />
                  </Button>
                  <img className="coin" alt="coin" src={calendarPng} />
                  <h2 className="h2">Finish this task later?</h2>
                  <h4 className="h4 h4-w4 text-modal">Don’t worry! We’ll save your work. Just click on the task in your dashboard to resume.</h4>
                  <div className="buttons">
                    <Button
                      className="button button-modal-ok mt--10"
                      onClick={(e) => {
                        setModalSubmit("close");
                      }}
                    >
                      I’ll do this later
                    </Button>
                    <Button
                      className="button button-modal-cancel"
                      onClick={() => setModalSubmit("inactive")}
                    >
                      I want to finish this task now
                    </Button>
                  </div>
                  <br />
                  <Checkbox onChange={onChangeCheckbox}>Don’t show this message again</Checkbox>
                </div>
              </div >
            }
          </div>
        </ModalTaskRightContext.Provider>
      )}
    </div>
  );
};
export default ModalTask;
