import React, { useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import { Button, InputNumber } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import coinPng from 'assets/images/coin-shadow.png';
import CardUploadResponse from '../../../../../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { useGetTaskResponseComments, useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import { useCreateResponseTask, useDeleteFileResponse } from '../../../../../services/task/mutations';
import { File, Task, TaskResponse, TaskState } from '../../../../../gql/graphql';
import { formatterCurrency } from '../../../../../@common/utils/formatters/formatter';
import ModalFinish from './ModalFinish';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import IssuesTaskResponse from 'scenes/Private/components/IssuesTaskResponse/IssuesTaskResponse';
import CircleTag from 'components/Atoms/Tags/CircleTag/CircleTag';

interface ConstructionBudgetProps {
  task: Task;
}
const ConstructionBudget = ({ task }: ConstructionBudgetProps) => {

  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const { execute: _deleteFile } = useDeleteFileResponse();
  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();
  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId: task._id, state: 'pending' }
  });

  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [budgetValue, setBudgetValue] = useState<any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [isReview, setIsReview] = useState(false);
  const [isErrorData, setIsErrorData] = useState(false);

  useEffect(() => {
    if (openContext === 'construction' || openContext === ModuleKeys.CONSTRUCTION) {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setTaskCompleted(_response?.state === TaskState.Approved);
    setIsReview(_response?.state === TaskState.Review);
    if (_response?.metadata) setBudgetValue(_response.metadata.budgetValue);
  }, [taskResponses]);

  useEffect(() => {
    if (
      taskComments &&
      taskComments.length > 0 &&
      taskComments?.every(item => item.metadata?.dataOrDocumentSelected?.includes('Text/data'))
    )
      setIsErrorData(true);
  }, [taskComments]);

  useEffect(() => {
    if (isErrorData && budgetValue)
      setIsErrorData(false);
  }, [budgetValue])

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const submit = async (files: any) => {
    let payload: any = {
      key: 'construction-budget',
      label: 'Itemized construction Budget/SoW',
      step: 1,
      metadata: {
        budgetValue
      },
      state: TaskState.Review,
      task: task?._id,
      files
    };

    if (response) payload._id = response._id;

    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
    setIsErrorData(false);
  };

  const onDeleteFile = (file: File) => {
    _deleteFile({ fileKey: file.key, taskResponseId: response._id });
  }

  const { taskName, phase } = findTaskRules(ModuleKeys.CONSTRUCTION);

  const firstDocumentName = response?.files?.find((item: any) => item.state === 'request_error')?.name;

  const renderTracker = () => (
    <div style={{ height: '100%' }}>
      <span className='title-summary'>Task summary</span>
      <div className="mt-20">
        <p className="placeholder">
          My estimated construction budget is{' '}
          {(response?.metadata?.budgetValue || budgetValue) &&
            <span className="bold-label">
              {formatterCurrency(response?.metadata?.budgetValue || budgetValue)}
            </span>
          }
          {!(response?.metadata?.budgetValue || budgetValue) &&
          <span className='link-blue'>unknown</span>
          }
        </p>
        <div>
          <p className="placeholder" style={{ marginTop: 40, fontWeight: 500 }}>
            Document Status{' '}
          </p>

          {taskResponses && (
            <div className="owners-tags mt-5">
              <span>{response? 1: 0}/1 documents submitted</span>
            </div>
          )}

          <div className="mt-10 circles-tags">
            <CircleTag
              state={(response?.files && response?.files[0].state) || 'DRAFT'}
              tagKey={(response?.files && response?.files[0].state) || 'DRAFT'}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      renderButtonTrigger={(openModal: any) => (
        <Button
          className={`button-open-modal button-open-modal-${task.state}`}
          onClick={() => openModal(true)}
        >
          {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
            (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
              'Begin Task'
          }
        </Button>
      )}
      header={{
        title: <span>{phase} | {taskName.toUpperCase()}</span>,
        actionLeft:
          isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={coinPng} alt="icon" className="paperPng" />,
        actionRight: (
          <Button
            className="button button-transparent"
            onClick={() => onHelpDocs()}
          >
            <QuestionCircleOutlined />
            Help docs
          </Button>
        )
      }}
      animationDirection="onRight"
      isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
      responses={taskResponses}
      stepsTotal={1}
      currentStep={step}
      setStep={setStep}
      tracker={renderTracker()}
    >
      <div className="children ConstructionBudget">
        <h2 className="h3 mt-5">
          We need to know what work you plan to do on your property to order an
          appraisal
        </h2>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: () => setModalTaskVisible(false) }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} titleAddText={`Issues with`} isTitleDocument />
        }

        <h2 className="h2 mt-30">What’s your total construction budget?</h2>
        <div className="item">
          <InputNumber
            placeholder="150,000"
            value={budgetValue}
            controls={false}
            disabled={!!response?.metadata?.budgetValue && response?.state !== TaskState.RequestError}
            className={`input input-number mt-15 ${isErrorData && 'input-number-error'}`}
            onChange={(v) => setBudgetValue(v)}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value!.replace(/\$\s?|(,*)/g, '') as any}
          />
        </div>
        <div id={response?._id} className="mt-15">
          <CardUploadResponse
            title="Itemized construction Budget/SoW"
            description="Use our template or upload your own itemized list of your planned construction budget"
            isButtonTemplate={{ show: true, link: 'https://storage.googleapis.com/constlending-borrowers/static/ConstructionBudgetScopeOfWork.xlsx' }}
            files={response?.files}
            state={response?.state}
            limitFiles={1}
            actionDeleteFile={(file: File) => onDeleteFile(file)}
            submit={{
              title: 'Submit for review',
              disabled: task.state !== TaskState.RequestError && (!budgetValue || response?.metadata?.budgetValue === budgetValue),
              ignoreDisabledNotFiles: false,
              loading: fetching,
              location: 'outside',
              modalConfirm: true,
              confirmDescription:
                "After submitting, you’ll have to contact us if you want to make changes. We’ll reach out if your loan needs to be adjusted (which could happen if the information you entered is significantly different from your estimates).",
              onClick: (files) => submit(files)
            }}
            insertHtmlBottom={
              response?.state === TaskState.RequestError && <IssuesTaskResponse taskId={task._id} taskResponseId={response._id} />
            }
          />
        </div>

        {/* <AddAnotherDoc taskId={task._id} taskState={task.state}/>*/}

        <CollapseIssues taskId={task._id} rejectionCode='no_action_required' />

        {modalFinish &&
          <ModalFinish
            visible={modalFinish}
            setVisible={setModalFinish}
            closeTask={() => setModalTaskVisible(false)}
          />
        }
      </div>
    </ModalTask>
  );
};
export default ConstructionBudget;
