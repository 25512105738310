import React, { useState, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import calendarPng from 'assets/images/calendar.png';
import { useForm } from 'antd/es/form/Form';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { formatterPhone as utilsFormatterPhone } from '@common/utils/formatters/formatter';

interface Props {
  response?: any;
  handlerSubmit: any;
}

const CardSomeoneElse = ({ response, handlerSubmit }: Props) => {

  const [form] = useForm();
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (response) {
      form.setFieldsValue({
        name: response.metadata?.name,
        lastName: response.metadata?.lastName,
        email: response.metadata?.email,
        phone: response.metadata?.phone,
      });
    }
  }, [response, form]);

  const onFinish = async () => {
    handlerSubmit({ id: 2 }, form.getFieldsValue());
    setIsModalConfirm(false);
  }
  
  const disabledSubmit = () => {
    const isFieldsValidate = Object.values(form.getFieldsValue()).every(
      (value) => !!value
    );

    return (
      !isFieldsValidate ||
      form.getFieldsError().filter(({ errors }) => errors.length)?.length > 0
    ); 
  }

  const onChangeForm = () => setDisabled(disabledSubmit());
  
  const formatterPhone = (e: any) => form.setFieldValue('phone', utilsFormatterPhone(e.target.value));

  return (
    <div className="CardSomeoneElse">
      <Form
        form={form}
        name="basic"
        autoComplete="off"
        onChange={onChangeForm}
      >
        <div className="row">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your first name!'
              }
            ]}
          >
            <Input placeholder="First name" />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input your last name!'
              }
            ]}
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          rules={[
            { required: true, type: 'email', message: 'Invalid email address' }
          ]}
        >
          <Input 
            type="email"
            placeholder="Email address" 
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: 'Please input your phone!'
            }
          ]}
        >
          <InputBorderNone
            placeholder="Phone number"
            onChange={formatterPhone}
          />
        </Form.Item>
      </Form>
      <ModalEndSubmit
        visible={isModalConfirm}
        setVisible={setIsModalConfirm}
        buttonAction={{ 
          label: 'Submit and complete task',
          disabled: response || disabled
        }}
        modal={{
          title: 'Ready to submit?',
          description: (
            <h4 className="h4 h4-w4">
              After submitting, you’ll have to contact us if you want to make
              changes to the property access details
            </h4>
          )
        }}
        className="modal-end-submit--white"
        icon={calendarPng}
        buttonSubmit={
          <Button htmlType='submit' className="button button-modal-ok" onClick={onFinish}>
            Yes, this is correct
          </Button>
        }
        buttonCancel={
          <Button className="button button-modal-cancel" onClick={() => setIsModalConfirm(false)}>
            I want to make changes
          </Button>
        }
      />
    </div>
  );
};
export default CardSomeoneElse;
