import { gql } from "urql";

export const CREATE_RESPONSE_TASK: any = gql(`
    mutation CreateResponseTask($createResponseTaskInput: CreateResponseTaskInput!) {
        createResponseTask(createResponseTaskInput: $createResponseTaskInput) {
            _id
            label
            key
            state
        }
    }
`)

export const DELETE_FILE_TASK_RESPONSE: any = gql(`
    mutation DeleteFileTaskResponse($taskResponseId: String!, $fileKey: String!) {
        DeleteFileTaskResponse(taskResponseId: $taskResponseId, fileKey: $fileKey) {
            _id
            label
            key
        }
    }
`)

export const REMOVE_TASK_RESPONSE: any = gql(`
    mutation RemoveTaskResponse($taskResponseId: String!) {
        RemoveTaskResponse(taskResponseId: $taskResponseId) {
            _id
            label
            key
        }
    }
`)