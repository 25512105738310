import React from 'react';
import { Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import uploadCircleSvg from 'assets/images/upload-svg.svg';
import fileSvg from 'assets/images/file.svg';
import deleteSvg from 'assets/images/delete.svg';
import checkCircleSvg from 'assets/images/check-circle-basic.svg';
import iconErrorSvg from 'assets/images/error-red.svg';
import iconClockSvg from 'assets/images/clock-background.svg';
import { File } from '@common/utils/interfaces/file.interface';
import { TaskState } from 'gql/graphql';

interface InputUploadedFileProps {
  file: File;
  seeFile?: Function;
  deleteFile?: Function;
  loadingDeleteFile?: boolean;
  state?: TaskState | undefined;
  isActionSeeFile?: boolean;
  icon?: string;
}

const InputUploadedFile = ({
  file,
  seeFile,
  deleteFile,
  loadingDeleteFile,
  state,
  isActionSeeFile,
  icon
}: InputUploadedFileProps) => {

  let hiddenIconStart = false;

  const _seeFile = (files: File) => {
    if (files?.url !== undefined && files?.url !== "") {
      // window.open(files?.url, '_blank')
      var win: any = window.open();
      win.document.write(`<iframe width='100%' height='100%' src="${files.url}"><\/iframe>`);
    }
  };

  return (
    <div className="InputUploadedFile">
      <div className="file-ok">
        <div className="file" style={{ paddingLeft: !hiddenIconStart ? 7 : 0 }}>
          {!hiddenIconStart && (
            <div className="circle-input">
              {state?.toUpperCase() === TaskState.Approved &&
                <img src={checkCircleSvg} alt="upload" className="upload" />
              }
              {state?.toUpperCase() === TaskState.RequestError &&
                <img src={iconErrorSvg} alt="upload" className="upload" />
              }
              {state?.toUpperCase() === TaskState.Review &&
                <img src={iconClockSvg} alt="upload" className="upload" />
              }
              {(!state || state?.toUpperCase() === TaskState.Draft || state?.toUpperCase() === TaskState.Incomplete) && 
                <img src={uploadCircleSvg} alt="upload" className="upload" />
              }
            </div>
          )}
          <div className={`file-content file-content-${state}`}>
            <div className="flex-btw">
              <div className="file-name">
                <img className="fileIcon" alt="fileIcon" src={fileSvg} />
                <span className="name">{file?.name}</span>
              </div>
              <div className="content-buttons">
                {seeFile && (
                  <Button
                    className="button button-transparent sent"
                    onClick={() => seeFile(file)}
                  >
                    <EyeOutlined />
                  </Button>
                )}
                {isActionSeeFile && (
                  <Button
                    className="button button-transparent local"
                    onClick={() => _seeFile(file)}
                  >
                    <EyeOutlined />
                  </Button>
                  // <a href={`${file.url}`} target="_blank" rel="noopener noreferrer">
                  //   <EyeOutlined />
                  // </a>
                )}
                {deleteFile && (
                  <Button
                    className="button button-transparent"
                    loading={loadingDeleteFile}
                    onClick={() => deleteFile(file)}
                  >
                    <img
                      src={deleteSvg}
                      alt="delete"
                      className="delete"
                      width="15"
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InputUploadedFile;
