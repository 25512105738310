import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { Link, useParams } from "react-router-dom";
import { useResetPassword } from "../../../../../services/auth/mutations";

interface Props {
  onReady: Function;
  handlerRedirectSignIn?: any;
}

const FormResetPassword = ({ onReady, handlerRedirectSignIn }: Props) => {

  let { token }: any = useParams();

  const { data: response, execute: resetPassword, fetching } = useResetPassword();

  useEffect(() => {
    if (response) onReady();
  }, [response])

  const onFinish = (values: any) => {
    resetPassword({ newPassword: values.password, token });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      className="form"
      autoComplete="off">
      <Form.Item
        name="password"
        className="form-item"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
        ]}>
        <Input.Password placeholder="New password" className="form-item__input" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        className="form-item"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The new password that you entered do not match!'));
            },
          }),
        ]}>
        <Input.Password placeholder="Confirm new password" className="form-item__input" />
      </Form.Item>

      {handlerRedirectSignIn && 
        <span>
          Want to sign in instead?{" "}
          <Button type="link" className="body-regular link" onClick={handlerRedirectSignIn}>
            <span className="body-regular-bold">Click here</span>
          </Button>
        </span>
      }

      <Form.Item>
        <Button
          className="button button-primary button-txt-big"
          htmlType="submit"
          loading={fetching}
        >
          Reset password
        </Button>
      </Form.Item>
    </Form>
  );
};
export default FormResetPassword;
