import React, { useRef } from 'react';
import { Input, InputNumber } from 'antd';

interface InputBorderNoneProps {
  type?: string;
  placeholder: string;
  label?: string | null;
  className?: string | null;
  calculateBalance?: boolean;
  valuePercentage?: number;
  onChange?: any;
  value?: any;
  height?: string | number;
  width?: string | number;
  formatter?: 'currency' | any;
  max?: number;
  min?: number;
  color?: 'default' | 'warning' | 'bold' | 'bold-lith' | 'error';
  disabled?: boolean;
  onBlur?: any;
  onFocus?: any;
  onKeyDown?: any;
  suffix?: any;
  defaultValue?: any;
  innerRef?: any;
  error?: boolean;
  removeSpaceAfterDollar?: boolean;
  style?: any;
}

const InputBorderNone = ({
  type,
  placeholder,
  label,
  calculateBalance,
  className,
  valuePercentage,
  onChange,
  value,
  height = 'auto',
  formatter,
  color,
  innerRef,
  error,
  removeSpaceAfterDollar = false,
  ...props
}: InputBorderNoneProps) => {
  const ref = useRef<any>();

  const getFormatter: any = (value: string) => {
    if (value && formatter === 'currency') {
      const formattedValue = `$${removeSpaceAfterDollar ? '' : ' '}${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedValue;
    }
    return '';
  };

  return (
    <div className="InputBorderNone" style={{ height }}>
      {label && <p className="body-card">{label}</p>}
      {type === 'number' && (
        <InputNumber
          ref={ref}
          value={value}
          controls={false}
          className={`input input-transparent body-placeholder ${className} ${color}`}
          placeholder={placeholder}
          style={{ width: props.width || '100%' }}
          formatter={formatter && typeof formatter === 'string' ? getFormatter : formatter}
          onChange={(v) => onChange(v, ref)}
          suffix={props.suffix}
          {...(!formatter ? { type: 'number' } : {})}
          {...props}
        />
      )}
      {type !== 'number' && (
        <Input
          ref={innerRef}
          type={type}
          value={value}
          className={`input input-transparent body-placeholder ${className} ${color} ${error && 'border-error'}`}
          placeholder={placeholder}
          onChange={onChange}
          {...props}
        />
      )}
      {calculateBalance && (
        <div className="percentage-bottom">
          <span className="body-card">{valuePercentage}% of balance</span>
          <span className="body-regular">
            <span>$</span>
            {`${value / 2}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </span>
        </div>
      )}
      {/* <p className="body-card">Account balance</p> */}
    </div>
  );
};

export default InputBorderNone;
