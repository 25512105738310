import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface SectionErrorProps {
  title: string;
  subtitle: string;
  description: string;
  subtitleInDescription?: boolean;
  button?: { title: string, onClick?: any, arrow?: boolean, underline?: boolean };
}

const SectionError = ({ 
  title, 
  description, 
  subtitle, 
  subtitleInDescription, 
  button 
}: SectionErrorProps) => {
  return (
    <div className="SectionError">
      <div className="flex-btw">
        {title && <h4 className="body-regular body-regular-title">{title}</h4>}
        { !subtitleInDescription && <h4 className="body-regular">{subtitle}</h4> }
      </div>
      <div className='description'>
        <p className="body-regular">
          {subtitleInDescription && subtitle && 
            <span className="bold">{subtitle}{'. '}</span>
          }
          {description}
        </p>
      </div>
      {button && 
        <div className="flex-end">
          <Button 
            type="link"
            className="button button-transparent" 
            style={{ width: 90 }}
            onClick={button.onClick}
          >
            <span style={{ textDecoration: button.underline? 'underline': '' }}>
              {button.title} 
            </span>
            {button.arrow && <ArrowRightOutlined />}
          </Button>
        </div>
      }
    </div>
  );
};
export default SectionError;
