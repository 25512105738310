import React, { useContext } from 'react';
import checkSvg from 'assets/images/check-circle.svg';
import clockSvg from 'assets/images/clock.svg';
import errorSvg from 'assets/images/alert-circle.svg';
import loaderBlueSvg from 'assets/images/loader-blue.svg';
import { TaskState } from 'gql/graphql';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip';

export interface CardLoanTasksProps {
  key: string;
  taskKey?: string;
  title: React.ReactElement | null;
  text?: React.ReactElement | null;
  text2?: React.ReactElement | null;
  steps: number;
  stepCurrent: number;
  currentInfo?: React.ReactElement | null;
  state?: TaskState;
  type: 'quick task' | 'Major task';
  progress?: React.ReactElement | null;
  actions?: React.ReactElement[] | null;
  stateRename?: {
    DRAFT?: string,
    REVIEW?: string
  },
  stateTooltipHtml?: string;
  disabled?: boolean;
}

const CardLoanTasks = ({
  taskKey,
  state,
  type,
  steps,
  progress,
  title,
  text,
  text2,
  currentInfo,
  actions,
  stateRename,
  stepCurrent,
  stateTooltipHtml,
  disabled
}: CardLoanTasksProps) => {
  
  const getKeyState = () => {
    const _stateRename: any = stateRename || {};
    return _stateRename[state as string]?.replaceAll(' ', '_') || state
  }

  const tooltipsTexts: any = {
    "complete": 'APPROVED',
    "incomplete": 'IN PROGRESS',
    "review": 'IN REVIEW',
    "error": 'REJECTED',
    '': 'NOT STARTED'
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];

  return (
    <article
      className={
        `CardLoanTasks CardLoanTasks-${getKeyState()}`
      }
    >
      <div className="CardLoanTasks__top">
        <div className="CardLoanTasks__top__head">
          <div className="state">
            {state === TaskState.Approved && (
              <>
                <img src={checkSvg} alt="check" className="check" />
                <span className="state-name state-name-w6">APPROVED</span>
              </>
            )}

            {state === TaskState.Incomplete && (
              <>
              <img src={loaderBlueSvg} alt="progress" className="check" width={12}/>
                <span className="state-name state-name-w6">IN PROGRESS</span>
              </>
            )}

            {state === TaskState.Draft && (
              <>
                {getKeyState() === 'NEW_TASK' &&
                  <img src={errorSvg} alt="check" className="check" />
                }
                <span
                  className="state-name state-name-w6"
                  data-tooltip-id={`state-` + taskKey}
                  data-tooltip-html={stateTooltipHtml}
                >
                  {stateRename?.DRAFT || 'NOT STARTED'}
                  {stateTooltipHtml && stateRename?.DRAFT === 'COMING SOON' &&
                    <Tooltip
                      id={`state-` + taskKey}
                      className={`card-state-tooltip`}
                    />
                  }
                </span>
              </>
            )}

            {state === TaskState.Review && (
              <>
                <img src={clockSvg} alt="check" className="check" />
                <span className="state-name state-name-w6">
                  {stateRename?.REVIEW || 'IN REVIEW'}
                </span>
              </>
            )}

            {state === TaskState.RequestError && (
              <>
                <img src={errorSvg} alt="check" className="check" />
                <span className="state-name state-name-w6">ERROR</span>
              </>
            )}
          </div>

          <div className="flex-end" style={{ gap: 10 }}>
            <div className={`flex-end ${disabled && 'disabled'}`}>
              {type === 'quick task' && (
                <svg className="svg-grid quick-task">
                  <path d="M5 1.5H1.5V5H5V1.5Z" />
                  <path d="M10.5 1.5H7V5H10.5V1.5Z" />
                  <path d="M10.5 7H7V10.5H10.5V7Z" />
                  <path d="M5 7H1.5V10.5H5V7Z" />
                </svg>
              )}
              {type === 'Major task' && (
                <svg className="svg-grid major-task">
                  <path d="M5 1.5H1.5V5H5V1.5Z" />
                  <path d="M10.5 1.5H7V5H10.5V1.5Z" />
                  <path d="M10.5 7H7V10.5H10.5V7Z" />
                  <path d="M5 7H1.5V10.5H5V7Z" />
                </svg>
              )}
              <span className="body-small body-small-up">{type}</span>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 3 }}>
          {title}
          <div className="text">{text}</div>

          {progress}
          {text2}
        </div>
      </div>
      <div className="CardLoanTasks__bottom">
        {currentInfo}
        <div className="CardLoanTasks-buttons-actions">
          {actions && actions?.length > 0 && actions?.map(item => item)}
        </div>
      </div>
    </article>
  );
};
export default CardLoanTasks;
