import React from 'react';
import { TaskState } from 'gql/graphql';
import { Tooltip } from 'react-tooltip';
import { ENTITY_DOCS_LABEL } from 'scenes/Private/Dashboard/components/EntityDocs/responses.constants';

const tooltipsTexts: any = {
  "APPROVED": 'Approved',
  "REVIEW": 'In Review',
  "REQUEST_ERROR": 'Rejected',
  "DRAFT": 'Not submmitted',
}

const tooltipTexts = (state: string) => state? tooltipsTexts[state]: 'Not submmitted';

interface Props {
  tagKey: string;
  textTwo?: string;
  state: TaskState | string;
}

const CircleTag = ({
  tagKey,
  state,
  textTwo
}: Props) => {
  state = state.toUpperCase();

  return (
    <div className={`circle-tag circle-tag-${state}`}>
      <div
        data-tooltip-id={`circle-state-${tagKey}`}
        data-tooltip-html={`<b>${tooltipTexts(state)}</b> ${textTwo? `<br/><span>${textTwo}</span>`: ''}`}
        className={`circle circle-${state}`}
      />
      <Tooltip
        id={`circle-state-${tagKey}`}
        className={`circle-state-tooltip circle-state-tooltip-${state}`}
      />
    </div>
  );
};

export default CircleTag;
