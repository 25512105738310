import { PRICER_URL } from "@common/config/environments";
import { Loan } from "gql/graphql";

type PropertyType =
  | "Single family"
  | "2-4 unit property"
  | "Portfolio"
  | "Warrantable condo"
  | "Non-warrantable condo";

type LoanPurpose = "purchase" | "refinance";

type FICO =
  | "660-679"
  | "680-699"
  | "700-719"
  | "720-739"
  | "740-759"
  | "760-779"
  | "780+";

export type PricerPayload = {
  property_type: PropertyType;
  loan_purpose: LoanPurpose | string;
  fico: FICO;
  buydown: number;
  price: number;
  payoff: number;
  monthly_market_rent: number;
  annual_property_taxes: number;
  annual_insurance: number;
  annual_hoa: number;
  loan_balance: number;
};

export const PricerPayloadFactory = (loanObject: Loan, dscrValues: any) => {
  const pricerPayload: PricerPayload = {
    fico: loanObject.loanData['What’s your credit score?'],
    loan_balance: loanObject.loanData.selectedOption.totalLoanAmount,
    price: loanObject.loanData.price,
    loan_purpose: loanObject.purpose!.toLowerCase(),
    payoff: loanObject.loanData.currentLoanBalance || 0,
    property_type: loanObject.loanData['What kind of property is it?'],
    monthly_market_rent: dscrValues.monthlyRentalIncome || loanObject.loanData.monthlyRent,
    annual_property_taxes: dscrValues.annualPropertyTaxes || loanObject.loanData.annualTaxes,
    annual_insurance: dscrValues.annualPropertyInsurance || loanObject.loanData.annualInsurance,
    annual_hoa: dscrValues.annualHoaFee || loanObject.loanData.annualHoa,
    buydown: dscrValues.monthlyPayment || loanObject.loanData.selectedOption.buydownPoints
  };

  return pricerPayload;
}

export const sendPricerPayload = async (pricerPayload: any) => {
  try {
    const response = await fetch(`${PRICER_URL}/rental`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(pricerPayload),
    });
    const data = await response.json();
    return data.data.dscr;
  } catch (error) {
    console.error('Error sending pricer payload', error);
    return null;
  }
};