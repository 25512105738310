import React, { useState } from 'react';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import coinPng from 'assets/images/coin-shadow.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { Button } from 'antd';

interface CardProps {
  ammountFiles: number;
}
const CardLeaseAgreements = ({ ammountFiles }: CardProps) => {

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [ListFileUpload, setListFileUpload] = useState([]);

  return (
    <div className="CardLeaseAgreements">
      <article className="flex-btw">
        <p className="h4">Lease agreements</p>
        <p className="h4 h4-w4">
          Occupied units: <span className="h4">{ammountFiles}</span>
        </p>
      </article>
      <UploadDocs
        numberOfFiles={ammountFiles}
        button={
          <ModalEndSubmit
            visible={isModalConfirm}
            setVisible={setIsModalConfirm}
            buttonAction={{ label: 'Submit for review' }}
            modal={{
              title: 'Your change request has been submitted',
              description: (
                <h4 className="h4 h4-w4">
                  We’ll reach out if your loan needs to be adjusted, otherwise
                  check back in a few days for next steps
                </h4>
              )
            }}
            className="modal-normal"
            icon={coinPng}
            buttonSubmit={
              <Button className="button button-modal-ok">
                Go to next task
              </Button>
            }
            buttonCancel={
              <Button className="button button-modal-cancel">
                Back to dashboard
              </Button>
            }
          />
        }
        listFileUpload={ListFileUpload}
        setListFileUpload={setListFileUpload}
      />
    </div>
  );
};
export default CardLeaseAgreements;
