import React, { useContext, useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from '../../../../../../gql/graphql';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import { TaskContext } from '../TitleCompany';
import InputBorderNone from '../../../../../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { Button, Form } from 'antd';
import { capitalizeNames, formatterPhone as utilsFormatterPhone } from '../../../../../../@common/utils/formatters/formatter';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { ArrowRightOutlined } from '@ant-design/icons';
import ButtonSubmit from 'components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import ModalFinish from './ModalFinish';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import ModalFinish2 from './ModalFinish2';

interface IForm {
  titleCompany: string;
  name: string;
  phone: string;
  email: string;
}

interface Props {
  setStep: Function;
  closeTask: Function;
}

const Step1 = ({ setStep, closeTask }: Props) => {
  const [form] = Form.useForm();
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { execute: createResponseTask, fetching } = useCreateResponseTask();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [modalFinish2, setModalFinish2] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [isRequestTitle, setRequestTitle] = useState(false);

  useEffect(() => {
    if (response && response.state !== TaskState.RequestError)
      form.setFieldsValue(response.metadata);
  }, [response])

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setRequestTitle(_response?.metadata.actionButton === 'request_title_company' || _response?.key === 'not-title');
    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const onFinish = async (values: IForm) => {
    values.name = capitalizeNames(values.name);

    await createResponseTask({
      _id: response?._id,
      task: task._id,
      key: `contact-information`,
      label: `Contact information for the property’s title company/agent`,
      step: 1,
      metadata: { reviewRename: 'MANUAL ACTION', ...values },
      state: TaskState.Review
    });

    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
  }

  const onNotTitle = async () => {
    await createResponseTask({
      task: task._id,
      key: `not-title`,
      label: `I don’t have a title company. Give me one`,
      step: 1,
      metadata: { reviewRename: 'MANUAL ACTION' },
      state: TaskState.Review
    });

    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish2(true);
  }

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          Enter the contact information for the property’s title company/agent
          so we can order a title search
        </h3>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask 
            taskId={task._id} 
            componentDataError={(textDataError: any) => (
              <div className='text-data'>
                <div className='row'>
                  <span>Title company name</span>
                  <b>{textDataError.titleCompany}</b>
                </div>
                <div className='row'>
                  <span>Contact name</span>
                  <b>{textDataError.name}</b>
                </div>
                <div className='row'>
                  <span>Phone number</span>
                  <b>{textDataError.phone}</b>
                </div>
                <div className='row'>
                  <span>Email address</span>
                  <b>{textDataError.email}</b>
                </div>
              </div>
            )}
          />
        }

        <div id={response?._id} className={`mt-40 title-company-step-1 title-company-step-1-${response?.state}`}>

          {response?.state && response?.state !== TaskState.Review && <StateTag state={response?.state} />}
          {response?.state && response?.state === TaskState.Review && <StateTag state={response?.state} isLabelLocked />}

          <Form
            name="edit"
            autoComplete="off"
            requiredMark="optional"
            colon={false}
            form={form}
            onFinish={onFinish}
            disabled={response && response?.state !== TaskState.RequestError && !isRequestTitle}
          >
            <Form.Item
              label=""
              name="titleCompany"
              className="titleCompany"
              rules={[{ required: true, message: '' }]}
            >
              <InputBorderNone
                placeholder="Title company name"
                className="bold"
              />
            </Form.Item>
            <Form.Item
              label="Contact name (if different from lender)"
              name="name"
              className="name"
              rules={[{ required: true, message: '' }]}
            >
              <InputBorderNone
                placeholder="Edgar"
                height={20}
                className="bold-lith"
              />
            </Form.Item>

            <Form.Item
              label="Phone number"
              name="phone"
              className="phone"
              rules={[
                { required: true, message: '' },
                { min: 12, message: '' }
              ]}
            >
              <InputBorderNone
                placeholder="329-230-2390"
                height={20}
                className="bold-lith"
                onChange={formatterPhone}
              />
            </Form.Item>

            <Form.Item
              label="Email address"
              name="email"
              className="email"
              rules={[{ required: true, type: 'email', message: ' ' }]}
            >
              <InputBorderNone
                type="email"
                placeholder="ricardo@mervetscoolbiz.com"
                height={20}
                color={emailError ? 'error' : 'bold-lith'}
                onBlur={(e: any) => setEmailError(e.target.value && form.getFieldError('email').length > 0)}
              />
            </Form.Item>

            {response?.state !== TaskState.Approved &&
              <Form.Item className="submit" shouldUpdate>
                {() => (
                  <ButtonSubmit
                    disabled={
                      (response?.state === TaskState.Review && !isRequestTitle) ||
                      !form.getFieldsError().every((fieldErr) => fieldErr.errors.length === 0) ||
                      !form.isFieldsTouched() ||
                      !Object.values(form.getFieldsValue()).every(value => value)
                    }
                    loading={fetching}
                    modalConfirm={{ show: true }}
                    onClick={form.submit}
                  >
                    Submit and complete task
                  </ButtonSubmit>
                )}
              </Form.Item>
            }
          </Form>
        </div>
        <div className='mt-20' >
          <b
            className={`blue-bold cursor ${response && 'disabled'}`}
            style={{ fontWeight: 500 }}
            onClick={onNotTitle}
          >
            I don’t have a title company. Give me one
          </b>{' '}
          <ArrowRightOutlined
            className={`blue-bold cursor ${response && 'disabled'}`}
            onClick={onNotTitle}
          />
        </div>

        <CollapseIssues taskId={task._id} rejectionCode='no_action_required' />

        {modalFinish &&
          <ModalFinish
            visible={modalFinish}
            setVisible={setModalFinish}
            closeTask={closeTask}
          />
        }
        {modalFinish2 &&
          <ModalFinish2
            visible={modalFinish2}
            setVisible={setModalFinish2}
            closeTask={closeTask}
          />
        }
      </div>
    </section>
  );
};
export default Step1;
