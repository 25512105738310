import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from '../../../../../components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { ModuleKeys } from '../../../../../services/task/modules-keys.constanst';
import { useGetUserLoggin } from '../../../../../services/user/querys';
import DscrQuestionsAgreements from './DscrQuestionsAgreements';

interface Props {
  tasks?: Task[] | any[],
}

const useCardDSCRQuestionAgreements = ({ tasks }: Props) => {
  const { data: user } = useGetUserLoggin();

  const [dataTask, setDataTask] = useState<Task>();
  const [response, setResponse] = useState<TaskResponse>();
  const [stepNow, setStepNow] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.DSCR);
      setDataTask(data);
      setResponse(data?.responses?.find(item => item.metadata.userEmail));

      if (data?.responses.length > 0)
        // Based on the responses the step is set
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.DSCR,
    title: <span>APPROVAL DSCR QUESTIONS & LEASE AGREEMENTS</span>,
    steps: 3,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    // state: dataTask?.state,
    // stateRename: { REVIEW: 'AWAITING ACTION' },
    actions: dataTask && user ? [<DscrQuestionsAgreements key={1} task={dataTask} />] : [],
    // text: (
    //   <div>
    //     <p className="body-card">
    //       An appraisal payment link {' '}

    //       <span className={`body-card-bold-line body-card-bold-line-${dataTask?.state}`}>
    //         {dataTask?.state !== TaskState.Review && ' has not been sent '}
    //         {dataTask?.state === TaskState.Review && ' has been sent '}
    //       </span>
    //     </p>
    //     {dataTask?.state === TaskState.Review &&
    //       <div className="box-message-state-review">
    //         <span>Once payment is submitted, the appraiser will reach out to schedule the appraisal.</span>
    //       </div>
    //     }
    //   </div>
    // ),
    // text2: (
    //   <div style={{ marginTop: 15 }}>
    //     {response?.metadata.userEmail &&
    //       <>
    //         <p className={`body-card body-card-${dataTask?.state}`}>
    //           EMAIL
    //         </p>
    //         <span className="body-card body-card-w5">{response?.metadata.userEmail}</span>
    //       </>
    //     }
    //   </div>
    // )
  };

  if (!dataTask)
    return { card: null };

  return {
    card,
    dataTask
  };
};

export default useCardDSCRQuestionAgreements;