import React, { createContext, useState, useEffect, useContext } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { LoanContext } from '../../Dashboard';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import CircleTag from 'components/Atoms/Tags/CircleTag/CircleTag';
import { formatterCurrency } from '@common/utils/formatters/formatter';

const DSCRContext = {
  totalRents: 0,
  setTotalRents: () => { },
  totalUnits: 0,
  setTotalUnits: () => { },
  vacantUnits: 0,
  setVacantUnits: () => { },
  occupiedUnits: 0,
  setOccupiedUnits: () => { }
}

export const TaskContext = createContext<Task | typeof DSCRContext>(DSCRContext);

interface DscrQuestionsAgreementsProps {
  task?: Task;
  currentStep: number | any;
}
const DscrQuestionsAgreements = ({
  // @ts-ignore
  task = {},
  currentStep

}: DscrQuestionsAgreementsProps) => {
  const loan: Loan | any = useContext(LoanContext);

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(currentStep || 1);

  const [totalRents, setTotalRents] = useState(0);
  const [totalUnits, setTotalUnits] = useState<number>(0);
  const [vacantUnits, setVacantUnits] = useState<number>(0);
  const [occupiedUnits, setOccupiedUnits] = useState<number>(0);
  const [response3, setResponse3] = useState<TaskResponse | any>();
  const [dscrValues, setDSCRValues] = useState<any>({
    monthlyRentalIncome: loan.loanData.monthlyRent,
    annualPropertyInsurance: loan.loanData.annualInsurance,
    annualPropertyTaxes: loan.loanData.annualTaxes,
    annualHOAFee: loan.loanData.annualHoa,
    estimatedDSCR: loan.loanData.selectedOption.dscr
  });
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    setOccupiedUnits(totalUnits - vacantUnits);
  }, [totalUnits, vacantUnits])

  useEffect(() => {
    if (taskResponses && taskResponses.length > 0) {
      const response = taskResponses.find((item) => item.key === 'market-rent');
      const response1 = taskResponses.find((item) => item.step === 1);
      const response3 = taskResponses.find((item) => item.key === 'lease-agreements');
      setResponse3(response3);
      if (response?.metadata) {

        const { totalUnits, vacantUnits } = response.metadata;

        if (totalUnits !== undefined) setTotalUnits(totalUnits);
        if (vacantUnits !== undefined) setVacantUnits(vacantUnits);
        if (totalUnits !== undefined && vacantUnits !== undefined) {
          setOccupiedUnits(totalUnits - vacantUnits);
        }
      }
    }
    setIsReview(task.state === TaskState.Review);
  }, [taskResponses]);

  useEffect(() => {
    if (task && task.metadata && Object.keys(task.metadata).length > 0) {
      setDSCRValues({
        ...dscrValues,
        estimatedDSCR: task?.metadata?.estimatedDSCR
      });
      setTotalUnits(task?.metadata?.totalUnits || 0);
      setVacantUnits(task?.metadata?.vacantUnits || 0);
      setOccupiedUnits(task?.metadata?.occupiedUnits || 0);
    }
  }, [task])

  const dscrContextValue = {
    totalRents,
    setTotalRents,
    totalUnits,
    setTotalUnits,
    vacantUnits,
    setVacantUnits,
    occupiedUnits,
    setOccupiedUnits,
    dscrValues,
    setDSCRValues
  };

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const getFileState = (index: number) => {
    if (!response3 || !response3.files) return 'draft';
    const { state, files } = response3;
    if (state !== TaskState.Review && state !== TaskState.Approved) {
      return files[index]?.state || 'draft';
    }
    return files.length > 0 ? files[files.length - 1].state : 'draft';
  };

  if (!task) return null;

  const renderTracker = () => (
    <div style={{ height: '100%' }}>
      <span className='title-summary'>Task summary</span>
      <div className="mt-20">
        <p className="placeholder" style={{ fontWeight: 500 }}>
          Vacant Units{' '}
        </p>

        <div className='mt-10'>
          <span>Total rent</span>
          <span className='mt-5 price'>
            {
              formatterCurrency(
                totalRents,
                false
              )
            }
          </span>
        </div>
        <div>
          <p className="placeholder" style={{ marginTop: 40, fontWeight: 500 }}>
            Occupied Units{' '}
          </p>

          {taskResponses && !response3?.files && (
            <div className="owners-tags mt-5">
              <span>{0}/{occupiedUnits} leases submitted</span>
            </div>
          )}
          {taskResponses && occupiedUnits > 0 && response3?.files && (
            <div className="owners-tags mt-5">
              <span>{response3.files.filter((item: any) => item.state !== 'request_error').length}/{occupiedUnits} leases submitted</span>
            </div>
          )}

          <div className="mt-10 circles-tags">
            {occupiedUnits > 0 && Array.from({ length: occupiedUnits }).map((_, index) => (
              <CircleTag
                state={getFileState(index)}
                tagKey={getFileState(index)}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <TaskContext.Provider value={{ ...task, ...dscrContextValue }}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
          >
            {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
              (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                'Begin Task'
            }
          </Button>
        )}
        header={{
          title: <span>APPROVAL | DSCR and Lease Agreements</span>,
          actionLeft: (
            isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
          ),
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              Help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        responses={taskResponses}
        stepsTotal={3}
        currentStep={step}
        setStep={setStep}
        tracker={renderTracker()}
      >
        <section className="children DscrQuestionsAgreements">
          {step === 1 && <Step1 setStep={setStep} task={task} />}
          {step === 2 && <Step2 setStep={setStep} task={task} />}
          {step === 3 && <Step3 task={task} />}
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default DscrQuestionsAgreements;
