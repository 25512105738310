import React, { useContext, useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import PropertyAccessDetails from './PropertyAccessDetails';

interface Props {
  tasks?: Task[] | any[],
  user?: any,
}

const useCardPropertyAccess = ({ user, tasks }: Props) => {

  const [dataTask, setDataTask] = useState<Task>();
  const [response, setResponse] = useState<TaskResponse>();
  
  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.PROPERTY_ACCESS);
      setDataTask(data);
      setResponse(data?.responses?.find(item => item.step === 1));
    }
  }, [tasks]);
  
  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.PROPERTY_ACCESS,
    title: <h4 className="h4">Property Access Details</h4>,
    text: (
      <p className="body-card">
        The main contact for the property is
        <span className="body-card-full">
          {response ?
            response?.key === 'me' ? 'me' : 'someone else'
            :
            'unknown'
          }
          { }.
        </span>
      </p>
    ),
    type: 'quick task',
    steps: 1,
    stepCurrent: 1,
    state: dataTask?.state,
    actions: dataTask ? [<PropertyAccessDetails key={1} task={dataTask} />] : [],
    currentInfo: (
      <div>
        {(response?.metadata || (response?.key === 'me' && user)) &&
          <>
            <p className={`body-card body-card-${dataTask?.state}`}>CURRENT CONTACT</p>
            <div className="flex-btw">
              <p className={`body-card body-card-${dataTask?.state} body-card-w5`}>
                {(response?.metadata?.name || user?.name)}
              </p>
            </div>
          </>
        }
      </div>
    )
  };

  return {
    card,
    dataTask
  };
};

export default useCardPropertyAccess;