import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import arrowPng from 'assets/images/arrow-blue.png';
import { TaskState } from 'gql/graphql';
import React, { useEffect, useState } from 'react';
import ModalEndSubmit from '../../../Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Props {
  title: string;
  description?: string;
  isButtonSample?: { show: boolean; link: string };
  isButtonTemplate?: { show: boolean; link: string };
  submit?: {
    title: string;
    confirmDescription?: string;
    modalConfirm?: boolean;
    loading: boolean;
    onClick: (files: File[]) => void;
    location?: 'outside' | 'inside' | undefined;
    disabled?: boolean;
    ignoreDisabledNotFiles?: boolean;
  };
  limitFiles?: number;
  files?: File[] | any;
  state?: TaskState;
  hiddenTagDraft?: boolean;
  actionDeleteFile?: any;
  onChangeFiles?: any;
  insertHtmlBottom?: any;
}

const CardUploadResponse = ({
  title,
  description,
  isButtonSample,
  isButtonTemplate,
  submit,
  limitFiles = 1,
  files,
  state,
  actionDeleteFile,
  onChangeFiles,
  hiddenTagDraft,
  insertHtmlBottom
}: Props) => {

  const [listFileUpload, setListFileUpload] = useState([]);
  const [isModalSubmit, setModalSubmit] = useState(false);

  useEffect(() => {
    if (onChangeFiles) onChangeFiles(listFileUpload);
  }, [listFileUpload]);

  const onSubmit = () => {
    if (submit?.modalConfirm) setModalSubmit(true);
    else submit?.onClick(listFileUpload);
  };

  return (
    <>
      <div className={`card-upload-response card-upload-response-${state}`}>
        {state && (state !== TaskState.Review && !hiddenTagDraft) && <StateTag state={state} />}
        {state && (state === TaskState.Review) && <StateTag state={state} isLabelLocked />}
        <div className="header" style={{ marginTop: !isButtonSample ? 5 : 0 }}>
          <span className="h4">{title}</span>
          {isButtonSample?.show && (
            <Button type="link" className="link" onClick={() => window.open(isButtonSample.link, '_blank')}>
              See sample
            </Button>
          )}
        </div>
        <div className="description">{description}</div>
        {isButtonTemplate?.show && (
          <div className="template">
            <Button type="link" className="button button-blue" onClick={() => window.open(isButtonTemplate.link, '_blank')}>
              Download template
            </Button>
            <img src={arrowPng} className="arrow-right" alt="arrow" />
          </div>
        )}
        <div className="section-upload">
          {files && (
            <div className="cont-files">
              {files?.filter((item: any) => item.state !== 'request_error').map((file: any, index: number) => (
                <InputUploadedFile
                  key={index}
                  file={file}
                  isActionSeeFile
                  state={state ? file.state : null}
                  deleteFile={
                    (file.state !== 'approved' && file.state !== 'review') ? 
                    actionDeleteFile : null
                  }
                />
              ))}
            </div>
          )}

          {(!files || files?.length === 0 || state === TaskState.RequestError) && (
            <UploadDocs
              numberOfFiles={limitFiles}
              loading={submit?.loading}
              listFileUpload={listFileUpload}
              setListFileUpload={setListFileUpload}
              responsesFiles={files}
              button={
                submit && (!submit.location || submit.location === 'inside') ? (
                  <Button
                    className="button button-submit"
                    loading={submit.loading}
                    disabled={listFileUpload.length === 0 || submit.disabled}
                    onClick={onSubmit}
                  >
                    {submit.title}
                  </Button>
                ) : null
              }
            />
          )}
        </div>

        {submit?.modalConfirm && (
          <ModalEndSubmit
            visible={isModalSubmit}
            setVisible={setModalSubmit}
            buttonAction={{
              hidden: true
            }}
            modal={{
              title: 'Ready to submit?',
              description: submit?.confirmDescription ? (
                <h4 className="h4 h4-w4">{submit?.confirmDescription}</h4>
              ) : undefined
            }}
            buttonSubmit={
              <Button
                className="button button-modal-ok"
                onClick={() => {
                  setModalSubmit(false);
                  submit.onClick(listFileUpload)
                }}
                loading={submit.loading}
              >
                Yes, this is correct
              </Button>
            }
            buttonCancel={(actionCancel: any) => (
              <Button
                className="button button-modal-cancel"
                onClick={actionCancel}
              >
                I want to make changes
              </Button>
            )}
            icon={calendarPng}
            className="modal-end-submit--white"
          />
        )}
        {insertHtmlBottom}
        
      </div>
      {submit?.location === 'outside' && (
        <div className="mt-20">
          <Button
            className="button button-submit"
            loading={submit?.loading}
            disabled={(listFileUpload.length === 0 && !submit.ignoreDisabledNotFiles) || submit.disabled}
            onClick={onSubmit}
          >
            {submit?.title}
          </Button>
        </div>
      )}
    </>
  );
};
export default CardUploadResponse;
