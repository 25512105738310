import { useQuery } from 'urql';
import { GET_USER_LOGGIN } from './graphql';
import { GetUserLogginQuery, User } from 'gql/graphql';

declare global {
    interface Window {
        __insp: any;
    }
}

export const useGetUserLoggin = (query?: any) => {
    const [{ data, fetching, error }] = useQuery<GetUserLogginQuery>({ query: query || GET_USER_LOGGIN });
    
    if (data?.GetUserLoggin?.email) {
        window.__insp = window.__insp || [];
        window.__insp.push(['identify', {
            email: data.GetUserLoggin.email
        }]);
    }

    return { data: data?.GetUserLoggin, fetching, error };
}
