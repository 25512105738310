import React, { useContext, useState } from 'react';
import QuestionBasic from 'components/Organisms/QuestionBasic/QuestionBasic';
import { Task, TaskState } from '../../../../../../gql/graphql';
import { TaskContext } from '../Payoff';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';

interface Step1Props {
  setStep: Function;
  closeTask: Function;
}
const Step1 = ({ setStep, closeTask }: Step1Props) => {
  const task: Task | any = useContext(TaskContext);

  const { execute: createResponseTask } = useCreateResponseTask();

  const onChangeOption1 = async (option: {
    id: number;
    key: string;
    title: string;
  }) => {
    await createResponseTask({
      key: option.key,
      label: option.title,
      step: 1,
      metadata: {},
      state: TaskState.Approved,
      task: task?._id
    });

    setStep(2);
  };

  return (
    <section>
      <div className="section-1">
        <div className="mt-20">
          <QuestionBasic
            type="buttons"
            question={<h3 className="h2">Do you have a payoff letter that’s good through the estimated closing date?</h3>}
            text={
              <p className="h4 h4-w4">
                If not, you’ll need to give us the contact information for your agent so we can reach out and make sure you’re insured
              </p>
            }
            options={[
              {
                id: 1,
                title: 'Yes, I have a payoff letter',
                onClick: (option: any) =>
                  onChangeOption1({ key: 'yes', ...option })
              },
              {
                id: 2,
                title: 'No, I have a payoff contact',
                onClick: (option: any) =>
                  onChangeOption1({ key: 'no', ...option }),
              }
            ]}
          />
        </div>
      </div>
    </section>
  );
};
export default Step1;
