import { useMutation } from 'urql';
import { CreateOwnershipInput, CreateOwnershipMutation, DeleteOwnershipMutation, MutationCreateOwnershipArgs, MutationDeleteOwnershipArgs } from 'gql/graphql';
import { MUTATION_CREATE_OWNERSHIP, MUTATION_DELETE_OWNERSHIP } from './graphql';

export const useCreateOwnership = (query?: any) => {
  const [results, execute] = useMutation<CreateOwnershipMutation>(query || MUTATION_CREATE_OWNERSHIP);

  return {
    ...results,
    execute: (data: CreateOwnershipInput) =>
      execute({ createOwnershipInput: data }),
    data: results.data?.CreateOwnership,
  };
};

export const useDeleteOwnership = (query?: any) => {
  const [results, execute] = useMutation<DeleteOwnershipMutation>(query || MUTATION_DELETE_OWNERSHIP);

  return {
    execute: (data: MutationDeleteOwnershipArgs) => execute(data),
    data: results.data?.DeleteOwnership,
    ...results
  };
};