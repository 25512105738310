import React, { useState } from 'react';
import { Button } from 'antd';
import { CheckOutlined, CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import alert from 'assets/images/alert-small-border.svg';
import alertGreen from 'assets/images/alert-green.svg';
import iconErrorSvg from 'assets/images/alert-circle-red.svg';

interface Props {
  state: 'complete' | 'no-guarantor' | 'next-step' | 'incomplete' | 'verify' | 'error';
  className: string;
  onChange?: Function;
  isExpand?: boolean;
  setExpand?: any;
}

const TagOwnerState = ({ state, className, onChange, isExpand, setExpand }: Props) => {
  
  const changeExpand = () => {
    setExpand(!isExpand);
    if(onChange) onChange(!isExpand);
  }

  return (
    <div className={`${className}`}>
      <div className={`tag-owner-content tag-owner-content-${state}`}>
        {state === 'complete' && (
          <>
            <b className={`approved`}>
              <CheckOutlined /> Profile complete
            </b>
            <b>|</b>
          </>
        )}
        {state === 'next-step' && (
          <>
            <b><CloseOutlined /> No guarantor profile</b>
            <span className='tag-description'>You’ll make a profile in the next step</span>
          </>
        )}
        {state === 'error' && (
          <>
            <b><img src={iconErrorSvg} alt="upload" className="upload" /> Profile error</b>
            <span className='tag-description'>Update and resubmit</span>
            <b>|</b>
          </>
        )}
        {state === 'incomplete' && (
          <>
            <b><img src={alert} width="15"/> Complete guarantor profile</b>
            <b>|</b>
          </>
        )}
        {state === 'verify' && (
          <>
            <b><img src={alertGreen} width="15"/> Verify guarantor profile</b>
            <b>|</b>
          </>
        )}
        {state === 'no-guarantor' && (
          <>
            <b>No profile needed</b>
            <span>Ownership is less than 20%</span>
          </>
        )}
        {(state === 'complete' || state === 'incomplete' || state === 'verify' || state === 'error') && 
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={changeExpand}
          >
            {!isExpand && 'View profile'}
            {isExpand && 'Close profile'}
            {!isExpand && <DownOutlined />}
            {isExpand && <UpOutlined />}
          </Button>
        }
      </div>
    </div>
  );
};
export default TagOwnerState;
