import React, { useContext, useEffect, useState } from 'react';
import clockCircleSvg from 'assets/images/clock-background.svg';
import calendarPng from 'assets/images/calendar.png';
import errorSvg from 'assets/images/error-red.svg';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { Button } from 'antd';
import { File } from '@common/utils/interfaces/file.interface';
import { useCreateResponseTask, useDeleteFileResponse } from 'services/task/mutations';
import { TaskContext } from '../../MinimumLiquidity';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { useGetTaskResponseComments } from 'services/task/querys';
import { ModalTaskRightContext } from 'components/Organisms/Modals/ModalTask/ModalTask';
import { formatterCurrency } from '@common/utils/formatters/formatter';

interface CardStateAccountProps {
  account: TaskResponse;
  state: string;
  label: React.ReactElement;
  subTitle: React.ReactElement;
  text: React.ReactElement | null;
}

const CardStateAccount = ({
  account,
  state,
  label,
  subTitle,
  text
}: CardStateAccountProps) => {
  const task: Task | any = useContext(TaskContext);
  const { setHandler }: any = useContext(ModalTaskRightContext);

  const { execute: _deleteFile } = useDeleteFileResponse();
  const { data: dataCreateResponse, fetching, execute: createResponseTask } = useCreateResponseTask();
  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId: task?._id, state: 'pending' }
  });

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [listFileUpload, setListFileUpload] = useState([]);
  const [numberOfFiles] = useState(2);
  const [balance, setBalance] = useState<any>(null);

  useEffect(() => {
    if (dataCreateResponse) {
      setIsModalConfirm(false);
      setListFileUpload([]);
    }
  }, [dataCreateResponse]);

  useEffect(() => {
    if (account.metadata)
      setBalance(account.metadata.balance);
  }, [account?.metadata])

  const deleteFile = (fileKey: string): void => {
    _deleteFile({ fileKey, taskResponseId: account._id });
  };

  const seeFile = (file: File) => window.open(file.url, '_blank');

  const addFilesForReview = async () => {
    if (task) {
      await createResponseTask({
        _id: account._id,
        key: account.key,
        label: account.label,
        step: 2,
        metadata: {
          ...account.metadata,
          balance
        },
        state: TaskState.Review,
        task: task?._id,
        files: listFileUpload
      });
    }
  };
  
  const filesApproved = account.files?.filter(file => {
    const _state = file.state.toUpperCase();
    return _state === TaskState.Approved || _state === TaskState.Review
  })?.length || 0;
  const issuesAccount = taskComments?.filter(item => item.taskResponse._id === account._id);

  return (
    <div className={`CardStateAccount CardStateAccount--${state}`}>
      {label}
      {(state === 'inReview' || state === 'approved') && (
        <div className="CardStateAccount_head">
          <div className="box">
            <h3 className="body-regular body-regular-w6" style={{ marginBottom: 16 }}>
              {account.metadata.nameAccount}
            </h3>
            {subTitle}
            <p className="body-card body-card-dark">
              $
              <span className="fz-14">
                {account.metadata.balance?.toLocaleString('en')}
              </span>
            </p>
          </div>
          <div className="box" style={{ width: 300 }}>
            {account.files?.filter(file => file.state.toUpperCase() !== TaskState.RequestError)?.map((file, index) => (
              <InputUploadedFile
                key={index}
                file={file}
                seeFile={seeFile}
                state={file.state as TaskState}
                icon={
                  file.state.toUpperCase() === TaskState.Approved
                    ? fileCheckSvg
                    : file.state.toUpperCase() === TaskState.Review
                      ? clockCircleSvg
                      : errorSvg
                }
              />
            ))}
          </div>
        </div>
      )}
      {state === 'error' && (
        <div>
          <div className="row">
            {text}
          </div>
          <div className="section-upload">
            <UploadDocs
              numberOfFiles={
                account?.files?.length == 0 ? numberOfFiles :
                  (account?.files?.filter(file => file.state.toUpperCase() === TaskState.RequestError).length || numberOfFiles)
              }
              button={null}
              listFileUpload={listFileUpload}
              setListFileUpload={setListFileUpload}
              responsesFiles={account?.files as any}

            />
          </div>
          <div className="divider">
            <div className="row row-btw">
              <div className="box">
                <h3 className="body-regular body-regular-w6" style={{ marginBottom: 16 }}>
                  {account.metadata.nameAccount}
                </h3>
                {subTitle}
                <InputBorderNone
                  type="number"
                  formatter={"currency"}
                  className="body-placeholder-w4"
                  label=""
                  placeholder="Enter amount"
                  onChange={(value: any) => setBalance(value)}
                  value={balance}
                />
                {/*
                  <p className="body-card body-card-dark">
                    $
                    <span className="fz-14">
                      {account.metadata.balance?.toLocaleString('en')}
                    </span>
                  </p>
                */}
              </div>
              <div className={`box`}>
                {account.files?.filter(file => file.state.toUpperCase() !== TaskState.RequestError)
                  .map((file, index) => (
                    <InputUploadedFile
                      key={index}
                      file={file}
                      seeFile={seeFile}
                      state={file.state as TaskState}
                      icon={
                        file.state.toUpperCase() === TaskState.Approved
                          ? fileCheckSvg
                          : file.state.toUpperCase() === TaskState.Review
                            ? clockCircleSvg
                            : errorSvg
                      }
                      deleteFile={
                        file.state.toUpperCase() === TaskState.Approved || file.state.toUpperCase() === TaskState.Review ?
                          undefined : () => deleteFile(file.key)
                      }
                    />
                  ))}
              </div>
            </div>
          </div>

          {(
            ((listFileUpload.length + (account.files?.length || 0)) >= numberOfFiles) ||
            (balance && balance !== account.metadata.balance)) && (
              <ModalEndSubmit
                visible={isModalConfirm}
                setVisible={setIsModalConfirm}
                buttonAction={{
                  label: listFileUpload.length !== numberOfFiles - filesApproved ?
                    `Upload ${(numberOfFiles - filesApproved)} document and confirm balance to resubmit` :
                    'Submit for review',
                  disabled: listFileUpload.length !== numberOfFiles - filesApproved,
                  loading: fetching
                }}
                modal={{
                  title: 'Ready to submit?',
                  description: (
                    <h4 className="h4 h4-w4">
                      After submitting, you’ll have to contact us if you want to
                      make changes
                    </h4>
                  )
                }}
                buttonSubmit={
                  <Button
                    className="button button-modal-ok"
                    onClick={addFilesForReview}
                    loading={fetching}
                  >
                    Yes, this is correct
                  </Button>
                }
                buttonCancel={(actionCancel: any) => (
                  <Button
                    className="button button-modal-cancel"
                    onClick={actionCancel}
                  >
                    I want to make changes
                  </Button>
                )}
                icon={calendarPng}
                className="modal-end-submit--white"
                checkboxDontShow={{
                  label: 'Don’t show this message again for this task',
                  storageKey: 'minimium-confirm-new-account',
                  autoSubmit: addFilesForReview
                }}
              />
            )}

          <div className="divider divider-dark">
            <div className="row row-btw">
              <div className="box">
                <h3 className="body-regular body-regular-w6">
                  Details from your loan officer
                </h3>
              </div>
            </div>

            {issuesAccount?.map(issue => (
              <div className="row" key={issue._id}>
                <div className='issue-text'>
                  <h3 className="body-regular body-regular-w6">
                    {issue.rejectionCode.label}.
                  </h3>{' '}
                  <span>{issue.description}</span>
                </div>
                <Button className='button button-link-error' onClick={() =>
                  setHandler(true, {
                    comment: issue,
                    noteErrorDetail: issue.metadata?.rejectedAmount ?
                      <h4 className="text-error-note">
                        <span>Rejected amount:</span>{' '}
                        {formatterCurrency(issue?.metadata?.rejectedAmount || 0)}
                      </h4>
                      : null
                  })
                }>
                  See details
                </Button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default CardStateAccount;
