import React, { useState } from 'react';
import CardEstimatedDSCR from './CardEstimatedDSCR/CardEstimatedDSCR';

interface Step1Props {
  setStep: Function;
}
const Step1 = ({ setStep }: Step1Props) => {
  return (
    <section>
      <h3 className="h3">
        You estimated your DSCR when you started your loan application. Let’s
        get some documentation for it
      </h3>
      <h2 className="h2">First, are these estimations correct?</h2>
      <div className="">
        <CardEstimatedDSCR setStep={setStep} />
      </div>
    </section>
  );
};
export default Step1;
