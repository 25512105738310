import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useCreateResponseTask } from 'services/task/mutations';
import { Button } from 'antd';
import coinPng from 'assets/images/coin-shadow.png';
import { SubmitContext, TaskContext } from '../EntityGuarantorCreation';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { useCreateEntity } from 'services/entity/mutations';
import { LoanContext } from 'scenes/Private/Dashboard/Dashboard';
import moment from 'moment';
import useGuarantorUtil from '../useGuarantorUtil';

interface Step2Props {
  ownerships: any[];
  entity?: { key: string; name: string };
  step2Confirm?: any;
  newOwner: any;
  responseStep1: TaskResponse;
  taskEntityDocs?: Task;
}
const ButtonSubmit = ({
  ownerships,
  entity,
  step2Confirm,
  newOwner,
  responseStep1,
  taskEntityDocs
}: Step2Props) => {
  const task: Task | any = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);
  const onSubmitParent: { step?: number; active?: boolean } | any =
    useContext(SubmitContext);

  const { getGuarantorsName, getGuarantorsCount } = useGuarantorUtil({ ownerships });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();
  const { execute: createEntity } = useCreateEntity();

  const [isModalConfirm, setModalConfirm] = useState<boolean>(false);
  const [referenceEntityId, setReferenceEntityId] = useState<String | any>();

  useEffect(() => {
    if (onSubmitParent.active && onSubmitParent.step === 2) submitOwnerships();
  }, [onSubmitParent]);

  useEffect(() => {
    if (responseStep1?.label) setReferenceEntityId(responseStep1.key);
  }, [responseStep1]);

  const getTotal = () =>
    ownerships
      .reduce(
        (total: number, ownership: any) => total + ownership.ownership,
        0
      );

  const submitOwnerships = () => {
    createResponseTask({
      task: task._id,
      key: `complete-ownerships`,
      label: `Add 100% ownership to submit`,
      step: 3,
      metadata: {},
      state: TaskState.Approved
    });

    createEntity({
      _id:
        responseStep1.label === 'Existing entity'
          ? responseStep1.key
          : undefined,
      name: entity?.name || `Entity created on ${moment().format('DD/MM/YY')}`,
      loans: [loan._id],
      task: task._id
    });
  };


  return (
    <ModalEndSubmit
      visible={isModalConfirm}
      setVisible={setModalConfirm}
      buttonAction={{
        label: step2Confirm
          ? 'Confirm ownership'
          : getTotal() !== 100
            ? 'Add 100% ownership to submit'
            : 'Create entity and complete profiles',
        disabled: getTotal() !== 100 && !step2Confirm,
        loading: fetching,
        hidden: newOwner
      }}
      modal={{
        title: !step2Confirm
          ? `It looks like ${getGuarantorsName()} ${getGuarantorsCount && getGuarantorsCount > 1 ? 'all own' : 'owns'
          } at least 20% of your entity.`
          : `Confirm your entity ownership?`,
        description: (
          <h4 className="h4 h4-w4">
            {step2Confirm
              ? `Because of this, they’ll automatically be added as
                      guarantors on the loan and we’ll need to run a credit and
                      background check on them.`
              : `After submitting, you’ll have to contact us if you want to make changes`}
          </h4>
        )
      }}
      className="modal-end-submit--white"
      icon={coinPng}
      buttonSubmit={
        <Button className="button button-modal-ok" onClick={submitOwnerships}>
          {step2Confirm ? 'Yes, submit it' : 'Yes, this is correct'}
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => setModalConfirm(false)}
        >
          {step2Confirm
            ? 'I need to make some changes'
            : 'I want to make changes'}
        </Button>
      }
    />
  );
};
export default ButtonSubmit;
