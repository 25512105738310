import React from 'react';
import { Select, Space } from 'antd';

interface Options {
  value: string,
  label: string,
}

interface SelectProps {
  label: string | null;
  placeholder: string | null;
  suffixIcon: string | null;
  options: Options[];
  handleSelect: Function;
}
const SelectPrimary = ({ label, placeholder, suffixIcon, options, handleSelect }: SelectProps) => {

  const handleChange = (value: string) => {
    handleSelect(value);
  };

  return (
    <Space className="flex-col SelectPrimary">
      {label && <p className="body-regular">{label}</p>}
      <Select
        placeholder={placeholder}
        className="select select-primary"
        onChange={handleChange}
        suffixIcon={
          suffixIcon && <img src={suffixIcon} alt="icon" className="icon" />
        }
        options={options}
      />
    </Space>
  );
};

export default SelectPrimary;
