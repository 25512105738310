import React, { useEffect, useState } from "react";
import addDocSvg from 'assets/images/addDocSvg.svg';
import screenPng from "assets/images/screenshop.png";
import Layout from "../components/Layout/Layout";
import FormChangePassword from "./components/FormResetPassword/FormChangePassword";
import { useNavigate, useParams } from "react-router-dom";
import { useVerifyTokenResetPassword } from "../../../services/auth/querys";
import { Button } from "antd";
import { useSendEmailResetPassword } from "../../../services/auth/mutations";
import ModalBasic from "../../../components/Organisms/Modals/ModalBasic/ModalBasic";

const ChangePassword = () => {

  const navigate = useNavigate();
  let { token }: any = useParams();

  const { execute: sendEmailResetPassword } = useSendEmailResetPassword();
  const { data: isVerifyToken, fetching } = useVerifyTokenResetPassword({ variables: { token } });
  
  const [modalConfirmEmail, setModalConfirmEmail] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>();

  const onReadyResetPassword = () => {
    setReady(true);
  }

  const redirectSignIn = () => {
    //COPIADO DEL BarDashboard
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
    navigate("/");
    navigate(0);
  }

  const onSendEmailResetPassword = () => {
    sendEmailResetPassword();
    setModalConfirmEmail(true);
  }

  if(fetching)
    return null;

  return (
    <Layout>
      <div className="Login view-change-password">
        {ready && 
          <div className="Login-form">
            <img src={addDocSvg} className="coin" alt="coin" />
            <h1 className="h1">Your password has been updated!</h1>
            <p className="description">Click below to sign in with your new password</p>
            <Button className="button button-primary button-txt-big" onClick={redirectSignIn}>
              Sign in
            </Button>
          </div>
        }
        {!ready && isVerifyToken && 
          <div className="Login-form">
            <img src={addDocSvg} className="coin" alt="coin" />
            <h1 className="h1">Create a new password</h1>
            <FormChangePassword onReady={onReadyResetPassword} handlerRedirectSignIn={redirectSignIn}/>
          </div>
        } 
        {!ready && !isVerifyToken && 
          <div className="Login-form">
            <img src={addDocSvg} className="coin" alt="coin" />
            <h1 className="h1">Oops! That link is expired.</h1>
            <p className="description">If you’d still like to change your password, you’ll need to generate a new link.</p>
            <Button className="button button-primary button-txt-big" onClick={onSendEmailResetPassword}>
              Reset Password
            </Button>
          </div>
        }
        <div className="rigth">
          <div className="">
            <h2 className="h2">
              Testimonial goes here Testimonial goes here Testimonial goes here
              Testimonial goes here Testimonial goes here
            </h2>
            <p className="body-regular">— Ricardo Sims, Title @company</p>
          </div>
          <div className="bottom-flex">
            <div className="back">
              <img src={screenPng} className="screen" alt="screen" />
            </div>
          </div>
        </div>
      </div>

      <ModalBasic
        visible={modalConfirmEmail}
        setVisible={setModalConfirmEmail}
        title='Check your email'
        description={'We sent you instructions on how to reset your password. Please follow the link in the email to create a new password.'}
        submit={{ buttonLabel: 'OK!', onSubmit: () => setModalConfirmEmail(false) }}
      />
    </Layout>
  );
};
export default ChangePassword;