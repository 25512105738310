import React, { useContext, useEffect, useState } from 'react';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import {
  Task,
  TaskResponse,
  TaskState
} from '../../../../../../gql/graphql';
import { TaskContext } from '../Payoff';
import ModalFinishTask from './ModalFinishTask';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import CardLender from './CardLender';
import CardUploadResponse from 'components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import AddAnotherDoc from 'scenes/Private/components/AddAnotherDoc/AddAnotherDoc';

interface Props {
  setStep: Function;
  closeTask: Function;
}

const Step2 = ({ setStep, closeTask }: Props) => {
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { fetching, execute: createResponseTask } = useCreateResponseTask();

  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [responseStep1, setResponseStep1] = useState<TaskResponse | any>();
  const [responsePayoffLetter, setResponsePayoffLetter] = useState<TaskResponse | any>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    const _response1 = taskResponses?.find((item) => item.step === 1);
    setResponseStep1(_response1);

    const _response = taskResponses?.find((item) => item.key === 'payoff-letter');
    setResponsePayoffLetter(_response);

    const _responseLender = taskResponses?.find((item) => item.key === 'payoff-lender');

    setTaskCompleted(_response?.state === TaskState.Approved || _responseLender?.state === TaskState.Approved);
  }, [taskResponses]);

  const submit = async (files: any) => {
    let payload: any = {
      key: 'payoff-letter',
      label: 'Payoff letter',
      step: 2,
      metadata: {},
      state: TaskState.Review,
      task: task?._id,
      files
    };

    if (responsePayoffLetter) payload._id = responsePayoffLetter._id;

    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinishTask(true);
  };

  return (
    <section className='insurance-step-2 '>
      <div className="section-1">
        <h3 className="h3">
          {responseStep1?.key === 'yes' ? 'Upload your payoff letter' : 'Who is your current lender?'}
        </h3>
        <div className="mt-10">
          <p className="h4 h4-w4">
            {responseStep1?.key === 'yes' ?
              'Remember, it needs to be good through the closing date' :
              'We’ll reach out and get a up-to-date payoff letter'
            }
          </p>
        </div>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} />
        }

        <div className="mt-40">
          {responseStep1?.key === 'yes' ?
            <CardUploadResponse
              title="Payoff letter"
              isButtonSample={{ show: true, link: '' }}
              files={responsePayoffLetter?.files}
              state={responsePayoffLetter?.state}
              limitFiles={1}
              submit={{
                title: 'Submit for review',
                disabled: responsePayoffLetter,
                loading: fetching,
                modalConfirm: true,
                confirmDescription:
                  "After submitting, you'll have to contact us to make any changes.",
                onClick: (files) => submit(files)
              }}
            />
            :
            <CardLender setModalFinishTask={setModalFinishTask} />
          }

        </div>
      </div>

      <AddAnotherDoc taskId={task._id} taskState={task.state}/>

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required'/>

      <ModalFinishTask
        visible={modalFinishTask}
        setVisible={setModalFinishTask}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step2;
