import React from 'react';
import { Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
	params: any;
	close: any;
}

const SectionRight = ({ params, close }: Props) => {
	return (
		<div className='ModalTask-content-right'>

			<Button type="link" className="button-close" onClick={close}>
				X
			</Button>

			{params?.isTitleIssues &&
				<h3 className="h3">Issues with “{params.comment.title}”</h3>
			}

			{!params?.isTitleIssues &&
				<h3 className="h3">{params.comment.title}</h3>
			}

			{params?.noteErrorDetail &&
				<div className='mt-30'>{params?.noteErrorDetail}</div>
			}

			{/*<div className='mt-10'>
				<p className="body-regular">
					<span className="bold">{params?.comment?.subtitle}{'. '}</span>
					{params?.comment?.description}
				</p>
		</div>*/}

			{params.comment?.markdown &&
				<div className='mt-10'>
					<ReactQuill
						value={params.comment?.markdown}
						readOnly={true}
						theme={"bubble"}
						style={{ height: 'auto' }}
					/>
				</div>
			}
		</div>
	);
};

export default SectionRight;
