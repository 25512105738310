import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import TaskProgress from 'components/Atoms/Progress/TaskProgress/TaskProgress';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/cort.png';
import Step2 from './Step2/Step2';
import Step1 from './Step1/Step1';
import { Task, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { formatPriceResume } from '@common/utils/formatters/formatter';
import MinimumProgressVertical from 'components/Atoms/Progress/MinimumProgressVertical/MinimumProgressVertical';

export const TaskContext = createContext<Task | {}>({});

interface MinimumLiquidityProps {
  task: Task;
  maxBalance?: any;
}

const MinimumLiquidity = ({ task, ...props }: MinimumLiquidityProps) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const [step, setStep] = useState(1);
  const [totalBalance, setTotalBalance] = useState<any>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);

  const { data: taskResponses } = useGetTaskResponsesByTask({ variables: { findResponsesByTask: { taskId: task._id } } });

  useEffect(() => {
    if (openContext === 'minimum_liquidity' || openContext === 'minimium_liquidity') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    const responsesStep2 = taskResponses?.filter((item) => item.step === 2);
    setIsReview(!!responsesStep2?.some((item: any) => item.state === TaskState.Review));
  }, []);

  const onHelpDocs = () => { };

  if (!task) return null;

  const { taskName, phase } = findTaskRules(ModuleKeys.MINIMUM_LIQUIDITY);

  const renderTracker = () => (
    <div style={{ height: '100%' }}>
      <span className='title-summary'>Task summary</span>
      <h1 className='task-title'>Minimum Liquidity</h1>
      <MinimumProgressVertical
        title={<h4 className="h4 h4-w4">Minimum liquidity</h4>}
        price={<h4 className="body-card body-card-w5">{formatPriceResume(task?.metadata.jungoLimitBalance)}</h4>}
        totalBalance={props.maxBalance || 0}
        balances={totalBalance}
        colorBackground="#DFE4EB"
      />
    </div>
  );

  return (
    <TaskContext.Provider value={task}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        disabledDots={true}
        isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
            disabled={!task?.metadata.jungoLimitBalance}
          >
            {(task.state === TaskState.Review || task.state === TaskState.Approved) ?
              'View task' :
              task.state === TaskState.RequestError ?
                'Resolve issues' :
                'Add docs'
            }
          </Button>
        )}
        header={{
          title: <span>{phase} | {taskName.toUpperCase()}</span>,
          actionLeft:
            isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />,
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        responses={taskResponses}
        stepsTotal={2}
        currentStep={step}
        setStep={setStep}
        tracker={renderTracker()}
      >
        <div className="children MinimumLiquidity">
          {step === 1 &&
            <Step1 setStep={setStep} handlerCloseModalTask={setModalTaskVisible} />
          }
          {step === 2 && (
            <Step2
              totalBalance={totalBalance}
              limitBalance={props.maxBalance || 0}
              handlerCloseModalTask={setModalTaskVisible}
              handlerSetTotalBalance={setTotalBalance}
            />
          )}
        </div>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default MinimumLiquidity;
