import React from "react";
import PropTypes from 'prop-types';

import Header from "scenes/Public/components/Header/Header";

const Layout = ({ children }: any) => {
  return (
    <section className="Layout">
      <div className="content">
        <Header />
        {children}
      </div>
    </section>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
