import { gql } from "urql";

export const GET_USER_LOGGIN: any = gql(`
    query GetUserLoggin{
        GetUserLoggin {
            _id
            name
            lastName
            email
            phone
            avatar
        }  
    }
`)