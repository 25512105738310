import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import arrowSelect from 'assets/images/arrow-select.svg';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import ButtonSubmit from 'components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { useCreateResponseTask } from 'services/task/mutations';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import CardUploadResponse from 'components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { PlusCircleOutlined } from '@ant-design/icons';
import SelectPrimary from 'components/Atoms/Selects/SelectPrimary/SelectPrimary';
import { TasksContext } from 'scenes/Private/Dashboard/Dashboard';
import { TaskKeysAnotherDoc } from '@common/utils/constants/add-another-doc.constanst';

interface Props {
  taskId?: string;
  taskState?: TaskState;
  hiddenDocs?: boolean;
}

const AddAnotherDoc = ({ taskId, taskState, hiddenDocs }: Props) => {
  const tasks: any = useContext(TasksContext);
  const [form] = useForm();

  const taskAddDocs = tasks.find((item: any) => item.key === 'add-docs');
  
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { taskId: taskId || taskAddDocs?._id }
    },
    pause: true
  });
  const { data: createResponse, fetching, execute: createResponseTask } = useCreateResponseTask();

  const [listFileUpload, setListFileUpload] = useState([]);
  const [isFormActive, setFormActive] = useState<boolean>(!taskId);
  const [docs, setDocs] = useState<any>();

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [tasks])

  useEffect(() => {
    setDocs(taskResponses?.filter(item => item.key === 'doc-another'));
  }, [taskResponses]);

  useEffect(() => {
    if (createResponse) {
      form.resetFields();
      setListFileUpload([]);

      if(taskId) setFormActive(false);
    }
  }, [createResponse]);

  const onFinish = async (values: any) => {
    await createResponseTask({
      key: 'doc-another',
      label: values.name,
      step: -1,
      metadata: values,
      state: taskId? TaskState.Review: TaskState.Approved,
      task: taskId || taskAddDocs._id,
      //task: taskId || values.taskId,
      files: listFileUpload
    });

    getTaskResponses({ requestPolicy: 'network-only' });
  }

  const saveChanges = async (doc: TaskResponse, files: any) => {
    if (taskId || taskAddDocs._id) {
      await createResponseTask({
        _id: doc._id,
        key: 'doc-another',
        label: doc.label,
        step: -1,
        metadata: doc.metadata,
        state: TaskState.Review,
        task: taskId || taskAddDocs._id,
        files: files
      });

      getTaskResponses({ requestPolicy: 'network-only' });
    }
  }

  let tasksList = tasks.filter((item: Task) => TaskKeysAnotherDoc.includes(item.key || null));
  tasksList = tasksList.map((item: Task) => ({ value: item._id, label: item.name }))

  return (
    <section className='add-another-doc'>
      {!hiddenDocs && docs?.length > 0 && docs.map((doc: TaskResponse) => (
        <div className="mt-30" key={doc._id}>
          <CardUploadResponse
            title={doc.label}
            description={doc.metadata?.description}
            state={doc?.state}
            files={doc?.files}
            submit={{
              title: 'Submit for review',
              loading: fetching,
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) => saveChanges(doc, files)
            }}
          />
        </div>
      ))}
      {isFormActive &&
        <div className="card-create-doc mt-30">
          <Form
            form={form}
            name="basic"
            className='form'
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              name="name"
              className='form-doc-name'
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your doc name!'
                }
              ]}
            >
              <InputBorderNone
                placeholder="Enter doc name"
                className="form-task-name"
              />
            </Form.Item>

            <Form.Item
              name="description"
              style={{ marginBottom: 15 }}
              rules={[
                {
                  required: true,
                  message: 'Please input your doc description!'
                }
              ]}
            >
              <InputBorderNone
                placeholder="Leave a note for our processing team"
                className="form-doc-description"
              />
            </Form.Item>

            {/*!taskId &&
              <Form.Item
                name="taskId"
                style={{ marginBottom: 15 }}
                rules={[
                  {
                    required: true,
                    message: 'Please input your task!'
                  }
                ]}
              >
                <SelectPrimary
                  placeholder={"Select task"}
                  label="Link doc to a task (optional)"
                  suffixIcon={arrowSelect}
                  options={tasksList}
                  handleSelect={(taskId: string) => 
                    form.setFieldValue('taskId', taskId)
                  }
                />
              </Form.Item>
            */}

            <UploadDocs
              numberOfFiles={1}
              listFileUpload={listFileUpload}
              setListFileUpload={setListFileUpload}
            />

            <Form.Item className="submit" shouldUpdate>
              {() => (
                <ButtonSubmit
                  disabled={
                    !form.getFieldsError().every((fieldErr) => fieldErr.errors.length === 0) ||
                    !form.isFieldsTouched() ||
                    !Object.values(form.getFieldsValue()).every(value => value) ||
                    listFileUpload.length === 0
                  }
                  modalConfirm={{ show: true }}
                  onClick={form.submit}
                  loading={fetching}
                >
                  Submit for review
                </ButtonSubmit>
              )}
            </Form.Item>
          </Form>
        </div>
      }

      {taskId && 
        <Button
          type="link"
          className="button button-link-blue mt-10"
          onClick={() => setFormActive(true)}
          disabled={isFormActive || taskState === TaskState.Approved || taskState === TaskState.Review}
        >
          <PlusCircleOutlined />
          Add another doc
        </Button>
      }
    </section>
  );
};
export default AddAnotherDoc;