import { formatterCurrency } from '@common/utils/formatters/formatter';
import React, { useEffect, useState } from 'react';

interface Props {
  title: React.ReactElement;
  price: React.ReactElement | null;
  totalBalance: number;
  balances: {
    error: number;
    draft: number;
    success: number;
    review: number;
  };
  className?: string;
  colorBackground: string | '#DFE4EB';
}

const MinimumProgressVertical = ({
  title,
  price,
  balances,
  totalBalance,
  colorBackground,
  className
}: Props) => {
  const [percentages, setPercentages] = useState<any>({});

  useEffect(() => {
    if (balances) {
      const _percentages: any = {};

      _percentages.draft = (limitTotalBalance(balances.draft) / totalBalance) * 100;
      _percentages.error = (limitTotalBalance(balances.error) / totalBalance) * 100;
      _percentages.success = (limitTotalBalance(balances.success) / totalBalance) * 100;
      _percentages.review = (limitTotalBalance(balances.review) / totalBalance) * 100;

      setPercentages(normalizarPorcentajes(_percentages));
    }
  }, [balances]);

  const normalizarPorcentajes = (json: any) => {
    const { success, ...otrosPorcentajes } = json;
    let empty = 0;
    let totalPorcentajesRestantes: any = Object.values(otrosPorcentajes)
      .reduce((acc: any, val) => acc + val, 0);

    if (success === 100) {
      const porcentajesNormalizados: any = { success };
      for (const key in otrosPorcentajes) {
        porcentajesNormalizados[key] = 0;
      }
      return porcentajesNormalizados;
    }

    if ((success + totalPorcentajesRestantes) < 100)
      empty = 100 - (success + totalPorcentajesRestantes);

    if (totalPorcentajesRestantes === 0) return json;

    if (otrosPorcentajes.draft > 100)
      otrosPorcentajes.draft = otrosPorcentajes.draft - (totalPorcentajesRestantes - otrosPorcentajes.draft);

    if (totalPorcentajesRestantes != 100) totalPorcentajesRestantes = 100;

    let porcentajesNormalizados: any = {};

    for (const key in otrosPorcentajes) {
      porcentajesNormalizados[key] = (otrosPorcentajes[key] / totalPorcentajesRestantes) * 100;
    }
    porcentajesNormalizados.success = success;

    //Priorizar el draft y restar los otros
    function adjustDraft(data: any) {
      let total: any = Object.values(data).reduce((sum: any, value) => sum + value, 0);
      let limit = 100;

      if (total <= limit) return data;

      let priorities = ["error", "review", "success"];
      let excess = total - limit;

      for (let key of priorities) {
        if (data[key] > 0) {
          let reduction = Math.min(data[key], excess);
          data[key] -= reduction;
          excess -= reduction;
          if (excess === 0) break;
        }
      }

      return data;
    }

    return adjustDraft(porcentajesNormalizados);
  };

  const limit100 = (percentage: number) => {
    return percentage > 100 ? 100 : percentage;
  };

  const limitTotalBalance = (value: number) => {
    if (!value)
      return 0;
    return value > totalBalance ? totalBalance : value;
  };

  return (
    <div className={`MinimumProgressVertical ${className}`}>
      <div className="bar" style={{ background: `${colorBackground}` }}>
        {!!percentages.success && (
          <div
            className="bar-percentage"
            style={{
              height: `${limit100(percentages.success)}%`,
              background: `#6EAC72`
            }}
          >
            <div className='label-price label-price-approved' style={{ marginLeft: 33 }}>
              <span className='label'>Approved</span>
              <span className='price'>{formatterCurrency(balances.success)}</span>
            </div>
          </div>
        )}
        {!!percentages.review && (
          <div
            className="bar-percentage"
            style={{
              height: `${limit100(percentages.review)}%`,
              background: `#AC78B3`
            }}
          >
            <section className='label-price label-price-review' style={{ marginLeft: 33 }}>
              <span className='label'>In review</span>
              <span className='price'>{formatterCurrency(balances.review)}</span>
            </section>
          </div>
        )}
        {!!percentages.error && (
          <div
            className="bar-percentage"
            style={{
              height: `${limit100(percentages.error)}%`,
              background: `#C94F4A`
            }}
          >
            <section className='label-price label-price-reject' style={{ marginLeft: 33 }}>
              <span className='label'>Rejected</span>
              <span className='price'>{formatterCurrency(balances.error)}</span>
            </section>
          </div>
        )}
        {!!percentages.draft && (
          <div
            className="bar-percentage"
            style={{
              height: `${limit100(percentages.draft)}%`,
              background: `#2A2F3C`
            }}
          >
             <section className='label-price label-price-draft' style={{ marginLeft: 33 }}>
              <span className='label'>Draft</span>
              <span className='price'>{formatterCurrency(balances.draft)}</span>
            </section>
          </div>
        )}
      </div>
      <div className='label-price' style={{ height: 'auto', gap: 8 }}>
        <span className='label'>Total</span>
        <span className='price'>{formatterCurrency(totalBalance)}</span>
      </div>
    </div>
  );
};
export default MinimumProgressVertical;