import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { Tooltip } from 'react-tooltip'
import InvestmentExperienceVerification from '../InvestmentExperienceVerification/InvestmentExperienceVerification';
import { ModuleKeys } from 'services/task/modules-keys.constanst';

interface Props {
  tasks?: Task[] | any[],
  loan?: any;
  trackRecord: any;
}

const useCardInvestment = ({ tasks, loan, trackRecord }: Props) => {

  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [response, setResponse] = useState<TaskResponse>();
  const [countProperties, setCountProperties] = useState<number>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.INVESTMENT_EXPERIENCE);
      setDataTask(data);
      setResponse(data?.responses.find(item => item.key !== 'doc-another'));

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  useEffect(() => {
    if(loan)
      setCountProperties(loan.investmentExperience?.properties === '1-2' ? 2 : 3);
  }, [loan]);

  const generateCirclesFooter: any = () => {
    const filesCirclesStates = new Array(countProperties).fill(undefined).map((item, index) => {
      
      if (response?.files && response.files[index] && response.state !== TaskState.Review && response.state !== TaskState.Approved)
        return response.files[index].state;
      if (response?.files && (response.state === TaskState.Review || response.state === TaskState.Approved))
        return response.files[response.files.length - 1].state;
      return 'draft';
    });
    
    const addAnotherDocs = dataTask?.responses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || []; 
    return [...filesCirclesStates, ...docsStates];
  };
  
  const getTotalApproved = () => {
    if(dataTask?.responses && dataTask?.responses[0])
      return dataTask?.responses[0]?.files?.filter((item: any) => item.state !== TaskState.Draft.toLowerCase() && item.state !== TaskState.RequestError.toLowerCase())?.length;
    else
      return 0;
  }

  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];

  const isDisabled = trackRecord?.state === TaskState.Draft;

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.INVESTMENT_EXPERIENCE,
    title: <h4 className={`h4 ${isDisabled && 'disabled'}`}>Investment Experience Verification</h4>,
    steps: 1,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    state: dataTask?.state,
    stateRename: {
      DRAFT: isDisabled? 'COMING SOON': undefined
    },
    stateTooltipHtml: `<span>${'Please complete '}</span><b>Past Investment Experience</b><span> to unlock this</span>`,
    actions: dataTask
      ? [
        <InvestmentExperienceVerification
          key={1}
          task={dataTask}
          disabled={isDisabled}
        />
      ]
      : [],
    text: (
      <p className={`body-card ${isDisabled && 'disabled'}`}>
        I’ve purchased/sold {loan?.investmentExperience?.properties} real estate properties in the past 24 months
      </p>
    ),
    text2: (
      <div className='docs-section' style={{ marginTop: 5 }}>
        <div className='labels'>
          <p className={`body-regular body-regular-bold ${isDisabled && 'disabled'}`}>
            Document status
          </p>
          <p className={`body-card ${isDisabled && 'disabled'}`}>
            {getTotalApproved()}/{generateCirclesFooter()?.length || 0} submitted
          </p>
        </div>
        <div className={`circles-tags ${isDisabled && 'disabled'}`}>
          {generateCirclesFooter().map((state: string, index: number) => {
            let _state = state.toUpperCase() || 'DRAFT';
            
            return (
              <div key={index}>
                <div
                  data-tooltip-id={`circle-state-${_state}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip
                  id={`circle-state-${_state}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  };

  if(!dataTask) 
    return { card: null };
  
  return {
    card,
    dataTask
  };
};

export default useCardInvestment;