import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button } from 'antd';

import checkSvg from 'assets/images/check-circle-basic.svg';
import clockBackground from 'assets/images/clock-background.svg';
import errorSvg from 'assets/images/error-background.svg';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import leafPng from 'assets/images/leaf.png';
import guarantorGraySvg from 'assets/images/guarantor-gray.svg';
import Step2 from './Step2/Step2';
import Step1 from './Step1/Step1';
import { Entity, Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import Step3 from './Step3/Step3';
import { LoanContext } from '../../Dashboard';
import { useGetEntitiesByOwner } from '../../../../../services/entity/querys';
import DropdownEntities from './DropdownEntities/DropdownEntities';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { Tooltip } from 'react-tooltip';

const STEPS = 3;

export interface SubmitContextInterface {
  beforeStep?: number;
  step?: number;
  active?: boolean;
  metadata?: any;
}

export const TaskContext = createContext<Task | {}>({});
export const TaskResponsesContext = createContext<TaskResponse | {}>({});
export const SubmitContext = createContext<SubmitContextInterface | {}>({});

interface EntityGuarantorCreationProps {
  task: Task;
  taskEntityDocs?: Task;
}

const EntityGuarantorCreation = ({
  task,
  taskEntityDocs
}: EntityGuarantorCreationProps) => {
  const loan: Loan | any = useContext(LoanContext);
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const [ownerSelect, setOwnerSelect] = useState<string>('');

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });

  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );
  const { data: taskResponsesEntityDocs } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { loanId: loan._id, taskKey: ModuleKeys.ENTITY_DOCS }
    }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<any>();
  const [responseStep1Draft, setResponseStep1Draft] = useState<boolean>(false);
  const [responsesStep2, setResponsesStep2] = useState<any[]>();
  const [step2Cache, setStep2Cache] = useState<any[]>();
  const [step2ConfirmPerson, setStep2ConfirmPerson] = useState<any>();
  const [responseStep3, setResponseStep3] = useState<any>();
  const [entity, setEntity] = useState<Entity | any>();
  const [handlerSubmit, setHandlerSubmit] = useState<SubmitContextInterface>(
    {}
  );

  useEffect(() => {
    if (step === 1) setResponsesStep2(undefined);
  }, [step])

  useEffect(() => {
    if (openContext === 'entity_guarantor' || openContext === 'entity_guarantors') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (entities) {
      const _entity = entities.find((item) =>
        item.loans.find((item2) => item2._id === loan._id)
      );
      setEntity(_entity);
    }
  }, [entities]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);


  useEffect(() => {
    const step3 = taskResponses?.find((item) => item.step === 3);
    setResponseStep3(step3);

    const step1 = taskResponses?.find((item) => item.step === 1);
    setOwnerSelect(step1?.metadata?.ownerId);
    setResponseStep1(step1);

    if (taskResponses && taskResponses.length > 0) {
      const step2 = taskResponses?.filter(
        (item) =>
          item.step === 2 &&
          item.state !== TaskState.Cache &&
          item.key !== 'confirm-persons'
      );
      const step2Cache = taskResponses?.filter(
        (item) =>
          item.step === 2 &&
          item.state === TaskState.Cache &&
          item.key !== 'confirm-persons'
      );
      const confirmPerson = taskResponses?.find(
        (item) => item.key === 'confirm-persons'
      );
      setStep2ConfirmPerson(confirmPerson);
      setResponsesStep2(step2);
      setStep2Cache(step2Cache);

      if (step2) {
        const isReview = step2.find((item) => item.state === TaskState.Review);
        setIsReview(!!isReview);

        const _isApproved = step2.every((item) => item.state === TaskState.Approved);
        setIsApproved(!!_isApproved);
      }

      if (!step2 && !step3 && step1) setStep(1);
      else if (step2 && step2?.length > 0 && !step3) setStep(2);
      else if (step3) setStep(3);
    }
  }, [taskResponses]);

  const actionOwnership = (action: 'unconfirmed' | 'confirmed') => {
    if (action === 'unconfirmed') setHandlerSubmit({ step: 2, active: true });
  };

  const isConfirmedEntityDocs = () =>
    taskResponsesEntityDocs?.some((item) => item.state === TaskState.Approved);

  if (!task) return null;

  const { taskName, phase } = findTaskRules(ModuleKeys.ENTITY_GUARANTORS);

  const isOptionEntityExist = entities && entities?.length > 0 && responseStep1?.label === 'Existing entity';

  const renderGuarantors = (responses: any, maxVisible = 3) => {
    const filteredResponses = responses.filter((response: any) => response.metadata.ownership >= 20);
    const visibleGuarantors = filteredResponses.slice(0, maxVisible);
    const remainingCount = filteredResponses.length - maxVisible;

    return (
      <>
        {visibleGuarantors.map((response: any, index: number) => (
          <React.Fragment key={response._id}>
            <span className="bold">
              {response.metadata.name} {response.metadata.lastname} ({response.metadata.ownership}%)
            </span>
            {index < visibleGuarantors.length - 1 && (
              remainingCount === 0 && index === visibleGuarantors.length - 2
                ? ' and '
                : ', '
            )}
          </React.Fragment>
        ))}
        {remainingCount > 0 && (
          <>
            , and{' '}
            <span
              className="bold"
              data-tooltip-id="remaining-guarantors-tooltip"
              data-tooltip-html={filteredResponses
                .slice(maxVisible)
                .map(
                  (remaining: any) =>
                    `${remaining.metadata.name} ${remaining.metadata.lastname}`
                )
                .join('<br/>')}
              data-tooltip-class-name="remaining-guarantors-tooltip"
              style={{ textDecoration: 'underline' }}
            >
              {remainingCount} more
            </span>
            <Tooltip id="remaining-guarantors-tooltip" />
          </>
        )}
      </>
    );
  };

  const renderTracker = () => (
    <div style={{ height: '100%' }}>
      <span className='title-summary'>Task summary</span>
      <div className='mt-20'>
        <p className="placeholder">
          {!responseStep3 && (
            <>
              {'My entity is '}
              <DropdownEntities />
            </>

          )}
          {isOptionEntityExist && (
            <>
              {responseStep3 ? (
                <>
                  {'My entity is '}
                  <span className="bold">
                    {responseStep1?.metadata?.entity}
                  </span>
                  <p className="placeholder" style={{ paddingTop: 8 }}>
                    The guarantors are{' '}
                    {renderGuarantors(responsesStep2)}
                  </p>
                </>
              ) : (
                <>
                  {' Its ownership is '}
                  <Button
                    type="link"
                    className="button link-blue"
                    style={{ padding: 0 }}
                    onClick={() => actionOwnership('unconfirmed')}
                  >
                    unconfirmed
                  </Button>
                  {` and its 4 documents are `}
                  <span className="bold" style={{ padding: 0 }}>
                    {isConfirmedEntityDocs() ? 'confirmed' : 'unconfirmed'}
                  </span>
                </>
              )}
            </>
          )}
        </p>
        {!isOptionEntityExist && ((responsesStep2 && responsesStep2?.length > 0) || responseStep3) && (
          <div className="owners-tags">

            <div className="placeholder">
              {responseStep3 &&
                <span className='description'>
                  {'My entity is '}
                  <span className="tag-blue">
                    {responseStep1?.metadata?.entity}
                  </span>
                </span>
              }
              <p className="placeholder" style={{ paddingTop: 8, marginTop: 40 }}>
                The guarantors are{' '}
                {renderGuarantors(responsesStep2)}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <section className="EntityGuarantorCreation">
      <TaskContext.Provider value={task}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          disabledDots={responseStep3}
          tracker={renderTracker()}
          isConfirmModalClose={!isApproved && !isReview}
          responses={taskResponses}
          renderButtonTrigger={(openModal: any) => (
            <Button
              className={`button-open-modal button-open-modal-${task.state}`}
              onClick={() => openModal(true)}
            >
              {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
                (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                  'Begin Task'
              }
            </Button>
          )}
          header={{
            title: <span>{phase} | {taskName.toUpperCase()}</span>,
            actionLeft:
              task.state === TaskState.Review ? <StateTag state={TaskState.Review} size="large" /> : <img src={leafPng} alt="icon" className="paperPng" />
          }}
          animationDirection="onRight"
          stepsTotal={STEPS}
          currentStep={step}
          setStep={setStep}
        >
          <div className="children">
            {taskResponses && (
              <TaskResponsesContext.Provider value={taskResponses}>
                <SubmitContext.Provider value={handlerSubmit}>

                  {step === 1 && (
                    <Step1
                      setStep={setStep}
                      responsesStep2={responsesStep2}
                      setResponseStep1Draft={setResponseStep1Draft}
                      step2Confirm={step2ConfirmPerson}
                      getTaskResponses={getTaskResponses}
                      setHandlerSubmit={setHandlerSubmit}
                    />
                  )}
                  {step === 2 && (
                    <Step2
                      setStep={setStep}
                      responseStep1={responseStep1}
                      responsesStep2={responsesStep2}
                      step2Cache={step2Cache}
                      step2Confirm={step2ConfirmPerson}
                      taskEntityDocs={taskEntityDocs}
                      setHandlerSubmit={setHandlerSubmit}
                      closeTask={() => setModalTaskVisible(false)}
                    />
                  )}
                  {step === 3 && responsesStep2 && (
                    <Step3
                      setStep={setStep}
                      responseStep1={responseStep1}
                      responsesStep2={responsesStep2}
                      closeTask={() => setModalTaskVisible(false)}
                      entity={entity}
                    />
                  )}
                </SubmitContext.Provider>
              </TaskResponsesContext.Provider>
            )}
          </div>
        </ModalTask>
      </TaskContext.Provider>
    </section>

  );
};
export default EntityGuarantorCreation;
