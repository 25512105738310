import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import plusSvg from 'assets/images/plus.svg';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import addDocSvg from 'assets/images/addDocSvg.svg';
import AddAnotherDoc from 'scenes/Private/components/AddAnotherDoc/AddAnotherDoc';

interface AddAdditionalDocumentProps {
}
const AddAdditionalDocument = ({}: AddAdditionalDocumentProps) => {
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      renderButtonTrigger={(openModal: any) => (
        <Link to="./" className="link body-card body-card-w5" onClick={() => openModal(true)}>
          <img src={plusSvg} className="plus" alt="plus" />
          Add additional document
        </Link>
      )}
      header={{
        title: <span>ADD ADDITIONAL DOCUMENT</span>,
        actionLeft: <img src={addDocSvg} alt="icon" className="paperPng" />
      }}
      animationDirection="onRight"
      stepsTotal={2}
      currentStep={step}
      setStep={setStep}
      footer={null}
    >
      <section className="children AddAdditionalDocument">
        <h2 className="h2 mt-30">Add another document</h2>
        <p className="h4 h4-w4 mt-10">
          Think we missed something that’s relevant to your loan? Add it here
        </p>
        <div className="mt-30">
          <AddAnotherDoc/>
        </div>
      </section>
    </ModalTask>
  );
};
export default AddAdditionalDocument;
