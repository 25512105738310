import React, { useContext, useEffect, useState } from 'react';

import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { useGetEntitiesByOwner } from '../../../../../../services/entity/querys';
import DropdownBlue from '../../../../../../components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import {
  useCreateResponseTask,
  useRemoveTaskResponse
} from '../../../../../../services/task/mutations';
import { LoanContext, TasksContext } from '../../../Dashboard';
import { useCreateEntity } from '../../../../../../services/entity/mutations';

interface Props {
  onSubmit?: any;
  isReviewBold?: boolean;
}

const DropdownEntities = ({ onSubmit, isReviewBold }: Props) => {
  const loan: Loan | any = useContext(LoanContext);
  const tasks: any = useContext(TasksContext);

  const [ownerSelect, setOwnerSelect] = useState<string>('');

  const { execute: removeTaskResponse } = useRemoveTaskResponse();
  const {
    data: taskResponsesEntityGuarantors,
    reexecute: getResponsesEntityGuarantors
  } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { loanId: loan._id, taskKey: 'entity_guarantors' }
    },
    pause: true
  });

  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );

  const { execute: createResponseTask } = useCreateResponseTask();
  const { execute: createEntity } = useCreateEntity();

  const [isReview, setIsReview] = useState(false);
  const [entity, setEntity] = useState<any>();
  const [responseStep1, setResponseStep1] = useState<any>();
  const [responsesStep2, setResponsesStep2] = useState<any>();
  const [entityGuarantorStep3, setEntityGuarantorStep3] = useState<any>();

  useEffect(() => {
    getResponsesEntityGuarantors({ requestPolicy: 'network-only' });
  }, []);

  useEffect(() => {
    if (entities) {
      const _entity = entities.find((item) =>
        item.loans.find((item2) => item2._id === loan._id)
      );
      setEntity(_entity);
    }
  }, [entities]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);

  useEffect(() => {
    const step1 = taskResponsesEntityGuarantors?.find(
      (item) => item.step === 1
    );
    setOwnerSelect(step1?.metadata.ownerId);
    setResponseStep1(step1);

    const steps2 = taskResponsesEntityGuarantors?.filter(
      (item) =>
        item.step === 2 &&
        item.state !== TaskState.Cache &&
        item.key !== 'confirm-persons'
    );

    if (steps2) {
      const isReview = steps2.find((item) => item.state === TaskState.Review);
      setIsReview(!!isReview);
      setResponsesStep2(steps2);
    }

    const step3 = taskResponsesEntityGuarantors?.find(
      (item) => item.step === 3
    );
    setEntityGuarantorStep3(step3);
  }, [taskResponsesEntityGuarantors]);

  const submitChangeEntity = async (entityKey: string) => {
    removeResponsesBefore();

    const entity = entities?.find((item) => item._id === entityKey);
    const taskGuarantor = tasks.find(
      (item: Task) => item.key === 'entity_guarantors'
    );

    if (entity?.ownerships) createResponsesOwners(entity, taskGuarantor);

    await changeResponseEntity(entity, taskGuarantor);

    if (entityGuarantorStep3 && entity) {
      await createEntity({
        _id: entity?._id,
        name: entity?.name,
        loans: [loan._id],
        task: taskGuarantor._id
      });
    }

    if (onSubmit) onSubmit(entity);
  };

  const removeResponsesBefore = async () => {
    for (const response of responsesStep2) {
      removeTaskResponse({ taskResponseId: response._id });
    }

    const step2Confirm = taskResponsesEntityGuarantors?.find(
      (item) => item.key === 'confirm-persons'
    );

    if (step2Confirm) removeTaskResponse({ taskResponseId: step2Confirm?._id });

    let findStep1 = taskResponsesEntityGuarantors?.find(
      (item) => item.step === 1
    );
    if (findStep1) removeTaskResponse({ taskResponseId: findStep1._id });
  };

  const changeResponseEntity = async (entity: any, taskGuarantor: any) => {
    let payload: any = {
      task: taskGuarantor._id,
      key: entity?._id,
      label: 'Existing entity',
      step: 1,
      metadata: {
        entity: entity?.name,
        ownerId: ownerSelect
      },
      state: TaskState.Approved
    };

    await createResponseTask(payload);
  };

  const createResponsesOwners = async (entity: any, taskGuarantor: any) => {
    for (const guarantor of entity.ownerships) {
      let { files, stakes, ...others }: any = guarantor;
      files = files.map((item: any) => ({
        ...item,
        state: TaskState.Review
      }));

      await createResponseTask({
        task: taskGuarantor._id,
        key: `ownership-${guarantor.name}`,
        label: `Ownership ${guarantor.name}`,
        step: 2,
        metadata: { ...others, ownership: stakes[entity._id] },
        state: stakes[entity._id] >= 20 ? TaskState.Draft : TaskState.Approved,
        filesExist: files
      });
    }

    let isConfirmPerson = taskResponsesEntityGuarantors?.find(
      (item: any) =>
        item.key === 'confirm-persons' && item.state !== TaskState.Draft
    );

    if (!isConfirmPerson)
      await createResponseTask({
        task: taskGuarantor._id,
        key: `confirm-persons`,
        label: `Confirm or Edit the ownership`,
        step: 2,
        metadata: {},
        state: TaskState.Draft
      });
  };
  
  if (!responseStep1?.metadata?.entity && entities?.length === 0)
    return (
      <span className='body-regular-bold'>not formed yet</span>
    );
    
  if (!entities || entities.length <= 1 || isReviewBold)
    return (
      <span style={{ fontWeight: 800 }}>
        {entity?.name || responseStep1?.metadata?.entity}
      </span>
    );

  if (entities)
    return (
      <DropdownBlue
        label={entity?.name || responseStep1?.metadata?.entity}
        disabled={isReview}
        items={entities
          .map((item) => ({ key: item._id, label: item.name }))
          .filter(
            (item) =>
              item.label !== responseStep1?.metadata?.entity &&
              item.label !== entity?.name
          )}
        onClickSelect={submitChangeEntity}
        className={isReview ? 'dropdown-review-bold' : `dropdown-blue-bold link-blue`}
      />
    );
  else
    return null;
};
export default DropdownEntities;
