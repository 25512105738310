export const formatterSsn = (value: string) => {
  if (!value) return '';

  const numericValue = value
    .toString()
    .replace(/\D/g, '')
    .replace(/[^0-9-]/g, '');

  if (numericValue.length === 3) {
    return `${numericValue}-`;
  } else if (numericValue.length === 5) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}-`;
  } else if (numericValue.length > 5) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(
      3,
      5
    )}-${numericValue.slice(5, 9)}`;
  }

  return value.replace(/[^0-9-]/g, '');
};

export const formatterPhone = (value: string) => {
  if (!value) return '';

  const numericValue = value
    .toString()
    .replace(/\D/g, '')
    .replace(/[^0-9-]/g, '');

  if (numericValue.length === 3) {
    return `${numericValue}-`;
  } else if (numericValue.length === 6) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}-`;
  } else if (numericValue.length >= 9) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(
      3,
      6
    )}-${numericValue.slice(6, 10)}`;
  }

  return value.replace(/[^0-9-]/g, '');
};

export const formatterCurrency = (value: any) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return formatter.format(value);
};

export const capitalizeNames = (text: string) => {
  // Dividir el texto en palabras usando espacios como delimitadores
  let words = text.split(' ');
  // Capitalizar cada palabra y unirlas nuevamente
  let capitalizedWords = words.map(word => {
    // Solo capitalizar si la palabra no está vacía
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  });
  // Unir las palabras nuevamente en una sola cadena
  return capitalizedWords.join(' ');
}
