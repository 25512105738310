import React, { useEffect, useState } from 'react';

interface Props {
  title: React.ReactElement;
  price: React.ReactElement | null;
  totalBalance: number;
  balances: {
    error: number;
    draft: number;
    success: number;
    review: number;
  };
  colorBackground: string | '#DFE4EB';
}

const TaskProgress = ({
  title,
  price,
  balances,
  totalBalance,
  colorBackground
}: Props) => {
  const [percentages, setPercentages] = useState<any>({});

  useEffect(() => {
    if (balances) {
      const _percentages: any = {};

      _percentages.draft = (limitTotalBalance(balances.draft) / totalBalance) * 100;
      _percentages.error = (limitTotalBalance(balances.error) / totalBalance) * 100;
      _percentages.success = (limitTotalBalance(balances.success) / totalBalance) * 100;
      _percentages.review = (limitTotalBalance(balances.review) / totalBalance) * 100;

      setPercentages(normalizarPorcentajes(_percentages));
    }
  }, [balances]);

  const normalizarPorcentajes = (json: any) => {
    const { success, ...otrosPorcentajes } = json;
    let empty = 0;
    let totalPorcentajesRestantes: any = Object.values(otrosPorcentajes)
      .reduce((acc: any, val) => acc + val, 0);

    if (success === 100) {
      const porcentajesNormalizados: any = { success };
      for (const key in otrosPorcentajes) {
        porcentajesNormalizados[key] = 0;
      }
      return porcentajesNormalizados;
    }

    if ((success + totalPorcentajesRestantes) < 100)
      empty = 100 - (success + totalPorcentajesRestantes);

    if (totalPorcentajesRestantes === 0) return json;

    if (otrosPorcentajes.draft >= 100)
      otrosPorcentajes.draft = otrosPorcentajes.draft - (totalPorcentajesRestantes - otrosPorcentajes.draft);

    if (totalPorcentajesRestantes != 100) totalPorcentajesRestantes = 100;

    const porcentajesNormalizados: any = {};

    for (const key in otrosPorcentajes) {
      porcentajesNormalizados[key] = (otrosPorcentajes[key] / totalPorcentajesRestantes) * 100;
    }
    porcentajesNormalizados.success = success;

    return porcentajesNormalizados;
  };

  const limit100 = (percentage: number) => {
    return percentage > 100 ? 100 : percentage;
  };

  const limitTotalBalance = (value: number) => {
    if (!value)
      return 0;
    return value > totalBalance ? totalBalance : value;
  };

  return (
    <div className="TaskProgress">
      <div className="flex-btw">
        {title}
        {price}
      </div>
      <div className="bar" style={{ background: `${colorBackground}` }}>
        {!!percentages.success && (
          <div
            className="bar-percentage"
            style={{
              width: `${limit100(percentages.success)}%`,
              background: `#6EAC72`
            }}
          />
        )}
        {!!percentages.review && (
          <div
            className="bar-percentage"
            style={{
              width: `${limit100(percentages.review)}%`,
              background: `#AC78B3`
            }}
          />
        )}
        {!!percentages.error && (
          <div
            className="bar-percentage"
            style={{
              width: `${limit100(percentages.error)}%`,
              background: `#C94F4A`
            }}
          />
        )}
        {!!percentages.draft && (
          <div
            className="bar-percentage"
            style={{
              width: `${limit100(percentages.draft)}%`,
              background: `#2A2F3C`
            }}
          />
        )}
      </div>
    </div>
  );
};
export default TaskProgress;
