import React, { useEffect, useState } from 'react';
import { useRemoveTaskResponse } from 'services/task/mutations';
import { Button } from 'antd';
import coinPng from 'assets/images/coin-shadow.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Step2Props {
  entity?: { key: string, name: string };
  step2Confirm: any;
}
const ButtonEditOwnership = ({ entity, step2Confirm }: Step2Props) => {
  const {
    data: responseRemove,
    fetching: loadingRemove,
    execute: removeTaskResponse
  } = useRemoveTaskResponse();

  const [isModalEditOwnership, setModalEditOwnership] =
    useState<boolean>(false);

  useEffect(() => {
    if (responseRemove) setModalEditOwnership(false);
  }, [responseRemove]);

  const editOwnership = () => {
    removeTaskResponse({ taskResponseId: step2Confirm._id });
  };

  return (
    <ModalEndSubmit
      visible={isModalEditOwnership}
      setVisible={setModalEditOwnership}
      buttonAction={{
        label: 'Edit ownership',
        loading: loadingRemove,
        className: 'button-link-border'
      }}
      modal={{
        title: `Do you want to edit the ownership of ${entity?.name}?`,
        description: (
          <h4 className="h4 h4-w4">
            This won’t affect any closed loan applications, but the profile will
            be updated on any active or in progress loans
          </h4>
        )
      }}
      className="modal-end-submit--white"
      icon={coinPng}
      buttonSubmit={
        <Button className="button button-modal-ok" onClick={editOwnership}>
          Yes, I want to edit
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => setModalEditOwnership(false)}
        >
          No, leave it as is
        </Button>
      }
    />
  );
};
export default ButtonEditOwnership;
