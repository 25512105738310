import React from 'react';
import CardMarketRent from './CardMarketRent/CardMarketRent';
import CardLeaseAgreements from './CardLeaseAgreements/CardLeaseAgreements';

const Step3 = () => {
  return (
    <section className="Step3">
      <h2 className="h2">
        Give us market rents for the{' '}
        <span className="blue-bold">4 vacant units</span> and lease agreements
        for the <span className="blue-bold">8 occupied units</span> in the
        property
      </h2>
      <p className="h4 h4-w4">
        We’ll review the leases and calculate your final DSCR for you
      </p>
      <div className="mt-25">
        <CardMarketRent />
      </div>
      <div className="mt-25">
        <CardLeaseAgreements ammountFiles={8} />
      </div>
    </section>
  );
};
export default Step3;
