import { Button } from 'antd';
import InputAmount from 'components/Atoms/Inputs/InputAmount/InputAmount';
import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../DscrQuestionsAgreements';

interface Step2Props {
  setStep: Function;
}
const Step2 = ({ setStep }: Step2Props) => {
  // @ts-ignore
  const { totalUnits, setTotalUnits, vacantUnits, setVacantUnits, occupiedUnits, setOccupiedUnits } = useContext(TaskContext);
  
  useEffect(() => {
    setOccupiedUnits(totalUnits - vacantUnits);
  }, [totalUnits, vacantUnits])

  const totalUnitHandler = (value: number) => {
    if (value < vacantUnits) {
      setTotalUnits(value);
      setVacantUnits(value);
    } else {
      setTotalUnits(value);
    }
  }

  const vacantUnitHandler = (value: number) => {
    if (value <= totalUnits) {
      setVacantUnits(value);
    }
  }

  const isButtonDisabled = totalUnits === 0

  return (
    <section>
      <h2 className="h2">
        Give us some more info on the units in the property
      </h2>
      <div className="mt-25">
        <InputAmount
          title="How many units are in the property?"
          type="operation"
          text={null}
          value={totalUnits}
          onChange={totalUnitHandler}
        />
        <InputAmount
          title="How many units are vacant?"
          type="operation"
          text={null}
          value={vacantUnits}
          onChange={vacantUnitHandler}
        />
        <InputAmount
          title="How many units are occupied?"
          type="text"
          text="Automatically calculated"
        />
        <Button
          className={!isButtonDisabled ?  "button button-submit" : "button button-grey"}
          onClick={!isButtonDisabled ? () => setStep(3) : undefined}
        >
          Add units to go to next step
        </Button>
      </div>
    </section>
  );
};
export default Step2;
