import { gql } from "urql";

export const GET_OWNERSHIPS_BY_ENTITY: any = gql(`
    query GetOwnershipsByEntity($entityId: String!){
        GetOwnershipsByEntity(entityId: $entityId) {
            _id
            name
            lastname
            stakes
        }  
    }
`)

export const GET_OWNERSHIPS_BY_CREATOR: any = gql(`
    query GetOwnershipsByCreator{
        GetOwnershipsByCreator {
            _id
            name
            lastname
            stakes
            ssn
            phone
            email
            address
            dateBirth
            files {
                key
                name
                url
            }
        }  
    }
`)