import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { useGetTaskResponseComments } from 'services/task/querys';
import { ModalTaskRightContext } from 'components/Organisms/Modals/ModalTask/ModalTask';
import { formatterCurrency } from '@common/utils/formatters/formatter';

interface Props {
  taskResponseId: string;
  taskId: string;
  componentDataError?: any;
}

const IssuesTaskResponse = ({
  taskResponseId,
  taskId,
  componentDataError
}: Props) => {
  const { setHandler }: any = useContext(ModalTaskRightContext);

  const { data: taskComments } = useGetTaskResponseComments({
    variables: { taskId, state: 'pending' }
  });

  const issues = taskComments?.filter(item => item.taskResponse._id === taskResponseId);

  return (
    <section className='issues-task-response'>
      {issues && issues.length > 0 &&
        <div className="divider divider-dark">
          <div className="row row-btw">
            <div className="box">
              <h3 className="body-regular body-regular-w6">
                Details from your loan officer
              </h3>
            </div>
          </div>


          {issues?.map(issue => (
            <div className="row" key={issue._id}>
              <div className='issue-text'>
                <h3 className="body-regular body-regular-w6">
                  {issue.rejectionCode.label}.
                </h3>{' '}
                <span>{issue.description}</span>
              </div>
              <Button className='button button-link-error' onClick={() =>
                setHandler(true, {
                  comment: issue,
                  componentDataError,
                  noteErrorDetail: issue.metadata?.rejectedAmount ?
                    <h4 className="text-error-note">
                      <span>Rejected amount:</span>{' '}
                      {formatterCurrency(issue?.metadata?.rejectedAmount || 0)}
                    </h4>
                    : null
                })
              }>
                See details
              </Button>
            </div>
          ))}
        </div>
      }
    </section>
  );
};
export default IssuesTaskResponse;