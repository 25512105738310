import React, { useState } from 'react';
import { Button } from 'antd';
import { TaskState } from '../../../../../../gql/graphql';

interface Props {
  fetching: boolean;
  disabled: boolean;
  onClick: any;
  ownerships?: any[];
  ownerState?: TaskState;
}

const ModalSubmitProfile = (props: Props) => {

  return (
    <Button
      className={props.ownerState === TaskState.RequestError ? 'button button-submit button-submit-error' : 'button button-submit'}
      onClick={props.onClick}
      disabled={props.disabled}
      loading={props.fetching}
    >
      {props.ownerState === TaskState.RequestError ?
        <b>Update profile to continue</b> :
        <span>
          Complete guarantor information to continue
        </span>
      }
    </Button>
  );
};
export default ModalSubmitProfile;
