import { ModalTaskRightContext } from 'components/Organisms/Modals/ModalTask/ModalTask';
import React, { useContext, useEffect } from 'react';
import { CreateTaskResponseContext } from 'scenes/Private/Dashboard/Dashboard';
import SectionError from 'scenes/Private/components/SectionErrorTask/SectionError/SectionError';
import { useGetTaskResponseComments } from 'services/task/querys';
import { formatterCurrency } from '@common/utils/formatters/formatter';

interface Props {
	taskId: string;
	noteError?: React.ReactElement;
	noteErrorDetail?: React.ReactElement;
	getComments?: any;
	hidden?: boolean;
}

const SectionErrorTask = ({ taskId, noteError, noteErrorDetail, getComments, hidden }: Props) => {
	const { setHandler }: any = useContext(ModalTaskRightContext);
	const { value: isCreateResponseTask }: any = useContext(CreateTaskResponseContext);
	const { data: taskComments, reexecute: getTaskComments } = useGetTaskResponseComments({ 
		variables: { taskId, state: 'pending' } 
	});
		
	useEffect(() => {
		getTaskComments({ requestPolicy: 'network-only' });
	}, [isCreateResponseTask])

	useEffect(() => {
		if (taskComments && taskComments?.length > 0 && getComments)
			getComments(taskComments);
	}, [taskComments])

	if (taskComments && taskComments?.length > 0 && !hidden)
		return (
			<div className="mt-20">

				{noteError && <div>{noteError}</div>}

				{taskComments?.map((comment) => comment.rejectionCode.key !== 'no_action_required' ? (
					<div className="mt-12" key={comment._id}>
						<SectionError
							title={comment.title}
							subtitle={comment.rejectionCode.label}
							description={comment.description}
							subtitleInDescription={true}
							button={{
								title: "See details",
								underline: true,
								onClick: () =>
									setHandler(true, {
										comment,
										isTitleIssues: !!noteErrorDetail,
										noteErrorDetail: comment.metadata?.rejectedAmount ?
											<h4 className="text-error-note">
												<span>Rejected amount:</span>{' '}
												{formatterCurrency(comment?.metadata?.rejectedAmount || 0)}
											</h4>
											: null
									})
							}}
						/>
					</div>
				) : null)}
			</div>
		);

		else
			return null;
};
export default SectionErrorTask;
