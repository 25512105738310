import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusCircleFilled,
  MinusCircleFilled
} from '@ant-design/icons';

interface InputProps {
  title: string;
  type: string | null;
  text: string | null;
  value?: number;
  onChange?: Function;
}
const InputAmount = ({ title, type, text, value, onChange }: InputProps) => {
  const handleOperation = (type: any) => {
    if (value !== undefined && onChange !== undefined) {
      if (type === 'addition') {
        onChange(value + 1);
      }
      if (type === 'subtraction') {
        if (value >= 1) {
          onChange(value - 1);
        }
      }
    }
  };
  
  return (
    <div className="InputAmount flex-btw">
      <p className="h4 h4-w4">{title}</p>
      <div className="flex">
        {type === 'operation' && (
          <>
            <Button
              className="button-operation"
              onClick={() => handleOperation('subtraction')}
            >
              <MinusCircleOutlined className="anticon anticon-empty" />
              <MinusCircleFilled className="anticon anticon-full" />
            </Button>
            <span className={`${Number(value) >= 1 && 'text-bold'}`}>
              {value}
            </span>
            <Button
              className="button-operation"
              onClick={() => handleOperation('addition')}
            >
              <PlusCircleOutlined className="anticon anticon-empty" />
              <PlusCircleFilled className="anticon anticon-full" />
            </Button>
          </>
        )}
        {type === 'text' && <p className="h4 h4-w4">{text}</p>}
      </div>
    </div>
  );
};
export default InputAmount;
