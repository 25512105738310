import React from 'react';
import { Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';

interface Props {
	params: any;
	close: any;
}

const SectionRight = ({ params, close }: Props) => {
	
	const issueDocuments = params?.comment?.taskResponse?.files?.filter((item: any) => 
		params.comment.metadata.dataOrDocumentSelected.includes(item.name)
	)

	return (
		<div className='ModalTask-content-right'>

			<Button type="link" className="button-close" onClick={close}>
				X
			</Button>

			{params?.titleAddText &&
				<h3 className="h3">{params?.titleAddText + ' ' + params.comment.title}</h3>
			}

			{!params?.titleAddText &&
				<h3 className="h3">{params.comment.title}</h3>
			}

			{params?.noteErrorDetail &&
				<div className='mt-30'>{params?.noteErrorDetail}</div>
			}

				<div className='mt-20 rejected-information'>
					<b className='bold'>Rejected Information</b>
					<span className='reject-documents-label'>{issueDocuments?.length} document</span>
					<div className="content-files">
						{issueDocuments?.map((file: any, index: number) => (
							<InputUploadedFile 
							key={index}
							file={file}
							isActionSeeFile={true}
							state={file.state}
							/>
						))}
					</div>
				</div>

				<b className='type bold'>{params.comment.rejectionCode?.label}.</b>

			{/*<div className='mt-10'>
				<p className="body-regular">
					<span className="bold">{params?.comment?.subtitle}{'. '}</span>
					{params?.comment?.description}
				</p>
		</div>*/}

			{params.comment?.markdown &&
				<div className='mt-10'>
					<ReactQuill
						value={params.comment?.markdown}
						readOnly={true}
						theme={"bubble"}
						style={{ height: 'auto' }}
					/>
				</div>
			}
		</div>
	);
};

export default SectionRight;
