import React, { useEffect, useState } from 'react'
import { Avatar, Button, Upload, UploadFile, UploadProps, message } from 'antd'

import editBlue from 'assets/images/edit-blue.png';
import { useGetUserLoggin } from '../../../../services/user/querys';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useUpdateUser } from '../../../../services/user/mutations';
import InputBorderNone from '../../../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';

const CardProfile = () => {

  const { data: user, fetching: loadingUser } = useGetUserLoggin();
  const { execute: updateUser, fetching: loadingUpdate } = useUpdateUser();
  
  const [fileAvatar, setFileAvatar] = useState<UploadFile>();
  const [isEdit, setEdit] = useState(false);
  const [previewAvatar, setPreviewAvatar] = useState();
  const [editName, setEditName] = useState();
  const [editLastname, setEditLastname] = useState();
  const [editEmail, setEditEmail] = useState();

  useEffect(() => {
    if (!fileAvatar) {
      setPreviewAvatar(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(fileAvatar as any)
    setPreviewAvatar(objectUrl as any)

    return () => URL.revokeObjectURL(objectUrl)
  }, [fileAvatar])

  const onClickEdit = () => {
    if (isEdit) {
      let payload: any = {
        _id: user?._id, 
        name: editName || user?.name,
        lastName: editLastname || user?.lastName,
        email: editEmail || user?.email,
      };

      if(fileAvatar)
        payload.avatar = fileAvatar;
        
      updateUser(payload as any);
      setPreviewAvatar(undefined);
      setFileAvatar(undefined);
    }

    setEdit(!isEdit);
  }

  const props: UploadProps = {
    beforeUpload: (file) => {
      setFileAvatar(file);
      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  const uploadButton = (
    previewAvatar ?
      <Avatar src={previewAvatar} size={68} className='avatar' />
      :
      <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
      </button>
  );

  return (
    <div className='card-profile-generic flex-btw gap-3'>
      <div className='flex-btw gap-3'>

        {!isEdit &&
          <Avatar src={!loadingUser && !loadingUpdate && user?.avatar?.url} size={68} className='avatar'>
            {!user?.avatar && user?.name?.charAt(0)}
            {(loadingUser || loadingUpdate) && <LoadingOutlined />}
          </Avatar>
        }

        {isEdit &&
          <Upload
            name="avatar"
            listType="picture-circle"
            className="avatar"
            {...props}
          >
            {uploadButton}
          </Upload>
        }
        <div className='body'>
          {!isEdit &&
            <>
              <h1 className='card-title'>{user?.name + ' ' + user?.lastName}</h1>
              <h5 className='title-normal mt-5'>{user?.email}</h5>
            </>
          }
          {isEdit &&
            <div>
              <InputBorderNone
                color="bold-lith"
                placeholder="Name"
                onChange={(e: any) => setEditName(e.target.value)}
                defaultValue={user?.name}
              />
              <InputBorderNone
                color="bold-lith"
                placeholder="Lastname"
                onChange={(e: any) => setEditLastname(e.target.value)}
                defaultValue={user?.lastName}
                className="mt-5"
              />
              <InputBorderNone
                color="bold-lith"
                placeholder="Email"
                onChange={(e: any) => setEditEmail(e.target.value)}
                defaultValue={user?.email}
                className="mt-5"
              />
            </div>
          }
          <h5 className='title-normal mt-5'>{user?.phone}</h5>
          <Button className='button button-round mt-15'>{user?.__typename?.toUpperCase()}</Button>
        </div>
      </div>
      <Button className='button button-transparent button-edit' onClick={onClickEdit}>
        <img src={editBlue} className='edit' alt='edit' />
        {isEdit ? 'SAVE' : 'EDIT'}
      </Button>
    </div>
  )
}

export default CardProfile