import { OperationContext, useMutation } from 'urql';
import {
  CreateResponseTaskInput,
  CreateResponseTaskMutation,
  DeleteFileTaskResponseMutation,
  MutationDeleteFileTaskResponseArgs,
  MutationRemoveTaskResponseArgs,
  RemoveTaskResponseMutation
} from 'gql/graphql';
import {
  CREATE_RESPONSE_TASK,
  DELETE_FILE_TASK_RESPONSE,
  REMOVE_TASK_RESPONSE
} from './graphql';
import { useContext } from 'react';
import { CreateTaskResponseContext, ProgressContext } from 'scenes/Private/Dashboard/Dashboard';

export const useCreateResponseTask = (query?: any) => {
  const { setValue: setCreateResponseTask }: any = useContext(CreateTaskResponseContext);
  const loanProgress: any = useContext(ProgressContext);
  
  const [{ data, fetching, error }, execute] = useMutation<CreateResponseTaskMutation>(
    query || CREATE_RESPONSE_TASK
  );
  return {
    execute: async (data: CreateResponseTaskInput) => {
      setCreateResponseTask('loading');
      await execute({ 
        createResponseTaskInput: { 
          ...data,
          loanProgress
        } 
      });
      setCreateResponseTask('success');
      setTimeout(() => {
        setCreateResponseTask('idle');
      }, 1000); 
    },
    data: data?.createResponseTask,
    fetching,
    error
  };
};

export const useDeleteFileResponse = (query?: any) => {
  const [results, execute] = useMutation<DeleteFileTaskResponseMutation>(
    query || DELETE_FILE_TASK_RESPONSE
  );

  return {
    execute: (data: MutationDeleteFileTaskResponseArgs) => execute(data),
    data: results.data?.DeleteFileTaskResponse,
    ...results
  };
};

export const useRemoveTaskResponse = (query?: any) => {
  const [results, execute] = useMutation<RemoveTaskResponseMutation>(
    query || REMOVE_TASK_RESPONSE
  );

  return {
    execute: (data: MutationRemoveTaskResponseArgs) => execute(data),
    data: results.data?.RemoveTaskResponse,
    ...results
  };
};
