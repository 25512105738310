import React, { createContext, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import calcPng from 'assets/images/calculator.png';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Task } from 'gql/graphql';

const DSCRContext = {
  totalUnits: 0,
  setTotalUnits: () => {},
  vacantUnits: 0,
  setVacantUnits: () => {},
  occupiedUnits: 0,
  setOccupiedUnits: () => {}
}

export const TaskContext = createContext<Task | typeof DSCRContext>(DSCRContext);

interface DscrQuestionsAgreementsProps {
  task?: Task;
  // classButton: string;
}
const DscrQuestionsAgreements = ({
  // classButton,
  // @ts-ignore
  task = {}
}: DscrQuestionsAgreementsProps) => {
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);

  const [totalUnits, setTotalUnits] = useState<number>(0);
  const [vacantUnits, setVacantUnits] = useState<number>(0);
  const [occupiedUnits, setOccupiedUnits] = useState<number>(0);

  const dscrContextValue = {
    totalUnits,
    setTotalUnits,
    vacantUnits,
    setVacantUnits,
    occupiedUnits,
    setOccupiedUnits
  };

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  if (!task) return null;

  return (
    <TaskContext.Provider value={{...task, ...dscrContextValue}}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`ModalCardState_button-open-modal`}
            onClick={() => openModal(true)}
          >
            The DSCR
          </Button>
        )}
        header={{
          title: <span>APPROVAL | DSCR QUESTIONS & LEASE AGREEMENTS</span>,
          actionLeft: (
            <img
              src={calcPng}
              alt="icon"
              className="paperPng"
            />
          ),
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              Help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        // dots={true}
        stepsTotal={3}
        currentStep={step}
        setStep={setStep}
        footer={
          <div className="text-footer mt-20">
            <p className="placeholder">
              The DSCR for my property is <span className="bold-label">1.2</span>
            </p>
          </div>
        }
      >
        <section className="children DscrQuestionsAgreements">
          {step === 1 && <Step1 setStep={setStep} />}
          {step === 2 && <Step2 setStep={setStep} />}
          {step === 3 && <Step3 />}
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default DscrQuestionsAgreements;
