import lienzoSvg from 'assets/images/guarantor-blue.svg';
import { Entity, Ownership, Task } from 'gql/graphql';
import React, { useContext, useState } from 'react';
import { EntityContext, TaskContext } from '../EntityDocs';
import InputBorderNone from '../../../../../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { Button } from 'antd';
import { useUpdateEntity } from '../../../../../../services/entity/mutations';
import useGuarantorUtil from '../../EntityGuarantorCreation/useGuarantorUtil';

interface Props {
  setStep: any
}

const Step1 = ({ setStep }: Props) => {
  const entity: Entity | any = useContext(EntityContext);

  const { data, fetching, execute: updateEntity } = useUpdateEntity();
  const { getGuarantorsByEntity } = useGuarantorUtil({ ownerships: entity.ownerships });

  const [newEntityName, setNewEntityName] = useState();

  const submit = () => {
    updateEntity({
      _id: entity._id,
      name: newEntityName
    });
    setStep(2);
  };

  const countGuarantors = () =>
    entity.ownerships
      .filter((item: any) => item.stakes[entity._id])
      .filter((item: any) => item.stakes[entity._id] >= 20)?.length;

  const countOwners = () =>
    entity.ownerships.filter((item: any) => item.stakes[entity._id])?.length;

  return (
    <section className="step-1">
      <div className="section-1">
        <h3 className="h3">
          {getGuarantorsByEntity(entity._id)} all own at least 20% of the entity on this loan{' '}
        </h3>
      </div>

      <div className="mt-40">
        <span style={{ fontWeight: 600, fontSize: 24 }}>
          {"What's your new entity called?"}
        </span>
        <div className="mt-20">
          <div className="main">
            <div className={`content-card`}>
              <div className="section-1">
                <div className="name">
                  <InputBorderNone
                    type="text"
                    color="bold"
                    placeholder="Entity name"
                    height={20}
                    value={newEntityName}
                    onChange={(e: any) => setNewEntityName(e.target.value)}
                  />
                </div>
                <div className="footer">
                  <div className="footer-info">
                    <div>
                      <span className="body-regular body-regular-w5 guarantor">
                        <img
                          src={lienzoSvg}
                          className="lienzo"
                          alt="lienzoSvg"
                        />{' '}
                        {countGuarantors()}{' '}
                        {countGuarantors() > 1 ? 'Guarantors' : 'Guarantor'}
                      </span>
                      <span className="body-regular body-regular-w5 owners">
                        | {countOwners()}{' '}
                        {countOwners() > 1 ? 'owners' : 'owner'}
                      </span>
                    </div>
                    <div
                      className="small-detail"
                      style={{ fontSize: 11, paddingTop: 5 }}
                    >
                      *Contact us to make changes to ownership
                    </div>
                  </div>
                  <div className="list-owners">
                    {entity.ownerships.map((owner: Ownership) => (
                      <div key={owner._id} className="owner">
                        <div className="owner-name">
                          {owner.name + ' ' + owner.lastname}
                        </div>
                        <div className="owner-percentage">
                          {owner.stakes[entity._id] >= 20 && (
                            <img
                              src={lienzoSvg}
                              className="lienzo"
                              alt="lienzoSvg"
                            />
                          )}{' '}
                          {owner.stakes[entity._id]}%
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-20">
              <Button
                htmlType="submit"
                className="button button-submit"
                loading={fetching}
                onClick={submit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Step1;
