import React, { useContext, useState } from 'react';
import { Button, Form } from 'antd';
import { formatterCurrency } from '@common/utils/formatters/formatter';
import { CurrencyInput } from '../../Step3/CardMarketRent/CardMarketRent';
import { TaskContext } from '../../DscrQuestionsAgreements';
import { useEffect, useRef } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { EditTwoTone } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useCreateResponseTask } from 'services/task/mutations';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { LoanContext } from 'scenes/Private/Dashboard/Dashboard';
import { PricerPayloadFactory, sendPricerPayload } from 'services/external/pricer';

interface Step1Props {
  setStep: Function;
  task: Task | any;
}

const CardEstimatedDSCR = ({ setStep, task }: Step1Props) => {

  //@ts-ignore
  const { dscrValues, setDSCRValues } = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);

  const [currentDSCR, setCurrentDSCR] = useState(dscrValues.estimatedDSCR)
  const [editingField, setEditingField] = useState<string | null>(null);
  const [response, setResponse] = useState<TaskResponse | any>();
  const [form] = useForm();
  const inputRef = useRef<HTMLDivElement | null>(null);
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current?.contains(event.target as Node)){
        setEditingField(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(taskResponses) {
      const lastStep1Response = taskResponses.find((item) => item.step === 1);
      setResponse(lastStep1Response);
      if(lastStep1Response) {
        setDSCRValues({
          ...lastStep1Response.metadata,
        });
        setCurrentDSCR(lastStep1Response.metadata.estimatedDSCR);
      }
    }
  }, [taskResponses]);

  const { execute: createResponseTask } = useCreateResponseTask();

  const [touched, setTouched] = useState(false);
  const handleChange = async ({ name, value }: any) => {
    setDSCRValues({
      ...dscrValues,
      [name]: value,
    });
    setTouched(true);
  };

  const onFinish = async (values: any) => {
    let payload: any ={
      key: 'estimated-dscr',
      label: 'Estimated DSCR',
      step: 1,
      metadata: {
        ...dscrValues,
        estimatedDSCR: currentDSCR
      },
      state: TaskState.Approved,
      task: task._id
    }
    if (response) payload._id = response._id;
    await createResponseTask(payload);
    getTaskResponses({ requestPolicy: 'network-only' });
    setStep(2);
  };

  useEffect(() => {
    setCurrentDSCR(dscrValues.estimatedDSCR);
  }, [dscrValues.estimatedDSCR]);

  useEffect(() => {
    if (touched) {
      const pricerPayload = PricerPayloadFactory(loan, dscrValues);
      sendPricerPayload(pricerPayload).then((dscr) => {
        setCurrentDSCR(dscr)
      });
    }
  }, [dscrValues]);

  const isValidDSCR = currentDSCR !== null

  return (
    <Form form={form} onFinish={onFinish} initialValues={dscrValues}>
      <div className="CardEstimatedDSCR">
        <div className="card">
            <p className="h4 h4-w5" style={{ marginBottom: 6}}>Income</p>
          {[
            { label: "Monthly rental income", name: "monthlyRentalIncome" },
          ].map(({ label, name }) => (
            <div className="card-row" key={name}>
              <div className="card-column">
                <p className="h4 h4-w4">{label}:</p>
              </div>
              <div className="card-column-group" ref={editingField === name ? inputRef : null}>
                <div className="card-column">
                {editingField === name ? (
                  <CurrencyInput
                    value={dscrValues[name]}
                    onChange={(value: any) => handleChange({ name, value })}
                    className="dscr-input"
                  />
                ) : (
                  <p className="h4 h4-w6">{formatterCurrency(dscrValues[name])}</p>
                )}
                </div>
              <div className="card-column">
                <Button className="edit-button" onClick={() => setEditingField(name)}>
                    <EditTwoTone twoToneColor={'#5E8EB7'} />
                    EDIT
                </Button>
              </div>
              </div>
            </div>
          ))}
          <p className="h4 h4-w5" style={{ marginTop: 24, marginBottom: 7 }}>Annual Expenses</p>
          {[
            { label: "Annual property insurance", name: "annualPropertyInsurance" },
            { label: "Annual property taxes", name: "annualPropertyTaxes" },
            { label: "Annual HOA fee", name: "annualHOAFee" },
          ].map(({ label, name }) => (
            <div className="card-row" key={name}>
              <div className="card-column">
                <p className="h4 h4-w4">{label}:</p>
              </div>
              <div className="card-column-group" ref={editingField === name ? inputRef : null}>
              <div className="card-column">
                {editingField === name ? (
                  <CurrencyInput
                    value={dscrValues[name]}
                    onChange={(value: any) => handleChange({ name, value })}
                    className="dscr-input"
                  />
                ) : (
                  <p className="h4 h4-w6">{formatterCurrency(dscrValues[name])}</p>
                )}
              </div>
              <div className="card-column">
                <Button className="edit-button" onClick={() => setEditingField(name)}>
                  <EditTwoTone twoToneColor={'#5E8EB7'} />
                  EDIT
                </Button>
              </div>
              </div>
            </div>
          ))}
          <div className="card-row card-row_footer">
            <div className="card-column">
              <p className="h4">Estimated DSCR</p>
            </div>
            <div className="card-column">
              {
                !isValidDSCR ?
                  <p className="h4 h4-w6">Invalid DSCR</p>
                  :
                  <p className="h4 h4-w6">{currentDSCR || dscrValues.estimatedDSCR}</p>
              }
            </div>
          </div>
        </div>
        <div className="flex-center">
          <Form.Item style={{ width: '100%' }}>
            <Button style={{ color: "white"}} disabled={!isValidDSCR} className="button button-submit" htmlType="submit">
              Continue to units
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};
export default CardEstimatedDSCR;
