import React, { useContext, useEffect, useRef, useState } from 'react';

import checkSvg from 'assets/images/check-circle.svg';
import checkGreenSvg from 'assets/images/check-green-ligth.svg';
import alertSvg from 'assets/images/alert-circle.svg';
import loaderSvg from 'assets/images/loader-blue.svg';
import clockSvg from 'assets/images/clock-purple-light.svg';

import homeWhiteEmptySvg from 'assets/images/home-white-empty.svg';
import homeBlackEmptySvg from 'assets/images/home-black-empty.svg';
import homeGreenSvg from 'assets/images/home-green-all.svg';
import homeErrorEmptySvg from 'assets/images/home-error-empty.svg';
import homeReviewEmptySvg from 'assets/images/home-review-empty.svg';

import { Loan, LoanDocState, Task, TaskState } from 'gql/graphql';
import { CreateTaskResponseContext, LoanContext } from 'scenes/Private/Dashboard/Dashboard';
import { LOAN_PHASES, LoanPhases, Phases } from 'scenes/Private/Dashboard/LoanPhases.constanst';
import { Divider, Flex } from 'antd';
import { useGetLoanDocByLoan } from 'services/loan/querys';
import { ArrowRightOutlined } from '@ant-design/icons';

interface Props {
  loan: any;
  rulesTasksCards: any[];
  phaseSelect: string;
  handlerSetPhaseSelect: any;
  handlerSetTaskOpenModal: any;
  handlerSetFilter: any;
  handlerSetProgressData: any;
}

const LoanProgress = ({
  loan,
  rulesTasksCards,
  handlerSetPhaseSelect,
  handlerSetTaskOpenModal,
  handlerSetFilter,
  handlerSetProgressData
}: Props) => {

  const divOpen = useRef<HTMLDivElement>(null);

  const { value: isCreateTaskResponse }: any = useContext(CreateTaskResponseContext);

  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const [phaseSelect, setPhaseSelect] = useState<LoanPhases>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (divOpen.current && !divOpen?.current?.contains(event.target))
        setPhaseSelect(undefined);
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLoanPhases = () => {
    let phases: any = JSON.parse(JSON.stringify(LOAN_PHASES));

    if (!rulesTasksCards)
      return [];

    for (const _phase of phases) {

      const _rulesTaskCards = rulesTasksCards
        .filter(item => item.phaseProgress === _phase.key as Phases &&
          (!item.loanType || item.loanType?.includes(loan?.type)) &&
          (!item.loanPurposes || item.loanPurposes?.includes(loan?.purpose))
        );

      const loanDocRef = loanDocs?.find(item =>
        item.state === LoanDocState.Blocking &&
        _rulesTaskCards.find(item2 => 
          item2.state !== TaskState.Approved && 
          item2.state !== TaskState.Review && 
          item2.key.includes(item.key)
        )
      )

      const isBlocking = !!loanDocRef;
      const isCompleted = _rulesTaskCards.length > 0 &&
        _rulesTaskCards.every(item => item.state === TaskState.Approved);
      const isProgress = _rulesTaskCards.length > 0 &&
        _rulesTaskCards.find(item => (
          item.state === TaskState.Review || item.state === TaskState.Approved || item.state === TaskState.Incomplete
        ));
      const isError = _rulesTaskCards.find(item => item.state === TaskState.RequestError);
      //const isNotStarted = _rulesTaskCards.every(item => item.state === TaskState.Draft);

      if (_phase.key === Phases.PRE_APPROVAL) {
        _phase.state = 'complete';
        _phase.stateName = 'COMPLETE';
      } else {
        _phase.state = isCompleted ? 'complete' : isBlocking ? 'blocked': isProgress ? 'progress' : isError ? 'error' : 'not-started'
        _phase.stateName = isCompleted ? 'COMPLETE' : isBlocking ? 'BLOCKED': isProgress ? 'IN PROGRESS' : isError ? 'ERROR' : 'NOT STARTERD'
      }

      //console.log(_phase.key, _phase.state, rulesTasksCards);

      rulesTasksCards.forEach((taskCard, index) => {
        if (
          !!taskCard.card &&
          taskCard.phaseProgress === _phase.key as Phases &&
          (!taskCard.loanType || taskCard.loanType?.includes(loan.type)) &&
          (!taskCard.loanPurposes || taskCard.loanPurposes?.includes(loan?.purpose))
        ) {
          const buttonState = taskCard.progressButtons && taskCard.progressButtons[taskCard.state];
          
          const loanDocBlocked = loanDocs?.find(item =>
            item.state === LoanDocState.Blocking &&
            taskCard.state !== TaskState.Approved && 
            taskCard.state !== TaskState.Review && 
            taskCard.key.includes(item.key)
          )

          const loanDocRef = loanDocs?.find(item =>
            taskCard.key.includes(item.key) &&
            item.file?.url &&
            !item.hidden
          )

          _phase.tasksItems.push({
            id: index,
            key: taskCard.key,
            modalKey: taskCard.modalKey,
            icon: taskCard.state === TaskState.Approved ? homeGreenSvg :
              taskCard.state === TaskState.RequestError ? homeErrorEmptySvg :
                taskCard.state === TaskState.Review ? homeReviewEmptySvg :
                  _phase.state === 'not-started' ? homeBlackEmptySvg : homeWhiteEmptySvg,
            text: taskCard.name,
            titleButton: (buttonState ? buttonState.label : 'View'),
            state: loanDocBlocked? 'BLOCKED': taskCard.state,
            loanDoc: loanDocRef
          });
        }
      })
    }

    return phases;
  }

  const onClickPhaseProgress = (phase: LoanPhases) => {
    if (phase.id === phaseSelect?.id)
      setPhaseSelect(undefined);
    else setPhaseSelect(phase);

    if (phase.key === Phases.APPROVAL || phase.key === Phases.DUE_DILIGENCE)
      handlerSetPhaseSelect(phase.key);
  }

  const handlerClickTask = (task: any) => {
    if(task.loanDoc) 
      window.open(task.loanDoc.file.url, '_blank');
    else
      handlerSetTaskOpenModal(task.key);
  }

  const phases = getLoanPhases();

  useEffect(() => {
    handlerSetProgressData(
      phases.map((item: any) => ({
        key: item.key,
        state: item.state,
        stateName: item.stateName,
      })).filter((item: any) => item.key !== 'pre-approval')
    );
  }, [isCreateTaskResponse])
  
  return (
    <section className="LoanProgress">
      {phases?.map((tab: any) => (
        <div
          className={`tab tab-${tab.state}`}
          key={tab.key}
          onClick={() => onClickPhaseProgress(tab)}
        >
          <TabBarProgress tab={tab} />
        </div>
      ))}

      {phaseSelect &&
        <div className='tab-open-content' ref={divOpen}>
          <div className='content-bar-status'>
            {phases?.map((tab: any) => (
              <div
                className={`tab tab-${tab.state}`}
                key={tab.key}
                onClick={() => onClickPhaseProgress(tab)}
              >
                <div className={`bar-status ${phaseSelect?.key === tab.key && 'bar-status-selected'}`}>
                  {phaseSelect?.key !== tab.key &&
                    <div className='text-status'>
                      {tab.state === 'complete' &&
                        <img src={checkSvg} alt="check" className="check" />
                      }
                      {(tab.state === 'error' || tab.state === 'blocked') &&
                        <img src={alertSvg} alt="error" />
                      }
                      <span>{tab.stateName}</span>
                    </div>
                  }
                </div>
                <p className="body-small body-small-w4">{tab.name}</p>
              </div>
            ))}
          </div>
          <div className={`content-phase-select content-phase-select-${phaseSelect.state}`}>
            <TagStatus state={phaseSelect.state} stateName={phaseSelect.stateName} />
            <Flex className='content-phase' vertical={false}>
              <div className='content-phase-first'>
                <b>{phaseSelect.name}</b>
                <p>{phaseSelect.description}</p>
              </div>
              <Divider type="vertical" className="divider" />
              <div className='content-phase-second'>
                <b>LENDER TASKS</b>
                <div className='task-item'>
                  <div className='circle' />
                  <span>Appraisal ordered</span>
                </div>
              </div>
              <div className='content-phase-third'>
                <b>BORROWER TASKS</b>
                {phaseSelect.tasksItems.map(task => (
                  <div 
                    key={task.id} 
                    className={`task-item task-item-${task.state}`}
                    style={{ cursor: task.state === 'BLOCKED'? 'pointer': 'pointer' }}
                    onClick={() => handlerClickTask(task)}
                  >
                    {task.state === TaskState.Approved &&
                      <img src={checkGreenSvg} alt="check" className="check" />
                    }
                    {(task.state === TaskState.RequestError || task.state === 'BLOCKED') &&
                      <img src={alertSvg} alt="check" className="check" />
                    }
                    {task.state === TaskState.Review &&
                      <img src={clockSvg} alt="check" className="check" />
                    }
                    {(task.state === TaskState.Draft || task.state === TaskState.Incomplete) &&
                      <div className='circle' />
                    }
                    <span>{task.text}</span>
                    {task.state === 'BLOCKED' && !task.loanDoc && 
                      <b>| Complete <strong>&#8594;</strong></b>
                    }
                    {task.loanDoc && 
                      <b>| View <strong>&#8594;</strong></b>
                    }
                  </div>
                ))}
              </div>
            </Flex>
          </div>
        </div>
      }
    </section>
  );
};

const TagStatus = ({ state, stateName }: any) => {
  return (
    <div className={`tag-state`}>
      {(state === 'error' || state === 'blocked') &&
        <img src={alertSvg} alt="error" />
      }
      {state === 'complete' && <img src={checkSvg} alt="check" />}
      {state === 'progress' && <img src={loaderSvg} alt="loader" />}
      <b>{stateName}</b>
      {(state === 'error' || state === 'blocked') &&
        <>
          <b>|</b>
          <span>Borrower action required</span>
        </>
      }
    </div>
  );
};

interface TabBarProgressProps {
  tab: LoanPhases;
}

const TabBarProgress = ({ tab }: TabBarProgressProps) => {
  return (
    <div className='popover-label'>
      <p className="body-small body-small-w4">{tab.name}</p>
      <div className="bar-status" />
    </div>
  );
};

export default LoanProgress;
