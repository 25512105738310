import React from 'react';
import { Button } from 'antd';
import logoCPng from 'assets/images/logo-c.png';
import logoPng from 'assets/images/logo.png';
import { useGetOAuthToken } from 'services/auth/mutations';
import ProcessingTeamImpersonationBar, { useIsImpersonating } from 'components/Atoms/Bars/ProcessingTeamImpersonationBar/ProcessingTeamImpersonationBar';

const HeaderHome = () => {
  const { execute: getOAuthToken } = useGetOAuthToken();

  const onCreateNewLoan = () => {
    getOAuthToken()
      .then(({ data }) => {
        if (data) {
          const oAuthToken = data.getOAuthToken;
          window.open(`https://sbo.constlending.com/?oauth=${oAuthToken}`, '_blank');
        }
      })
  }

  const { showProcessingBar }  = useIsImpersonating();

  const componentClassName = showProcessingBar ? 'HeaderHome-space processing' : 'HeaderHome-space';

  return (
    <div className={componentClassName}>
      <div className="HeaderHome">
        {
          false &&
            <ProcessingTeamImpersonationBar />
        }
        <div className='content'>
          <div className="box">
            <img className="logo-c" src={logoCPng} alt="logo-c" />
            <img className="logo" src={logoPng} alt="logo" />
          </div>
          <Button className="button button-secondary" onClick={onCreateNewLoan}>New loan</Button>
        </div>
      </div>
    </div>
  );
};
export default HeaderHome;
