import React from 'react';
import { Button, Modal } from 'antd';

import addDocSvg from 'assets/images/addDocSvg.svg';

interface Props {
  visible: boolean;
  setVisible: Function;
  title: string;
  description?: string;
  submit: { buttonLabel: string, onSubmit: Function, color?: 'white' | 'red' };
  cancel?: { buttonLabel: string, color?: 'white' | 'red' };

  icon?: any;
}

const ModalBasic = ({
  visible,
  setVisible,
  title,
  description,
  submit,
  cancel,
  icon,
}: Props) => {

  const handleCancel = () => setVisible(false);

  return (
    <Modal
      open={visible}
      onCancel={handleCancel}
      className={`modal-basic`}
    >
      <div className="modal-end-submit__content">
        <img className="coin" alt="coin" src={icon || addDocSvg}/>
        <h2 className="h2">{title}</h2>
        <h4 className="h4 h4-w4 mt-10" style={{ color: '#404859' }}>
          {description}
        </h4>
        <div className="buttons">
        <Button className={`button ${!submit.color && 'button-modal-ok'} button--${submit.color}`} onClick={() => submit.onSubmit()}>
          {submit.buttonLabel}
        </Button>
        {cancel && 
          <Button className={`button ${!submit.color && 'button-modal-ok'} button--${cancel.color}`} onClick={(e) => setVisible(false)}>
            {cancel.buttonLabel}
          </Button>
        }
        </div>
      </div>
    </Modal>
  );
};
export default ModalBasic;
