import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import arrowBlue from 'assets/images/arrow-blue.png';
import { useGetLoansByOwner } from '../../../../services/loan/querys';
import { useGetEntitiesListByOwner } from '../../../../services/entity/querys';
import { EntitiesViewContext, LoansViewContext, ProfileViewContext } from '../../Private';

const CardEntitiesAndLoans = () => {

  const { setHandlerVisible: setVisibleEntities }: any = useContext(EntitiesViewContext);
  const { setHandlerVisible: setVisibleLoans }: any = useContext(LoansViewContext);
  const { setHandlerVisible: setVisibleProfile }: any = useContext(ProfileViewContext);

  const { data: loans } = useGetLoansByOwner();
  const { data: entities } = useGetEntitiesListByOwner();

  const openLink = (visible: 'loans' | 'entities' | 'profile') => {
    setVisibleLoans(visible === 'loans');
    setVisibleEntities(visible === 'entities');
    setVisibleProfile(visible === 'profile');
  }

  return (
    <div className='card-profile-generic'>
      <h1 className='card-title'>Entities and loans</h1>
      <div className='flex-btw mt-10'>
        <span className='title-bold'>
          <span className='bold'>{loans?.length}</span> {loans?.length === 1? 'loan': 'loans'} | {' '}
          <span className='bold'>{loans?.length}</span> open
          {/*| <span className='bold'>2</span> closed*/}
        </span>
        <Link className="body-regular link" to="./" onClick={() => openLink('loans')}>
          See all loans <img src={arrowBlue} alt='arrow' className='arrow' />
        </Link>
      </div>
      <div className='flex-btw mt-10'>
        <span className='title-bold'>
          <span className='bold'>{entities?.length}</span> {entities?.length === 1? 'entity': 'entities'}
        </span>
        <Link className="body-regular link" to="./" onClick={() => openLink('entities')}>
          See all entities <img src={arrowBlue} alt='arrow' className='arrow' />
        </Link>
      </div>
    </div>
  )
}

export default CardEntitiesAndLoans