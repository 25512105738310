import { gql } from "urql";

export const GET_TASKS_BY_LOAN: any = gql(`
    query GetTasksByLoan($loanId: String!, $state: String){
        GetTasksByLoan(loanId: $loanId, state: $state) {
            _id
            key
            name
            description
            state
            phase
            difficulty
            metadata
            responses {
                _id
                key
                label
                step
                state
                metadata
                files {
                    key
                    name
                    url
                    state
                }
            }
            hidden
        }  
    }
`)

export const GET_TASK_RESPONSES_BY_TASK: any = gql(`
    query GetTaskResponsesByTask($findResponsesByTask: FindResponsesByTask!){
        GetTaskResponsesByTask(findResponsesByTask: $findResponsesByTask) {
            _id
            key
            label
            step
            state
            metadata
            createdAt
            task {
                _id
            }
            files {
                key
                name
                url
                state
            }
        }  
    }
`)

export const GET_TASK_RESPONSE_COMMENTS: any = gql(`
    query GetTaskResponseComments($taskId: String!, $rejectionCode: String, $state: String){
        GetTaskResponseComments(taskId: $taskId, rejectionCode: $rejectionCode, state: $state) {
            _id
            title
            subtitle
            description
            markdown
            metadata
            createdAt
            taskResponse {
                _id
                metadata
                key
                files {
                    key
                    name
                    url
                    state
                }
            }
            rejectionCode {
                _id
                key
                label
            }
        }  
    }
`)