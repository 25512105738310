import { AutoComplete, Input } from "antd";
import { useEffect } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

interface Props {
  disabled?: boolean;
  onSelect?: any;
  initialValue?: string;
}

export const InputAutocompleteAddress = ({ disabled, onSelect, initialValue }: Props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "us" },
      language: 'en'
    },
    debounce: 300
  });

  useEffect(() => {
    if(initialValue)
      setValue(initialValue);
  }, [])

  const handleInput = (value: any) => {
    setValue(value);
  };

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      onSelect({ ...results[0], latLng: { lat, lng } });
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  const options = status === 'OK' ? data.map(({ place_id, description }) => ({
    value: description,
    label: description,
  })) : [];

  return (
    <AutoComplete
      value={value}
      onChange={handleInput}
      onSelect={handleSelect}
      options={options}
      disabled={!ready || disabled}
      style={{ width: '100%' }}
    >
      <Input.Search
        placeholder="356 14th St. San Francisco, CA 93201"
        enterButton={null}
        className="bold-lith"
        disabled={disabled}
      />
    </AutoComplete>
  );
}