import React from "react";
import { Link } from "react-router-dom";
import CoinPng from "assets/images/coin-shadow.png";
import screenPng from "assets/images/screenshop.png";
import Layout from "../components/Layout/Layout";
import FormLogin from "./components/FormLogin";

const Login = () => {
  return (
    <Layout>
      <div className="Login">
        <div className="Login-form">
          <img src={CoinPng} className="coin" alt="coin" />
          <h1 className="h1">Welcome back!</h1>
          <FormLogin />
          <a 
            className="body-regular link-absolute" 
            href="https://sbo.constlending.com/" 
            rel="noopener noreferrer"
          >
            Don’t have an account?{" "}
            <span className="body-regular-bold">Sign up</span>
          </a>
        </div>
        <div className="rigth">
          <div className="">
            <h2 className="h2">
              {`"You are my angel!"`}
            </h2>
            <p className="body-regular" style={{ marginBottom: 10 }}>
              {`"Dealing with multiple lenders is SO exhausting and wondering if l'd get financing made me  apprehensive about chasing deals. Thanks to you, I can just focus on buying properties!"`}
            </p>
            <p className="body-regular">
              <span className="body-regular-bold">— Chandra M,</span> Real estate investor
            </p>
          </div>
          <div className="bottom-flex">
            <div className="back">
              <img src={screenPng} className="screen" alt="screen" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Login;
