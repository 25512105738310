interface Bar {
  color: 'success' | 'empty' | 'error' | 'black' | 'review';
  percentage?: string;
} 

interface Props {
  bars: Bar[];
}

export const Progress = (props: Props) => {
  const { bars } = props;

  return (
    <div className="progress">
      {bars.map((bar, index) => (
        <div key={index} className="bar" style={{ background: `#DFE4EB` }}>
          <div
            className={`bar-percentage bar-percentage-${bar.color}`}
            style={{ width: `100%` }}
          />
        </div>
      ))}
    </div>
  );
}