import { useQuery } from "urql";
import { UseQueryInterface } from "../../../@common/utils/interfaces/use-query.interface";
import { QueryVerifyTokenResetPasswordArgs, VerifyTokenResetPasswordQuery } from "../../../gql/graphql";
import { VERIFY_TOKEN_RESET_PASSWORD } from "./graphql";

export const useVerifyTokenResetPassword = (
  vars?: UseQueryInterface<QueryVerifyTokenResetPasswordArgs>
) => {
  const [{ data, fetching, error }, reexecute] = useQuery<VerifyTokenResetPasswordQuery>({
    query: vars?.query || VERIFY_TOKEN_RESET_PASSWORD,
    ...vars
  });
  return {
    data: data?.VerifyTokenResetPassword,
    fetching,
    error,
    reexecute
  };
};