import CardUploadResponse from 'components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { Entity, File, Task, TaskResponse, TaskState } from 'gql/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { EntityContext, TaskContext } from '../EntityDocs';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import {
  useCreateResponseTask,
  useDeleteFileResponse,
  useRemoveTaskResponse
} from 'services/task/mutations';
import { ArrowRightOutlined } from '@ant-design/icons';
import ButtonEditDoc from './ButtonEditDoc';
import ButtonConfirmDoc from './ButtonConfirmDoc';
import DropdownEntities from '../../EntityGuarantorCreation/DropdownEntities/DropdownEntities';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import AddAnotherDoc from 'scenes/Private/components/AddAnotherDoc/AddAnotherDoc';
import ModalFinish from '../ModalFinish';

interface Props {
  closeTask: any;
  isConfirmDocs: boolean;
  onSubmitChangeEntity: any;
}

const Step1 = ({ closeTask, isConfirmDocs, onSubmitChangeEntity }: Props) => {
  const task: Task | any = useContext(TaskContext);
  const entity: Entity | any = useContext(EntityContext);

  const { execute: _deleteFile } = useDeleteFileResponse();
  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({ variables: { findResponsesByTask: { taskId: task._id } } });

  const {
    data,
    fetching,
    execute: createResponseTask
  } = useCreateResponseTask();

  const { execute: removeTaskResponse } = useRemoveTaskResponse();

  const [responseEin, setResponseEin] = useState<TaskResponse>();
  const [responseCertificate, setResponseCertificate] = useState<TaskResponse>();
  const [responseOperating, setResponseOperating] = useState<TaskResponse>();
  const [responseGoodStanding, setResponseGoodStanding] = useState<TaskResponse>();
  const [listFileUpload, setListFileUpload] = useState({ ein: [], operating: [] });
  const [isModalEdit, setModalEdit] = useState<boolean>(false);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [loadingCreate, setLoadingCreate] = useState<string>();
  const [modalFinish, setModalFinish] = useState<boolean>(false);

  useEffect(() => {
    let einLetter: any = taskResponses?.find((item) => item.key === 'ein-letter');
    setResponseEin(einLetter);

    let certificate: any = taskResponses?.find((item) => item.key === 'certificate-organization');
    if (certificate) setResponseCertificate(certificate);

    let operating: any = taskResponses?.find((item) => item.key === 'operating-agreement');
    setResponseOperating(operating);

    let goodStanding: any = taskResponses?.find((item) => item.key === 'certificate-good-standing');
    if (goodStanding) setResponseGoodStanding(goodStanding);

    setTaskCompleted([einLetter, certificate, operating, goodStanding].every(item => item?.state === TaskState.Approved));
  }, [taskResponses]);

  useEffect(() => {
    if (data) getTaskResponses({ requestPolicy: 'network-only' });
  }, [data]);

  const submit = async (
    id: string | undefined,
    key: string,
    label: string,
    files: any
  ) => {
    if (task) {
      setLoadingCreate(key);

      await createResponseTask({
        _id: id,
        key,
        label,
        step: 2,
        metadata: {},
        state: TaskState.Review,
        task: task?._id,
        files
      });

      let inReviews = [
        responseEin,
        responseCertificate,
        responseOperating,
        responseGoodStanding
      ].filter((item) => item?.state === TaskState.Review);

      if (inReviews.length === 3) {
        setModalEdit(true);
        setModalFinish(true);
      };
      setLoadingCreate(undefined);
    }
  };

  const deleteFile = (doc: TaskResponse) => {
    removeTaskResponse({ taskResponseId: doc._id });
  };

  const onChangeFiles = (key: string, files: any) => {
    setListFileUpload({ ...listFileUpload, [key]: files });
  };

  const onDeleteFile = (file: File, doc: TaskResponse) => {
    if(doc)
      _deleteFile({ fileKey: file.key, taskResponseId: doc._id });
  }

  let docs = [
    {
      title: 'EIN letter',
      key: 'ein-letter',
      state: responseEin,
      description:
        'Write a blurb about what this is and where to find it and how to get one and it’s probably going to be more than one line blah blah'
    },
    {
      title: 'Certificate of organization',
      key: 'certificate-organization',
      state: responseCertificate,
      description:
        'Write a blurb about what this is and where to find it and how to get one and it’s probably going to be more than one line blah blah'
    },
    {
      title: 'Operating Agreement',
      key: 'operating-agreement',
      state: responseOperating,
      description:
        'Write a blurb about what this is and where to find it and how to get one and it’s probably going to be more than one line blah blah'
    },
    {
      title: 'Certificate of Good Standing',
      key: 'certificate-good-standing',
      state: responseGoodStanding,
      description:
        'Write a blurb about what this is and where to find it and how to get one and it’s probably going to be more than one line blah blah'
    }
  ];

  if (isConfirmDocs)
    docs = docs.filter((item) => item.key !== 'ein-letter' && item.key !== 'operating-agreement')

  return (
    <section className="entity-docs-step-2">
      <div className="section-1">
        <h3 className="h3">
          {isConfirmDocs && (
            <>
              Confirm or edit the documents for{' '}
              <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
            </>
          )}
          {!isConfirmDocs && (
            <>
              Here are the docs we need for{' '}
              <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
            </>
          )}
        </h3>

        {isConfirmDocs && (
          <div className="h4 h4-w4">
            Editing won’t affect closed loan applications, but if you have any
            active applications, the entity details will be updated there. Don’t
            want anything to change?{' '}
            <b className="bold cursor">Create a new entity instead</b>{' '}
            <ArrowRightOutlined className="cursor" />
          </div>
        )}
        {isConfirmDocs && (
          <div className="mt-40">
            <span style={{ fontWeight: 800 }}>New documents needed</span>
            <p style={{ marginTop: 6 }}>
              Your property location or entity formation date can affect the
              documentation we need to process your loan
            </p>
          </div>
        )}
      </div>

      {taskCompleted &&
        <SectionSuccessTask
          title="You've completed this task."
          button={{ title: "Back to dashboard", onClick: closeTask }}
        />
      }

      {!taskCompleted &&
        <SectionErrorTask taskId={task._id} />
      }

      {docs.map((itemDoc: any) =>
        <div key={itemDoc.key} className="mt-20">
          <CardUploadResponse
            title={itemDoc.title}
            description={itemDoc.description}
            isButtonSample={{ show: true, link: '' }}
            actionDeleteFile={(file: File) => onDeleteFile(file, itemDoc.state)}
            submit={{
              title: 'Submit for review',
              loading: loadingCreate === itemDoc.key,
              modalConfirm: true,
              confirmDescription:
                "After submitting, you'll have to contact us to make any changes.",
              onClick: (files) =>
                submit(itemDoc.state?._id, itemDoc.key, itemDoc.title, files)
            }}
            files={itemDoc.state?.files}
            state={itemDoc.state?.state}
          />
        </div>
      )}
      {isConfirmDocs && (
        <div className="mt-40">
          <span style={{ fontWeight: 800 }}>Submitted documents</span>
          <div className="mt-20">
            <CardUploadResponse
              title="EIN letter"
              onChangeFiles={(files: any) => onChangeFiles('ein-letter', files)}
              files={responseEin?.files}
              actionDeleteFile={
                responseEin?.state === TaskState.Draft
                  ? () => deleteFile(responseEin)
                  : null
              }
              state={
                responseEin?.state !== TaskState.Incomplete &&
                  responseEin?.state !== TaskState.Draft
                  ? responseEin?.state
                  : undefined
              }
            />
          </div>
          <div className="mt-20">
            <CardUploadResponse
              title="Operating Agreement"
              onChangeFiles={(files: any) =>
                onChangeFiles('operating-agreement', files)
              }
              files={responseOperating?.files}
              actionDeleteFile={
                responseOperating?.state === TaskState.Draft
                  ? () => deleteFile(responseOperating)
                  : null
              }
              state={
                responseOperating?.state !== TaskState.Incomplete &&
                  responseOperating?.state !== TaskState.Draft
                  ? responseOperating?.state
                  : undefined
              }
            />
          </div>

          <div className="mt-20 submits">
            <ButtonConfirmDoc
              isConfirmDocs={isConfirmDocs}
              isModalEdit={isModalEdit}
              setModalEdit={setModalEdit}
              ein={responseEin}
              agreement={responseOperating}
              certificate={responseCertificate}
              goodStanding={responseGoodStanding}
              closeTask={closeTask}
              listFileUpload={listFileUpload}
              buttonLabel={
                responseEin?.state === TaskState.Incomplete
                  ? 'Confirm documents'
                  : 'Submit changes'
              }
              buttonShow={
                responseEin?.state === TaskState.Incomplete ||
                responseEin?.state === TaskState.Draft ||
                responseOperating?.state === TaskState.Incomplete ||
                responseOperating?.state === TaskState.Draft
              }
            />
            {responseEin?.state === TaskState.Incomplete && (
              <ButtonEditDoc
                entity={entity?.name}
                ein={responseEin}
                agreement={responseOperating}
              />
            )}
          </div>
        </div>
      )}

      <AddAnotherDoc taskId={task._id} taskState={task.state} />

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required' />

      <ModalFinish
        visible={modalFinish}
        setVisible={setModalFinish}
        closeTask={closeTask}
      />
    </section>
  );
};
export default Step1;
