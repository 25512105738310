import React, { createContext, useEffect, useState } from 'react';
import { Button, Checkbox } from 'antd';
import SectionRight from './SectionRight/SectionRight';
import calendarPng from 'assets/images/calendar.png';
import closePng from 'assets/images/close-gray.png';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { TaskState } from '../../../../gql/graphql';
import { Tooltip } from 'react-tooltip';
import ProcessingTeamImpersonationBar, { useIsImpersonating } from 'components/Atoms/Bars/ProcessingTeamImpersonationBar/ProcessingTeamImpersonationBar';

interface Props {
  visible: boolean;
  handlerSetVisibile: Function;
  renderButtonTrigger: Function;
  header?: {
    title: React.ReactElement;
    actionLeft?: React.ReactElement;
    actionRight?: React.ReactElement;
  };
  stepsTotal: number | 1;
  currentStep: number;
  setStep: Function | null;
  children: React.ReactElement;
  footer?: React.ReactElement | null;
  tracker?: React.ReactElement | null;
  animationDirection: string | 'onRight';
  responses?: any[];
  disabledDots?: boolean;
  isConfirmModalClose?: boolean;

}
interface dotObjet {
  id: number;
  state: string;
}

export const ModalTaskRightContext = createContext<{ value: string; setHandler: any } | {}>({});

const ModalTask = (props: Props) => {

  const [isDots, setDots] = useState<dotObjet[]>();
  const [isModalSubmit, setModalSubmit] = useState<string>("inactive");
  const [openSectionRight, setOpenSectionRight] = useState<{ show: boolean, params?: any }>();
  const [isHovered, setIsHovered] = useState<number | null>(null);

  useEffect(() => {
    const arrayDots = [];
    for (let index = 0; index < props.stepsTotal; index++) {
      arrayDots.push({ id: index + 1, state: '' });
    }

    if (props.responses) {
      for (const dot of arrayDots) {
        //console.log('props.responses', props.header?.title.props.children, dot, props.responses);

        const responsesStep = props.responses.filter(response =>
          response?.step === dot.id ||
          //Entity guarantor
          ((dot.id === 3 || dot.id === 2) && response?.key?.includes('ownership')) ||
          //Minimium liquidity
          //totalBalanceCurrency >= props.maxBalance? 'approved': isReview? 'review': null
          //Track Record
          (response?.step === 2 && response?.key?.includes('purchased/sold'))
        );

        if (responsesStep?.length > 0) {
          const isApproved = responsesStep.every((item: any) => item.state === TaskState.Approved);
          const isError = !!responsesStep.find((item: any) => item.state === TaskState.RequestError);
          const isReview = !!responsesStep.find((item: any) => item.state === TaskState.Review);
          const isDraft = responsesStep.every((item: any) => item.state === TaskState.Draft || item.state === TaskState.Incomplete);

          if (isApproved) dot.state = 'approved';
          else if (isError) dot.state = 'error';
          else if (isReview) dot.state = 'review';
          else if (isDraft) dot.state = 'draft';
        }
      }
      //console.log('arrayDots', props.header?.title.props.children, arrayDots);

      setDots(arrayDots);
    }
  }, [props.responses])

  useEffect(() => {
    document.body.style.overflowY = (props.visible) ? "hidden" : "scroll";
  }, [props.visible])

  const isStyleDots = (item: any, index: number) => {
    if (item.id === props.currentStep) {
      if (item.state === 'approved' && props.responses && props.responses.length === 1) return 'dots-button-success';
      if (item.state === 'approved' && props.responses && props.responses.length > 1) return 'dots-button-approved-active';
      if (item.state === 'review' && props.responses && props.responses.length === 1) return 'dots-button-success-review';
      if (item.state === 'review' && props.responses && props.responses.length > 1) return 'dots-button-review-active';
      if (item.state === 'error' && props.responses && props.responses.length === 1) return 'dots-button-success-error';
      if (item.state === 'error' && props.responses && props.responses.length > 1) return 'dots-button-error-active';
      else if (item.state === 'review') return 'dots-button-review-active';
      else if (item.state === 'error') return 'dots-button-error-active';
      else return 'dots-button-active';
    } else if (item.id < props.currentStep || item.state === 'approved') {
      return 'dots-button-success';
    } else {
      return '';
    }
  };

  const closeModal = () => {
    props.handlerSetVisibile(false);
    setOpenSectionRight({ show: false });
    setModalSubmit("inactive");
    /*
    if(localStorage.getItem('modalTaskClose') === 'true') {
      setModalSubmit("close")  
      return;
    }
    
    setModalSubmit(props.isConfirmModalClose ? "active" : "close")*/
  }

  const handlerModalTaskRight = (show: boolean, params: any) =>
    setOpenSectionRight({ show, params });

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    if (e.target.checked)
      localStorage.setItem('modalTaskClose', 'true');
    else
      localStorage.removeItem('modalTaskClose');
  };

  useEffect(() => {
    if (isModalSubmit === "close") {
      props.handlerSetVisibile(false);
      setOpenSectionRight({ show: false });
      setModalSubmit("inactive");
    }
  }, [isModalSubmit])

  const handleMouseEnter = (id: number) => setIsHovered(id);
  const handleMouseLeave = () => setIsHovered(null);

  const setHoverText = (isDisabled: boolean, isActive: boolean, step: number, state: string, response: any) => {
    let stepText = "";
    let errorText = "";
    const responseFromEntity = response?.filter((item: any) => item.key === 'complete-ownerships');
    if (state === 'error' && responseFromEntity.length === 0) {
      const getCountError = response?.filter((item: any) => item.step === step && item.state === 'REQUEST_ERROR')
      errorText = getCountError.length > 1 ? getCountError.length + ' ERRORS' : getCountError.length + ' ERROR'
    } else if (state === 'error' && responseFromEntity.length > 0) {
      const getCountError = response?.filter((item: any) => item.step === 2 && item.state === 'REQUEST_ERROR')
      errorText = getCountError.length > 1 ? getCountError.length + ' ERRORS' : getCountError.length + ' ERROR'
    }
    //logica para entity ownership and guarantors
    if (responseFromEntity.length > 0 && state === '' && !isActive
      || responseFromEntity.length > 0 && state === 'review' && !isActive
      || responseFromEntity.length > 0 && state === 'error' && !isActive
    ) {
      return "STEP " + step + " | APPROVED"
    }
    switch (step) {
      case 1:
        stepText = isDisabled ? "STEP " + step + " | " : "STEP ONE";
        break;
      case 2:
        stepText = isDisabled ? "STEP " + step + " | " : "STEP TWO";
        break;
      case 3:
        stepText = isDisabled ? "STEP " + step + " | " : "STEP THREE";
        break;
      default:
        stepText = "STEP " + step;
        break;
    }
    return `${stepText}${!isDisabled ? '<br/>' : ''}
      ${state === 'review' ? 'IN ' : ''}${state !== 'error' ? state.toUpperCase() : ''}
      ${state === 'error' ? errorText : ''}`;
  }
  
  const { showProcessingBar }  = useIsImpersonating();

  return (
    <div className="cont-modal">
      {props.renderButtonTrigger(props.handlerSetVisibile)}
      {props.visible && (
        <ModalTaskRightContext.Provider value={{ value: openSectionRight, setHandler: handlerModalTaskRight }}>
          <div className="ModalTask">
            {!openSectionRight?.show &&
              <Button
                className="button-close-mask"
                style={{ width: openSectionRight?.show ? '0%' : '10%' }}
                onClick={closeModal}
              />
            }
            <div
              className={`ModalTask-content animate__animated ${props.animationDirection === 'onRight' && 'animate__fadeInRight'}`}
              style={{ width: openSectionRight?.show ? '80%' : '90%' }}
            >
              {
                showProcessingBar &&
                  <ProcessingTeamImpersonationBar />
              }
              <div className="content-modal">
                <div className="modal-review-task__content">
                  <div className="modal-title">
                    <div className="flex-btw">
                      <h3 className="body-card body-card-w6">
                        {props.header?.title}
                      </h3>
                      <Button
                        className="body-card"
                        onClick={closeModal}
                      >
                        Close X
                      </Button>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>

                  {props.tracker &&
                    <div className='task-tracker-summary' style={{ height: 'default' }}>{props.tracker}</div>
                  }

                  <div
                    className="modal-container"
                    style={{ height: props.tracker ? '90vh' : 'calc(100vh - 180px)' }}
                  >
                    {isDots && (
                      <div className="dots">
                        {isDots?.map((item, index) => {
                          const isDisabled = props.disabledDots || props.currentStep <= index;
                          const tooltipId = `tooltip-button-${item.id}`;
                          const isActive = isStyleDots(item, index).includes('active');
                          const bubbleFromGuarantors = props?.responses?.filter((item: any) => item.key === 'complete-ownerships') || [];
                          const tooltipPlace = item.id === 1 ? 'top' : 'right';
                          
                          return (
                            <React.Fragment key={item.id}>
                              <Button
                                onMouseEnter={() => handleMouseEnter(item.id)}
                                onMouseLeave={handleMouseLeave}
                                onClick={() => props.setStep && props.setStep(item.id)}
                                disabled={isDisabled}
                                className={
                                  `dots-button ${isStyleDots(item, index)} ${!isDisabled && isDots.length > 1 && 'dots-button-prev-hover'} ${isDisabled && `dots-button-disabled-${props.responses && bubbleFromGuarantors.length === 0 && props.responses[index]?.state}`}`
                                }
                                key={item.id}
                                data-tooltip-id={tooltipId}
                                data-tooltip-html={setHoverText(isDisabled, isActive, item.id, item.state, props.responses)}
                                data-tooltip-offset={-5}
                              >
                              </Button>
                              <Tooltip
                                id={tooltipId}
                                place={tooltipPlace}
                                className={`tooltip-button ${item.state && bubbleFromGuarantors.length === 0 ? `tooltip-button-${item.state}`
                                  : item.state === '' && bubbleFromGuarantors.length > 0 ?
                                    'tooltip-button-complete-ownerships' :
                                    item.state === 'review' && bubbleFromGuarantors.length > 0 ?
                                      `tooltip-button-${item.state}` :
                                      item.state === 'error' && bubbleFromGuarantors.length > 0 && item.id !== 3 ?
                                        `tooltip-button-${bubbleFromGuarantors[0]?.state.toLowerCase()}` :
                                        `tooltip-button-${props?.responses?.[index]?.state.toLowerCase()}`}`}
                              />
                            </React.Fragment>
                          )
                        })}
                      </div>
                    )}
                    <div className="modal-cards modal-cards--full">
                      <div className="modal-cards-mx-w">
                        <div className="flex-btw">
                          {props.header?.actionLeft}
                          {props.header?.actionRight}
                        </div>
                        {props.children}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {props.footer && (
                <div className="modal-review-task__footer">
                  <div className="modal-container">
                    <div className="modal-cards">{props.footer}</div>
                  </div>
                </div>
              )}
            </div>
            {openSectionRight?.show &&
              <SectionRight params={openSectionRight.params} close={() => setOpenSectionRight({ show: false })} />
            }
            {isModalSubmit === "active" &&
              <div className='ModalCloseTask'>
                <Button className='ModalCloseTask-mask' onClick={() => setModalSubmit("inactive")}></Button>
                <div className="modal-end-submit__content">
                  <Button className='button-close' onClick={() => setModalSubmit("inactive")}>
                    <img src={closePng} alt='close' />
                  </Button>
                  <img className="coin" alt="coin" src={calendarPng} />
                  <h2 className="h2">Finish this task later?</h2>
                  <h4 className="h4 h4-w4 text-modal">Don’t worry! We’ll save your work. Just click on the task in your dashboard to resume.</h4>
                  <div className="buttons">
                    <Button
                      className="button button-modal-ok mt--10"
                      onClick={(e) => {
                        setModalSubmit("close");
                      }}
                    >
                      I’ll do this later
                    </Button>
                    <Button
                      className="button button-modal-cancel"
                      onClick={() => setModalSubmit("inactive")}
                    >
                      I want to finish this task now
                    </Button>
                  </div>
                  <br />
                  <Checkbox onChange={onChangeCheckbox}>Don’t show this message again</Checkbox>
                </div>
              </div >
            }
          </div>
        </ModalTaskRightContext.Provider>
      )}
    </div>
  );
};
export default ModalTask;
