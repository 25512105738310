import React from 'react';
import checkSvg from 'assets/images/check-circle.svg';
import clockSvg from 'assets/images/clock.svg';
import errorSvg from 'assets/images/alert-circle.svg';
import paperSvg from 'assets/images/paper-gray.svg';
import { TaskState } from 'gql/graphql';

interface Props {
  state: TaskState | string;
  isOtherState?: boolean;
  colorState?: TaskState | undefined;
  isLabelLocked?: boolean;
  size?: 'default' | 'large';
  margin?: string;
}

const StateTag = ({ 
  state, 
  isLabelLocked, 
  size, 
  margin,
  isOtherState,
  colorState
}: Props) => {
  
  return (
    <div 
      className={`state-tag state-tag-${colorState || state}`} 
      style={{ padding: isLabelLocked? '6px 9px': 'default', margin: margin || 'default' }}
    >
      {isOtherState && (
        <>
          {colorState === TaskState.RequestError && 
            <img src={errorSvg} alt="check" className="check" width={size === 'large'? 18: 12}/>
          } 
          { colorState === TaskState.Draft && 
            <img src={paperSvg} alt="check" className="check" width={size === 'large'? 18: 12}/>
           }
          <span className="tag-name state-name-w6">{state}</span>
        </>
      )}
      {state === TaskState.Approved && (
        <>
          <img src={checkSvg} alt="check" className="check" width={size === 'large'? 18: 12}/>
          <span className="tag-name state-name-w6" style={{ fontSize: (size === 'large'? 14: 'default')}}>
            APPROVED
          </span>
        </>
      )}
      {!isOtherState && state === TaskState.Draft && (
        <span className="tag-name state-name-w6">NOT STARTED</span>
      )}

      {state === TaskState.Incomplete && (
        <span className="tag-name state-name-w6">IN PROGRESS</span>
      )}

      {state === TaskState.Review && (
        <>
          <img src={clockSvg} alt="check" className="check" width={size === 'large'? 18: 12}/>
          <span className="tag-name state-name-w6" style={{ fontSize: (size === 'large'? 14: 'default')}}>
            IN REVIEW{' '}
            <span className='bold-little'>{isLabelLocked && "| Editing Locked"}</span>
          </span>
        </>
      )}

      {state === TaskState.RequestError && (
        <>
          <img src={errorSvg} alt="check" className="check" width={size === 'large'? 18: 12}/>
          <span className="tag-name state-name-w6" style={{ fontSize: (size === 'large'? 14: 'default')}}>
            REJECTED | Edit or resubmit
          </span>
        </>
      )}
    </div>
  );
};

export default StateTag;
