import React, { useEffect, useState, useContext } from 'react';
import CardMarketRent from './CardMarketRent/CardMarketRent';
import CardLeaseAgreements from './CardLeaseAgreements/CardLeaseAgreements';
import CardTotalUnits from './CardTotalUnits/CardTotalUnits';
import { TaskContext } from '../DscrQuestionsAgreements';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { useCreateResponseTask } from 'services/task/mutations';
import { useGetTaskResponsesByTask } from 'services/task/querys';

interface Step3Props {
  task: Task | any;
}

const Step3 = ({ task }: Step3Props) => {
  // @ts-ignore
  const { totalUnits, setTotalUnits, vacantUnits, setVacantUnits, occupiedUnits, setOccupiedUnits } = useContext(TaskContext);
  const [response1, setResponse1] = useState<TaskResponse | any>();
  const [response2, setResponse2] = useState<TaskResponse | any>();
  const [response3, setResponse3] = useState<TaskResponse | any>();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });
  const handleTotalUnitsChange = (newTotalUnits: number, newVacantUnits: number) => {
    setTotalUnits(newTotalUnits);
    setVacantUnits(newVacantUnits);
  };

  const { execute: createResponseTask } = useCreateResponseTask();

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    if(taskResponses) {
      const step3Response_totalUnits = taskResponses.find((item) => item.key === 'units-in-property-dscr-step3') as TaskResponse;
      const step3Response_vacantUnits = taskResponses.find((item) => item.key === 'market-rent') as TaskResponse;
      const step3Response_occupiedUnits = taskResponses.find((item) => item.key === 'occupied-units-in-property-dscr-step3') as TaskResponse;
      setResponse1(step3Response_totalUnits);
      setResponse2(step3Response_vacantUnits);
      setResponse3(step3Response_occupiedUnits);
      if (step3Response_totalUnits && !step3Response_totalUnits && !step3Response_vacantUnits && !step3Response_occupiedUnits) {
        setTotalUnits(response1.metadata.totalUnits);
        // setVacantUnits(lastStep2Response.metadata.vacantUnits);
      } else if (step3Response_totalUnits && step3Response_vacantUnits) {
        setTotalUnits(step3Response_totalUnits.metadata.totalUnits);
        setVacantUnits(step3Response_vacantUnits.metadata.vacantUnits);
      }
    }
  }, [taskResponses]);

  const saveResponse = async (key: string, label: string, metadata: any, state: TaskState, files?: any) => {
    let payload: any = {
      key: key,
      label: label,
      step: 3,
      metadata: metadata,
      state: state,
      task: task._id,
      files: files
    }
    if (response1 && key === 'units-in-property-dscr-step3') payload._id = response1._id;
    if (response2 && key === 'market-rent') payload._id = response2._id;
    if (response3 && key === 'occupied-units-in-property-dscr-step3') payload._id = response3._id;
    await createResponseTask(payload);
    
  }

  return (
    <section className="Step3">
      <h2 className="h2">
        Give us market rents for the{' '}
        <span className="blue-bold">{vacantUnits} vacant units</span> and lease agreements
        for the <span className="blue-bold">{occupiedUnits} occupied units</span> in the
        property
      </h2>
      <p className="h4 h4-w4">
        We’ll review the leases and calculate your final DSCR for you
      </p>
      <div className="mt-25">
        <CardTotalUnits
          totalUnits={totalUnits}
          setTotalUnits={setTotalUnits}
          saveResponse={saveResponse}
          task={task}
        />
      </div>
      <div className="mt-25">
        <CardMarketRent 
          vacantUnits={vacantUnits} 
          setVacantUnits={setVacantUnits}
          totalUnits={totalUnits}
          setTotalUnits={setTotalUnits}
          occupiedUnits={occupiedUnits}
          saveResponse={saveResponse}
          task={task}
          />
      </div>
      <div className="mt-25">
        <CardLeaseAgreements
          occupiedUnits={occupiedUnits}
          setOccupiedUnits={setOccupiedUnits}
          totalUnits={totalUnits}
          setTotalUnits={setTotalUnits}
          ammountFiles={occupiedUnits}
          task={task}
          onTotalUnitsChange={handleTotalUnitsChange}
          saveResponse={saveResponse}
        />
      </div>
    </section>
  );
};
export default Step3;
