import React from 'react';
import './ProcessingTeamImpersonationBar.scss';
import { useGetUserLoggin } from 'services/user/querys';

const ProcessingTeamImpersonationBar = () => {
  const { data: user } = useGetUserLoggin();
  
  if (!user) return null;

  const name = `${user?.name} ${user?.lastName}`;

  return (
    <div className='processing-bar'>
      🚨 You are now impersonating <b>{name}</b>. Changes you make here will be shown to the borrower
    </div>
  )
}
export const useIsImpersonating = () => {
  const showProcessingBar = localStorage.getItem('fromOAuth') === 'true';
  return { showProcessingBar };
};

export default ProcessingTeamImpersonationBar;