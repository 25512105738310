// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.processing-bar {
  background: var(--yellow-500, #FFC857);
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center !important;
  color: var(900-grey, #3C302A);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px; }
  .processing-bar b {
    font-weight: 600; }
`, "",{"version":3,"sources":["webpack://./src/components/Atoms/Bars/ProcessingTeamImpersonationBar/ProcessingTeamImpersonationBar.scss"],"names":[],"mappings":"AAAA;EACI,sCAAsC;EACtC,iBAAiB;EACjB,oBAAoB;EACpB,6BAA6B;EAE7B,6BAA+B;EAC/B,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,sBAAsB,EAAA;EAb1B;IAgBQ,gBAAgB,EAAA","sourcesContent":[".processing-bar {\n    background: var(--yellow-500, #FFC857);\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: center !important;\n\n    color: var(--900-grey, #3C302A);\n    text-align: right;\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    letter-spacing: 0.28px;\n\n    b {\n        font-weight: 600;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
