import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import bellSvg from 'assets/images/bell.svg';
import logoutSvg from 'assets/images/logout.svg';
import avatarSvg from 'assets/images/avatar.png';
import { EntitiesViewContext, LoansViewContext, ProfileViewContext } from '../../../Private';
import { Avatar, Divider } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetUserLoggin } from 'services/user/querys';

const BarDashboard = () => {

  const naviagate = useNavigate();

  const { data: user, fetching: loadingUser } = useGetUserLoggin();

  const { visible: visibleEntities, setHandlerVisible: setVisibleEntities }: any = useContext(EntitiesViewContext);
  const { visible: visibleLoans, setHandlerVisible: setVisibleLoans }: any = useContext(LoansViewContext);
  const { visible: visibleProfile, setHandlerVisible: setVisibleProfile }: any = useContext(ProfileViewContext);

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
    naviagate("/");
    naviagate(0);
  }

  const activeOption = (visible: 'loans' | 'entities' | 'profile') => {
    setVisibleLoans(visible === 'loans');
    setVisibleEntities(visible === 'entities');
    setVisibleProfile(visible === 'profile');
  }
  
  return (
    <div className="BarDashboard-space">
      <div className="BarDashboard">
        <div className="box">
          {/*<div className="notification">
            <div className="number-ammoint">
              <span>9</span>
            </div>
            <img className="icon bell" src={bellSvg} alt="bell" />
          </div>*/}
        </div>
        <div style={{ width: 40, margin: 'auto' }}>
          <Divider style={{ background: '#ccd3db', margin: 0 }}/>
        </div>
        <div className="box box-medium">
          <div className="menu-item">
            <Link className={`link ${visibleLoans && 'link-active' }`} to="./" onClick={() => activeOption('loans')}>
              <div className="brief"/>
              <span>Loans</span>
            </Link>
            <Link className={`link ${visibleEntities && 'link-active' }`} to="./" onClick={() => activeOption('entities')}>
              <div className="award"/>
              <span>Entities</span>
            </Link>
          </div>
        </div>
        <div style={{ width: 40, margin: 'auto' }}>
          <Divider style={{ background: '#ccd3db', margin: 0 }}/>
        </div>
        <div className="box box-end">
          <Link className="link" onClick={logout} to="">
            <img src={logoutSvg} className="icon" alt="logout" />
            <span>Log out</span>
          </Link>
          <Link className={`link ${visibleProfile && 'link-active' }`} to="./" onClick={() => activeOption('profile')}>
            <Avatar src={!loadingUser && user?.avatar?.url} size={35} className='avatar'>
              {!user?.avatar && user?.name?.charAt(0)}
              {(loadingUser) && <LoadingOutlined />}
            </Avatar>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default BarDashboard;
