import React, { useContext } from 'react';
import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';

interface Props {
  visible: boolean;
  setVisible: Function;
  closeTask?: Function;
}

const ModalFinish = ({ visible, setVisible, closeTask }: Props) => {
  return (
    <ModalEndSubmit
      visible={visible}
      setVisible={setVisible}
      buttonAction={{ hidden: true }}
      modal={{
        title: 'Great job!',
        description: (
          <h4 className="h4 h4-w4">
            You just completed one of the six tasks required to get you approved for your loan
          </h4>
        )
      }}
      className=""
      icon={calendarPng}
      buttonSubmit={
        <Button
          htmlType="submit"
          className="button button-modal-ok"
          onClick={() => {
            setVisible(false);
            if(closeTask)
              closeTask();
          }}
        >
          Go to dashboard
        </Button>
      }
    />
  );
};
export default ModalFinish;
