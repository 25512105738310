import React, { useContext, useEffect, useState } from 'react';
import QuestionBasic from 'components/Organisms/QuestionBasic/QuestionBasic';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import calendarPng from 'assets/images/calendar.png';
import coinPng from 'assets/images/coin-2.svg';
import { Button, Input } from 'antd';
import "./Step1.scss";
import { useCreateResponseTask } from 'services/task/mutations';
import { TaskState } from 'gql/graphql';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import { LoanContext } from 'scenes/Private/Dashboard/Dashboard';

interface Step1Props {
  task: any;
  userEmail: string;
	closeTask: Function;
}

const Step1 = ({ task, userEmail, closeTask }: Step1Props) => {

  const loan: any = useContext(LoanContext);

  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { data: createResponse, fetching, execute: createResponseTask } = useCreateResponseTask();

  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [customUserEmail, setCustomUserEmail] = useState<string>('');

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isSubmittedModalVisible, setIsSubmittedModalVisible] = useState(false);

  const [taskCompleted, setTaskCompleted] = useState<boolean>();

	useEffect(() => {
    const _response = taskResponses?.find((item) => item.key === 'payment_email');
		setTaskCompleted(_response?.state === TaskState.Approved)
	}, [taskResponses])
  
  const handleSumbit = () => setIsConfirmationModalVisible(true);

  const handleConfirmation = async () => {
    try {
      if (task) {
        await createResponseTask({
          key: "payment_email",
          label: "Which email would you like the payment link sent to?",
          step: 1,
          metadata: {
            userEmail: customUserEmail || userEmail
          },
          state: TaskState.Review,
          task: task?._id,
          files: []
        });
  
        setIsConfirmationModalVisible(false);
        setIsSubmittedModalVisible(true);
      }
    } catch (error) {
      console.error(error)
    } finally {  
    }
  }

  return (
    <section>
      <article className="section-select-option">
        <h3 className="h3">We’ll need pre-payment to order your appraisal</h3>

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} />
        }

        <QuestionBasic
          type="buttons"
          text={null}
          question={<h3 className="h2">Which email would you like the payment link sent to?</h3>}
          listItems={null}
          options={[
            {
              id: 1,
              title: userEmail,
              dropdown: undefined,
              onClick: () => { setIsCustomSelected(false); handleSumbit(); setCustomUserEmail('') }
            },
            {
              id: 2,
              title: 'A different email',
              dropdown: undefined,
              styleHover: 'option-hover-blue',
              styleActive: 'option-active-blue',
              onClick: () => setIsCustomSelected(true),
              subtitle: (
                isCustomSelected ?
                  <div className='DifferentEmail'>
                    <div className='space' />
                    <Input
                      type='email'
                      placeholder="Email Address" 
                      value={customUserEmail} 
                      onChange={(e) => setCustomUserEmail(e.target.value)}
                      className="input input-transparent input-placeholder-bold"
                    />
                    {
                      customUserEmail.length ?
                        <React.Fragment>
                          <div className='space' />
                          <div className='space' />
                          <div className='buttons'>
                            <Button
                              id='button-submit'
                              className="button button-modal-ok"
                              onClick={handleSumbit}
                            >
                              Submit and complete task
                            </Button>
                          </div>
                        </React.Fragment>
                        :
                        null
                    }
                  </div>
                  :
                  undefined
              ),
            }
          ]}
        />
      </article>

      <CollapseIssues taskId={task._id} rejectionCode='no_action_required'/>

      <ModalEndSubmit
        visible={isConfirmationModalVisible}
        setVisible={setIsConfirmationModalVisible}
        modal={{
          title: 'Ready to submit?',
          description: (
            <h4 className="h4 h4-w4">
              After submitting, you’ll have to contact us for a new payment link
            </h4>
          ),
        }}
        buttonSubmit={
          <Button
            className="button button-modal-ok"
            onClick={handleConfirmation}
            loading={fetching}
          >
            Yes, this is correct
          </Button>
        }
        buttonCancel={(actionCancel: any) => (
          <Button
            className="button button-modal-cancel"
            onClick={actionCancel}
          >
            I want to make changes
          </Button>
        )}
        className="modal-end-submit--white"
        icon={calendarPng}
      />
      <ModalEndSubmit
        visible={isSubmittedModalVisible}
        setVisible={(isVisible: boolean) => {
          setIsSubmittedModalVisible(isVisible)
          closeTask()
        }}
        modal={{
          title: 'Great Job!',
          description: (
            <h4 className="h4 h4-w4">
              You just completed a task. We’ll review the information you submitted and contact you if we need any changes.
            </h4>
          ),
        }}
        buttonSubmit={() => (null)}
        buttonCancel={(actionCancel: any) => (
          <Button
            className="button button-modal-ok"
            onClick={actionCancel}
          >
            Back to dashboard
          </Button>
        )
        }
        className="modal-end-submit"
        icon={coinPng}
      />

    </section>
  );
};
export default Step1;