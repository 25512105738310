import React, { useState } from 'react';

interface listItems {
  id: number;
  text: string;
  subTitle: string;
}

export interface OptionsQuestionBasic {
  id: number;
  title: string;
  subtitle?: React.ReactElement | string;
  subtitleBold?: string;
  dropdown?: React.ReactElement | any;
  onClick: Function | null;
  textRight?: String | null;
  textHover?: String | null;
  hidden?: Function;
  icon?: any;
  styleActive?: 'option-active-blue' | 'option-active-purpure';
  styleHover?: 'option-hover-blue' | 'option-hover-purpure';
}

interface Props {
  question: React.ReactElement;
  options: OptionsQuestionBasic[];
  listItems?: listItems[] | null;
  type: string | null;
  text?: React.ReactElement | null;
  optionSelected?: any;
  metadata?: any;
  disabled?: boolean;
}

const QuestionBasic = ({
  question,
  options,
  listItems,
  type,
  text,
  optionSelected,
  metadata,
  disabled
}: Props) => {
  const [isOptionSelected, setOptionSelected] = useState(0);
  const [isHoverActive, setHoverActive] = useState<number>();

  const handleFInish = (option: any) => {
    if (!disabled) {
      setOptionSelected(option.id);
      option.onClick(option, metadata);
    }
  };

  return (
    <div className="QuestionBasic">
      {question}
      <div className="mt-10">{text && text}</div>
      {listItems && (
        <ol className="list">
          {listItems?.map((item) => (
            <li className="h4" key={item.id}>
              <span>{item.subTitle}</span>{' '}
              <span className="h4-w4">{item.text}</span>
            </li>
          ))}
        </ol>
      )}
      <div className="options">
        {options?.map((option) =>
          !option.hidden || (option.hidden && !option.hidden(optionSelected)) ? (
            <div
              className={
                `Button-option ${option.styleHover} ${isOptionSelected === option.id && option.styleActive} ${disabled && 'disabled'}`
              }
              onClick={() => handleFInish(option)}
              key={option.id}
            >
              <div className={option.textRight ? "flex-btw" : "flex-btw wide"}>
                <div className="content-left">
                  <label className='h4 Button-option-text'>
                    <span className='title-span'>
                      {option.title}
                    </span>
                    {option.title === "No" &&
                      <span className='sub-title-no'>
                        <br />
                        We only do investment-purpose loans. That means you’ll need a legal entity to borrow with us. It’s ok if you don’t have one yet, but we do need to run a background and credit check on anyone who is going to be a personal guarantor on a loan.
                      </span>
                    }
                    {isHoverActive === option.id && option.textHover && option.id !== isOptionSelected && option.textHover}
                  </label>

                  <span className="Button-option-active-description">
                    {option.icon &&
                      <>
                        <img src={option.icon} className="lienzo" alt="lienzoSvg" />
                        {' '}
                      </>
                    }
                    <span className='bold-little'>{option.subtitleBold}{' '}</span>
                    <span className='body-regular'>{option.subtitle}</span>
                  </span>
                </div>
                {option.textRight && (
                  <p className="body-regular body-regular-w5">
                    {option.textRight}
                  </p>
                )}
              </div>
              {type === 'dropdown' &&
                option.dropdown &&
                option.id === isOptionSelected &&
                option.dropdown(metadata)
              }
              {isHoverActive === option.id && option.dropdown && option.id !== isOptionSelected &&
                option.dropdown(metadata)
              }
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};
export default QuestionBasic;
