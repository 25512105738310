import React, { useEffect, useState } from 'react'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import arrowBlue from 'assets/images/arrow-blue.png';
import iconTag from 'assets/images/icon-tag.svg'; 
import ModalBasic from 'components/Organisms/Modals/ModalBasic/ModalBasic';
import { useSendEmailResetPassword } from 'services/auth/mutations';
import { useDeleteAccount } from '../../../../services/user/mutations';

const CardAccountSecurity = () => {

  const navigate = useNavigate();

  const { execute: sendEmailResetPassword } = useSendEmailResetPassword();
  const { data: responseDelete, execute: deleteAccountAction } = useDeleteAccount();

  const [modalConfirmEmail, setModalConfirmEmail] = useState<boolean>(false);
  const [modalDeleteAccount, setModalDeleteAccount] = useState<boolean>(false);

  useEffect(() => {
    if(responseDelete?._id) logout();
  }, [responseDelete])

  const onSendEmailResetPassword = () => {
    sendEmailResetPassword();
    setModalConfirmEmail(true);
  }

  const deleteAccount = () => {
    deleteAccountAction();
  }

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
    navigate("/");
    navigate(0);
  }

  return (
    <div className='card-profile-generic'>
      <h1 className='card-title'>Account security</h1>
      <div className='flex-btw mt-10 mb-5'>
        <span className='title-bold'>Password updated</span>
        <span className='body-bold'>3/23/2023</span>
      </div>
      <Button 
        type="link" 
        className="button button-link-blue link" 
        onClick={onSendEmailResetPassword}
      >
        Send password reset email <img src={arrowBlue} alt='arrow' className='arrow' />
      </Button>
      {/*<div className='flex-btw mt-15 mb-5'>
        <span className='title-bold'>2-step verification</span>
        <span className='body-bold'>SMS: 293-293-3928</span>
      </div>
      <Link className="body-regular link" to="./">
        Send password reset email <img src={arrowBlue} alt='arrow' className='arrow' />
      </Link>
      */}
      <div>
        <Button className='mt-15 button button-transparent' onClick={() => setModalDeleteAccount(true)}>
          Delete account
        </Button>
      </div>

      <ModalBasic
        visible={modalConfirmEmail}
        setVisible={setModalConfirmEmail}
        title='Check your email'
        description={'We sent you instructions on how to reset your password. Please follow the link in the email to create a new password.'}
        submit={{ buttonLabel: 'OK!', onSubmit: () => setModalDeleteAccount(false)}}
      />

      <ModalBasic
        visible={modalDeleteAccount}
        setVisible={setModalDeleteAccount}
        icon={iconTag}
        title='Are you sure you want to delete your account? This action can not be undone'
        submit={{ 
          buttonLabel: 'Yes, delete account', 
          onSubmit: deleteAccount,
          color: 'red'
        }}
        cancel={{ 
          buttonLabel: 'No, go back to profile',
          color: 'white'
        }}
      />
    </div>
  )
}

export default CardAccountSecurity