import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';

import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import leafPng from 'assets/images/leaf.png';
import { Entity, Loan, Task, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import {
  LoanContext
} from '../../Dashboard';
import { useGetEntitiesByOwner } from '../../../../../services/entity/querys';
import { ENTITY_DOCS_LABEL, ENTITY_DOCS_RESPONSES } from './responses.constants';
import DropdownEntities from '../EntityGuarantorCreation/DropdownEntities/DropdownEntities';
import Step2 from './Step2/Step2';
import Step1 from './Step1/Step1';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { TaskOpenModalContext } from '../../../Private';
import CircleTag from 'components/Atoms/Tags/CircleTag/CircleTag';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const EntityDocs = ({ task }: Props) => {
  const loan: Loan | any = useContext(LoanContext);
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const [ownerSelect, setOwnerSelect] = useState<string>('');

  const { data: taskResponsesEntityGuarantors, reexecute: getTaskGuarantors } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { loanId: loan._id, taskKey: ModuleKeys.ENTITY_GUARANTORS }
    }
  });
  const { data: taskResponses, reexecute: getTaskResponsesByTask } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } },
      pause: false
    });
  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isConfirmDocs, setConfirmDocs] = useState(false);
  const [step, setStep] = useState(2);
  const [entity, setEntity] = useState<Entity | any>();
  const [notEntity, setNotEntity] = useState<boolean>(false);
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    const step1 = taskResponsesEntityGuarantors?.find((item: any) => item.step === 1);

    setOwnerSelect(step1?.metadata.ownerId);
    getEntitiesByOwner({ requestPolicy: 'network-only' });

    if (step1 && !step1.metadata.entity) {
      setNotEntity(true);
    }
  }, [taskResponsesEntityGuarantors]);

  useEffect(() => {
    if (entities) {
      const _entity = entities.find((item) => item.loans.find((item2) => item2._id === loan._id));

      setEntity(_entity);
      if (_entity?.name.includes('Entity created on')) {
        setStep(1);
        setNotEntity(true);
      }
    }
  }, [entities]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);

  useEffect(() => {
    if (openContext === ModuleKeys.ENTITY_DOCS) {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (entity?.loans?.length > 1 && taskResponses?.some(item => item.metadata?.viewConfirm))
      setConfirmDocs(true);
    else if (taskResponses?.length === 0) setConfirmDocs(false);

    const responsesStep2 = taskResponses?.filter((item) => item.step === 2);
    setIsReview(!!responsesStep2?.some((item: any) => item.state === TaskState.Review));
  }, [entity, taskResponses]);

  useEffect(() => {
    if (isModalTaskVisible) {
      getTaskResponsesByTask({ requestPolicy: 'network-only' });
      getTaskGuarantors({ requestPolicy: 'network-only' });
      getEntitiesByOwner({ requestPolicy: 'network-only' });
    }
  }, [isModalTaskVisible]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const getTotalApproved = () =>
    taskResponses?.filter((item: any) => item.state !== TaskState.Draft)?.length;

  const isConfirmedEntityDocs = () =>
    taskResponses?.some((item) => item.state === TaskState.Approved);

  const getResponse = (key: string) =>
    taskResponses?.find((item) => item.key === key);

  const onSubmitChangeEntity = (entity: Entity) => {
    if (entity?.loans?.length > 1) setConfirmDocs(true);

    getTaskResponsesByTask({ requestPolicy: 'network-only' });
  };

  const circlesOtherDocs: any = () => {
    const addAnotherDocs = task.responses?.filter(item => item.key === 'doc-another');

    const docs = addAnotherDocs?.map(item =>
      ({ state: item.state, name: item.label })
    ) || [];
    return docs;
  };

  if (!task) return null;

  const renderTracker = () => (
    <div style={{ height: '100%' }}>
      <span className='title-summary'>Task summary</span>
      <div className="mt-20">
        {isConfirmDocs && entities && (
          <p className="placeholder">
            My entity is{' '}
            <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
            {' Its ownership is '}
            <span className="bold" style={{ padding: 0 }}>
              confirmed
            </span>
            {` and its ${taskResponses && taskResponses?.length >= 4
              ? taskResponses?.length
              : 4
              } documents are `}
            <span className="bold" style={{ padding: 0 }}>
              {isConfirmedEntityDocs() ? 'confirmed' : 'unconfirmed'}
            </span>
          </p>
        )}
        {!isConfirmDocs && (
          <>
            {notEntity && (
              <>
                <p className="placeholder">
                  My entity is {' '}
                  {!entity?.name.includes('Entity created on') && (
                    <DropdownEntities onSubmit={onSubmitChangeEntity} isReviewBold />
                  )}
                </p>
              </>
            )}

            <div className="owners-tags">
              <p
                className="placeholder"
                style={{ marginTop: 15, fontWeight: 500 }}
              >
                Document status{' '}
              </p>

              {taskResponses && !notEntity && (
              <div className="owners-tags mt-5">
                <span>{getTotalApproved()}/4 submitted</span>
              </div>
            )}

              <div className="mt-10 circles-tags">
                {Object.keys(ENTITY_DOCS_RESPONSES).map((key) => (
                  <CircleTag
                    key={key}
                    state={getResponse(ENTITY_DOCS_RESPONSES[key])?.state || 'DRAFT'}
                    tagKey={key}
                    textTwo={ENTITY_DOCS_LABEL[key]}
                  />
                ))}
                {circlesOtherDocs().map((item: any, index: number) => {
                  return (
                    <CircleTag
                      key={index}
                      state={item.state}
                      tagKey={item.state}
                      textTwo={item.name}
                    />
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const { taskName, phase } = findTaskRules(ModuleKeys.ENTITY_DOCS);

  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <section className="EntityDocs">
          <ModalTask
            visible={isModalTaskVisible}
            handlerSetVisibile={setModalTaskVisible}
            renderButtonTrigger={(openModal: any) => (
              <Button
                className={`button-open-modal button-open-modal-${task.state}`}
                onClick={() => openModal(true)}
                disabled={!entity}
              >
                {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
                  (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                    'Begin Task'
                }
              </Button>
            )}
            header={{
              title: <span>{phase} | {taskName.toUpperCase()}</span>,
              actionLeft:
                isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={leafPng} alt="icon" className="paperPng" />
            }}
            animationDirection="onRight"
            isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
            stepsTotal={notEntity ? 2 : 1}
            disabledDots={step === 2}
            responses={taskResponses}
            currentStep={notEntity ? step : 1}
            setStep={setStep}
            tracker={renderTracker()}
          >
            <div className="children">
              {step === 1 && <Step1 setStep={setStep} />}
              {step === 2 && (
                <Step2
                  closeTask={() => setModalTaskVisible(false)}
                  isConfirmDocs={isConfirmDocs}
                  onSubmitChangeEntity={onSubmitChangeEntity}
                />
              )}
            </div>
          </ModalTask>
        </section>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default EntityDocs;
