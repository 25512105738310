import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { Tooltip } from 'react-tooltip'
import TrackRecord from './TrackRecord';
import { ModuleKeys } from 'services/task/modules-keys.constanst';

interface Props {
  tasks?: Task[] | any[],
  loan: Loan | any
}

const useCardTrackRecord = ({ tasks, loan }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [response, setResponse] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.TRACK_RECORD);
      setDataTask(data);
      setResponse(data?.responses.find(item => item.key !== 'doc-another'));

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const generateCirclesFooter: any = () => {
    const addAnotherDocs = dataTask?.responses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || []; 
    return [(response?.state || 'DRAFT'), ...docsStates];
  };

  const getTotalApproved = () => {
    if(dataTask?.responses && dataTask?.responses[0])
      return dataTask?.responses[0]?.files?.filter((item: any) => item.state !== TaskState.Draft)?.length;
    else
      return 0;
  }
  
  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }
  
  const tooltipTexts = (state: string) => tooltipsTexts[state];

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.TRACK_RECORD,
    title: <h4 className="h4">Past Investment Experience</h4>,
    type: 'quick task',
    steps: 1,
    stepCurrent: stepNow?.step || 0,
    state: dataTask?.state,
    actions: dataTask ? [<TrackRecord key={1} task={dataTask} />] : [],
    text: (
      <p className="body-card">
        I’ve purchased/sold{' '}
        <span className="body-card-bold-line">
          {loan?.investmentExperience?.properties === 'none' ? 'no' : loan?.investmentExperience?.properties}
        </span>
        {' '}real estate properties in the past 24 months
      </p>
    ),
    text2: (
      <div className='docs-section' style={{ marginTop: 5 }}>
        <div className='labels'>
          <p className={`body-regular body-regular-bold}`}>
            Document status
          </p>
          <p className={`body-card`}>
            {getTotalApproved()}/{generateCirclesFooter()?.length || 0} submitted
          </p>
        </div>
        <div className={`circles-tags`}>
          {generateCirclesFooter().map((state: string, index: number) => {
            let _state = state.toUpperCase() || 'DRAFT';
            
            return (
              <div key={index}>
                <div
                  data-tooltip-id={`circle-state-${_state}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip
                  id={`circle-state-${_state}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
    
  };

  if(!dataTask) 
    return { card: null };
  
  return {
    card,
    dataTask
  };
};

export default useCardTrackRecord;
