export const ModuleKeys = {
  PROPERTY_ACCESS: 'property_access',
  ENTITY_GUARANTORS: 'entity_guarantors',
  MINIMUM_LIQUIDITY: 'minimium_liquidity',
  CONSTRUCTION: 'construction_budget',
  ENTITY_DOCS: 'entity_docs',
  INSURANCE: 'insurance',
  INVESTMENT_EXPERIENCE: 'investment_experience',
  PAY_APPRAISAL: 'pay_appraisal',
  PAYOFF: 'payoff',
  PURCHASE: 'purchase',
  TITLE_COMPANY: 'title_company',
  TRACK_RECORD: 'track_record',
  DSCR: 'dscr'
}