import React, { useContext, useEffect, useState } from 'react';
import guarantorSvg from 'assets/images/guarantor-blue.svg';
import { Button, Input } from 'antd';
import {
  SubmitContext,
  SubmitContextInterface,
  TaskContext,
  TaskResponsesContext
} from '../EntityGuarantorCreation';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import {
  useCreateResponseTask,
  useRemoveTaskResponse
} from 'services/task/mutations';
import { LoanContext } from '../../../Dashboard';
import { useGetEntitiesByOwner } from '../../../../../../services/entity/querys';
import { useCreateEntity } from 'services/entity/mutations';
import { useGetOwnershipsByCreator } from '../../../../../../services/ownership/querys';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';

export const useStep1 = ({
  setHandlerSubmit,
  getTaskResponses,
  setStep,
  responsesStep2,
  step2Confirm
}: any) => {
  const task: Task | any = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);
  const taskResponses: TaskResponse | any = useContext(TaskResponsesContext);
  const onSubmitParent: SubmitContextInterface | any = useContext(SubmitContext);

  const [response, setResponse] = useState<any>();
  const [entity, setEntity] = useState<string>();
  const [ownerSelect, setOwnerSelect] = useState<any>();
  const [optionsExistEntities, setOptionsExistEntities] = useState<any>();

  //Es necesario volver a llamarlo para actualizar la data del DropdownEntities
  const { reexecute: getResponsesEntityGuarantors } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { loanId: loan._id, taskKey: 'entity_guarantors' }
    },
    pause: true
  });
  const { data: ownerships } = useGetOwnershipsByCreator({});
  const { data: entities, reexecute: getEntitiesByOwner } = useGetEntitiesByOwner(
    {
      variables: { ownerId: ownerSelect },
      pause: true
    }
  );

  const {
    data: responseCreate,
    fetching,
    execute: createResponseTask
  } = useCreateResponseTask();

  const { execute: removeTaskResponse } = useRemoveTaskResponse();
  const { execute: createEntity } = useCreateEntity();

  const onChangeOption = (option: any) => setResponse(option);

  const optionsNotEntities: any = [
    {
      id: 1,
      title: response?.id !== 1 ? <h4 className='bold-blue'>Yes, I have an entity</h4> : <h4 className='bold-blue'>Yes, I have an entity - <span>Great! What’s it called?</span></h4>,
      styleHover: 'option-hover-blue',
      styleActive: 'option-active-blue',
      onClick: onChangeOption,
      textHover: <h4 className='bold-blue'><span className='ml-3'>- Great! What’s it called?</span></h4>,
      dropdown: (metadata: any) => (
        <div className="content-entity-input">
          <Input
            className="input input-transparent input-placeholder-bold"
            placeholder="Entity name"
            onChange={(e) => setEntity(e.target.value)}
          />
          <Button
            className="button button-submit content-entity-input-button-save"
            onClick={() =>
              onSaveStep1(
                !entities || entities?.length === 0
                  ? 'yes'
                  : 'another-entity-i-formed',
                !entities || entities?.length === 0
                  ? 'Yes'
                  : 'Another entity I’ve formed',
                taskResponses,
                metadata.entity
              )
            }
          >
            Save and go to next step
          </Button>
        </div>
      ),
    },
    {
      id: 2,
      title: 'No, I don’t have an entity',
      onClick: () => onSaveStep1(
        'multiple-owners',
        'My entity will have multiple owners',
        taskResponses,
        entity
      ),
      styleHover: 'option-hover-purpure',
      styleActive: 'option-active-purpure',
      dropdown: (metadata: any) => (
        <div className="mt-10">
          <p className="h4 h4-w4">
            {(!entities || entities?.length === 0) &&
              `We only do investment-purpose loans. That means you’ll need a
              legal entity to borrow with us. It’s ok if you don’t have one yet,
              but we do need to run a background and credit check on anyone who
              is going to be a personal guarantor on a loan.`}
            {entities && entities?.length > 0 &&
              'Usually you’ll need a legal entity to borrow with us. We may be able to approve the loan without one, but we do need to run a background and credit check on anyone who is going to be a personal guarantor on the loan.'}
          </p>
        </div>
      ),
      //hidden: (optionSelected: any) => optionSelected?.id === 2
    }
  ];

  useEffect(() => {
    if (ownerships && ownerships.length > 0) setOwnerSelect(ownerships[0]._id);
  }, [ownerships]);

  useEffect(() => {
    if (ownerSelect) getEntitiesByOwner();
  }, [ownerSelect]);

  useEffect(() => {
    if (onSubmitParent.active && onSubmitParent.step === 1 && entities) {
      const entity = entities?.find(
        (item) => item._id === onSubmitParent.metadata.entityKey
      );

      onSaveStep1(
        onSubmitParent.metadata.entityKey,
        'Existing entity',
        taskResponses,
        entity?.name
      );

      if (onSubmitParent.beforeStep === 3 && entity) {
        createEntity({
          _id: entity?._id,
          name: entity?.name,
          loans: [loan._id],
          task: task._id
        });
      }
    }
  }, [onSubmitParent, entities]);

  useEffect(() => {
    if (responseCreate && responseCreate.state !== TaskState.Cache) {
      getTaskResponses({ requestPolicy: 'network-only' });
      setEntity('');
      setTimeout(() => setStep(2), 500);
    }
  }, [responseCreate]);

  useEffect(() => {
    if (entities) {
      const _optionsExistEntities = [];
      for (const entity of entities) {
        _optionsExistEntities.push({
          id: entity._id,
          title: entity.name,
          subtitleBold:
            entity.ownerships && entity.ownerships?.length > 0
              ? 'Guarantors:'
              : '',
          subtitle: entity.ownerships
            ?.filter((item) => item.stakes[entity._id] >= 20)
            .map((item) => item.name + ' ' + item.lastname)
            .join(', '),
          textRight: `${entity.loans.length} LOAN${entity.loans.length == 1 ? '' : 'S'
            }`,
          onClick: (option: any, metadata: any) =>
            onSaveStep1(
              entity._id,
              'Existing entity',
              metadata.taskResponses,
              entity.name
            ),
          icon:
            entity.ownerships && entity.ownerships?.length > 0
              ? guarantorSvg
              : null
        });
      }
      _optionsExistEntities.push({
        ...optionsNotEntities[0],
        title:
          response?.id !== 1
            ? 'Another entity I’ve formed'
            : 'Another entity I’ve formed - Great! What’s it called?'
      });
      _optionsExistEntities.push({
        ...optionsNotEntities[1],
        title: 'Another entity I haven’t formed yet',
        //hidden: (optionSelected: any) => optionSelected?.id === 2
      });
      setOptionsExistEntities(_optionsExistEntities);
    }
  }, [entities]);

  useEffect(() => {
    if (responsesStep2) {
      let isEntity;
      for (const response of responsesStep2) {
        const { createdAt, __typename, files, task, ...others }: any = response;

        if (response.metadata?._id || response.metadata.referenceId) {
          isEntity = true;
          removeTaskResponse({ taskResponseId: response._id });
        } else
          createResponseTask({
            ...others,
            state: TaskState.Cache,
            filesExist: files,
            task: task._id
          });
      }

      if (isEntity && step2Confirm)
        removeTaskResponse({ taskResponseId: step2Confirm?._id });

      let findStep1 = taskResponses?.find(
        (item: TaskResponse) => item.step === 1
      );
      if (findStep1) removeTaskResponse({ taskResponseId: findStep1._id });

      getResponsesEntityGuarantors({ requestPolicy: 'network-only' });
    }
  }, []);

  const onSaveStep1 = async (
    key:
      | 'yes'
      | 'another-entity-i-formed'
      | '100%-entity'
      | 'multiple-owners'
      | string,
    title: string,
    _taskResponses: any[],
    _entity?: string
  ) => {
    if (task) {
      const entitySelected = entities?.find((item) => item._id === key);

      if (entitySelected?.ownerships) {
        for (const guarantor of entitySelected.ownerships) {
          let { files, stakes, ...others }: any = guarantor;
          files = files.map((item: any) => ({
            ...item,
            state: TaskState.Review
          }));

          await createResponseTask({
            task: task._id,
            key: `ownership-${guarantor.name}`,
            label: `Ownership ${guarantor.name}`,
            step: 2,
            metadata: { ...others, ownership: stakes[entitySelected._id] },
            state:
              stakes[entitySelected._id] >= 20
                ? TaskState.Incomplete
                : TaskState.Approved,
            filesExist: files
          });
        }

        let isConfirmPerson = _taskResponses?.find(
          (item: TaskResponse) => item.key === 'confirm-persons' && item.state !== TaskState.Incomplete
        );

        if (!isConfirmPerson)
          await createResponseTask({
            task: task._id,
            key: `confirm-persons`,
            label: `Confirm or Edit the ownership`,
            step: 2,
            metadata: {},
            state: TaskState.Draft
          });
      }

      let payload: any = {
        task: task._id,
        key,
        label: title,
        step: 1,
        metadata: {
          entity: _entity ? _entity : '',
          ownerId: ownerSelect
        },
        state: TaskState.Approved
      };

      await createResponseTask(payload);
      setHandlerSubmit({});
      getResponsesEntityGuarantors({ requestPolicy: 'network-only' });
    }
  };

  return {
    entity,
    optionsExistEntities,
    optionsNotEntities,
    ownerships,
    response,
    fetching,
    onSaveStep1,
    entities,
    ownerSelect,
    setOwnerSelect
  };
};
