import React from 'react';
import coinPng from 'assets/images/coin-shadow.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { Button } from 'antd';

const ModalFinalReview = ({ submit, visible, setVisible }: any) => {
  return (
    <ModalEndSubmit
      visible={visible}
      setVisible={setVisible}
      modal={{
        title: 'Great job!',
        description: (
          <h4 className="h4 h4-w4">
            You just completed a task. We’ll review the information you submitted and contact you if we need any changes.
          </h4>
        )
      }}
      className="modal-normal"
      icon={coinPng}
      buttonSubmit={
        <Button className="button button-modal-ok" onClick={submit}>
          Back to dashboard
        </Button>
      }
    />
  );
};
export default ModalFinalReview;
