import { Button } from "antd";
import { Task, TaskState } from "gql/graphql";
import React, { useState } from "react";
import { Phases } from "scenes/Private/Dashboard/LoanPhases.constanst";

interface Props {
  tasks: Task[] | any,
  filter: string,
  handlerChange: any,
  phaseSelect: any
}

const ButtonTabsFilters = ({ tasks, phaseSelect, filter, handlerChange }: Props) => {
  
  const getTotal = (state: string) => {
    if (state === 'AWAITING_ACTION') {
      return tasks?.filter((item: any) => item?.state === TaskState.Review && item?.metadata?.reviewRename === 'MANUAL ACTION')?.length || 0;
    }
    if (state === 'REVIEW') {
      return tasks?.filter((item: any) => 
        item?.state === TaskState.Review && 
        item?.metadata?.reviewRename !== 'MANUAL ACTION'
      )?.length || 0;
    }
    return tasks?.filter((item: any) => item?.state === state)?.length || 0;
  }

  const dummyTabsFilters = [
    { id: 1, name: "All", key: "all", amount: tasks?.length || 0 },
    { id: 2, name: "Not Started", key: "DRAFT", amount: getTotal('DRAFT') },
    { id: 3, name: "In Progress", key: "INCOMPLETE", amount: getTotal('INCOMPLETE') },
    { id: 4, name: "In Review", key: "REVIEW", amount: getTotal('REVIEW') },
    { id: 5, name: "Awaiting action", key: "AWAITING_ACTION", amount: getTotal('AWAITING_ACTION') },
    { id: 6, name: "Rejected", key: "REQUEST_ERROR", amount: getTotal('REQUEST_ERROR') },
    //+1 for Property Access Details
    { id: 7, name: "Approved", key: "APPROVED", amount: getTotal('APPROVED')},
  ];

  return (
    <section className="ButtonTabsFilters">
      {dummyTabsFilters?.map((tab) => (
        <Button
          onClick={() => handlerChange(tab.key)}
          className={`tab ${filter === tab.key && "tab-active"} ${tab.amount === 0 && "disabled"}`}
          key={tab.key}
          disabled={tab.amount === 0}>
          {tab.name} <span className="number">{tab.amount}</span>
        </Button>
      ))}
    </section>
  );
};
export default ButtonTabsFilters;
