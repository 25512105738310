import React, { useContext, useEffect, useState } from 'react';
import fileCheckSvg from 'assets/images/file-check-green.svg';
import { AutoComplete, Button, Divider, Form, Input, Space } from 'antd';
import moment from 'moment';
import { File, Task, TaskState } from 'gql/graphql';

import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import {
  formatterSsn as utilsFormatterSsn,
  formatterPhone as utilsFormatterPhone
} from '../../../../../../@common/utils/formatters/formatter';
import ModalSubmitProfile from './ModalSubmitProfile';
import { Ownership } from './CardOwnership';
import { useDeleteFileResponse } from '../../../../../../services/task/mutations';
import UploadDocs from '../../../../../../components/Atoms/UploadDocs/UploadDocs';
import { DATE_FORMAT } from '@common/utils/constants/dates.constanst';
import { InputAutocompleteAddress } from 'components/Atoms/Inputs/InputAutocompleteAddress/InputAutocompleteAddress';
import iconSpinnerPurple from 'assets/images/spinner-purple.svg';
import DatePicker from '../../../../../../components/Atoms/DatePicker/DatePicker';

interface Props {
  ownership: Ownership;
  hiddenSectionViewProfile?: boolean;
  hiddenTextEdit?: boolean;
  disabledForm?: boolean;
  hiddenSubmitChanges?: boolean;
  hiddenColorsState?: boolean;
  isModalSubmit?: boolean;
  saveEdit?: string;
  fetching: boolean;
  saveChanges: Function;
  ownerships?: Ownership[];
  otherButton?: any;
  closeForm?: Function;
  stepVerify?: boolean;
  verifyProfile: any;
  isExpand?: any;
}

const FormEdit = ({
  ownership,
  hiddenSectionViewProfile,
  hiddenTextEdit,
  disabledForm,
  hiddenSubmitChanges,
  hiddenColorsState,
  saveChanges,
  isModalSubmit,
  saveEdit,
  fetching,
  ownerships,
  otherButton,
  closeForm,
  stepVerify,
  verifyProfile,
  isExpand
}: Props) => {
  const [form] = Form.useForm();
  const {
    fetching: loadingDeleteFile,
    execute: _deleteFile
  } = useDeleteFileResponse();

  const [listFileUpload, setListFileUpload] = useState<any>([]);
  const [isEditVerify, setEditVerify] = useState<boolean>(false);
  const [birthDate, setBirthDate] = useState<any>();

  useEffect(() => {
    form.setFieldsValue(getInitialValues());
    if(!birthDate) {
      setListFileUpload([]);
    }
  }, [isExpand, birthDate]);

  useEffect(() => {
    if (listFileUpload?.length > 0) {
      saveChanges(form.getFieldsValue(), listFileUpload);
    }
  }, [listFileUpload])

  const getInitialValues = () => {
    return ({
      ...ownership,
      dateBirth: ownership.dateBirth ? moment(ownership.dateBirth) : (birthDate ? moment(birthDate, DATE_FORMAT) : null),
      ssn: utilsFormatterSsn(ownership.ssn),
      phone: utilsFormatterPhone(ownership.phone)
    })
  };

  const formatterSsn = (e: any) => {
    form.setFieldValue('ssn', utilsFormatterSsn(e.target.value));
  };

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const isFormDisabled = () => {
    const isFieldsValidate = Object.values(form.getFieldsValue()).every(
      (value) => !!value
    );

    return (
      !isFieldsValidate ||
      form.getFieldsError().filter(({ errors }) => errors.length)?.length > 0 ||
      (listFileUpload?.length === 0 && ownership?.files?.length === 0)
    );
  };

  const deleteFile = (file: File) => {
    _deleteFile({ fileKey: file.key, taskResponseId: ownership.key });
    saveChanges(form.getFieldsValue(), null, TaskState.Incomplete);
  };

  const isDisabledItem = () => {
    return disabledForm ||
      (!!ownership._id && ownership.state === TaskState.Incomplete && !isEditVerify && isValidForm(ownership, ownership.files));
  }

  const isValidForm = (values: any, files: any) => {
    return (values.address || ownership.address) &&
      (values.dateBirth || ownership.dateBirth) &&
      (values.email || ownership.email) &&
      (values.phone || ownership.phone) &&
      (values.ssn || ownership.ssn) &&
      (files?.length > 0 || ownership?.files?.length > 0) &&
      (values.ownership || ownership.ownership);
  }

  return (
    <Form
      form={form}
      name="edit"
      autoComplete="off"
      className="form-edit"
      requiredMark="optional"
      colon={false}
    >

      <Form.Item
        label={"SSN" + (form.getFieldValue("ssn") ? "" : "*")}
        name="ssn"
        className="ssn"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is missing' },
          { min: 11, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="239-23-2323"
          height={20}
          className="bold-lith"
          disabled={isDisabledItem()}
          onChange={formatterSsn}
          onBlur={(e: any) => {
            if (e.target.value)
              saveChanges(form.getFieldsValue(), listFileUpload)
          }}
        />
      </Form.Item>

      <Form.Item
        label={"Date of birth" + (form.getFieldValue("dateBirth") ? "" : "*")}
        name="dateBirth"
        className="datebirth"
        validateTrigger="onBlur"
        rules={[{ required: true, message: 'Field is missing' }]}
      >
        <DatePicker
          open={false}
          placeholder="12/31/1997"
          className="input input-transparent"
          disabled={isDisabledItem()}
          format={DATE_FORMAT}
          suffixIcon={null}
          onBlur={(e: any) => {
            const isValidFormat = moment(e.target.value, [DATE_FORMAT, 'MM/DD/YYYY']).isValid();

            if (isValidFormat) {
              setBirthDate(e.target.value)
              saveChanges({
                ...form.getFieldsValue(),
                dateBirth: e.target.value
              }, listFileUpload);
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label={"Phone number" + (form.getFieldValue("phone") ? "" : "*")}
        name="phone"
        className="phone"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is missing' },
          { min: 12, message: 'Field is missing' }
        ]}
      >
        <InputBorderNone
          placeholder="329-230-2391"
          height={20}
          className="bold-lith"
          disabled={isDisabledItem()}
          onChange={formatterPhone}
          onBlur={(e: any) => {
            if (e.target.value)
              saveChanges(form.getFieldsValue(), listFileUpload)
          }}
        />
      </Form.Item>

      <Form.Item
        label={"Email address" + (form.getFieldValue("email") ? "" : "*")}
        name="email"
        className="email"
        rules={[{ required: true, type: 'email', message: 'Invalid email address' }]}
      >
        <InputBorderNone
          type="email"
          placeholder="email@gmail.com"
          height={20}
          disabled={isDisabledItem()}
          color={'bold-lith'}
          onBlur={(e: any) => {
            if (!(e.target.value && form.getFieldError('email').length > 0))
              saveChanges(form.getFieldsValue(), listFileUpload)
          }}
        />
      </Form.Item>

      <Form.Item
        label={"Home address" + (form.getFieldValue("address") ? "" : "*")}
        name="address"
        className="address"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Field is missing' },
          { min: 3, message: 'Field is missing' },
          { max: 50, message: 'Field is missing' }
        ]}
      >
        <InputAutocompleteAddress
          disabled={isDisabledItem()}
          initialValue={ownership.address}
          onSelect={({ formatted_address }: any) => {
            form.setFieldValue('address', formatted_address)
            saveChanges({
              ...form.getFieldsValue(),
              address: formatted_address
            }, listFileUpload)
          }
          }
        />

      </Form.Item>

      {!hiddenTextEdit && (
        <span className="textedit textedit-ab">
          {saveEdit === 'loading' && (
            <>
              <img src={iconSpinnerPurple} alt="upload" className="upload" width={10} />
              <span style={{ color: '#A278B3' }}>SAVING</span>
            </>
          )}
          {saveEdit === 'saved' && (
            <>
              <img src={fileCheckSvg} alt="upload" className="upload" width={12} />
              <span style={{ color: '#6B956E' }}>EDITS SAVED</span>
            </>
          )}
        </span>
      )}

      <div className="identification">
        <label>{`Driver’s License`}</label>
        {ownership.files?.map((file: File, index: number) => (
          <InputUploadedFile
            key={index}
            file={file}
            isActionSeeFile
            deleteFile={
              ownership.state === TaskState.RequestError ||
                ownership.state === TaskState.Incomplete ||
                ownership.state === TaskState.Draft
                ? deleteFile : undefined
            }
            loadingDeleteFile={loadingDeleteFile}
            state={
              !hiddenColorsState
                ? (ownership.state.toLocaleLowerCase() as TaskState)
                : TaskState.Draft
            }
          />
        ))}
        {(!ownership.files || ownership.files.length === 0) && (
          <UploadDocs
            numberOfFiles={1}
            listFileUpload={listFileUpload}
            setListFileUpload={setListFileUpload}
            disabled={isDisabledItem()}
          />
        )}
      </div>
      {otherButton &&
        <Button className="button button-link-border submit">
          <b>{otherButton.b}</b>
          <span>{otherButton.span}</span>
        </Button>
      }
      {!isEditVerify && stepVerify && ownership._id && isValidForm(ownership, ownership.files) && ownership.state === TaskState.Incomplete &&
        <div className='submit verify-submits'>
          <Button
            className="button button-submit button-yellow"
            onClick={verifyProfile}
          >
            Verify profile
          </Button>
          <Button
            className="button button-submit button-edit"
            onClick={() => setEditVerify(true)}
          >
            Edit profile
          </Button>
        </div>
      }

      {!hiddenSubmitChanges && (!ownership._id || ownership.state !== TaskState.Incomplete || isEditVerify) && (
        <Form.Item className="submit" shouldUpdate>
          {() =>
            isModalSubmit ? (
              <ModalSubmitProfile
                ownerships={ownerships}
                ownerState={ownership.state as TaskState}
                fetching={fetching}
                disabled={isFormDisabled()}
                onClick={() => {
                  if (closeForm) closeForm();
                  //saveChanges(form.getFieldsValue(), listFileUpload)
                }}
              />
            ) : (
              <Button
                className="button button-submit"
                loading={fetching}
                disabled={isFormDisabled()}
                onClick={() => saveChanges(form.getFieldsValue(), listFileUpload)}
              >
                Save changes
              </Button>
            )
          }
        </Form.Item>
      )}
    </Form>
  );
};
export default FormEdit;
