import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Form } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import guarantorYellow from 'assets/images/guarantor-yellow.svg';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { File, Loan, Task, TaskState } from 'gql/graphql';
import { useCreateResponseTask, useRemoveTaskResponse } from 'services/task/mutations';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import ModalCompleteProfile from './ModalComplete';
import ModalFinishTask from './ModalFinishTask';
import FormEdit from './FormEdit';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import TagOwnerState from '../Step2/CardOwnershipEdit/TagOwnerState';
import { useCreateOwnership, useDeleteOwnership } from '../../../../../../services/ownership/mutations';
import { LoanContext } from '../../../Dashboard';

export interface Ownership {
  _id?: string;
  key: string;
  name: string;
  lastname: string;
  ownership: number;
  dateBirth: string;
  email: string;
  phone: string;
  ssn: string;
  address: string;
  state: string;
  stakes: any;
  files: File[];
  taskResponse: any;
}

interface Props {
  index?: any;
  ownership: Ownership;
  ownerEdits?: any[];
  hiddenSectionViewProfile?: boolean;
  hiddenGuarantorTag?: boolean;
  hiddenTextEdit?: boolean;
  disabledForm?: boolean;
  hiddenSubmitChanges?: boolean;
  showTagState?: boolean;
  hiddenColorsState?: boolean;
  disabledPercentage?: boolean;
  isModalSubmit?: boolean;
  ownerships?: Ownership[];
  closeTask?: Function;
  isActionRemoved?: boolean;
  enabledDataBasic?: boolean;
  otherButton?: any;
  stepVerify?: boolean;
  isOwnerModelEdit?: boolean;
  entity?: any;
  onDeleteOwner?: any;
  onExpand?: any;
  isExpand?: any;
  setNewPercentageLiveEdit?: any;
}

let isUpdatePercentage = false;

const CardOwnership = ({
  ownership,
  ownerEdits,
  hiddenSectionViewProfile,
  hiddenGuarantorTag,
  hiddenTextEdit,
  disabledForm,
  hiddenSubmitChanges,
  showTagState,
  hiddenColorsState,
  isModalSubmit,
  ownerships,
  closeTask,
  disabledPercentage,
  enabledDataBasic,
  isActionRemoved,
  otherButton,
  stepVerify,
  isOwnerModelEdit,
  entity,
  onDeleteOwner,
  onExpand,
  isExpand,
  index,
  setNewPercentageLiveEdit
}: Props) => {
  const [form] = Form.useForm();

  const loan: Loan | any = useContext(LoanContext);

  const { data: responseCreateOwner, execute: fetchCreateOwnership } = useCreateOwnership();
  const { data: createResponse, fetching, execute: createResponseTask } = useCreateResponseTask();
  const { data: responseRemove, fetching: loadingRemove, execute: removeTaskResponse } = useRemoveTaskResponse();
  const { data: responseDeleteOwner, execute: deleteOwner } = useDeleteOwnership();
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: ownership.taskResponse.task._id } },
    pause: true
  });

  const [modalComplete, setModalComplete] = useState<boolean>(false);
  const [modalFinishTask, setModalFinishTask] = useState<boolean>(false);
  const [saveEdit, setSaveEdit] = useState<string>();
  const [percentageOwner, setPercentageOwner] = useState<number>();
  const [pendingUpdate, setPendingUpdate] = useState<any>();

  useEffect(() => {
    if (responseCreateOwner) {
      const ownerId = responseCreateOwner._id;
      const payload = pendingUpdate;
      if (payload) {
        const { files, ...pendingCreateResponse } = payload;
        pendingCreateResponse.metadata.ownerId = ownerId;
        createResponseTask(pendingCreateResponse);
      }
    }
  }, [responseCreateOwner])

  useEffect(() => {
    if (responseRemove)
      getTaskResponses({
        requestPolicy: 'network-only',
        variables: { taskId: ownership.taskResponse.task._id }
      });
  }, [responseRemove]);

  useEffect(() => {
    if (ownership?.ownership) setPercentageOwner(ownership.ownership);
  }, [ownership]);

  useEffect(() => {
    if (!isUpdatePercentage) form.resetFields()
  }, [ownership]);

  useEffect(() => {
    if (createResponse) {
      setSaveEdit('saved');
      const isDrafts = ownerships?.filter(
        (item) => item.state === TaskState.Draft
      );
      //El ownerships esta atrasado en 1, entonces cuando hay 1 draft, entonces ya todos estan revisados
      const isFinish = isDrafts?.length === 1;

      if (ownership.state !== TaskState.RequestError) {
        //if (isModalSubmit && isFinish) setModalFinishTask(true);
        //if (isModalSubmit && !isFinish) setModalComplete(true);
      }
    }
  }, [createResponse]);

  const changeEdit = () => {
    onExpand(!isExpand);

    if (!isExpand) onExpand(ownership);
    else onExpand(undefined);

    setSaveEdit('');
  };

  const saveChanges = async (values: any, files: any, newState?: TaskState) => {
    isUpdatePercentage = false;

    const {
      files: ignoreFile,
      task,
      __typename,
      createdAt,
      ...taskResponse
    } = ownership.taskResponse;

    let changeState = taskResponse.state;

    if (
      changeState === TaskState.Incomplete &&
      isValidForm(values, files)
    ) {
      const { _id, ownerId, referenceId, ...metadata } = taskResponse.metadata;

      await fetchCreateOwnership({
        ...metadata,
        ...values,
        filesExists: ownership?.files,
        files: files,
        entity: entity._id,
        loan: loan._id,
        state: TaskState.Draft,
        stakes: {
          [entity._id]: ownership.ownership
        },
        _id: ownerId || _id
      });
      //createGuarantorState usarlo en el primer draft
      changeState = TaskState.Draft;
    } else if (changeState !== TaskState.Incomplete) {
      changeState = TaskState.Draft;
    }

    if (newState) changeState = newState;

    const payload = {
      ...taskResponse,
      task: task._id,
      state: changeState,
      metadata: { ...taskResponse.metadata, ...values },
      files
    };

    setPendingUpdate(payload);
    await createResponseTask(payload);
  };

  const verifyProfile = async () => {
    isUpdatePercentage = false;

    const {
      files: ignoreFile,
      task,
      __typename,
      createdAt,
      ...taskResponse
    } = ownership.taskResponse;

    await createResponseTask({
      ...taskResponse,
      task: task._id,
      state: TaskState.Draft,
      metadata: taskResponse.metadata,
    });
  }

  const changeOwnership = async (_ownership: any) => {
    isUpdatePercentage = true;

    const {
      files: ignoreFile,
      task,
      __typename,
      createdAt,
      ...taskResponse
    } = ownership.taskResponse;

    let isCompleteProfile = false;

    if (_ownership && entity &&
      taskResponse.metadata.address &&
      taskResponse.metadata.dateBirth &&
      taskResponse.metadata.email &&
      taskResponse.metadata.phone &&
      taskResponse.metadata.ssn &&
      ignoreFile?.length > 0
    ) {
      isCompleteProfile = true;
      const { ownerId, referenceId, ...metadata } = taskResponse.metadata;

      await fetchCreateOwnership({
        ...metadata,
        stakes: { ...ownership.stakes, [entity._id]: _ownership },
        entity: entity._id,
        loan: loan._id,
        _id: ownerId
      });
    }

    await createResponseTask({
      ...taskResponse,
      task: task._id,
      state: _ownership < 20 ?
        TaskState.Draft : isCompleteProfile && !taskResponse?.metadata?._id ?
          TaskState.Draft : TaskState.Incomplete,
      metadata: { ...taskResponse.metadata, ownership: _ownership }
    });
  };

  const deleteOwnership = () => {
    removeTaskResponse({ taskResponseId: ownership.key });

    const ownerId = ownership?._id || (ownership as any)?.ownerId;
    if (isOwnerModelEdit && ownerId) {
      deleteOwner({ ownerId, entityId: entity._id, loanId: loan._id });
    }

    if (onDeleteOwner)
      onDeleteOwner(ownerId);
  }

  const isValidForm = (values: any, files: any) => {
    return (values.address || ownership.address) &&
      (values.dateBirth || ownership.dateBirth) &&
      (values.email || ownership.email) &&
      (values.phone || ownership.phone) &&
      (values.ssn || ownership.ssn) &&
      (files?.length > 0 || ownership?.files?.length > 0) &&
      (values.ownership || ownership.ownership);
  }

  const closeForm = () => {
    onExpand(!isExpand);
    changeEdit();
  }

  const tagOwnerState = (!ownership.ownership || ownership.ownership < 20) ? 'no-guarantor' :
    ownership.state === TaskState.RequestError ? 'error' :
      (!ownership._id || !isValidForm(ownership, ownership.files)) && ownership.state === TaskState.Incomplete && !stepVerify ? 'next-step' :
        !ownership._id && ownership.state === TaskState.Incomplete ? 'incomplete' :
          ownership._id && ownership.state === TaskState.Incomplete && isValidForm(ownership, ownership.files) && stepVerify ? 'verify' : 
            ownership._id && ownership.state === TaskState.Incomplete && !isValidForm(ownership, ownership.files)? 'incomplete':
            'complete';

  const disabledRemove = loadingRemove || index === 0;
  
  return (
    <div className={`card-ownership ${isExpand && 'card-ownership-expand'}`}>
      {isActionRemoved && (
        <CloseCircleFilled
          className={`icon-delete ${(disabledRemove) && 'icon-delete-disabled'}`}
          onClick={!disabledRemove ? deleteOwnership : () => { }}
          disabled={disabledRemove}
        />
      )}
      <div
        className={`content-card ${showTagState ? 'content-card-' + ownership.state : ''
          } ${!isActionRemoved ? 'content-card-hover-removed' : ''}`}
        style={{
          height: stepVerify && !isExpand ? 74 : 'auto',
          padding: stepVerify && !isExpand ? '5px 18px 5px 16px' : '10px 18px 5px 16px'
        }}
      >
        <div className={`card-ownership`}>
          <div className="section-1">
            {showTagState && stepVerify && isExpand &&
              <StateTag
                state={ownership.state as TaskState}
                isLabelLocked
                isOtherState={ownership.state === TaskState.Draft}
                colorState={ownership.state === TaskState.Draft ? TaskState.Draft : undefined}
              />
            }
            {(!stepVerify || (stepVerify && isExpand)) &&
              <div className="names" style={{ marginTop: showTagState ? 7 : 0 }}>
                <div className="name">
                  <label className="body-regular text-blue">First name</label>
                  <span>{ownership.name}</span>
                </div>
                {ownership.lastname && (
                  <div className="name">
                    <label className="body-regular text-blue">Last name</label>
                    <span>{ownership.lastname}</span>
                  </div>
                )}
              </div>
            }
            {stepVerify && !isExpand &&
              <div className="resume-names" style={{ marginTop: showTagState ? 7 : 0 }}>
                <StateTag
                  state={ownership.state}
                  isOtherState={ownership.state === TaskState.Draft}
                  isLabelLocked
                  colorState={ownership.state === TaskState.Draft ? TaskState.Draft : ownership.state as TaskState}
                />
                <div className="name">
                  <span>{ownership.name + ' ' + ownership.lastname}</span>
                </div>
              </div>
            }
            {!hiddenSectionViewProfile && (
              <TagOwnerState
                className="tag-owner-state"
                onChange={changeEdit}
                setExpand={onExpand}
                isExpand={isExpand}
                state={tagOwnerState}
              />
            )}
          </div>
          <div className="section-2">
            {stepVerify && !isExpand &&
              <div className='ownership-section'>
                {percentageOwner && percentageOwner >= 20 &&
                  <span className="body-regular body-regular-w5 guarantor">
                    <img src={guarantorYellow} className="lienzo" alt="lienzoSvg" />{' '}
                    Guarantor
                  </span>
                }
                <InputBorderNone
                  type="number"
                  placeholder="Ownership"
                  suffix={percentageOwner ? '%' : ' '}
                  className={`input-disabled-color ${!percentageOwner && 'suffix-null'} mt-0`}
                  disabled={
                    ownership.state !== TaskState.Incomplete && ownership.state !== TaskState.RequestError && ownership.state !== TaskState.Draft
                  }
                  color="warning"
                  width={percentageOwner ? '50px' : '80px'}
                  max={100}
                  min={1}
                  value={percentageOwner}
                  onChange={(value: number) => {
                    setPercentageOwner(value);
                    setNewPercentageLiveEdit({ ownerId: (ownership._id || (ownership as any).ownerId), value });
                  }}
                  onBlur={(e: any) => {
                    if (e.target.value > 0) changeOwnership(parseInt(e.target.value));
                  }}
                />
              </div>
            }

            {(!stepVerify || (stepVerify && isExpand)) && <>
              {percentageOwner && (
                <span
                  className="body-regular text-blue"
                  style={{ fontSize: 12, marginTop: 5 }}
                >
                  Ownership
                </span>
              )}

              <InputBorderNone
                type="number"
                placeholder="Ownership"
                suffix={percentageOwner ? '%' : ' '}
                className={`input-disabled-color ${!percentageOwner && 'suffix-null mt-20'}`}
                color="warning"
                height={20}
                width={'100px'}
                max={100}
                min={1}
                value={percentageOwner}
                disabled={enabledDataBasic !== undefined ? enabledDataBasic : (disabledForm || disabledPercentage)}
                onChange={(value: number) => {
                  if (value > 0) changeOwnership(value);
                  setPercentageOwner(value);
                  setNewPercentageLiveEdit({ ownerId: (ownership._id || (ownership as any).ownerId), value });
                }}
              />
              {!hiddenGuarantorTag && percentageOwner && percentageOwner >= 20 && (!stepVerify || (stepVerify && isExpand)) && (
                <span className="body-regular body-regular-w5 guarantor">
                  <img src={guarantorYellow} className="lienzo" alt="lienzoSvg" />{' '}
                  Guarantor
                </span>
              )}
            </>
            }
          </div>
        </div>
        {(hiddenSectionViewProfile || isExpand) && (
          <>
            {!hiddenSectionViewProfile && (
              <Divider style={{ margin: '2px 0 5px' }} />
            )}
            <FormEdit
              ownership={ownership}
              ownerships={ownerships}
              hiddenSectionViewProfile={hiddenSectionViewProfile}
              hiddenTextEdit={hiddenTextEdit}
              disabledForm={disabledForm}
              hiddenSubmitChanges={hiddenSubmitChanges}
              hiddenColorsState={hiddenColorsState}
              saveChanges={saveChanges}
              isModalSubmit={isModalSubmit}
              saveEdit={fetching ? 'loading' : saveEdit}
              fetching={fetching}
              otherButton={otherButton}
              closeForm={closeForm}
              stepVerify={stepVerify}
              verifyProfile={verifyProfile}
              isExpand={isExpand}
            />
          </>
        )}
        <ModalCompleteProfile
          visible={modalComplete}
          setVisible={setModalComplete}
          closeTask={closeTask}
        />
        <ModalFinishTask
          visible={modalFinishTask}
          setVisible={setModalFinishTask}
          closeTask={closeTask}
        />
      </div>
    </div>
  );
};
export default CardOwnership;
