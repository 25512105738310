import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse, TaskState } from 'gql/graphql';
import { useCreateResponseTask } from 'services/task/mutations';
import { Button } from 'antd';
import { SubmitContext, TaskContext } from '../EntityGuarantorCreation';
import { useCreateEntity } from 'services/entity/mutations';
import { LoanContext } from 'scenes/Private/Dashboard/Dashboard';
import moment from 'moment';

interface Step2Props {
  ownerships: any[];
  entity?: { key: string; name: string };
  step2Confirm?: any;
  newOwner: any;
  responseStep1: TaskResponse;
  taskEntityDocs?: Task;
}
const ButtonSubmit = ({
  ownerships,
  entity,
  step2Confirm,
  newOwner,
  responseStep1,
  taskEntityDocs
}: Step2Props) => {
  const task: Task | any = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);
  const onSubmitParent: { step?: number; active?: boolean } | any =
    useContext(SubmitContext);

  const { fetching, execute: createResponseTask } = useCreateResponseTask();
  const { execute: createEntity } = useCreateEntity();

  useEffect(() => {
    if (onSubmitParent.active && onSubmitParent.step === 2) submitOwnerships();
  }, [onSubmitParent]);

  const getTotal = () =>
    ownerships
      .reduce(
        (total: number, ownership: any) => total + ownership.ownership,
        0
      );

  const submitOwnerships = () => {
    createResponseTask({
      task: task._id,
      key: `complete-ownerships`,
      label: `Add 100% ownership to submit`,
      step: 3,
      metadata: {},
      state: TaskState.Approved
    });

    createEntity({
      _id:
        responseStep1.label === 'Existing entity'
          ? responseStep1.key
          : undefined,
      name: entity?.name || `Entity created on ${moment().format('DD/MM/YY')}`,
      loans: [loan._id],
      task: task._id
    });
  };

  if (newOwner)
    return null;

  return (
    <Button
      className="button button-submit"
      onClick={submitOwnerships}
      disabled={getTotal() !== 100 && !step2Confirm}
      loading={fetching}
    >
      {step2Confirm
        ? 'Confirm ownership'
        : getTotal() !== 100
          ? 'Add 100% ownership to submit'
          : 'Create entity and complete profiles'
      }
    </Button>
  );
};
export default ButtonSubmit;
