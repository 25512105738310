import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { Link } from "react-router-dom";
import ModalBasic from "../../../../../components/Organisms/Modals/ModalBasic/ModalBasic";
import { useSendEmailResetPasswordByEmail } from "../../../../../services/auth/mutations";

const FormResetPassword = () => {

  const { 
    data: response, 
    fetching, 
    execute: sendEmailResetPassword 
  } = useSendEmailResetPasswordByEmail();
  
  const [modalConfirmEmail, setModalConfirmEmail] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false); 
  
  useEffect(() => {
    if(response) { 
      setError(false);
      setModalConfirmEmail(true); 
    } 
    else if(response === false) setError(true);
  }, [response])

  const onFinish = (values: any) => {
    setError(false);
    sendEmailResetPassword({ email: values.email });
  };

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      className="form"
      autoComplete="off">
      <Form.Item
        name="email"
        className="form-item"
        rules={[
          {
            type: 'email',
            required: true,
            message: "Please input your email!",
          },
        ]}>
        <Input 
          type="email" 
          placeholder="Email address" 
          className="form-item__input" 
          onChange={() => setError(false)}
        />
      </Form.Item>

      {error && 
        <p className="message-error">
          {`We couldn't find an account associated with this email address. Please check the email address and try again.`}
        </p>
      }

      <Link className="body-regular link" to="/">
        Do you already have an account ?{" "}
        <span className="body-regular-bold">Login</span>
      </Link>

      <Form.Item>
        <Button
          className="button button-primary button-txt-big"
          loading={fetching}
          htmlType="submit">
          Reset password
        </Button>
      </Form.Item>

      <ModalBasic
        visible={modalConfirmEmail}
        setVisible={setModalConfirmEmail}
        title='Check your email'
        description={'We sent you instructions on how to reset your password. Please follow the link in the email to create a new password.'}
        submit={{ buttonLabel: 'OK!', onSubmit: () => setModalConfirmEmail(false) }}
      />
    </Form>
  );
};
export default FormResetPassword;
