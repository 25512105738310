export const formatterSsn = (value: string) => {
  if (!value) return '';

  const numericValue = value
    .toString()
    .replace(/\D/g, '')
    .replace(/[^0-9-]/g, '');

  if (numericValue.length === 3) {
    return `${numericValue}-`;
  } else if (numericValue.length === 5) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}-`;
  } else if (numericValue.length > 5) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(
      3,
      5
    )}-${numericValue.slice(5, 9)}`;
  }

  return value.replace(/[^0-9-]/g, '');
};

export const formatterPhone = (value: string) => {
  if (!value) return '';

  const numericValue = value
    .toString()
    .replace(/\D/g, '')
    .replace(/[^0-9-]/g, '');

  if (numericValue.length === 3) {
    return `${numericValue}-`;
  } else if (numericValue.length === 6) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(3)}-`;
  } else if (numericValue.length >= 9) {
    return `${numericValue.slice(0, 3)}-${numericValue.slice(
      3,
      6
    )}-${numericValue.slice(6, 10)}`;
  }

  return value.replace(/[^0-9-]/g, '');
};

export const formatterDate = (value: string) => {
  if (!value) return '';

  // Elimina cualquier carácter que no sea un número
  const numericValue = value.replace(/\D/g, '');

  // Aplica el formato MM/DD/YYYY
  if (numericValue.length <= 2) {
    return numericValue; // Solo los primeros dos dígitos (mes)
  } else if (numericValue.length <= 4) {
    return `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`; // MM/DD
  } else if (numericValue.length <= 8) {
    return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4, 8)}`; // MM/DD/YYYY
  }

  // Si el valor es más largo que 8 dígitos, lo trunca
  return `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4, 8)}`;
};

export const formatterCurrency = (value: any, decimal?: boolean) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: !decimal? 0: 3,
  });

  return formatter.format(value);
};

export const capitalizeNames = (text: string) => {
  // Dividir el texto en palabras usando espacios como delimitadores
  let words = text.split(' ');
  // Capitalizar cada palabra y unirlas nuevamente
  let capitalizedWords = words.map(word => {
    // Solo capitalizar si la palabra no está vacía
    if (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  });
  // Unir las palabras nuevamente en una sola cadena
  return capitalizedWords.join(' ');
}

export const formatPriceResume = (number: number) => {
  if(!number) return null;
    
  if (number >= 1e9) {
    const billions = number / 1e9;
    return billions % 1 === 0 ? `$${billions.toFixed(0)}B` : `$${billions.toFixed(1)}B`;
  } else if (number >= 1e6) {
    const millions = number / 1e6;
    return millions % 1 === 0 ? `$${millions.toFixed(0)}M` : `$${millions.toFixed(1)}M`;
  } else if (number >= 1e3) {
    const thousands = number / 1e3;
    return thousands % 1 === 0 ? `$${thousands.toFixed(0)}K` : `$${thousands.toFixed(1)}K`;
  } else {
    return '$' + number.toString(); // Números menores a 1,000 sin formato
  }
};