import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import TaskProgress from 'components/Atoms/Progress/TaskProgress/TaskProgress';
import MinimumLiquidity from './MinimumLiquidity';
import { Button } from 'antd';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { formatPriceResume } from '@common/utils/formatters/formatter';

interface Props {
  tasks?: Task[] | any[],
}

const useCardMinimumLiquidity = ({ tasks }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [accountsErrors, setAccountsErrors] = useState<TaskResponse[]>();

  useEffect(() => {
    const data: Task = tasks?.find((item) => item.key === ModuleKeys.MINIMUM_LIQUIDITY);
    setDataTask(data);
    
    const _accountsErrors = data?.responses.filter(item => item.state === TaskState.RequestError);
    setAccountsErrors(_accountsErrors);

    if(data?.responses.length > 0) 
      setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));

  }, [tasks]);

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.MINIMUM_LIQUIDITY,
    title: <h4 className="h4">Proof of Liquidity</h4>,
    steps: 2,
    stepCurrent: stepNow?.step || 0,
    type: 'Major task',
    text2: dataTask?.state === TaskState.Approved ?
      <div className="message-state-success">
        <span>Your docs have been approved!</span>
      </div>
      :
      dataTask?.state === TaskState.RequestError ?
        <div className="message-state-error">
          <span>
            We were unable to approve {accountsErrors?.length} of your
            accounts
          </span>
        </div>
        :
        <p className="body-card" style={{ marginTop: 5 }}>
          {dataTask?.state === TaskState.Review ?
            `Your docs are in review and you’ve shown the minimum account balance. No more action needed at this time`
            :
            dataTask?.metadata.totalBalance.review > 0 ?
              `We’re reviewing the docs you submitted, but we need proof of a higher account balance`
              :
              null
          }
        </p>,
    progress: (
      <TaskProgress
        title={<h4 className="body-card body-card-w5">Initial liquidity</h4>}
        price={<h4 className="body-card body-card-w5">{formatPriceResume(dataTask?.metadata.jungoLimitBalance)}</h4>}
        colorBackground="#DFE4EB)"
        balances={dataTask?.metadata?.totalBalance}
        totalBalance={dataTask?.metadata.jungoLimitBalance}
      />
    ),
    state: dataTask?.state,
    actions: dataTask ? [
      <MinimumLiquidity key={1} task={dataTask} maxBalance={dataTask?.metadata.jungoLimitBalance}/>,
      <Button
        key={2}
        className={`button button-link button-link-${dataTask?.state}`}
      >
        View doc list
      </Button>
    ] : []
  };

  if(!dataTask) 
    return { card: null };
  
  return {
    card,
    dataTask
  };
};

export default useCardMinimumLiquidity;
