import { Button } from 'antd';
import InputAmount from 'components/Atoms/Inputs/InputAmount/InputAmount';
import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../DscrQuestionsAgreements';
import { useCreateResponseTask } from 'services/task/mutations';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';

interface Step2Props {
  setStep: Function;
  task: Task | any;
}
const Step2 = ({ setStep, task }: Step2Props) => {
  // @ts-ignore
  const { totalUnits, setTotalUnits, vacantUnits, setVacantUnits, occupiedUnits } = useContext(TaskContext);
  const [response, setResponse] = useState<TaskResponse | any>();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    if(taskResponses) {
      const lastStep2Response = taskResponses.find((item) => item.step === 2);
      setResponse(lastStep2Response);
      const step3Response_totalUnits = taskResponses.find((item) => item.key === 'units-in-property-dscr-step3');
      const step3Response_vacantUnits = taskResponses.find((item) => item.key === 'market-rent');
      const step3Response_occupiedUnits = taskResponses.find((item) => item.key === 'occupied-units-in-property-dscr-step3');
      if (lastStep2Response && !step3Response_totalUnits && !step3Response_vacantUnits && !step3Response_occupiedUnits) {
        setTotalUnits(lastStep2Response.metadata.totalUnits);
        setVacantUnits(lastStep2Response.metadata.vacantUnits);
      } else if (step3Response_totalUnits && step3Response_vacantUnits) {
        setTotalUnits(step3Response_totalUnits.metadata.totalUnits);
        setVacantUnits(step3Response_vacantUnits.metadata.vacantUnits);
      }
    }
  }, [taskResponses]);
  const totalUnitHandler = (value: number) => {
    if (value < vacantUnits) {
      setTotalUnits(value);
      setVacantUnits(value);
    } else {
      setTotalUnits(value);
    }
  }

  const vacantUnitHandler = (value: number) => {
    if (value <= totalUnits) {
      setVacantUnits(value);
    }
  }

  const isButtonDisabled = totalUnits === 0

  const { execute: createResponseTask } = useCreateResponseTask();

  const continueStep3 = async () => {
    let payload: any = {
      key: 'units-in-property-dscr',
      label: 'Units in property DSCR',
      step: 2,
      metadata: {
        totalUnits: totalUnits,
        vacantUnits: vacantUnits,
        occupiedUnits: occupiedUnits,
      },
      state: TaskState.Approved,
      task: task._id
    }
    if (response) payload._id = response._id;
    await createResponseTask(payload);
    setStep(3)
  }

  return (
    <section>
      <h2 className="h2">
        Give us some more info on the units in the property
      </h2>
      <div className="mt-25">
        <InputAmount
          title="How many units are in the property?"
          type="operation"
          text={null}
          value={totalUnits}
          onChange={totalUnitHandler}
        />
        <InputAmount
          title="How many units are vacant?"
          type="operation"
          text={null}
          value={vacantUnits}
          onChange={vacantUnitHandler}
        />
        {
          !occupiedUnits && !vacantUnits && !totalUnits ?
            <InputAmount
              title="How many units are occupied?"
              type="text"
              text={"Automatically calculated"}
            />
            :
            <InputAmount
              title="How many units are occupied?"
              type="number"
              text={null}
              value={occupiedUnits}
            />
        }
        <Button
          className={!isButtonDisabled ?  "button button-submit" : "button button-grey"}
          onClick={!isButtonDisabled ? continueStep3 : undefined}
        >
          {
            isButtonDisabled ?
            "Add units to go to next step"
            :
            "Add lease agreements and estimated rent"
          }
        </Button>
      </div>
    </section>
  );
};
export default Step2;
