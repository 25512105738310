import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';

import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import {
  formatterSsn as utilsFormatterSsn,
  formatterPhone as utilsFormatterPhone
} from '../../../../../../../@common/utils/formatters/formatter';
import { DATE_FORMAT } from '@common/utils/constants/dates.constanst';
import { InputAutocompleteAddress } from 'components/Atoms/Inputs/InputAutocompleteAddress/InputAutocompleteAddress';
import guarantorYellow from 'assets/images/guarantor-yellow.svg';
import TagOwnerState from './TagOwnerState';

interface CardOwnershipEditProps {
  onFinish: (values: any) => void;
  ownership?: any;
  fetching?: boolean;
  disabledNames?: boolean;
  onSaveDraft: (values: any) => void;
  handlerClose: Function;
  totalPercentage: number;
  tagStateGuarantor?: 'incomplete';
}

const CardOwnershipEdit = ({
  onFinish,
  ownership = {},
  fetching,
  disabledNames,
  onSaveDraft,
  handlerClose,
  totalPercentage,
  tagStateGuarantor
}: CardOwnershipEditProps) => {
  const [form] = Form.useForm();
  
  const [emailError, setEmailError] = useState<boolean>(false);
  const [listFileUpload, setListFileUpload] = useState<any>([]);
  const [ownershipPercentage, setOwnershipPercentage] = useState<number>((100 - totalPercentage) < 0 ? 0 : (100 - totalPercentage));

  useEffect(() => form.resetFields(), [ownership]);
  
  useEffect(() => {
    if(totalPercentage){
      const newPercentage = (100 - totalPercentage) < 0 ? 0 : (100 - totalPercentage);
      setOwnershipPercentage(newPercentage);
      form.setFieldValue('ownership', newPercentage);
    }
  }, [totalPercentage])

  const isDisabled = () => {
    const isFieldsValidate = Object.values(form.getFieldsValue()).every(
      (value) => !!value
    );
    
    return (
      !isFieldsValidate ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0
    );
  };

  const formatterSsn = (e: any) => {
    form.setFieldValue('ssn', utilsFormatterSsn(e.target.value));
  };

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const deleteOwnership = () => handlerClose();

  const onBlurCreateOwner = () => {
    const values = form.getFieldsValue();

    if(values.name?.length > 0 && values.lastname?.length > 0 && values.ownership) {
      onSaveDraft({ ...values, files: listFileUpload })
    }
  }

  return (
    <Form
      form={form}
      name="edit"
      autoComplete="off"
      className="card-ownership-edit"
      requiredMark="optional"
      colon={false}
      initialValues={ownership}
      onFinish={(v) => onSaveDraft({ ...v, files: listFileUpload })}
    >
      <CloseCircleFilled
        className="icon-delete"
        onClick={deleteOwnership}
      />

      <Form.Item
        label="First name"
        name="name"
        className="name"
        rules={[{ required: true, message: '' }]}
      >
        <InputBorderNone
          placeholder="Edgar"
          height={20}
          className="bold-lith"
          disabled={disabledNames}
          onBlur={onBlurCreateOwner}
        />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="lastname"
        className="lastname"
        rules={[{ required: true, message: '' }]}
      >
        <InputBorderNone
          placeholder="Poe"
          height={20}
          className="bold-lith"
          disabled={disabledNames}
          onBlur={onBlurCreateOwner}
        />
      </Form.Item>

      <Form.Item
        label="Ownership"
        name="ownership"
        className="ownership"
        rules={[{ required: true, message: '' }]}
        initialValue={(100 - totalPercentage) < 0 ? 0 : (100 - totalPercentage)}
      >
        <InputBorderNone
          type="number"
          placeholder="20"
          height={20}
          max={100}
          min={1}
          className="bold-lith"
          suffix="%"
          onChange={(v: number) => setOwnershipPercentage(v)}
          onBlur={onBlurCreateOwner}
        />
      </Form.Item>
      {ownershipPercentage !== undefined && ownershipPercentage >= 20 &&
        <span className="body-regular body-regular-w5 guarantor tag-guarantor">
          <img src={guarantorYellow} className="lienzo" alt="lienzoSvg" />{' '}
          Guarantor
        </span>
      }

      <TagOwnerState
        className="tag-owner-state"
        state={ownershipPercentage && ownershipPercentage >= 20 ? 
          (tagStateGuarantor? tagStateGuarantor: 'next-step') : 'no-guarantor'
        }
      />
      {/*
      <Form.Item
        label="SSN"
        name="ssn"
        className="ssn"
        rules={[
          { required: true, message: '' },
          { min: 11, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="239-23-2338"
          height={20}
          className="bold-lith"
          onChange={formatterSsn}
        />
      </Form.Item>

      <Form.Item
        label="Date of birth"
        name="dateBirth"
        className="datebirth"
        rules={[{ required: true, message: '' }]}
      >
        <DatePicker
          placeholder="12/31/1997"
          className="input input-transparent"
          format={DATE_FORMAT}
          suffixIcon={null} 
        />
      </Form.Item>

      <Form.Item
        label="Phone number"
        name="phone"
        className="phone"
        rules={[
          { required: true, message: '' },
          { min: 12, message: '' }
        ]}
      >
        <InputBorderNone
          placeholder="329-230-2390"
          height={20}
          className="bold-lith"
          onChange={formatterPhone}
        />
      </Form.Item>

      <Form.Item
        label="Email address"
        name="email"
        className="email"
        rules={[{ required: true, type: 'email', message: ' ' }]}
      >
        <InputBorderNone
          type="email"
          placeholder="email@gmail.com"
          height={20}
          color={emailError ? 'error' : 'bold-lith'}
          onBlur={(e: any) => setEmailError(e.target.value && form.getFieldError('email').length > 0)}
        />
      </Form.Item>

      <Form.Item
        label="Home address"
        name="address"
        className="address"
        rules={[
          { required: true, message: '' },
          { min: 3, message: '' },
          { max: 500, message: '' }
        ]}
      >
        <InputAutocompleteAddress
          onSelect={({ formatted_address }: any) => 
            form.setFieldValue('address', formatted_address)
          }
        />
      </Form.Item>

      <div className="identification">
        <label>Proof of identification</label>
        <UploadDocs
          style={{ marginTop: 10 }}
          numberOfFiles={1}
          listFileUpload={ownership.files || listFileUpload}
          setListFileUpload={setListFileUpload}
        />
      </div>

      <Form.Item className="submit" shouldUpdate>
        {() => (
          <Button
            htmlType="submit"
            className="button button-submit"
            disabled={isDisabled()}
            loading={fetching}
          >
            {isDisabled() ? 'Complete profile to submit' : 'Submit'}
          </Button>
        )}
      </Form.Item>
      */}

      {/*<Form.Item className="submit-draft" shouldUpdate>
        {() => (
          <Button
            className="button button-link-border"
            loading={fetching}
            disabled={!form.getFieldValue('name') || !form.getFieldValue('lastname') || !isDisabled()}
            onClick={() => onSaveDraft({ ...form.getFieldsValue(), files: listFileUpload })}
          >
            Save profile as draft
          </Button>
        )}
      </Form.Item>*/}

    </Form>
  );
};
export default CardOwnershipEdit;
