import React, { useContext } from 'react';
import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { TaskOpenModalContext } from '../../../../Private';

interface Props {
  visible: boolean;
  setVisible: Function;
  closeTask?: Function;
}

const ModalFinishTask = ({ visible, setVisible, closeTask }: Props) => {
  const { setHandler }: any = useContext(TaskOpenModalContext);

  return (
    <ModalEndSubmit
      visible={visible}
      setVisible={setVisible}
      buttonAction={{ hidden: true }}
      modal={{
        title: 'Great job!',
        subtitle: (
          <h3 className="h3">
            Before we move to the next approval task, do you have your entity
            docs handy?
          </h3>
        ),
        description: (
          <h4 className="h4 h4-w4">
            You just completed one of the tasks required to get you approved for your loan
          </h4>
        ),
        subdescription: (
          <h4 className="h4 h4-w4" style={{ marginTop: 6 }}>
            We don’t need them right now, but we’ll need them to get final
            approval on your loan
          </h4>
        )
      }}
      className=""
      icon={calendarPng}
      buttonSubmit={
        <Button
          htmlType="submit"
          className="button button-modal-ok"
          onClick={() => {
            setHandler('entity_docs')
            setVisible(false);
            if(closeTask)
              closeTask();
          }}
        >
          Yes, I can submit those now
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => {
            setVisible(false);
            if(closeTask)
              closeTask();
          }}
        >
          Back to dashboard
        </Button>
      }
    />
  );
};
export default ModalFinishTask;
