import React, { useState } from "react";
import calendarPng from 'assets/images/calendar.png';
import { Button, ButtonProps } from "antd";
import ModalEndSubmit from "components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit";

interface Props extends ButtonProps{
  onClick: any;
  modalConfirm?: {
    show: boolean;
    confirmDescription?: string;
  }
}

const ButtonSubmit = ({ onClick, modalConfirm, ...props }: Props) => {
  const [isModalSubmit, setModalSubmit] = useState(false);

  const submit = (e: any) => {
    if(modalConfirm) setModalSubmit(true);
    else if(onClick) onClick(e);
  }

  return (
    <>
      <Button className="button button-submit" onClick={submit} {...props}>
        {props.children}
      </Button>
      {modalConfirm && (
        <ModalEndSubmit
          visible={isModalSubmit}
          setVisible={setModalSubmit}
          buttonAction={{
            hidden: true
          }}
          modal={{
            title: 'Ready to submit?',
            description: (
              <h4 className="h4 h4-w4">{modalConfirm.confirmDescription || "After submitting, you'll have to contact us to make any changes."}</h4>
            )
          }}
          buttonSubmit={
            <Button
              className="button button-modal-ok"
              onClick={(e) => {
                setModalSubmit(false);
                if(onClick) onClick(e);
              }}
              {...props}
            >
              Yes, this is correct
            </Button>
          }
          buttonCancel={(actionCancel: any) => (
            <Button
              className="button button-modal-cancel"
              onClick={actionCancel}
            >
              I want to make changes
            </Button>
          )}
          icon={calendarPng}
          className="modal-end-submit--white"
        />
      )}
    </>
  );
};
export default ButtonSubmit;
