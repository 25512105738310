import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import { Tooltip } from 'react-tooltip'
import Payoff from './Payoff';
import { ModuleKeys } from 'services/task/modules-keys.constanst';

interface Props {
  tasks?: Task[] | any[],
}

const useCardPayoff = ({ tasks }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [response, setResponse] = useState<TaskResponse>();
  const [step1, setStep1] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.PAYOFF);
      setDataTask(data);
      setStep1(data?.responses.find(item => item.step === 1))
      setResponse(data?.responses.find(item => item.step === 2));

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const getTotalApproved = () => response ? 1 : 0;

  const getFilesCircle = () => {
    if(dataTask?.state === TaskState.RequestError){
      const lastError = response?.files?.findLast((item: any) => item.state === 'request_error');
      return lastError? [lastError]: [];
    }

    return response?.files?.filter((item: any) => item.state !== 'request_error') || [];
  }

  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.PAYOFF,
    title: <h4 className="h4">Payoff</h4>,
    steps: 2,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    state: dataTask?.state,
    actions: dataTask ? [<Payoff key={1} task={dataTask} />] : [],
    text: (
      <p className="body-card">
        {dataTask?.state === TaskState.Draft ? 'Letter/contact is not submitted' : (
          <>
            My payoff contact is{' '}
            <span className="body-card-bold-line">
              {step1?.key === 'yes' ? response && response?.files?.length ? 'letter is submitted' :
                'letter is not submitted' : response && response.metadata?.email ? 'contact is submitted' :
                'contact is not submitted'}
            </span>
          </>
        )}
      </p>
    ),
    text2: !step1 || step1?.key === 'no' ? null : (
      <div className='docs-section'>
        <div className='labels'>
          <p className={`body-regular body-regular-bold`}>
            Document status
          </p>
          <p className={`body-card`}>
            {getTotalApproved()}/1 submitted
          </p>
        </div>
        <div className={`circles-tags`}>
          {getFilesCircle().map((_response, index) => {
              let _state = _response?.state.toUpperCase() || 'DRAFT';

              return (
                <div key={index}>
                  <div
                    data-tooltip-id={`circle-state-${index}`}
                    data-tooltip-html={`<b>${tooltipTexts(_state)}</b>`}
                    className={`circle circle-${_state}`}
                  />
                  <Tooltip
                    id={`circle-state-${index}`}
                    className={`circle-state-tooltip circle-state-tooltip-${_state}`}
                  />
                </div>
              )
            })}
        </div>
      </div>
    )
  };

  if (!dataTask)
    return { card: null };

  return {
    card,
    dataTask
  };
};

export default useCardPayoff;