import { useQuery } from 'urql';
import { GET_OWNERSHIPS_BY_CREATOR, GET_OWNERSHIPS_BY_ENTITY } from './graphql';
import { GetOwnershipsByCreatorQuery, GetOwnershipsByCreatorQueryVariables, GetOwnershipsByEntityQuery, GetOwnershipsByEntityQueryVariables, QueryGetOwnershipsByEntityArgs } from 'gql/graphql';
import { UseQueryInterface } from '@common/utils/interfaces/use-query.interface';

export const useGetOwnershipsByEntity = (vars: UseQueryInterface<QueryGetOwnershipsByEntityArgs>) => {
    const [{ data, ...others }, execute] = useQuery<GetOwnershipsByEntityQuery>({
        query: vars?.query || GET_OWNERSHIPS_BY_ENTITY,
        ...vars
      });
      return {
        data: data?.GetOwnershipsByEntity,
        execute: (data?: GetOwnershipsByEntityQueryVariables) => execute({ GetOwnershipsByEntity: data }),
        ...others
      };
}

export const useGetOwnershipsByCreator = (vars: UseQueryInterface<any>) => {
  const [{ data, ...others }, reexecute] = useQuery<GetOwnershipsByCreatorQuery>({
      query: vars?.query || GET_OWNERSHIPS_BY_CREATOR,
      ...vars
    });
    return {
      ...others,
      data: data?.GetOwnershipsByCreator,
      reexecute,
    };
}

