import React, { useState } from 'react';
import { Button } from 'antd';
import { TaskState } from '../../../../../../gql/graphql';

interface Props {
  fetching: boolean;
  disabled: boolean;
  onClick: any;
  ownerships?: any[];
  ownerState?: TaskState;
}

const ModalSubmitProfile = (props: Props) => {

  return (
    <Button
      className={props.ownerState === TaskState.RequestError ? 'button-submit button-submit-error' : 'button-submit button-submit-yellow'}
      onClick={props.onClick}
    >
      <b>
        {props.ownerState === TaskState.RequestError ?
          'Update profile to continue' : props.disabled ?
            'Complete profile to continue' : 'Profile complete!'}
      </b>
      <span>{props.disabled || props.ownerState === TaskState.RequestError ? '' : 'Click here to continue'}</span>
    </Button>
  );
};
export default ModalSubmitProfile;
