import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Entity,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext } from '../../Dashboard';
import { useCreateResponseTask } from 'services/task/mutations';

export const TaskContext = createContext<Task | {}>({});

interface Props {
  task: Task;
}

const TitleCompany = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const loan: any = useContext(LoanContext);

  const { execute: createResponseTask, fetching } = useCreateResponseTask();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse | any>();
  const [isReview, setIsReview] = useState(false);
  const [isRequestTitle, setRequestTitle] = useState(false);

  useEffect(() => {
    if (openContext === 'title_company') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    const step1 = taskResponses?.find((item) => item.step === 1);
    if (step1) setResponseStep1(step1);
    
    setIsReview(step1?.state === TaskState.Review);
    setRequestTitle(step1?.metadata?.actionButton === 'request_title_company' || step1?.key === 'not-title');
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const redirectLoanDoc = () => {
    const docTitle = loanDocs?.find(item => item.key === 'title');
    window.open(docTitle?.file?.url, "_blank");
  }

  const actionButtonCard = async (openModal: any) => {
    if (task.state === TaskState.Review && !isRequestTitle) {
      await createResponseTask({
        _id: responseStep1?._id,
        task: task._id,
        key: responseStep1?.key,
        label: responseStep1?.label,
        step: 1,
        metadata: { ...responseStep1.metadata, actionButton: 'request_title_company' },
        state: TaskState.Review
      });

      getTaskResponses({ requestPolicy: 'network-only' });
      return;
    }

    openModal(true);
  }

  const { taskName, phase } = findTaskRules(ModuleKeys.TITLE_COMPANY);

  return (
    <TaskContext.Provider value={task}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        responses={taskResponses}
        renderButtonTrigger={(openModal: any) => (
          <>
            <Button
              className={`button-open-modal button-open-modal-${task.state}`}
              onClick={() => actionButtonCard(openModal)}
            >
              {task.state === TaskState.Review ?
                (!isRequestTitle ? 'Request a title company' : 'I have my own title company') :
                task.state === TaskState.Approved ? 'View task' :
                  (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                    'Begin Task'
              }
            </Button>
          </>
        )}
        header={{
          title: <span>{phase} | {taskName.toUpperCase()}</span>,
          actionLeft: (
            isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
          ),
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              Help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        stepsTotal={1}
        currentStep={step}
        setStep={setStep}
        footer={
          <div className="text-footer mt-20">
            <p className="placeholder">
              My title company is{' '}
              {responseStep1?.metadata?.titleCompany &&
                <span className="bold-label">
                  {responseStep1?.metadata?.titleCompany}
                </span>
              }
            </p>
          </div>
        }
      >
        <section className="children AchPaymentForm">
          <Step1
            setStep={setStep}
            closeTask={() => setModalTaskVisible(false)}
          />
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default TitleCompany;
