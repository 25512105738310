import { gql } from "urql";

export const MUTATION_CREATE_OWNERSHIP: any = gql(`
    mutation CreateOwnership($createOwnershipInput: CreateOwnershipInput!) {
        CreateOwnership(createOwnershipInput: $createOwnershipInput) {
            _id
        }
    }
`)

export const MUTATION_DELETE_OWNERSHIP: any = gql(`
    mutation DeleteOwnership($ownerId: String!, $loanId: String!, $entityId: String!) {
        DeleteOwnership(ownerId: $ownerId, loanId: $loanId, entityId: $entityId) {
            _id
        }
    }
`)