import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import EntityGuarantorCreation from './EntityGuarantorCreation';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import useGuarantorUtil from './useGuarantorUtil';

interface Props {
  tasks?: Task[] | any[],
  entityDocs?: Task
}

const useCardEntityGuarantor = ({ tasks, entityDocs }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();
  const [guarantors, setGuarantors] = useState<any[]>();
  const [entity, setEntity] = useState<string>();

  const { getGuarantorsNames } = useGuarantorUtil({ ownerships: guarantors });

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.ENTITY_GUARANTORS);
      setDataTask(data);

      const step1 = data?.responses.find(item => item.step === 1);
      setEntity(step1?.metadata?.entity);

      const owners = data?.responses.filter(item => item.step === 2 && item.key !== 'confirm-persons');
      setGuarantors(owners?.filter(item => item.metadata.ownership >= 20).map(item => item.metadata));

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.ENTITY_GUARANTORS,
    title: <h4 className="h4">Entity Ownership And Guarantors</h4>,
    text: (
      <p className="body-card">
        My entity{' '}
        <span className={`body-card-bold-line body-card-bold-line-${dataTask?.state}`}>
          {entity ? entity :
            dataTask?.state !== TaskState.Draft ?
              'isn’t formed yet' :
              'is/isn’t formed yet'
          },
        </span>
        {' '}but it will have{' '}
        {guarantors && guarantors.length > 0 ?
          <span className={`body-card-bold-line body-card-bold-line-${dataTask?.state}`}>
            {guarantors.length} {guarantors.length > 1? 'guarantors': 'guarantor'}
          </span>
          :
          <span className="body-card-bold-line"># owners</span>
        }
        {' '}who {guarantors && guarantors.length > 1? 'own': 'owns'} more than 20%. The owners/loan guarantors are ...
      </p>
    ),
    text2: guarantors && guarantors.length > 0? (
      <div style={{ marginBottom: 5, marginTop: 10 }}>
        <p className={`body-card body-card-${dataTask?.state}`}>
          GUARANTORS
        </p>
        <span className="body-card body-card-w5">
          {getGuarantorsNames()}
        </span>
      </div>
    ): null,
    type: 'Major task',
    steps: 3,
    stepCurrent: stepNow?.step || 0,
    state: dataTask?.state,
    actions: dataTask
      ? [
        <EntityGuarantorCreation
          key={1}
          task={dataTask}
          taskEntityDocs={entityDocs}
        />
      ]
      : []
  };

  if(!dataTask) 
    return { card: null };

  return {
    card,
    dataTask
  };
};
export default useCardEntityGuarantor;
