import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import ButtonLink from 'components/Atoms/Buttons/ButtonLink/ButtonLink';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { useCreateResponseTask } from 'services/task/mutations';
import { TaskContext } from '../../MinimumLiquidity';
import { Task, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';

interface Props {
  title: string;
  accountKey: string;
  buttonLabel: string;
  text: React.ReactElement;
  infoExpand: React.ReactElement;
  handlerBalanceDraft: Function;
  handlerCreateAccount: Function;
  balancePercentage?: number;
  limitBalance: number;
  accounts: any;
}

const SectionNewBank = ({
  title,
  accountKey,
  buttonLabel,
  text,
  infoExpand,
  handlerBalanceDraft,
  handlerCreateAccount,
  balancePercentage,
  limitBalance,
  accounts
}: Props) => {
  const task: Task | any = useContext(TaskContext);
  const {
    data,
    fetching,
    execute: createResponseTask
  } = useCreateResponseTask();
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  const titleName = accountKey === 'account_bank' ? 'Bank' :
    accountKey === 'accounts_pension' ? 'Retirement' :
      accountKey === 'accounts_cash' ? 'Brokerage' :
        accountKey === 'accounts_others' ? 'Other Brokerage' : '';

  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isUpdateActive, setUpdateActive] = useState(false);
  const [listFileUpload, setListFileUpload] = useState<any>([]);
  const [numberOfFiles] = useState(2);
  const [balance, setBalance] = useState<any>(null);
  const [nameAccount, setNameAccount] = useState(`${titleName} account ` + ((accounts?.length || 0) + 1));

  useEffect(() => {
    if (data) {
      getTaskResponses({ requestPolicy: 'network-only' });
      handlerCreateAccount();
      setIsModalConfirm(false);
      setListFileUpload([]);
      setBalance(0);
      setNameAccount('');
      setUpdateActive(false);
    }
  }, [data]);

  useEffect(() => {
    handlerBalanceDraft(balancePercentage ? balance * balancePercentage : balance);
  }, [balance]);

  const handleOk = () => {
    if (task)
      createResponseTask({
        key: accountKey,
        label: title,
        step: 2,
        metadata: {
          balance: balancePercentage ? balance * balancePercentage : balance,
          nameAccount,
          limitBalance
        },
        state: TaskState.Review,
        task: task?._id,
        files: listFileUpload
      });
  };

  return (
    <div className="SectionNewBank">
      <h4 className="h4">{title}</h4>
      {text}
      <ButtonLink
        title={buttonLabel}
        icon={<PlusCircleOutlined />}
        className={isUpdateActive ? 'button-gray' : ''}
        onClick={() => setUpdateActive(!isUpdateActive)}
      />
      {isUpdateActive && (
        <div className="card-add-account">
          <div className={`setcion-grid`}>
            <div className="box">
              <InputBorderNone
                placeholder="Name Your Account"
                calculateBalance={false}
                valuePercentage={50}
                onChange={(e: any) => setNameAccount(e.target.value)}
                value={nameAccount}
              />

              <InputBorderNone
                type="number"
                formatter={"currency"}
                className="body-placeholder-w4"
                label="Latest documented balance"
                placeholder="Enter amount"
                calculateBalance={!!balancePercentage}
                valuePercentage={(balancePercentage || 1.0) * 100}
                onChange={(value: any) => setBalance(value)}
                value={balance}
              />

              {/* <InputBorderNone
                className="body-placeholder-w4"
                label="Latest documented balance"
                placeholder="Enter amount"
                calculateBalance={true}
                valuePercentage={50}
              /> */}
              {listFileUpload.length < numberOfFiles && (
                <p className="body-card">Upload 2 statements to submit</p>
              )}
            </div>
            <div className="box">
              <UploadDocs
                numberOfFiles={2}
                listFileUpload={listFileUpload}
                setListFileUpload={setListFileUpload}
              />
            </div>
          </div>
          <ModalEndSubmit
            visible={isModalConfirm}
            setVisible={setIsModalConfirm}
            buttonAction={{
              label: 'Submit for review',
              className: 'button-submit button-block',
              disabled: !balance || !nameAccount || listFileUpload?.length < 2,
              loading: fetching
            }}
            modal={{
              title: 'Ready to submit?',
              description: (
                <h4 className="h4 h4-w4">
                  After submitting, you’ll have to contact us if you want to
                  make changes
                </h4>
              )
            }}
            buttonSubmit={
              <Button
                className="button button-modal-ok"
                onClick={handleOk}
                loading={fetching}
              >
                Yes, this is correct
              </Button>
            }
            buttonCancel={(actionCancel: any) => (
              <Button
                className="button button-modal-cancel"
                onClick={actionCancel}
              >
                I want to make changes
              </Button>
            )}
            icon={calendarPng}
            className="modal-end-submit--white"
            checkboxDontShow={{
              label: 'Don’t show this message again for this task',
              storageKey: 'minimium-confirm-new-account',
              autoSubmit: handleOk
            }}
          />
          {infoExpand && <div className="top-InfoExpand">{infoExpand}</div>}
        </div>
      )}
    </div>
  );
};

export default SectionNewBank;
