import { useMutation } from 'urql';
import { DeleteAccountMutation, UpdateUserInput, UpdateUserMutation } from 'gql/graphql';
import { MUTATION_DELETE_ACCOUNT, MUTATION_UPDATE_USER } from './graphql';

export const useUpdateUser = (query?: any) => {
  const [results, execute] = useMutation<UpdateUserMutation>(query || MUTATION_UPDATE_USER);

  return {
    execute: (data: UpdateUserInput) =>
      execute({ updateUserInput: data }),
    data: results.data?.UpdateUser,
    ...results
  };
};

export const useDeleteAccount = (query?: any) => {
  const [results, execute] = useMutation<DeleteAccountMutation>(query || MUTATION_DELETE_ACCOUNT);
  
  return {
    ...results,
    execute,
    data: results.data?.DeleteAccount,
  };
};