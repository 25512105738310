import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import { LoanContext } from '../../Dashboard';
import {
  Loan,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import Step1 from './Step1/Step1';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import CircleTag from 'components/Atoms/Tags/CircleTag/CircleTag';

interface Props {
  task: any;
  disabled?: boolean;
}

const InvestmentExperienceVerification = ({ task, disabled }: Props) => {
  const loan: Loan | any = useContext(LoanContext);

  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const { data: taskResponses } =
    useGetTaskResponsesByTask({ variables: { findResponsesByTask: { taskId: task._id } } });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [countProperties, setCountProperties] = useState<number>();
  const [response, setResponse] = useState<TaskResponse>();
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setIsReview(_response?.state === TaskState.Review);
  }, [taskResponses]);

  useEffect(() => {
    setCountProperties(loan.investmentExperience?.properties === '1-2' ? 2 : 3);
  }, [loan]);

  useEffect(() => {
    if (openContext === 'investment_experience') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  const redirectTrackRecord = () => {
    setModalTaskVisible(false);
    setHandler('track_record');
  }

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const generateCircles: any = () => {
    return new Array(countProperties).fill(undefined).map((item, index) => {
      if (response?.files && response.files[index])
        return response.files[index].state;

      return 'draft';
    });
  };

  const generateCirclesFooter: any = () => {
    const filesCirclesStates = new Array(countProperties).fill(undefined).map((item, index) => {
      if (response?.files && response.files[index])
        return response.files[index].state;

      return 'draft';
    });

    const addAnotherDocs = taskResponses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || [];
    return [...filesCirclesStates, ...docsStates];
  };

  const { taskName, phase } = findTaskRules(ModuleKeys.INVESTMENT_EXPERIENCE);

  return (
    <div className="InvestmentExperienceVerification">
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
        responses={taskResponses}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
            disabled={disabled}
          >
            {(task.state === TaskState.Review || task.state === TaskState.Approved) ? 'View task' :
              (task.state === TaskState.RequestError || task.state === TaskState.Incomplete) ? 'Complete task' :
                'Begin Task'
            }
          </Button>
        )}
        header={{
          title: <span>{phase} | {taskName.toUpperCase()}</span>,
          actionLeft:
            isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />,
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        stepsTotal={1}
        currentStep={1}
        setStep={null}
        footer={
          <div className="flex-btw footer_dost mt-10">
            <div className="column">
              <p className="h4 h4-w5">Property HUD status</p>
              <p className="body-regular">
                {response?.files?.length || 0}/{countProperties?.toString()} submitted
              </p>
            </div>
            <div className="column">
              <div className="circles">
                {generateCirclesFooter()?.map((state: string, index: number) => (
                  <CircleTag key={index} state={state} tagKey={state} />
                ))}
              </div>
            </div>
          </div>
        }
      >
        <Step1
          countProperties={countProperties}
          generateCircles={generateCircles}
          redirectTrackRecord={redirectTrackRecord}
          response={response}
          setModalTaskVisible={setModalTaskVisible}
          task={task}
          closeTask={() => setModalTaskVisible(false)}
        />
      </ModalTask>
    </div>
  );
};
export default InvestmentExperienceVerification;
