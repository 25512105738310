import React, { useContext, useEffect, useState } from 'react';
import { useCreateResponseTask } from 'services/task/mutations';
import { Button } from 'antd';
import coinPng from 'assets/images/coin-shadow.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { Task, TaskResponse, TaskState } from '../../../../../../gql/graphql';
import { TaskContext } from '../EntityDocs';

interface Props {
  ein: TaskResponse | undefined;
  agreement: TaskResponse | undefined;
  certificate: TaskResponse | undefined;
  goodStanding: TaskResponse | undefined;
  buttonLabel: string;
  closeTask: any;
  listFileUpload: any;
  isModalEdit: boolean;
  setModalEdit: any;
  buttonShow: boolean;
  isConfirmDocs: boolean;
}
const ButtonConfirmDoc = ({
  isModalEdit,
  setModalEdit,
  buttonLabel,
  buttonShow,
  ein,
  agreement,
  certificate,
  goodStanding,
  closeTask,
  listFileUpload,
  isConfirmDocs
}: Props) => {
  const task: Task | any = useContext(TaskContext);

  const {
    data: newResponseTask,
    fetching,
    execute: createResponseTask
  } = useCreateResponseTask();

  useEffect(() => { 
    let inReviews = [
      ein,
      certificate,
      agreement,
      goodStanding
    ].filter((item) => item?.state === TaskState.Review);
    
    if (inReviews.length === 3) setModalEdit(true);
  }, [newResponseTask]);

  const confirm = () => {
    createOrUpdateResponse('EIN letter', 'ein-letter', ein);
    createOrUpdateResponse('Operating Agreement', 'operating-agreement', agreement);
  };

  const createOrUpdateResponse = (label: string, key: string, state: any) => {
    let payload: any = {
      key,
      label,
      step: 2,
      metadata: isConfirmDocs? { viewConfirm: true }: {},
      state: TaskState.Review,
      task: task._id,
    };
    if (state) payload._id = state._id;
    
    if (listFileUpload[key]?.length > 0)
      payload.files = listFileUpload[key];
    else 
      payload.filesExist = state?.files?.map((item: any) => ({ ...item, state: 'review' }));
    
    createResponseTask(payload);
  }
      
  return (
    <>
      {buttonShow && (
        <Button
          className="button button-submit"
          onClick={confirm}
          disabled={
            (!ein && !listFileUpload['ein-letter']) ||
            (!agreement && !listFileUpload['operating-agreement'])
          }
        >
          {buttonLabel}
        </Button>
      )}
      <ModalEndSubmit
        visible={isModalEdit}
        setVisible={setModalEdit}
        buttonAction={{
          label: buttonLabel,
          loading: fetching,
          className: 'button-submit',
          hidden: true
        }}
        modal={{
          title: `Your entity details are confirmed`,
          description: (
            <h4 className="h4 h4-w4">
              You just completed one of the tasks required to get you approved
              for your loan
            </h4>
          )
        }}
        className=""
        icon={coinPng}
        buttonSubmit={
          <Button
            className="button button-modal-ok"
            onClick={() => {
              closeTask();
              setModalEdit(false);
            }}
          >
            Go to next task
          </Button>
        }
        buttonCancel={
          <Button
            className="button button-modal-cancel"
            onClick={() => {
              closeTask();
              setModalEdit(false);
            }}
          >
            Back to dashboard
          </Button>
        }
      />
    </>
  );
};
export default ButtonConfirmDoc;
