import React, { useContext, useEffect } from 'react';
import QuestionBasic, {
  OptionsQuestionBasic
} from 'components/Organisms/QuestionBasic/QuestionBasic';
import { useCreateResponseTask } from 'services/task/mutations';
import { TaskContext } from '../MinimumLiquidity';
import { Task, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';

interface Step1Props {
  setStep: Function;
  handlerCloseModalTask: Function;
}

const Step1 = ({ setStep, handlerCloseModalTask }: Step1Props) => {
  const task: Task | any = useContext(TaskContext);
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } },
    pause: true
  });

  const {
    data,
    fetching,
    error,
    execute: createResponseTask
  } = useCreateResponseTask();

  useEffect(() => {
    getTaskResponses({ requestPolicy: 'network-only' });
  }, [])

  useEffect(() => {
    if (taskResponses?.find((response) => response.step === 1)) setStep(2);
  }, [taskResponses]);

  useEffect(() => {
    if (data) setStep(2);
  }, [data]);

  const onChangeOption = (option: OptionsQuestionBasic) => {
    if(option.id === 2) {
      handlerCloseModalTask(false);
      return;
    } 

    if (task)
      createResponseTask({
        key: option.id.toString(),
        label: option.title,
        step: 1,
        metadata: { optionId: option.id.toString() },
        task: task._id,
        state: TaskState.Approved,
      });

      getTaskResponses({ requestPolicy: 'network-only' });
  };

  return (
    <section>
      <h2 className="h3">
        We need to confirm you have funds to cover closing costs, interest, and
        a down payment
      </h2>
      <div className="mt-30">
        <QuestionBasic
          type="buttons"
          question={
            <h3 className="h2">
              You’ll need to show account balances totaling $2MM from any of:
            </h3>
          }
          text={null}
          listItems={[
            {
              id: 1,
              subTitle: 'Bank accounts:',
              text: 'You can use business or personal'
            },
            {
              id: 2,
              subTitle: 'Retirement/Pension accounts:',
              text: 'Only 50% of your balance will apply toward minimum liquidity'
            },
            {
              id: 3,
              subTitle: 'Cash-equivalent brokerage accounts',
              text: ''
            },
            {
              id: 4,
              subTitle: 'Other brokerage accounts:',
              text: 'Only 50% of your balance will apply toward minimum liquidity'
            }
          ]}
          options={[
            {
              id: 1,
              title: 'I’m ready to go!',
              onClick: onChangeOption,
            },
            {
              id: 2,
              title: 'I’ll do this later',
              onClick: onChangeOption
            }
          ]}
        />
      </div>
    </section>
  );
};

export default Step1;
