import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import PayAppraisal from './PayAppraisal';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { useGetUserLoggin } from '../../../../../services/user/querys';

interface Props {
  tasks?: Task[] | any[],
}

const useCardPayAppraisal = ({ tasks }: Props) => {
  const { data: user } = useGetUserLoggin();

  const [dataTask, setDataTask] = useState<Task>();
  const [response, setResponse] = useState<TaskResponse>();
  const [stepNow, setStepNow] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.PAY_APPRAISAL);
      setDataTask(data);
      setResponse(data?.responses?.find(item => item.metadata.userEmail));

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.PAY_APPRAISAL,
    title: <h4 className="h4">Appraisal Payment</h4>,
    steps: 1,
    stepCurrent: stepNow?.step || 0,
    type: 'quick task',
    state: dataTask?.state,
    stateRename: { REVIEW: 'AWAITING ACTION' },
    actions: dataTask && user ? [<PayAppraisal key={1} task={dataTask} userEmail={user?.email} />] : [],
    text: (
      <div>
        <p className="body-card">
          An appraisal payment link {' '}

          <span className={`body-card-bold-line body-card-bold-line-${dataTask?.state}`}>
            {dataTask?.state !== TaskState.Review && ' has not been sent '}
            {dataTask?.state === TaskState.Review && ' has been sent '}
          </span>
        </p>
        {dataTask?.state === TaskState.Review &&
          <div className="box-message-state-review">
            <span>Once payment is submitted, the appraiser will reach out to schedule the appraisal.</span>
          </div>
        }
      </div>
    ),
    text2: (
      <div style={{ marginTop: 15 }}>
        {response?.metadata.userEmail &&
          <>
            <p className={`body-card body-card-${dataTask?.state}`}>
              EMAIL
            </p>
            <span className="body-card body-card-w5">{response?.metadata.userEmail}</span>
          </>
        }
      </div>
    )
  };

  if (!dataTask)
    return { card: null };

  return {
    card,
    dataTask
  };
};

export default useCardPayAppraisal;