import React, { useState } from 'react';
import { Button } from 'antd';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import { TaskState } from '../../../../../../gql/graphql';

interface Props {
  fetching: boolean;
  disabled: boolean;
  onClick: any;
  ownerships?: any[];
  ownerState?: TaskState;
}

const ModalSubmitProfile = (props: Props) => {
  const { ownerships } = props;
  const [submitProfile, setSubmitProfile] = useState<boolean>(false);

  return (
    <ModalEndSubmit
      visible={submitProfile}
      setVisible={setSubmitProfile}
      buttonAction={{
        strong: props.ownerState === TaskState.RequestError ? 'Update profile to continue' :
          props.disabled ? 'Complete profile to continue' : 'Profile complete!',
        label: props.disabled || props.ownerState === TaskState.RequestError ? '' : 'Click here to continue',
        loading: props.fetching,
        disabled: props.disabled,
        className: props.ownerState === TaskState.RequestError? 'button-submit button-submit-error': 'button-submit button-submit-yellow'
      }}
      modal={{
        title: ownerships && ownerships?.length > 1 ? 'Ready to submit these guarantors profiles?' : 'Ready to submit this guarantor profile?',
        description: (
          <h4 className="h4 h4-w4">
            After submitting, you’ll have to contact us if you want to make changes
          </h4>
        )
      }}
      className="modal-end-submit--white"
      icon={calendarPng}
      buttonSubmit={
        <Button
          className="button button-modal-ok"
          onClick={() => {
            props.onClick();
            setSubmitProfile(false);
          }}
          loading={props.fetching}
          disabled={props.disabled}
        >
          Yes, submit it
        </Button>
      }
      buttonCancel={
        <Button
          className="button button-modal-cancel"
          onClick={() => setSubmitProfile(false)}
        >
          I need to make some changes
        </Button>
      }
      checkboxDontShow={{
        storageKey: 'guarantorProfileCheck',
        label: 'Don’t show this message again',
        autoSubmit: () => {
          props.onClick();
          setSubmitProfile(false);
        }
      }}
    />
  );
};
export default ModalSubmitProfile;
