import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

interface Props {
	title: string;
	description?: string;
	button?: { title: string, onClick?: any, arrow?: boolean, underline?: boolean };
}

const SectionSuccessTask = ({ title, description, button }: Props) => {
	return (
		<div className="mt-20">
			<div className="SectionSuccessTask">
				<div className="flex-btw">
					<h4 className="body-regular body-regular-title">{title}</h4>
				</div>
				{button &&
					<div className="flex-end">
						<Button
							type="link"
							className="button button-transparent"
							onClick={button.onClick}
						>
							<span style={{ textDecoration: button.underline ? 'underline' : '' }}>
								{button.title}
							</span>
							{button.arrow && <ArrowRightOutlined />}
						</Button>
					</div>
				}
			</div>
		</div>
	);
};
export default SectionSuccessTask;
