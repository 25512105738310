import React from 'react';
import { Button, Modal, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

interface ModalEndSubmitProps {
  visible: boolean;
  setVisible: Function;
  buttonAction?: {
    label?: string;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    hidden?: boolean;
    strong?: string;
  };
  modal: {
    title: string;
    subtitle?: React.ReactElement | null;
    description?: React.ReactElement;
    subdescription?: React.ReactElement;
  };
  buttonSubmit: React.ReactElement | Function;
  buttonCancel?: React.ReactElement | Function;

  icon: string;
  className: string;
  checkboxDontShow?: { label: string, storageKey: string, autoSubmit: any };
}

const ModalEndSubmit = ({
  visible,
  setVisible,
  modal,
  buttonAction,
  buttonSubmit,
  buttonCancel,
  icon,
  className,
  checkboxDontShow
}: ModalEndSubmitProps) => {

  const showModal = () => setVisible(true);
  const handleModalOk = () => setVisible(false);
  const handleCancel = () => setVisible(false);

  const onChange = (e: CheckboxChangeEvent) => {
    if(!checkboxDontShow?.storageKey)
        return;

    if(e.target.checked) 
      localStorage.setItem(checkboxDontShow?.storageKey, 'true');
    else 
      localStorage.removeItem(checkboxDontShow?.storageKey);
  };

  let isShowModalCheckbox;

  if(checkboxDontShow?.storageKey)
    isShowModalCheckbox = localStorage.getItem(checkboxDontShow?.storageKey);  
  
  return (
    <>
      {buttonAction && !buttonAction.hidden && (
        <Button
          className={`ModalEndSubmit_button-open-modal button ${buttonAction?.className || 'button-submit'
            }`}
          disabled={buttonAction.disabled}
          loading={buttonAction.loading}
          onClick={isShowModalCheckbox === 'true'? checkboxDontShow?.autoSubmit: showModal}
        >
          {buttonAction?.strong && 
            <b>{buttonAction?.strong}</b>
          }
          {buttonAction?.label}
        </Button>
      )}
      <Modal
        open={visible}
        onOk={handleModalOk}
        onCancel={handleCancel}
        className={`modal-end-submit ${className}`}
      >
        <div className="modal-end-submit__content">
          <img className="coin" alt="coin" src={icon} />
          <h2 className="h2">{modal.title}</h2>
          {modal.description}
          {modal.subtitle && (
            <div className="divider">
              {modal.subtitle}
              {modal.subdescription}
            </div>
          )}
          <div className="buttons">
            {typeof buttonSubmit === 'object' && buttonSubmit}
            {typeof buttonSubmit === 'function' &&
              buttonSubmit(() => setVisible(false))}
            {typeof buttonCancel === 'object' && buttonCancel}
            {typeof buttonCancel === 'function' &&
              buttonCancel(() => setVisible(false))}
          </div>
          {checkboxDontShow && (
            <Checkbox onChange={onChange}>{checkboxDontShow.label}</Checkbox>
          )}
        </div>
      </Modal>
    </>
  );
};
export default ModalEndSubmit;
