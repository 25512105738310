import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import InputAmount from 'components/Atoms/Inputs/InputAmount/InputAmount';
import { TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';

const CardMarketRent = ({ vacantUnits, setVacantUnits, totalUnits, setTotalUnits, saveResponse, task }: any) => {
  const initialRent = 2000;
  const [rents, setRents] = useState(Array(vacantUnits).fill(initialRent));
  const [touched, setTouched] = useState(Array(vacantUnits).fill(false));
  const [totalRents, setTotalRents] = useState(initialRent * vacantUnits);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [response, setResponse] = useState<TaskResponse | any>();

  const generateUnitNumbers = (count: number) => 
    Array.from({ length: count }, (_, i) => (i + 1)*100 + 1);
  const [unitNumbers, setUnitNumbers] = useState(generateUnitNumbers(vacantUnits));
  
  const handleRentChange = (index: number, value: number) => {
    const newRents = [...rents];
    newRents[index] = value;
    setRents(newRents);

    const newTotalRents = newRents.reduce((acc, rent) => acc + rent, 0);
    setTotalRents(newTotalRents);

    const newTouched = [...touched];
    newTouched[index] = true;
    setTouched(newTouched);
  };

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });

  const allTouched = touched.every(t => t);

  const vacantUnitsHandler = (value: number) => {
    if (value > totalUnits) {
      setVacantUnits(value);
      setTotalUnits(value);
    } else {
      setVacantUnits(value);
    }
    setRents(Array(value).fill(initialRent));
  }

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.key === 'market-rent');
    setResponse(_response);

    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  useEffect(() => {
    if (response?.metadata) {
      const storedRents = response.metadata?.rentsAndUnitNumbers?.map((item: any) => item.rent) || [];
      const storedUnitNumbers = response.metadata?.rentsAndUnitNumbers?.map((item: any) => item.unitNumber) || [];
  
      if (storedRents.length > 0) {
        setRents(storedRents);
        setTotalRents(storedRents.reduce((acc: number, rent: number) => acc + rent, 0));
      }
  
      if (storedUnitNumbers.length > 0) {
        setUnitNumbers(storedUnitNumbers);
      }
    }
  }, [response, taskResponses]);
  
  
  useEffect(() => {
    setTotalRents(rents.reduce((acc, rent) => acc + rent, 0));
  }, [rents, totalUnits]);

  useEffect(() => {
    setRents(Array(vacantUnits).fill(initialRent));
    setTotalRents(initialRent * vacantUnits);
    setUnitNumbers(generateUnitNumbers(vacantUnits));
  }, [vacantUnits]);

  const handleUnitNumberChange = (index: number, value: number) => {
    const newUnitNumbers = [...unitNumbers];
    newUnitNumbers[index] = Number(value) || 0;
    setUnitNumbers(newUnitNumbers)
  }

  const handleMarketRentsSubmit = () => {
    const rentsAndUnitNumbers = rents.map((rent, index) => ({
      rent: rent,
      unitNumber: unitNumbers[index]
    }));
    saveResponse(
      'market-rent',
      'Market rent',
      {
        totalUnits: totalUnits,
        vacantUnits: vacantUnits,
        rentsAndUnitNumbers: rentsAndUnitNumbers
      },
      TaskState.Review
    )
    getTaskResponses({ requestPolicy: 'network-only' });
  }

  return (
    <blockquote className={`CardMarketRent ${response?.state === TaskState.Review ? 'CardMarketRent-review' : ''}`}>
      {response?.state === TaskState.Review && (
        <StateTag 
          state={response?.state}
          colorState={response?.state}
          isLabelLocked
          margin='0 0 10px 0'
        />
      )}
      <InputAmount
          title="How many units are vacant?"
          type="edit"
          text={null}
          value={vacantUnits}
          onChange={vacantUnitsHandler}
          className="InputAmount-edit"
          disabled={response?.state === 'REVIEW'}
        />
      <article className="flex-btw">
        <div>
          <p className="body-card">
            Enter market rents for each of the vacant units
          </p>
          <h3 className="h4" style={{ marginTop: '15px'}}>Market rent for vacant units</h3>
        </div>
        <p className="h4 h4-w4" style={{ marginTop: '33px', color: '#404859'}}>
          Total <span className='bold' style={{ color: '#929FB0' }}>
            {formatCurrency(totalRents)}
            </span>
        </p>
      </article>
      <article style={{ display: 'block'}}>
        {Array.from({ length: vacantUnits }).map((_, index) => (
          <div key={index}>
            <span className="body-regular" style={{marginRight: '4px'}}>Unit number</span>
            <UnitNumberInput disabled={response?.state === 'REVIEW'} value={unitNumbers[index]} onChange={(value) => handleUnitNumberChange(index, value)} />
            <CurrencyInput disabled={response?.state === 'REVIEW'} value={rents[index]} onChange={(value) => handleRentChange(index, value)} />
            <span className='month'>/month</span>
          </div>
        ))}
      </article>
      <Button 
        id={"rent-button"}
        className={`button ${allTouched ? 'button-submit' : 'button-grey'}`}
        disabled={!allTouched || response?.state === 'REVIEW'}
        onClick={handleMarketRentsSubmit}>
        Add market rent for {vacantUnits} units to submit
      </Button>
    </blockquote>
  );
};

interface CurrencyInputProps {
  value: number;
  onChange: (value: number) => void;
  className?: string;
  moveCursorToEnd?: boolean;
  disabled?: boolean;
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(value);
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange, className, moveCursorToEnd = true, disabled = false }) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [edited, setEdited] = useState(false);
  const setCursorToEnd = (element: HTMLSpanElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerText = formatCurrency(value);
      if(edited){
        spanRef.current.className = 'bold';
      }
    }
  }, [value, edited]);

  const handleInput = (event: React.FormEvent<HTMLSpanElement>) => {
    if (disabled) return;
    const newValue = parseInt(event.currentTarget.innerText.replace(/[^0-9]/g, ''), 10) || 0;
    onChange(newValue);
    setEdited(true);
    event.stopPropagation();
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerText = formatCurrency(value);
      if (moveCursorToEnd && document.activeElement === spanRef.current) {
        setCursorToEnd(spanRef.current);
      }
    }
  }, [value]);

  const classNameFactory = (className: string) => {
    return `${className} body-regular body-regular-italic`;
  }

  return (
    <span
      contentEditable={!disabled}
      suppressContentEditableWarning
      onInput={handleInput}
      className={classNameFactory(className || '')}
      style={{ color: disabled ? '#2A2F3C' :'#929fb0', fontWeight: disabled ? '600' : '400' }}
      ref={spanRef}
    >
      {formatCurrency(value)}
    </span>
  );
};

export const UnitNumberInput: React.FC<{ value: number; onChange: (value: number) => void; disabled?: boolean }> = ({ value, onChange, disabled = false }) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [edited, setEdited] = useState(false);

  const setCursorToEnd = (element: HTMLSpanElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleInput = (event: React.FormEvent<HTMLSpanElement>) => {
    if (disabled) return;
    const newValue = parseInt(event.currentTarget.innerText.replace(/[^0-9]/g, ''), 10) || 0;
    onChange(newValue);
    setEdited(true);
    event.stopPropagation();
  };

  useEffect(() => {
    if (spanRef.current && value !== undefined) {
      spanRef.current.innerText = value.toString();
      if (edited) {
        spanRef.current.classList.add('bold');
      } else {
        spanRef.current.classList.remove('bold');
      }

      if (document.activeElement === spanRef.current) {
        setCursorToEnd(spanRef.current);
      }
    }
  }, [value, edited]);

  return (
    <span
      contentEditable={!disabled}
      suppressContentEditableWarning
      onInput={handleInput}
      ref={spanRef}
      style={{ color: disabled ? '#2A2F3C' :'#929fb0', fontWeight: disabled ? '600' : '400', marginRight: '18px', width: '32px' }}
    >
      {value}
    </span>
  );
};

export default CardMarketRent;
