import React, { useContext, useEffect } from 'react';
import { Button } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import iconErrorSvg from 'assets/images/error-red.svg';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import { CreateTaskResponseContext } from 'scenes/Private/Dashboard/Dashboard';

interface Props {
	params: any;
	close: any;
}

const SectionRight = ({ params, close }: Props) => {

	const { value: isCreateTaskResponse }: any = useContext(CreateTaskResponseContext);

	useEffect(() => {
		if (isCreateTaskResponse === 'success')
			close();
	}, [isCreateTaskResponse])

	const taskResponse = params?.comment?.taskResponse;

	const textDataError = params?.comment?.metadata?.dataOrDocumentSelected?.includes('Text/data') && taskResponse.metadata;
	
	const issueDocuments = taskResponse?.files?.filter((item: any) =>
		params.comment.metadata.dataOrDocumentSelected.includes(item.name)
	)

	const firstDocumentName = issueDocuments[0]?.name

	const scrollToError = () => {
		const section: any = document.getElementById(taskResponse?._id);
		if (section) section.scrollIntoView({ behavior: 'smooth' });
	}

	return (
		<div className='ModalTask-content-right'>

			<Button type="link" className="button-close" onClick={close}>
				X
			</Button>

			{params?.titleAddText &&
				<h3 className="h3">{
					params?.titleAddText + ' ' + (params.isTitleDocument ? firstDocumentName : params.comment.title)}</h3>
			}

			{!params?.titleAddText &&
				<h3 className="h3">{params.comment.title}</h3>
			}

			<Button className="button-submit button-submit-red-dark mt-10" onClick={scrollToError}>
				Scroll to issue
			</Button>

			{params?.noteErrorDetail &&
				<div className='mt-30'>{params?.noteErrorDetail}</div>
			}

			<div className='mt-20 rejected-information'>
				<b className='bold'>Rejected Information</b>

				{(issueDocuments.length > 0 || textDataError) &&
					<span className='reject-documents-label'>
						{textDataError && params.componentDataError && 'Data'}
						{textDataError && params.componentDataError && issueDocuments?.length > 0 && ', '}
						{issueDocuments?.length > 0 && (`${issueDocuments?.length} document`)}
					</span>
				}

				{textDataError && params.componentDataError &&
					<div className="content-text-data">
						<div className="circle-input">
							<img src={iconErrorSvg} alt="upload" className="upload" width={18} />
						</div>
						{params.componentDataError(textDataError)}
					</div>
				}

				<div className="content-files">
					{issueDocuments?.map((file: any, index: number) => (
						<InputUploadedFile
							key={index}
							file={file}
							isActionSeeFile={true}
							state={file.state}
						/>
					))}
				</div>
			</div>

			<b className='type bold'>{params.comment.rejectionCode?.label}.</b>

			{/*<div className='mt-10'>
				<p className="body-regular">
					<span className="bold">{params?.comment?.subtitle}{'. '}</span>
					{params?.comment?.description}
				</p>
		</div>*/}

			{params.comment?.markdown &&
				<div className='mt-5'>
					<ReactQuill
						value={params.comment?.markdown}
						readOnly={true}
						theme={"bubble"}
						style={{ height: 'auto' }}
					/>
				</div>
			}
		</div>
	);
};

export default SectionRight;
