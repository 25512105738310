import React, { useState } from 'react';
import { TaskState } from '../../../../../gql/graphql';

interface Props {
  ownerships?: any[];
  ownershipsUserCreator?: any[];
  newPercentageLiveEdit?: any;
  ownerPercentageLiveEdit?: any;
}
const useGuarantorUtil = ({ ownerships, ownershipsUserCreator, newPercentageLiveEdit, ownerPercentageLiveEdit }: Props) => {

  const getGuarantorsNameElements = () => {
    return ownerships?.filter((item: any) => item.ownership >= 20)
      .map((item: any, index: number, array: any) => {
        if (index === array.length - 1) {
          return (
            <label key={index}>
              {index > 0 && <b style={{ fontWeight: 800 }}>{array.length >= 3 ? ',' : ''} and </b>}
              <span
                className={`owner-${item.state}`}
                style={{ fontWeight: 800 }}
              >
                {`${item.name} ${item.lastname}`}
              </span>
            </label>
          );
        }
        return (
          <label
            key={index}
            className={`owner-${item.state}`}
            style={{ fontWeight: 800 }}
          >
            {item.name} {item.lastname}
            {index === array.length - 2 ? '' : ', '}
          </label>
        );
      });
  };

  const getGuarantorsByEntity = (entityId: string) =>
    ownerships?.filter((item: any) => item.stakes[entityId] >= 20)
      .map((item: any, index: number, array: any) => {
        if (index === array.length - 1 && array.length > 1)
          return `${array.length >= 3 ? ',' : ''}and ${item.name}`;

        return item.name;
      })
      .filter((item: any) => !!item)
      .join(', ')
      .replace(/,([^,]*)$/, '$1');

  const getGuarantorsNames = () =>
    ownerships?.map((item: any, index: number, array: any) => {
      if (index === array.length - 1 && array.length > 1)
        return `${array.length >= 3 ? ',' : ''}and ${item.name} ${item.lastname}`;

      return item.name + ' ' + item.lastname;
    })
      .filter((item: any) => !!item)
      .join(', ')
      .replace(/,([^,]*)$/, '$1');

  const getGuarantorsName = () =>
    ownerships?.filter((item: any) => item.ownership >= 20)
      .map((item: any, index: number, array: any) => {
        if (index === array.length - 1 && array.length > 1)
          return `${array.length >= 3 ? ',' : ''}and ${item.name}`;

        return item.name;
      })
      .filter((item: any) => !!item)
      .join(', ')
      .replace(/,([^,]*)$/, '$1');

  const getGuarantorsCount = ownerships?.filter((item: any) => item.ownership >= 20).length;
  
  const getTotal = () => ownerships?.filter(item => 
    !ownerPercentageLiveEdit ||
    !ownerPercentageLiveEdit.ownerId ||
    (item._id !== ownerPercentageLiveEdit?.ownerId && 
    item.ownerId !== ownerPercentageLiveEdit?.ownerId)
  )
    .reduce(
      (total: number, ownership: any) => total + (ownership.ownership || 0),
      0
    ) + (newPercentageLiveEdit?.value || 0) + (ownerPercentageLiveEdit?.value || 0);
    //newPercentageLiveEdit porcentaje que se edita al crear un owner desde 0
    //ownerPercentageLiveEdit porcentaje que se edita en un owner existente

  const getSomeoneNews = (isNewOwner: boolean) => {
    const ownershipsCache = ownershipsUserCreator?.filter((owner: any) =>
      !ownerships?.find((item: any) =>
        item._id === owner._id || item.taskResponse.metadata.ownerId === owner._id
      )
    );

    const persons: any = ownershipsCache?.map((item: any) => ({
      key: item._id,
      label: `${item.name} ${item.lastname}`
    }));
    if (isNewOwner) return persons;

    if (persons)
      return [{ key: 'new', label: 'someone new' }, ...persons];

    return [{ key: 'new', label: 'someone new' }];
  };

  const selectOwnersCache = (key: string, setNewOwner: any, createOwnership: any) => {
    if (key === 'new') setNewOwner({});
    else {
      const owner = ownershipsUserCreator?.find((item) =>
        item._id === key || item.taskResponse?.metadata?.ownerId === key
      );

      const { __typename, stakes, ...payload } = owner;

      if (payload.files?.length > 0)
        payload.files = payload.files.map((item: any) => ({ ...item, state: TaskState.Draft }));

      createOwnership({
        ...payload,
        referenceId: owner?._id
      });
    }
  };

  return {
    getGuarantorsNameElements,
    getGuarantorsByEntity,
    getGuarantorsNames,
    getGuarantorsName,

    getGuarantorsCount,
    getTotal,

    getSomeoneNews,
    selectOwnersCache
  };

};
export default useGuarantorUtil;
