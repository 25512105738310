import React, { useEffect, useRef, useState } from 'react';
import InputAmount from 'components/Atoms/Inputs/InputAmount/InputAmount';
import { TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';

const CardTotalUnits = ({ totalUnits, setTotalUnits, vacantUnits, setVacantUnits, occupiedUnits, saveResponse, task }: any) => {

  const [response, setResponse] = useState<TaskResponse | any>();
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const { data: taskResponses, reexecute: getTaskResponses } =
      useGetTaskResponsesByTask({
        variables: { findResponsesByTask: { taskId: task._id } }
      });

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.key === 'lease-agreements');
    setResponse(_response);
    setCanEdit(!_response?.files);
  }, [taskResponses]);

  const totalUnitHandler = (value: number) => {
    if (value < vacantUnits) {
      setTotalUnits(value);
      setVacantUnits(value);
    } else {
      setTotalUnits(value);
    }
    saveResponse(
      'units-in-property-dscr-step3',
      'Units in property DSCR Step 3',
      {
        totalUnits: value,
      },
      TaskState.Approved
    )
  }

  return (
    <blockquote className="CardTotalUnits InputAmount-edit">
      <InputAmount
          title="How many units are in the property?"
          type="edit"
          text={null}
          value={totalUnits}
          onChange={totalUnitHandler}
          disabled={!canEdit}
        />
    </blockquote>
  );
};

export default CardTotalUnits;
