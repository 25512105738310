import { useContext } from "react";
import RightScenes from "../components/Menu/BarDashboard/RightScenes/RightScenes";
import { ProfileViewContext } from "../Private";
import CardProfileNotifications from "./components/CardProfileNotifications";
import CardProfile from "./components/CardProfile";
import CardAccountSecurity from "./components/CardAccountSecurity";
import CardEntitiesAndLoans from "./components/CardEntitiesAndLoans";
import { Button } from "antd";

const Profile = () => {

  const { visible, setHandlerVisible }: any = useContext(ProfileViewContext);

  return (
    <RightScenes
      visible={visible}
      handlerSetVisibile={setHandlerVisible}
    >
      <div className="view-profile">
        <div className='view-profile-header'>
          <h1 className='title'>Profile and Settings</h1>
        </div>
        <div className="flex-2-col">
          <div className="box">
            {/*<CardProfileNotifications />*/}
            <CardAccountSecurity />
            <CardEntitiesAndLoans />
          </div>
          <div className="box">
            <CardProfile />
            {/*<div className="flex-center">
              <Button className="button-r">
                BROKER
              </Button>
              <Button className="button-delete">
                if broker use this tag
              </Button>
            </div>*/}
          </div>
        </div>
      </div>
    </RightScenes>
  );
}

export default Profile;