import { useQuery } from 'urql';
import { GET_ENTITY_BY_LOAN, GET_ENTITIES_BY_OWNER, GET_DOCS_BY_ENTITY, GET_ENTITIES_LIST_BY_OWNER } from './graphql';
import {
  GetEntitiesByOwnerQuery,
  GetEntitiesListByOwnerQuery,
  GetEntityByLoanQuery,
  GetEntityByLoanQueryVariables,
  GetEntityDocsByEntityQuery,
  QueryGetEntitiesByOwnerArgs,
  QueryGetEntitiesListByOwnerArgs,
  QueryGetEntityByLoanArgs,
  QueryGetEntityDocsByEntityArgs,
} from 'gql/graphql';
import { UseQueryInterface } from '@common/utils/interfaces/use-query.interface';

export const useGetEntitiesByOwner = (vars?: UseQueryInterface<QueryGetEntitiesByOwnerArgs>) => {
  const [{ data, ...others }, reexecute] = useQuery<GetEntitiesByOwnerQuery>({
    query: vars?.query || GET_ENTITIES_BY_OWNER,
    ...vars
  });
  return {
    data: data?.GetEntitiesByOwner,
    reexecute,
    ...others
  };
};

export const useGetEntityByLoan = (
  vars: UseQueryInterface<QueryGetEntityByLoanArgs>
) => {
  const [{ data, ...others }, execute] = useQuery<GetEntityByLoanQuery>({
    query: vars?.query || GET_ENTITY_BY_LOAN,
    ...vars
  });
  return {
    data: data?.GetEntityByLoan,
    execute: (data?: GetEntityByLoanQueryVariables) =>
      execute({ GetTasksByLoan: data }),
    ...others
  };
};

export const useGetEntityDocsByEntity = (
  vars: UseQueryInterface<QueryGetEntityDocsByEntityArgs>
) => {
  const [{ data, ...others }, reexecute] = useQuery<GetEntityDocsByEntityQuery>({
    query: vars?.query || GET_DOCS_BY_ENTITY,
    ...vars
  });
  return {
    data: data?.GetEntityDocsByEntity,
    reexecute,
    ...others
  };
};

export const useGetEntitiesListByOwner = (vars?: UseQueryInterface<QueryGetEntitiesListByOwnerArgs>) => {
  const [{ data, ...others }, reexecute] = useQuery<GetEntitiesListByOwnerQuery>({
    query: vars?.query || GET_ENTITIES_LIST_BY_OWNER,
    ...vars
  });
  return {
    data: data?.GetEntitiesListByOwner,
    reexecute,
    ...others
  };
};