import { gql } from "urql";

export const MUTATION_UPDATE_USER: any = gql(`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
        UpdateUser(updateUserInput: $updateUserInput) {
            _id
        }
    }
`)

export const MUTATION_DELETE_ACCOUNT: any = gql(`
    mutation DeleteAccount {
        DeleteAccount {
            _id
        }
    }
`)