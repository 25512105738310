import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import ChangePassword from './ChangePassword/ChangePassword';
import NotFound from 'scenes/404';

const Public = () => {
  return (
    <div className="Public">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-password/:token" element={<ChangePassword />} />
      </Routes>
    </div>
  );
};
export default Public;
