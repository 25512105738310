import React, { useContext, useEffect, useState } from 'react';
import { Button, Collapse } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useGetTaskResponseComments } from 'services/task/querys';
import { ModalTaskRightContext } from 'components/Organisms/Modals/ModalTask/ModalTask';
import { formatterCurrency } from '@common/utils/formatters/formatter';

interface Props {
	taskId: string;
	successBalance?: number | any;
	limitBalance?: number | any;
	rejectionCode?: string;
	noteErrorDetail?: React.ReactElement;
	styleFixed?: boolean;
}

const CollapseIssues = ({
	taskId,
	successBalance,
	limitBalance,
	rejectionCode,
	noteErrorDetail,
	styleFixed
}: Props) => {
	const { value, setHandler }: any = useContext(ModalTaskRightContext);
	const { data: taskComments } = useGetTaskResponseComments({ 
		variables: {
			taskId, 
			rejectionCode 
		}
	});

	useEffect(() => {
		if (
			taskComments && taskComments.length > 0 &&
			(successBalance >= limitBalance || rejectionCode)
		) {
			document.querySelector('.modal-container')?.classList.add('space-bottom-close');
			document.querySelector('.ant-collapse')?.classList.add('ant-collapse-close');
		}
	}, [taskComments, successBalance])

	const setCollapse = (open: boolean) => {
		setTimeout(() => {
			document.querySelector('.modal-container')?.classList.add(`space-bottom-${open ? 'open' : 'close'}`);
			document.querySelector('.modal-container')?.classList.remove(`space-bottom-${!open ? 'open' : 'close'}`);
			document.querySelector('.ant-collapse')?.classList.add(`${open ? 'ant-collapse-open' : 'ant-collapse-close'}`);
			document.querySelector('.ant-collapse')?.classList.remove(`${!open ? 'ant-collapse-open' : 'ant-collapse-close'}`);
		}, open ? 0 : 200)
	}
	
	if (taskComments && taskComments?.length > 0)
		return (
			<>
				<div style={{ marginBottom: 30 }}></div>
				<div 
					className={`collapse-issues collapse-issues-${styleFixed? 'fixed': 'absolute'}`} 
					style={{ paddingRight: value?.show ? '0' : '5%' }}
				>
					<Collapse
						ghost
						expandIconPosition="right"
						onChange={(v) => setCollapse(v.includes('1'))}
						expandIcon={(collapse) => (
							<>
								<span>Show issues</span>
								{!collapse.isActive ? <DownOutlined /> : <UpOutlined />}
							</>
						)
						}
						items={[
							{
								key: '1',
								label: `${taskComments?.length} issues that dont't require resolution`,
								children: <>
									{taskComments?.map(issue => (
										<div className="card-issues" key={issue._id}>
											<div className="flex-btw">
												<h4 className="body-regular body-regular-title">{issue.title}</h4>
											</div>
											<div className='description'>
												<p className="body-regular">
													<span className="bold">{issue.rejectionCode.label}{'. '}</span>
													{issue.description}
												</p>
											</div>
											<div className="flex-end">
												<Button
													type="link"
													className="button button-transparent"
													style={{ width: 90 }}
													onClick={() =>
														setHandler(true, { 
															comment: issue, 
															isTitleIssues: !!noteErrorDetail,
															noteErrorDetail: issue?.metadata?.rejectedAmount?
																<h4 className="text-error-note">
																	<span>Rejected amount:</span>{' '}
																	{formatterCurrency(issue?.metadata?.rejectedAmount || 0)}
																</h4>
																:
																null
														})
													}
												>
													<span style={{ textDecoration: 'underline' }}>
														See details
													</span>
												</Button>
											</div>
										</div>
									))}
								</>
							}]}
					/>
				</div>
			</>
		);
		
	else return null;
};
export default CollapseIssues;