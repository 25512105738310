import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button } from 'antd';
import { Tooltip } from 'react-tooltip';

import RightScenes from '../components/Menu/BarDashboard/RightScenes/RightScenes';
import { LoansViewContext, TaskOpenModalContext } from '../Private';
import searchIcon from 'assets/images/search.svg'
import { Loan, Task, TaskState } from '../../../gql/graphql';
import { TOOLTIP_STATES } from '../../../@common/utils/constants/tooltip-states.constant';
import { PhasesLabel } from '../Dashboard/LoanPhases.constanst';
import DropdownBlue from '../../../components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import { useGetLoansByOwner } from '../../../services/loan/querys';
import StateTag from '../../../components/Atoms/Tags/StateTag/StateTag';
import { Progress } from '../../../components/Atoms/Progress/Progress/Progress';
import TabsFilters from './TabsFilters';
import { ModuleKeys } from '../../../services/task/modules-keys.constanst';
import { RulesTasksCards } from '../Dashboard/RulesTaskCards.constanst';
import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';

const Loans = () => {
  const navigate = useNavigate();
  const { setHandler: setTaskOpenModal }: any = useContext(TaskOpenModalContext);
  const { visible, setHandlerVisible }: any = useContext(LoansViewContext);

  const itemsPerPage = 2;
  const [startIndex, setStartIndex] = useState(0);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const { data: loans, reexecute: getLoans } = useGetLoansByOwner({
    variables: { search: searchTerm }
  });

  useEffect(() => {
    getLoans({ requestPolicy: 'network-only' })
  }, [])

  const endIndex = startIndex + itemsPerPage;

  const handleNext = () => {
    if (loans && endIndex < loans.length) {
      setStartIndex(startIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - itemsPerPage);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  const getPhaseNow = (progress: any) => {
    if (!progress) return 'APPROVAL';

    const phaseStarted = progress.findLast((item: any) => item.state !== 'not-started')
    return phaseStarted ? PhasesLabel[phaseStarted.key] : 'APPROVAL';
  }

  const getProgress = (loan: Loan): any[] => {
    if (!loan.progress)
      return [];

    return loan.progress.map(item => {

      if (item.state === 'not-started')
        return { color: 'empty' };
      else if (item.state === 'started')
        return { color: 'black' };
      else if (item.state === 'complete')
        return { color: 'success' };
      else if (item.state === 'review')
        return { color: 'review' };
      else if (item.state === 'error')
        return { color: 'error' };
    })
  }

  const getStateLoan = (loan: Loan) => {
    if (loan.progress?.find(item => item.state === 'error'))
      return { key: 'error', label: 'NEEDS ATTENTION' }
    return { key: 'incomplete', label: 'IN PROGRESS' }
  }

  const countTaskApproved = (tasks: Task[]) =>
    tasks.filter(task => task.state === TaskState.Approved)?.length

  const redirectLoan = (loan: Loan) => {
    navigate(`/home/${loan._id}`);
    navigate(0);
    setHandlerVisible(false);
  }

  const redirectTask = (task: Task) => {
    setTaskOpenModal(task.key || task._id);
    setHandlerVisible(false);
  }

  const getTasksLast = (tasks: Task[]) => {
    for (const task of tasks) {
      if (!task.phase) {
        const rule = RulesTasksCards.find(item => item.key === task.key);
        task.phase = rule?.phase[0];
      }
    }

    const groupedByPhase: any[] = tasks.reduce((acc: any, item: any) => {
      if (!acc[item.phase]) acc[item.phase] = [];
      acc[item.phase].push(item);
      return acc;
    }, {});

    let phaseLastKey, taskLast = [];
    for (const phaseKey of Object.keys(groupedByPhase)) {
      const isInitPhase = groupedByPhase[phaseKey as any].find((task: Task) => task.state !== TaskState.Draft);
      if (isInitPhase) {
        phaseLastKey = phaseKey;
        taskLast = groupedByPhase[phaseKey as any];
      }
    }

    const PhasesLabel: any = {
      'pre-approval': 'Pre Approval',
      'approval': 'Approval Docs and Term Sheet',
      'due-diligence': 'Property evaluation and due diligence',
      'final': 'Final',
      'closing': 'Closing'
    }
    
    return {
      phaseLast: {
        key: phaseLastKey,
        label: PhasesLabel[phaseLastKey as any]
      },
      taskLast
    }
  }

  return (
    <RightScenes
      visible={visible}
      handlerSetVisibile={setHandlerVisible}
      elementAbsolute={
        <div className='button-actions-pagination'>
          {loans && loans.length > 2 &&
            <>
              <Button onClick={handleNext} disabled={loans && endIndex >= loans.length}>
                <RightOutlined />
              </Button>
              <Button onClick={handlePrev} disabled={startIndex === 0}>
                <LeftOutlined />
              </Button>
            </>
          }
        </div>
      }
    >
      <div className='view-loans'>
        <div className='view-loans-header'>
          <h1 className='title'>
            <DropdownBlue
              items={[{ key: 'active', label: 'Active' }, { key: 'all', label: 'All' }]}
              onClickSelect={() => { }}
              label={'Active'}
            />
            <span>loans</span>
          </h1>
          <div className='search-box'>
            <img src={searchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search by entity or loan address"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className='view-loans-filters'>
          <TabsFilters loans={loans} filter={filter} handlerChange={setFilter} />
        </div>

        <div className='view-loans-content'>
          {loans?.slice(startIndex, endIndex).map((loan: any, index: number) => {
            const { phaseLast, taskLast } = getTasksLast(loan.tasks);
            const stateLoan = getStateLoan(loan);

            if (filter === 'all' || filter === stateLoan.key)
              return (
                <div key={index} className={`card-loan card-loan-${stateLoan.key}`}>
                  <div className='card-loan-pre-header'>
                    <StateTag isOtherState state={stateLoan.label} colorState={stateLoan.key === 'error' ? TaskState.RequestError : TaskState.Incomplete} />
                    <div className='card-loan-pre-header-description'>
                      <p>
                        <b>{getPhaseNow(loan.progress)} |</b> Est. Closing: <b>{moment(loan?.onboarding?.estimateClosingDate).format('DD/MM/YY')}</b>
                      </p>
                    </div>
                  </div>
                  <div className='card-loan-header'>
                    <h3>{loan.name}</h3>
                    <div className='card-loan-header-description'>
                      <p>{loan.type} | {loan.purpose} | {loan.ltv}% LTV</p>
                    </div>
                  </div>
                  <div className='card-loan-content'>
                    <div className='card-loan-content-documents'>
                      <div className='card-loan-content-resume'>
                        <label>{phaseLast.label} Task Completion</label>
                        <span>{countTaskApproved(taskLast)}/{taskLast.length} incomplete tasks</span>
                      </div>
                      <div className='doc-states'>
                        <div className={`circles-tags`}>
                          {taskLast.map((task: Task, index: number) => (
                            <div key={index}>
                              <div
                                data-tooltip-id={`circle-state-${task._id}`}
                                className={`circle circle-${task.state} cursor`}
                              />
                              <Tooltip
                                id={`circle-state-${task._id}`}
                                openOnClick
                                clickable
                                className={`circle-state-tooltip circle-state-tooltip-${task.state}`}
                              >
                                <div>
                                  <b>{TOOLTIP_STATES[task.state]}</b>
                                  <br />
                                  <span>{task.name}</span>

                                  {task.key !== ModuleKeys.PROPERTY_ACCESS &&
                                    <>
                                      <br /><br />
                                      <b style={{ cursor: 'pointer' }} onClick={() => redirectTask(task)}>
                                        Go to task &#8594;
                                      </b>
                                    </>
                                  }
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='card-loan-content-documents'>
                      <div className='card-loan-content-resume'>
                        <label>Loan progress</label>
                        <Progress bars={[{ color: 'success' }, ...getProgress(loan)]} />
                      </div>
                    </div>
                  </div>
                  <Button className="button button-link-border card-loan-button" onClick={() => redirectLoan(loan)}>
                    Continue loan
                  </Button>
                </div>
              )
          })}

        </div>
      </div>
    </RightScenes>
  );
};

export default Loans;
