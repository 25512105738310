import { Button } from "antd";
import { Loan } from "gql/graphql";
import React, { useState } from "react";

interface Props {
  loans: Loan[] | any,
  filter: string,
  handlerChange: any,
}

const TabsFilters = ({ loans, filter, handlerChange }: Props) => {
  
  const getTotal = (loanState: string) =>
    loans?.filter((loan: Loan) => 
      loan?.progress?.find(progress => progress.state === loanState)
    )?.length || 0;

  const dummyTabsFilters = [
    { id: 1, name: "All", key: "all", amount: loans?.length || 0 },
    { id: 2, name: "Needs Attention", key: "error", amount: getTotal('error') },
  ];

  return (
    <section className="ButtonTabsFilters">
      {dummyTabsFilters?.map((tab) => (
        <Button
          onClick={() => handlerChange(tab.key)}
          className={`tab ${filter === tab.key && "tab-active"}`}
          key={tab.key}>
          {tab.name} <span className="number">{tab.amount}</span>
        </Button>
      ))}
    </section>
  );
};
export default TabsFilters;
