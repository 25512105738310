import React, { createContext, lazy, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import './LoanDetails.scss';
import { formatterCurrency } from '@common/utils/formatters/formatter';
import { LoanOption } from './components/LoanOption/LoanOption';
import { Button } from 'antd';
import { TaskOpenModalContext } from 'scenes/Private/Private';
import warningIcon from 'assets/images/warning-icon.svg';
import { useGetLoanDocByLoan } from 'services/loan/querys';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { TasksContext } from '../../Dashboard';
import { TaskState } from 'gql/graphql';

const ViewInfo = ({ url }: { url: string | null }) => {
  if (!url) return null
  return (<span onClick={() => { window.open(url, '_blank'); }}>{"View info ->"}</span>)
};

const getContactField = (
  label: string, 
  data: any, 
  taskKey: string, 
  openTask: any, 
  tasks: any, 
  draftMessage?: string,
  showNoContact: boolean = false
) => {
  const task = tasks.find((item: any) => item.key === taskKey);
  
  if (data?.url) {
    return { field: label, value: <>{data.name} <ViewInfo url={data.url} /></> };
  }

  if ((task?.state === TaskState.Draft || task?.state === TaskState.Incomplete) && draftMessage) {
    return { field: label, value: <span onClick={() => openTask(taskKey)}>{draftMessage}</span> };
  }

  if (task?.state === TaskState.RequestError) {
    return { field: label, value: <span onClick={() => openTask(taskKey)}>{"In error ->"}</span> };
  }

  if (task?.state === TaskState.Review) {
    return { field: label, value: <span onClick={() => openTask(taskKey)}>{`In ${task.state.toLowerCase()} ->`}</span> };
  }

  if (task?.state === TaskState.Approved) {
    return { field: label, value: <span onClick={() => openTask(taskKey)}>{"Approved ->"}</span> };
  }

  if (showNoContact || task?.state === TaskState.Draft) {
    return { field: label, value: <span onClick={() => openTask(taskKey)}>{"No contact set ->"}</span> };
  }

  return { field: label, value: <span onClick={() => openTask(taskKey)}>{"No contact set ->"}</span> };
};

const contactData = (loanSelected: any, openTask: any, tasks: any) => {
  const propertyTask = tasks.find((item: any) => item.key === 'property_access');

  return {
    label: 'Partners and contacts',
    content: [
      getContactField('Entity', loanSelected?.salesforceData?.entity, 'entity_guarantors', openTask, tasks, "Complete entity info ->"),
      getContactField('Guarantors', loanSelected?.salesforceData?.guarantors?.[0], 'entity_guarantors', openTask, tasks, "Complete guarantor profiles ->"),
      {
        field: 'Main loan contact',
        value: loanSelected?.salesforceData?.mainLoanContact?.url
          ? <>{loanSelected.salesforceData.mainLoanContact.name} <ViewInfo url={loanSelected.salesforceData.mainLoanContact.url} /></>
          : propertyTask?.metadata?.name
            ? <>{propertyTask.metadata.name}</>
            : <>{loanSelected?.owner?.name || "No contact set ->"}</>,
      },
      getContactField('Title agent', loanSelected?.salesforceData?.titleAgent, 'title_company', openTask, tasks),
      getContactField('Insurance agent', loanSelected?.salesforceData?.insuranceAgent, 'insurance', openTask, tasks, undefined, true),
      ...(loanSelected.purpose === "Refinance"
        ? [getContactField('Payoff contact', loanSelected?.salesforceData?.payoffContact, 'payoff', openTask, tasks, undefined, true)]
        : []
      ),
    ],
  };
};



const contentBoxes = (loanSelected: any, openTask: any) => {
  const originationFeePercent = loanSelected?.salesforceData?.fees?.originationFeePercent || 0.02;
  const data = {
    /* Loan Details */
    purpose: loanSelected?.purpose,
    type: loanSelected?.type,
    amortization: loanSelected?.type === 'Rental' ? '30 year' : 'Interest Only',
    lien: '1st Position',

    /* Property Details */
    propertyType: loanSelected?.loanData? loanSelected?.loanData['What kind of property is it?']: '',
    unitCount: 0,

    /* Budget And Valuation */
    purchasePrice: formatterCurrency(loanSelected?.salesforceData?.budget?.purchasePrice || loanSelected?.loanData?.price),

    asIsValue: formatterCurrency(loanSelected?.salesforceData?.budget?.asIsValue || loanSelected?.loanData?.price),
    appraiserAsIsValue: loanSelected?.salesforceData?.budget?.appraiserAIV ? formatterCurrency(loanSelected?.salesforceData?.budget?.appraiserAIV) : 'Awaiting appraisal',
    currentLoanBalance: formatterCurrency(loanSelected?.salesforceData?.budget?.currentLoanBalance || loanSelected?.loanData?.currentLoanBalance),

    estimatedARV: formatterCurrency(loanSelected?.salesforceData?.budget?.estimatedARV || loanSelected?.loanData?.afterRepairValue),
    appraiserARV: loanSelected?.salesforceData?.budget?.appraiserARV ? formatterCurrency(loanSelected?.salesforceData?.budget?.appraiserARV) : 'Awaiting appraisal',
    constructionReserve: formatterCurrency(loanSelected?.salesforceData?.budget?.constructionReserve || loanSelected?.loanData?.constructionBudget),
    rehabBudget: formatterCurrency(loanSelected?.salesforceData?.budget?.rehabBudget || loanSelected?.loanData?.constructionBudget),
    loanToCost: `${((loanSelected?.loanData?.selectedOption?.ltc) * 100).toFixed(2)}%`,
    advanceAmount: formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount - (loanSelected?.loanData?.constructionBudget ?? 0)),

    /* Rental */
    monthlyRent: formatterCurrency(loanSelected?.salesforceData?.budget?.monthlyRent || loanSelected?.loanData?.monthlyRent),
    annualTaxes: formatterCurrency(loanSelected?.salesforceData?.budget?.annualTaxes || loanSelected?.loanData?.annualTaxes),
    annualInsurance: formatterCurrency(loanSelected?.salesforceData?.budget?.annualInsurance || loanSelected?.loanData?.annualInsurance),
    annualHoa: formatterCurrency(loanSelected?.salesforceData?.budget?.annualHoa || loanSelected?.loanData?.annualHoa),
    dscr: loanSelected?.salesforceData?.budget?.dscr || loanSelected?.loanData?.selectedOption?.dscr,

    /* Fees */
    originationFee: `${formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount * (originationFeePercent))} (${(originationFeePercent * 100).toFixed(2)}%)`,
    processingFee: formatterCurrency(loanSelected?.salesforceData?.fees?.processingFee || 0),
    proRatedInterest: formatterCurrency(loanSelected?.salesforceData?.fees?.proRatedInterest || 0),
    thirdPartyCosts: "Contact closing agent",
    prepay: loanSelected?.salesforceData?.fees?.prepay || "3 Months",
    assignmentFee: formatterCurrency(loanSelected?.salesforceData?.fees?.assignmentFee || 0),
    floodCertificationFee: formatterCurrency(loanSelected?.salesforceData?.fees?.floodCertificationFee || 0),
    creditAndBackgroundCheckFee: formatterCurrency(loanSelected?.salesforceData?.fees?.creditAndBackgroundCheckFee || 0),
    miscFees: formatterCurrency(loanSelected?.salesforceData?.fees?.miscFees || 0),
    overnightDeliveryFee: formatterCurrency(loanSelected?.salesforceData?.fees?.overnightDeliveryFee || 0),
    lenderLegalFee: formatterCurrency(loanSelected?.salesforceData?.fees?.lenderLegalFee || 0),
    insurancePremiumToBeCollected: formatterCurrency(loanSelected?.salesforceData?.fees?.insurancePremiumToBeCollected || 0),
    totalLoanFees: formatterCurrency(loanSelected?.salesforceData?.fees?.totalLoanFees || 0),
  }

  return ([
    {
      label: 'Loan Details',
      content: [
        { field: 'Loan Purpose', value: data.purpose },
        { field: 'Loan Type', value: data.type },
        { field: 'Amortization', value: data.amortization },
        { field: 'Lien', value: data.lien },
      ],
    },
    {
      label: 'Property Details',
      content: [
        { field: 'Property Type', value: data.propertyType },
        ...(
          data.type === "Rental" ? 
          [
            { field: 'Unit count', value: <React.Fragment>{data.unitCount} <span>{"View unit info ->"}</span></React.Fragment> },
          ] : []
        )
      ],
    },
    {
      label: 'Budget and valuation',
      content: [
        ...(
          data.purpose === "Purchase" ?
            [
              { field: 'Purchase Price', value: data.purchasePrice },
            ]
            :
            [
              { field: 'As-is-value', value: data.asIsValue, extra: { field: 'Appraiser’s as-is-value', value: data.appraiserAsIsValue } },
              { field: 'Current Loan Balance', value: data.currentLoanBalance },
            ]
        ),
        ...(
          data.type === "Fix and Flip" ?
            [
              { field: 'Estimated ARV', value: data.estimatedARV, extra: { field: 'Appraiser’s ARV', value: data.appraiserARV } },
              { field: 'Construction reserve', value: data.constructionReserve },
              { field: 'Rehab Budget', value: data.rehabBudget },
            ]
            :
            []
        ),
        ...(
          data.type === "Fix and Flip" ?
            [
              { field: 'Loan-to-cost', value: data.loanToCost },
              { field: 'Advance amount', value: data.advanceAmount },
            ]
            :
            []
        ),
        ...(
          data.type === "Rental" ?
            [
              { field: 'Monthly Rent', value: data.monthlyRent },
              { field: 'Annual Taxes', value: data.annualTaxes },
              { field: 'Annual Insurance', value: data.annualInsurance },
              { field: 'Annual HOA', value: data.annualHoa },
              { field: 'DSCR', value: data.dscr },
            ]
            :
            []
        )
      ],
    },
    {
      label: 'Fees',
      content: [
        { field: 'Origination fee', value: data.originationFee },
        { field: 'Processing fee', value: data.processingFee },
        { field: 'Pro-rated interest', value: data.proRatedInterest },
        { field: 'Third-party costs', value: data.thirdPartyCosts },
        { field: 'Prepay', value: data.prepay },
        { field: 'Assignment fee', value: data.assignmentFee },
        { field: 'Flood certification fee', value: data.floodCertificationFee },
        { field: 'Credit and background check fee', value: data.creditAndBackgroundCheckFee },
        { field: 'Misc fees', value: data.miscFees },
        { field: 'Overnight delivery fee', value: data.overnightDeliveryFee },
        { field: 'Lender legal fee', value: data.lenderLegalFee },
        { field: 'Insurance premium to be collected', value: data.insurancePremiumToBeCollected },
        { field: 'Total loan fees', value: data.totalLoanFees },
      ],
    },
  ])
}

const additionalContentBoxes = (loanSelected: any, anotherDocs: any, openTask: any) => {
  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loanSelected._id } });

  const termSheet = loanDocs?.find((doc: any) => doc.key === 'term_sheet' && !doc.hidden);
  const appraisal = loanDocs?.find((doc: any) => doc.key === 'appraisal' && !doc.hidden);
  const showTermSheet = !termSheet || (!termSheet.isDeleted && !termSheet.hidden);
  const showAppraisal = !appraisal || (!appraisal.isDeleted && !appraisal.hidden);
  const otherDocuments = loanDocs?.filter((doc: any) => doc.key !== 'term_sheet' && doc.key !== 'appraisal' && !doc.hidden) ?? [];

  const loanDocumentsContent: Item[] = [
    ...(showTermSheet
      ? [{ field: 'Term sheet', value: <span onClick={() => window.open(loanSelected.onboarding.termSheetURL, '_blank')}>{"Download ->"}</span> }]
      : []),
    ...(showAppraisal
      ? [{ field: 'Appraisal', value: <span onClick={() => openTask('pay_appraisal')}>{"Pay appraisal ->"}</span> }]
      : []),
    ...otherDocuments.map((doc: any) => ({
      field: doc.name,
      value: <span onClick={() => window.open(doc?.file?.url, '_blank')}>{"Download ->"}</span>
    })),
    { field: 'Pre-approval letter', value: <span onClick={() => window.open(loanSelected.onboarding.preApprovalLetterUrl, '_blank')}>{"Download ->"}</span> },
    { field: 'Closing documents', value: <i>Available after closing</i> }
  ];

  const additionalDocumentsContent: Item[] = [
    { field: 'Construction Budget', value: <span onClick={() => openTask('construction')}>{"View task ->"}</span> },
    ...(loanSelected?.purpose === 'Purchase' ?
      [{ field: 'Purchase contract', value: <span onClick={() => openTask('purchase')}>{"View task ->"}</span> }]
      :
      []
    ),
    ...(loanSelected?.purpose === "Refinance" ?
      [{ field: 'Payoff letter', value: <span onClick={() => openTask('payoff')}>{"View task ->"}</span> }]
      :
      []
    ),
    { field: 'Insurance', value: <span onClick={() => openTask('insurance')}>{"View terms ->"}</span> },
    { field: 'Title', value: <span onClick={() => openTask('title_company')}>{"Download ->"}</span> },
  ];

  if(anotherDocs) {
    for (const anotherDoc of anotherDocs) {
      const file = anotherDoc.files[0];

      additionalDocumentsContent.push({
        field: anotherDoc.label,
        value: <span onClick={() => window.open(file.url, '_blank')}>{"View file ->"}</span>
      });
    }
  }
  
  if (loanSelected.loanData?.["What kind of property is it?"] === 'Portfolio') {
    return [
      {
        label: "Portfolio Addresses",
        content: <div className='portfolio'>{loanSelected?.salesforceData?.loanRecord?.Subitems__c?.split('\r\n').map((t: string, i: number) => <p key={`${t}_${i}`}>{t}</p>)}</div>
      },
      {
        label: 'Loan documents',
        content: loanDocumentsContent,
      },
      {
        label: 'Additional documents',
        content: additionalDocumentsContent,
      }
    ];
  } else {
    return [
      {
        label: 'Loan documents',
        content: loanDocumentsContent,
      },
      {
        label: 'Additional documents',
        content: additionalDocumentsContent,
      }
    ];
  }
}

interface ILoanDetailsProps {
  loanSelected: any;
  onClose: () => void;
}

const LoanDetails: React.FC<ILoanDetailsProps> = ({ loanSelected, onClose }) => {
  const tasks: any = useContext(TasksContext);

  const taskAddDocs = tasks.find((item: any) => item.key === 'add-docs');
  const { data: anotherDocs, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: {
      findResponsesByTask: { taskId: taskAddDocs?._id }
    },
    pause: true
  });
  const { setHandler: setTaskOpenModal }: any = useContext(TaskOpenModalContext);
  
  useEffect(() => {
    if(tasks.length > 0) 
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [tasks])

  if (!loanSelected)
    return null;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offset);

    const optionsMonthYear = { year: 'numeric', month: 'short' };
    // @ts-ignore
    const monthYear = date.toLocaleDateString('en-US', optionsMonthYear);

    const optionsDay = { day: 'numeric' };
    // @ts-ignore
    const day = date.toLocaleDateString('en-US', optionsDay);

    return { monthYear, day };
  };
  const closingDate = loanSelected?.salesforceData?.closingDate ? formatDate(loanSelected?.salesforceData?.closingDate) : null;
  const date = closingDate

  // @ts-ignore
  const momentDate = date ? moment(date) : date
  if (momentDate) {
    momentDate.add(1, 'month');
  }

  const closingScheduled = loanSelected?.salesforceData?.closingScheduled;

  return (
    <div className='loan-details'>
      <div className='loan-details-top-bar'>
        <div className='loan-info'>
          <span className='loan-borrower-name'>APPROVAL | </span>
          <span className='loan-address'>LOAN DETAILS</span>
        </div>
        <div className='close-button-container' onClick={onClose}>
          CLOSE X
        </div>
      </div>

      <div className='loan-details-first-row'>
        <div className='loan-progress'>
          <div className='loan-address'>
            {loanSelected.name}
          </div>
        </div>
      </div>

      <div className='loan-details-box-change-terms'>
        <img src={warningIcon} alt="icon" />
        <span>
          These terms are subject to change based on information you submit throughout the loan process. Well reach out if there any changes.
        </span>
      </div>

      <div className='loan-details-main'>
        <div className='left-column'>
          <div className='loan-summary'>
            <div className='closing'>
              {
                closingDate ?
                  <>
                    {
                      closingScheduled ?
                        <div className='label'>Sch. closing</div>
                        :
                        <div className='label'>Est. closing</div>
                    }
                    <div className='calendar'>
                      <div className='date'>
                        {closingDate.monthYear}
                      </div>
                      <div className='day'>
                        {closingDate.day}
                      </div>
                    </div>
                    {
                      closingScheduled ?
                        <div className='caption'>Closing <b>IS</b> scheduled</div>
                        :
                        <div className='caption'>Closing <b>NOT</b> scheduled</div>
                    }
                  </>
                  :
                  <>
                    <div className='label'>Est. closing</div>
                    <div className='calendar disabled'>
                      <div className='date'>
                        PENDING
                      </div>
                      <div className='day'>
                        00
                      </div>
                    </div>
                    <div className='caption'>Closing <b>NOT</b> scheduled</div>
                  </>
              }
            </div>
            <ContentBox {...contactData(loanSelected, setTaskOpenModal, tasks)} />
          </div>
          <LoanOption {...{
            rate: loanSelected?.loanData?.selectedOption?.rate,
            loanTerm: loanSelected?.loanData?.selectedOption?.loanTerm,
            monthlyPayment: formatterCurrency(loanSelected?.loanData?.selectedOption?.monthlyPayment),
            totalLoanAmount: formatterCurrency(loanSelected?.loanData?.selectedOption?.totalLoanAmount),
            ltv: loanSelected?.loanData?.selectedOption?.ltv,
          }} />
          {contentBoxes(loanSelected, setTaskOpenModal).map((box) => (
            <ContentBox
              key={box.label}
              label={box.label}
              content={box.content}
            />
          ))}
          {/* <div className='content-box'>
            <div className='label'>
              Notes
            </div>
            <div className='content'>
              <ReactQuill
                value={loanSelected?.salesforceData?.notes || ''}
                readOnly={true}
                theme={'bubble'}
                style={{
                  height: 420,
                  display: loanSelected?.salesforceData?.notes ? 'block' : 'none'
                }}
              />
            </div>
          </div> */}
        </div><div className='right-column'>
          {
            additionalContentBoxes(loanSelected, anotherDocs, setTaskOpenModal).map((box) => (
              <ContentBox
                key={box.label}
                label={box.label}
                // @ts-ignore
                content={box.content}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

/* Content Box */
type Item = { field: string, value?: string | React.ReactNode, extra?: Item }
interface IContentBoxProps {
  label: string;
  content: Item[];
}

const ContentBox: React.FC<IContentBoxProps> = (props) => {
  const { label, content } = props;
  if (!Array.isArray(content)) {
    return (
      <div className='content-box'>
        <div className='label'>
          {label}
        </div>
        <div className='content'>
          {content}
        </div>
      </div>
    )
  }
  const termSheetContent = content.filter(item => item.field === 'Term sheet');
  const appraisalContent = content.filter(item => item.field !== 'Pre-approval letter' &&
    item.field !== 'Closing documents' && item.field !== 'Term sheet')
  const closingContent = content.filter(item => item.field === 'Pre-approval letter' ||
    item.field === 'Closing documents')

  return (
    <div className='content-box'>
      <div className='label'>
        {label}
      </div>
      {termSheetContent.length > 0 && (
        <div className='content'>
          {termSheetContent.map(({ field, value, extra }, index) => (
            <div key={index} className='item'>
              <div className='item-row'>
                <div className={`field`}>{field}</div>
                <div className='value'>{value}</div>
              </div>
              {extra && (
                <div className='item-row extra'>
                  <div className='field'>{extra.field}</div>
                  <div className='value'>{extra.value}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <br />
      {appraisalContent.length > 0 && (
        <div className='content'>
          {appraisalContent.map(({ field, value, extra }, index) => (
            <div key={index} className='item'>
              <div className='item-row'>
                <div className={`field`}>{field}</div>
                <div className='value'>{value}</div>
              </div>
              {extra && (
                <div className='item-row extra'>
                  <div className='field'>{extra.field}</div>
                  <div className='value'>{extra.value}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <br />
      {closingContent.length > 0 && (
        <div className='content'>
          {closingContent.map(({ field, value, extra }, index) => (
            <div key={index} className='item'>
              <div className='item-row'>
                <div className={`field`}>{field}</div>
                <div className='value'>{value}</div>
              </div>
              {extra && (
                <div className='item-row extra'>
                  <div className='field'>{extra.field}</div>
                  <div className='value'>{extra.value}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const LoanDetailsModal = (props: any) => {
  const { openSectionRight: visible, closeModal } = props;

  return (
    <div className="cont-modal">
      {/* {props.renderButtonTrigger(props.handlerSetVisibile)} */}
      {props.visible && (
        <div className="ModalTask">
          <Button
            className="button-close-mask"
            style={{ width: visible ? '5%' : '30%' }}
            onClick={closeModal}
          />
          <div
            className={`ModalTask-content animate__animated animate__fadeInRight`}
            style={{ width: visible ? '60%' : '70%', overflow: 'auto' }}
          >
            {props.children}
          </div>
        </div>
      )}
    </div>
  );
}

export default LoanDetails;