import React from "react";
import { Button } from "antd";
import logoPng from "assets/logo.png";

const Header = () => {

  const redirectSignup = () => {
    window.location.href = 'https://sbo.constlending.com/';
  }

  return (
    <div className="Header">
      <img src={logoPng} alt="logo" className="logo-const" />
      <Button className="button button-secondary" onClick={redirectSignup}>
        Sign Up
      </Button>
    </div>
  );
};

export default Header;
