import React, { useState } from 'react';
import CardEstimatedDSCR from './CardEstimatedDSCR/CardEstimatedDSCR';
import { Loan, Task } from 'gql/graphql';

interface Step1Props {
  setStep: Function;
  task: Task | any;
}
const Step1 = ({ setStep, task }: Step1Props) => {
  return (
    <section>
      <h3 className="h3">
        You estimated your DSCR when you started your loan application. Let’s
        get some documentation for it
        <br></br>
        <br></br>
      </h3>
      <h2 className="h2">First, are these estimations correct?</h2>
      <div className="">
        <CardEstimatedDSCR setStep={setStep} task={task} />
      </div>
    </section>
  );
};
export default Step1;
