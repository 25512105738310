import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskResponse } from 'gql/graphql';
import { CardLoanTasksProps } from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import TrackRecord from './TrackRecord';
import { ModuleKeys } from 'services/task/modules-keys.constanst';

interface Props {
  tasks?: Task[] | any[],
  loan: Loan | any
}

const useCardTrackRecord = ({ tasks, loan }: Props) => {
  const [dataTask, setDataTask] = useState<Task>();
  const [stepNow, setStepNow] = useState<TaskResponse>();

  useEffect(() => {
    if (tasks) {
      const data: Task = tasks.find((item) => item.key === ModuleKeys.TRACK_RECORD);
      setDataTask(data);

      if (data?.responses.length > 0)
        setStepNow(data?.responses.reduce((max, obj) => obj.step > max.step ? obj : max, data?.responses[0]));
    }
  }, [tasks]);

  const card: CardLoanTasksProps =
  {
    key: ModuleKeys.TRACK_RECORD,
    title: <h4 className="h4">Past Investment Experience</h4>,
    type: 'quick task',
    steps: 1,
    stepCurrent: stepNow?.step || 0,
    state: dataTask?.state,
    actions: dataTask ? [<TrackRecord key={1} task={dataTask} />] : [],
    text: (
      <p className="body-card">
        I’ve purchased/sold{' '}
        <span className="body-card-bold-line">
          {loan?.investmentExperience?.properties === 'none' ? 'no' : loan?.investmentExperience?.properties}
        </span>
        {' '}real estate properties in the past 24 months
      </p>
    )
    
  };

  if(!dataTask) 
    return { card: null };
  
  return {
    card,
    dataTask
  };
};

export default useCardTrackRecord;
