import React, { useContext, useEffect, useState } from 'react';
import { capitalizeNames, formatterPhone as utilsFormatterPhone } from '../../../../../../@common/utils/formatters/formatter';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import {
	Task,
	TaskResponse,
	TaskState
} from '../../../../../../gql/graphql';
import { TaskContext } from '../Payoff';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import { Form } from 'antd';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import ButtonSubmit from 'components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';

interface IForm {
	titleCompany: string;
	name: string;
	phone: string;
	email: string;
}

interface Props {
	setModalFinishTask: any;
}

const CardLender = ({ setModalFinishTask }: Props) => {
	const [form] = Form.useForm();
	const task: Task | any = useContext(TaskContext);

	const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
		variables: { findResponsesByTask: { taskId: task._id } }
	});
	const { fetching, execute: createResponseTask } = useCreateResponseTask();

	const [emailError, setEmailError] = useState<boolean>(false);
	const [responseLender, setResponseLender] = useState<TaskResponse | any>();

	useEffect(() => {
		const _response = taskResponses?.find((item) => item.step === 2 && item.key === 'payoff-lender');
		setResponseLender(_response);
	}, [taskResponses]);

	useEffect(() => {
		if (responseLender && responseLender.state !== TaskState.RequestError)
			form.setFieldsValue(responseLender.metadata);
	}, [responseLender])

	const formatterPhone = (e: any) => {
		form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
	};

	const onFinish = async (values: IForm) => {
		values.name = capitalizeNames(values.name);

		await createResponseTask({
			_id: responseLender?._id,
			task: task._id,
			key: `payoff-lender`,
			label: `Current Lender`,
			step: 2,
			metadata: values,
			state: TaskState.Review
		});

		getTaskResponses({ requestPolicy: 'network-only' });
		setModalFinishTask(true);
	}

	return (
		<div className={`title-company-step-1 title-company-step-1-${responseLender?.state}`}>

			{responseLender?.state && responseLender?.state !== TaskState.Review && <StateTag state={responseLender?.state} />}
			{responseLender?.state && responseLender?.state === TaskState.Review && <StateTag state={responseLender?.state} isLabelLocked />}

			<Form
				name="edit"
				autoComplete="off"
				requiredMark="optional"
				colon={false}
				form={form}
				onFinish={onFinish}
				disabled={responseLender && responseLender?.state !== TaskState.RequestError}
			>
				<Form.Item
					label=""
					name="lenderName"
					className="titleCompany"
					rules={[{ required: true, message: '' }]}
				>
					<InputBorderNone
						placeholder="Lender name"
						className="bold"
					/>
				</Form.Item>
				<Form.Item
					label="Contact name (if different from lender)"
					name="name"
					className="name"
					rules={[{ required: true, message: '' }]}
				>
					<InputBorderNone
						placeholder="Edgar"
						height={20}
						className="bold-lith"
					/>
				</Form.Item>

				<Form.Item
					label="Phone number"
					name="phone"
					className="phone"
					rules={[
						{ required: true, message: '' },
						{ min: 12, message: '' }
					]}
				>
					<InputBorderNone
						placeholder="329-230-2390"
						height={20}
						className="bold-lith"
						onChange={formatterPhone}
					/>
				</Form.Item>

				<Form.Item
					label="Email address"
					name="email"
					className="email"
					rules={[{ required: true, type: 'email', message: ' ' }]}
				>
					<InputBorderNone
						type="email"
						placeholder="ricardo@mervetscoolbiz.com"
						height={20}
						color={emailError ? 'error' : 'bold-lith'}
            onBlur={(e: any) => setEmailError(e.target.value && form.getFieldError('email').length > 0)}
					/>
				</Form.Item>

				<Form.Item className="submit" shouldUpdate>
					{() => (
						<ButtonSubmit
							disabled={
								responseLender?.state === TaskState.Review ||
								!form.getFieldsError().every((fieldErr) => fieldErr.errors.length === 0) ||
								!form.isFieldsTouched() ||
								!Object.values(form.getFieldsValue()).every(value => value)
							}
							loading={fetching}
							modalConfirm={{ show: true }}
							onClick={form.submit}
						>
							Submit for review
						</ButtonSubmit>
					)}
				</Form.Item>
			</Form>
		</div>
	);
};

export default CardLender;