import React, { createContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from './Dashboard/Dashboard';
import Entities from './Entities/Entities';
import Loans from './Loans/Loans';
import Profile from './Profile/Profile';
import ChangePassword from 'scenes/Public/ChangePassword/ChangePassword';
import { useGetUserLoggin } from 'services/user/querys';
import NotFound from 'scenes/404';

export const EntitiesViewContext = createContext<{ visible: boolean; setHandlerVisible: any } | {}>({});
export const LoansViewContext = createContext<{ visible: boolean; setHandlerVisible: any } | {}>({});
export const ProfileViewContext = createContext<{ visible: boolean; setHandlerVisible: any } | {}>({});
export const TaskOpenModalContext = createContext<{ value: string; setHandler: any } | {}>({});

const Private = () => {

  const { error } = useGetUserLoggin();

  const [taskOpenModal, setTaskOpenModal] = useState<any>();
  const [entitiesView, setEntitiesView] = useState<boolean>(false);
  const [loansView, setLoansView] = useState<boolean>(false);
  const [profileView, setProfile] = useState<boolean>(false);

  useEffect(() => {
    if(error?.message.includes('Unauthorized'))
      logout();
  }, [error]);

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
  }

  return (
    <EntitiesViewContext.Provider value={{ visible: entitiesView, setHandlerVisible: setEntitiesView }}>
      <LoansViewContext.Provider value={{ visible: loansView, setHandlerVisible: setLoansView }}>
        <ProfileViewContext.Provider value={{ visible: profileView, setHandlerVisible: setProfile }}>
          <TaskOpenModalContext.Provider value={{ value: taskOpenModal, setHandler: setTaskOpenModal }}>
            <div className="Private">
              <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/home/:loanId" element={<Dashboard />} />
                <Route path="/change-password/:token" element={<ChangePassword />} />
              </Routes>
              { entitiesView && <Entities /> }
              { loansView && <Loans /> }
              <Profile />
            </div>
          </TaskOpenModalContext.Provider>
        </ProfileViewContext.Provider>
      </LoansViewContext.Provider>
    </EntitiesViewContext.Provider>
  );
};
export default Private;
