import React, { useEffect, useRef, useState, useContext } from 'react';
import { Form, Button } from 'antd';
import InputAmount from 'components/Atoms/Inputs/InputAmount/InputAmount';
import { TaskResponse, TaskState } from 'gql/graphql';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import InputBorderNone from 'components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import { TaskContext } from '../../DscrQuestionsAgreements';

const CardMarketRent = ({ vacantUnits, setVacantUnits, totalUnits, setTotalUnits, saveResponse, task }: any) => {
  const [form] = Form.useForm();
  // @ts-ignore
  const { totalRents, setTotalRents } = useContext(TaskContext);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [response, setResponse] = useState<TaskResponse | any>();
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [editedFields, setEditedFields] = useState<{ [key: string]: boolean }>({});

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.key === 'market-rent');
    setResponse(_response);

    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  useEffect(() => {
    if (response?.metadata) {
      const storedRents = response.metadata?.rentsAndUnitNumbers?.map((item: any) => item.rent) || [];
      const storedUnitNumbers = response.metadata?.rentsAndUnitNumbers?.map((item: any) => item.unitNumber) || [];

      if (storedRents.length > 0 || storedUnitNumbers.length > 0) {
        form.setFieldsValue({
          rents: storedRents,
          unitNumbers: storedUnitNumbers
        })
        setTotalRents(storedRents.reduce((acc: number, rent: number) => acc + rent, 0));
      }
    }
  }, [response, taskResponses, form]);

  const onFinish = (values: any) => {
    const rentsAndUnitNumbers = values.rents.map((rent: number, index: number) => ({
      rent: rent,
      unitNumber: values.unitNumbers[index]
    }));
    saveResponse(
      'market-rent',
      'Market rent',
      {
        totalUnits: totalUnits,
        vacantUnits: vacantUnits,
        rentsAndUnitNumbers: rentsAndUnitNumbers
      },
      TaskState.Review
    )
    getTaskResponses({ requestPolicy: 'network-only' });
  }

  const handleVacantUnitsChange = (value: number) => {
    if (value > totalUnits) {
      setVacantUnits(value);
      setTotalUnits(value);
    } else {
      setVacantUnits(value);
    }

    const existingRents = form.getFieldValue('rents') || [];
    const existingUnits = form.getFieldValue('unitNumbers') || [];

    form.setFieldsValue({
      rents: [...existingRents, ...Array(Math.max(value - existingRents.length, 0)).fill('')],
      unitNumbers: [...existingUnits, ...Array(Math.max(value - existingUnits.length, 0)).fill('')],
    });

    setIsFormTouched(false);
    setTotalRents(existingRents.reduce((acc: number, rent: number) => acc + (rent || 0), 0));
  };

  const isFormValid = () => {
    const isTouched = form.isFieldsTouched(true) && isFormTouched;
    const hasErrors = form.getFieldsError().some((field) => field.errors.length > 0);
    const hasValues = Object.values(form.getFieldsValue()).every((value) => value !== undefined && value !== '');
    return isTouched && !hasErrors && hasValues;
  };

  const handleFieldChange = (fieldName: string, index: number) => {
    setEditedFields((prev) => ({
      ...prev,
      [`${fieldName}-${index}`]: true,
    }));
  };


  return (
    <blockquote
      className={`CardMarketRent CardMarketRent-${response?.state}`}
    >
      {response?.state && response?.state !== TaskState.Review && <StateTag state={response?.state} />}
      {response?.state && response?.state === TaskState.Review && <StateTag state={response?.state} isLabelLocked />}

      <div className='mt-5'>
        <InputAmount
          title="How many units are vacant?"
          type="edit"
          text={null}
          value={vacantUnits}
          onChange={handleVacantUnitsChange}
          className="InputAmount-edit"
          disabled={response?.state === TaskState.Review || response?.state === TaskState.Approved}
        />
        <article className="flex-btw">
          <div>
            <p className="body-card">
              Enter market rents for each of the vacant units
            </p>
            <h3 className="h4" style={{ marginTop: '15px' }}>
              Market rent for vacant units
            </h3>
          </div>
          <p className="h4 h4-w4" style={{ marginTop: '33px', color: '#404859' }}>
            Total{' '}
            <span className="bold" style={{ color: '#929FB0' }}>
              {formatCurrency(totalRents || 0)}
            </span>
          </p>
        </article>
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        disabled={response?.state === TaskState.Review || response?.state === TaskState.Approved}
        onFieldsChange={async () => {
          setIsFormTouched(true);

          const rents = form.getFieldValue('rents') || [];
          const newTotalRents = rents.reduce((acc: number, rent: any) => {
            const numericRent = parseFloat(rent);
            return acc + (isNaN(numericRent) ? 0 : numericRent);
          }, 0);

          setTotalRents(newTotalRents);
        }}
      >
        <article className="ant-form">
          {Array.from({ length: vacantUnits }).map((_, index) => (
            <div className="form-row" key={index}>
              <span className="body-regular" style={{ marginRight: '4px' }}>
                Unit number
              </span>
              <Form.Item
                name={['unitNumbers', index]}
                rules={[{ required: true, message: 'Unit number is required' }]}
                className={`unit-input ${editedFields[`unitNumbers-${index}`] ? 'bold' : ''}`}
              >
                <InputBorderNone
                  placeholder={`${101 + index}`}
                  type="number"
                  // className={`unit-input ${editedFields[`unitNumbers-${index}`] ? 'bold' : ''}`}
                  onChange={() => handleFieldChange('unitNumbers', index)}
                />
              </Form.Item>
              <div className="rent-container">
                <Form.Item
                  name={['rents', index]}
                  rules={[{ required: true, message: 'Rent is required' }]}
                  className={`rent-input ${editedFields[`rents-${index}`] ? 'bold' : ''}`}
                >
                  <InputBorderNone
                    placeholder="$2000"
                    type="number"
                    formatter="currency"
                    className={`rent-input ${editedFields[`rents-${index}`] ? 'bold' : ''}`}
                    onChange={() => handleFieldChange('rents', index)}
                  />
                </Form.Item>
                <span className="month">/month</span>
              </div>
            </div>
          ))}
        </article>
        <Button
          id={'rent-button'}
          className={`button ${isFormValid() ? 'button-submit' : 'button-grey'}`}
          htmlType="submit"
          disabled={!isFormValid() || response?.state === 'REVIEW'}
        >
          Add market rent for {vacantUnits} units to submit
        </Button>
      </Form>
    </blockquote>
  );
};

interface CurrencyInputProps {
  value: number;
  onChange: (value: number) => void;
  className?: string;
  moveCursorToEnd?: boolean;
  disabled?: boolean;
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(value);
};

export const CurrencyInput: React.FC<CurrencyInputProps> = ({ value, onChange, className, moveCursorToEnd = true, disabled = false }) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [edited, setEdited] = useState(false);
  const setCursorToEnd = (element: HTMLSpanElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerText = formatCurrency(value);
      if (edited) {
        spanRef.current.className = 'bold';
      }
    }
  }, [value, edited]);

  const handleInput = (event: React.FormEvent<HTMLSpanElement>) => {
    if (disabled) return;
    const newValue = parseInt(event.currentTarget.innerText.replace(/[^0-9]/g, ''), 10) || 0;
    onChange(newValue);
    setEdited(true);
    event.stopPropagation();
  };

  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.innerText = formatCurrency(value);
      if (moveCursorToEnd && document.activeElement === spanRef.current) {
        setCursorToEnd(spanRef.current);
      }
    }
  }, [value]);

  const classNameFactory = (className: string) => {
    return `${className} body-regular body-regular-italic`;
  }

  return (
    <span
      contentEditable={!disabled}
      suppressContentEditableWarning
      onInput={handleInput}
      className={classNameFactory(className || '')}
      style={{ color: disabled ? '#2A2F3C' : '#929fb0', fontWeight: disabled ? '600' : '400' }}
      ref={spanRef}
    >
      {formatCurrency(value)}
    </span>
  );
};

export default CardMarketRent;
