import React from 'react';
import { Link } from 'react-router-dom';
import avatarSvg from 'assets/images/avatar.png';

interface CardProfileProps {
  avatar: string | '';
  name: string | '';
  email: string | '';
  phone: string | '';
}

const CardProfile = ({ avatar, name, phone, email }: CardProfileProps) => {
  return (
    <section className="CardProfile">
      <div className="CardProfile__content">
        <img src={avatar} className="avatar" alt="avatar" />
        <Link className="link" to="./">
          Loan officer
        </Link>
        <span className="text">YOUR MAIN CONTACT</span>
        <p className="text text-bold">{name}</p>
        <div className="CardProfile__content--bottom">
          <Link to="./" className="text text-small">
            {email}
          </Link>
          <span className="text text-small">{phone}</span>
        </div>
      </div>
    </section>
  );
};
export default CardProfile;
